import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link, useLocation } from 'react-router-dom';
import 'tippy.js/dist/tippy.css';
import { DXAccountContext } from '../../../../../../contexts/DXAccountContext';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import closeBtn from '../../../../../../images/svg/close.svg';
import infoCircle from '../../../../../../images/svg/iconography-16x16/info-circle.svg';
import Heart from '../../../../../../images/svg/icons/Heart';
import HeartOutline from '../../../../../../images/svg/icons/HeartOutline';
import nextBtn from '../../../../../../images/svg/next-btn.svg';
import prevBtn from '../../../../../../images/svg/prev-btn.svg';
import { getBaseAwsApiUrl } from '../../../../../../utils/config';
import OptimizedImage from '../../../../OptimizedImage/OptimizedImage';
import { formatNumberWithCommas } from '../../../utils/formatters';
import { modelNameTransformer } from '../../../utils/modelNameTransformer';
import aspectRatioSizer from '../aspect-ratio-sizer.gif';
import { HomeCardStyles } from './HomeCard.styled';

const HomeCard = ({
  homeObject,
  useSlideshow = true,
  showFavoritingHeart,
  showStickers = true,
  showSpecs = true,
  showPrice = true,
  maxSlides = 5,
  className,
  handleClick,
  cardLinkState,
  noSwipe,
  hideSaleSticker,
}) => {
  const accountContext = useContext(DXAccountContext);
  const singleImageRef = useRef(null);
  const [singleImageRefVisible, setSingleImageRefVisible] = useState(false);
  const canUseDOM = typeof window !== 'undefined';
  const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;
  useIsomorphicLayoutEffect(() => {
    if (!singleImageRefVisible) {
      return;
    }
  }, [singleImageRefVisible]);
  const [width, height] = useWindowSize();
  const location = useLocation();
  const redirectPath = location.pathname;

  const finalCardLinkState = {
    prevPath: location.pathname,
    ...cardLinkState,
  };

  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const showTooltip = () => setVisibleTooltip(true);
  const hideTooltip = () => setVisibleTooltip(false);
  let urlOverride = `${getBaseAwsApiUrl()}images/`;
  const isMobile = width <= 1023;

  let altText =
    homeObject && homeObject.description
      ? modelNameTransformer(homeObject.description)
      : '';

  //For homeObjects that are not a straight up string description ("Save your favorite homes")
  if (homeObject && typeof homeObject.altTextDescription !== 'undefined') {
    altText = homeObject.altTextDescription;
  }

  const arrowStyles = {};

  const favoriteCheck = (home) => {
    let favCheck = accountContext?.state?.favorites.some((element) => {
      if (
        element?.modelNumber === home?.modelNumber &&
        element?.serialNumber === home?.serialNumber
      ) {
        return true;
      } else {
        return false;
      }
    });

    return favCheck;
  };

  return (
    <HomeCardStyles className={className}>
      <div className="card-image-area">
        <img src={aspectRatioSizer} alt="" className="aspect-ratio-sizer" />
        {useSlideshow && homeObject.images && homeObject.images.length > 1 ? (
          <Carousel
            showArrows={isMobile ? false : true}
            autoPlay={false}
            showIndicators={true}
            showStatus={false}
            showThumbs={false}
            swipeable={!noSwipe}
            emulateTouch={true}
            infiniteLoop={true}
            isFocusWithinTheCarousel={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  className="slider-btns slider-btn-prev"
                >
                  <img src={prevBtn} alt="prev slide" />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{ ...arrowStyles, right: 15 }}
                  className="slider-btns slider-btn-next"
                >
                  <img src={nextBtn} alt="next slide" />
                </button>
              )
            }
          >
            {homeObject.images.slice(0, maxSlides).map((image, i) => {
              return (
                <div className="slide" key={i}>
                  <OptimizedImage
                    src={image?.source}
                    srcSet={[
                      { source: `${image?.reference}?fm=webp&width=400` },
                      {
                        source: `${image?.reference}?fm=webp&width=550`,
                        minWidth: '500px',
                      },
                    ]}
                    alt={
                      image?.caption ||
                      modelNameTransformer(homeObject?.description)
                    }
                    imgClass={`${
                      image.imageTypeAcronym.toLowerCase() === 'flp'
                        ? 'fill-container landscape flp'
                        : 'fill-container landscape'
                    }`}
                    lazy
                  />

                  <Link
                    tabIndex="-1"
                    className="slide-link"
                    to={{
                      pathname: homeObject.cardLink.pathname,
                      state: cardLinkState,
                    }}
                    onClick={handleClick}
                  ></Link>
                </div>
              );
            })}
          </Carousel>
        ) : (
          <div className="card-image-single">
            {homeObject.images && homeObject.images[0].reference && (
              <picture>
                <source
                  srcSet={
                    homeObject.images[0].reference.includes('http')
                      ? homeObject.images[0].reference + '?fm=webp&width=550'
                      : urlOverride +
                        homeObject.images[0].reference +
                        '?fm=webp&width=550'
                  }
                  media="(min-width: 500px)"
                />
                <source
                  srcSet={
                    homeObject.images[0].reference.includes('http')
                      ? homeObject.images[0].reference + '?fm=webp&width=350'
                      : urlOverride +
                        homeObject.images[0].reference +
                        '?fm=webp&width=350'
                  }
                  media="(min-width: 1px)"
                />
                <img
                  src={
                    homeObject.images[0].reference.includes('http')
                      ? homeObject.images[0].reference + '?width=550'
                      : urlOverride +
                        homeObject.images[0].reference +
                        '?width=550'
                  }
                  alt={
                    homeObject && homeObject.images[0].caption
                      ? homeObject.images[0].caption
                      : altText
                  }
                  ref={(e) => {
                    singleImageRef.current = e;
                    setSingleImageRefVisible(!!e);
                  }}
                  className={
                    singleImageRef &&
                    singleImageRef.current &&
                    singleImageRef.current.naturalHeight >
                      singleImageRef.current.naturalWidth
                      ? 'img-vertical'
                      : homeObject.images[0].imageTypeAcronym.toLowerCase() ===
                        'flp'
                      ? 'flp'
                      : 'img-horizontal'
                  }
                  loading="lazy"
                />
              </picture>
            )}

            <Link
              className="slide-link"
              to={{
                pathname: homeObject.cardLink.pathname,
                state: cardLinkState,
              }}
              onClick={handleClick}
            ></Link>
          </div>
        )}
        {showStickers &&
          homeObject &&
          !homeObject.isLand &&
          homeObject.stickers &&
          homeObject.stickers.length > 0 && (
            <div className="sticker-container">
              {homeObject.stickers.map((sticker, i) => {
                if (hideSaleSticker && sticker?.title == 'SALE') {
                  return null;
                }
                return typeof sticker !== 'undefined' && sticker.title ? (
                  <div
                    className={
                      sticker.className
                        ? 'sticker label ' + sticker.className
                        : 'sticker label'
                    }
                    key={i}
                  >
                    <div className="in-stock-dot">&nbsp;</div> {sticker.title}
                  </div>
                ) : null;
              })}
            </div>
          )}

        {showFavoritingHeart && (
          <div className="favoriting-heart-container">
            {accountContext?.state.isAuthenticated ? (
              <span
                className="favorite-button"
                onClick={() =>
                  accountContext.actions.toggleFavorite(
                    homeObject?.modelNumber,
                    homeObject?.serialNumber
                  )
                }
              >
                {favoriteCheck(homeObject) ? <Heart /> : <HeartOutline />}
              </span>
            ) : (
              <Link
                className="favorite-button"
                to={{
                  pathname: '/myhome-account-login',
                  state: {
                    redirectPage: redirectPath,
                    queuedFavorite: {
                      modelNumber: homeObject?.modelNumber,
                      serialNumber: homeObject?.serialNumber,
                    },
                  },
                }}
              >
                <HeartOutline />
              </Link>
            )}
          </div>
        )}
      </div>

      <div className="card-info-area">
        <div className="card-info-left">
          {homeObject &&
            homeObject.descriptionOverride &&
            (homeObject.isLand ||
              homeObject.isOnThirdPartyLand ||
              homeObject.isOnLand) &&
            (homeObject.address1 !== '' && homeObject.address1 !== null ? (
              homeObject.descriptionOverride()
            ) : (
              <p className="h5 card-heading">
                {modelNameTransformer(homeObject.description)}
              </p>
            ))}
          {homeObject &&
            homeObject.description &&
            !homeObject.isLand &&
            !homeObject.isOnLand &&
            !homeObject.isOnThirdPartyLand && (
              <p className="h5 card-heading">
                {modelNameTransformer(homeObject.description)}
              </p>
            )}

          {showSpecs ? (
            <p className="card-home-specs caption">
              {homeObject && homeObject.beds && (
                <span className="wordspan">
                  {homeObject.beds > 1
                    ? homeObject.beds + ' beds'
                    : homeObject.beds + ' bed'}
                </span>
              )}
              {homeObject && homeObject.baths && (
                <>
                  <span className="bullet">&nbsp;&bull;&nbsp;</span>
                  <span className="wordspan">
                    {homeObject.baths > 1
                      ? homeObject.baths + ' baths'
                      : homeObject.baths + ' bath'}
                  </span>
                </>
              )}
              {homeObject && homeObject.squareFeet && (
                <>
                  <span className="bullet">&nbsp;&bull;&nbsp;</span>
                  <span className="wordspan">
                    {homeObject.squareFeet
                      ? formatNumberWithCommas(homeObject.squareFeet) +
                        ' sq. ft.'
                      : null}
                  </span>
                </>
              )}
              {homeObject && homeObject.acres > 0 && (
                <>
                  {(homeObject.beds ||
                    homeObject.baths ||
                    homeObject.squareFeet) && (
                    <span className="bullet">&nbsp;&bull;&nbsp;</span>
                  )}
                  <span className="wordspan">
                    {homeObject.acres == 1
                      ? homeObject.acres + ' acre'
                      : homeObject.acres + ' acres'}
                  </span>
                </>
              )}
            </p>
          ) : null}
          <Link
            className="card-info-link"
            aria-label="home link"
            to={{
              pathname: homeObject.cardLink.pathname,
              state: cardLinkState,
            }}
            onClick={handleClick}
          ></Link>
        </div>

        <Tippy
          interactive
          theme="clayton"
          visible={visibleTooltip}
          onClickOutside={hideTooltip}
          placement="top-end"
          maxWidth={width > 1024 ? '373px' : '305px'}
          content={
            <>
              <img
                className="close-btn"
                onClick={visibleTooltip ? hideTooltip : showTooltip}
                src={closeBtn}
                alt="close tooltip"
              />
              <p>
                Base model starting prices do not include available options,
                required delivery & installation or taxes. Installed price will
                be higher. See home details for pricing details and home
                information.
              </p>
            </>
          }
        >
          <div
            className="price-tooltip"
            onClick={
              homeObject &&
              homeObject.price &&
              !homeObject.price.includes('Contact Us')
                ? visibleTooltip
                  ? hideTooltip
                  : showTooltip
                : null
            }
          >
            {showPrice &&
              homeObject &&
              homeObject.price &&
              !homeObject.priceOverride && (
                <div className="price">
                  {homeObject.price}
                  <img
                    className="info-circle"
                    src={infoCircle}
                    alt="info-circle"
                  />
                </div>
              )}

            {showPrice && homeObject && homeObject.priceOverride && (
              <>{homeObject.priceOverride()}</>
            )}
          </div>
        </Tippy>
      </div>
    </HomeCardStyles>
  );
};

HomeCard.propTypes = {
  homeObject: PropTypes.shape({
    modelId: PropTypes.number,
    modelNumber: PropTypes.string,
    description: PropTypes.string,
    baths: PropTypes.number,
    beds: PropTypes.number,
    squareFeet: PropTypes.number,
    price: PropTypes.string,
    priceOverride: PropTypes.func,
    altTextDescription: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.object),
    cardLink: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      state: PropTypes.shape({}),
    }),
  }),

  useSlideshow: PropTypes.bool,
  showFavoritingHeart: PropTypes.bool,
  stickers: PropTypes.array,
  showStickers: PropTypes.bool,
  showSpecs: PropTypes.bool,
  showPrice: PropTypes.bool,
  isFavorited: PropTypes.bool,
  handleFavoriteClick: PropTypes.func,
};

export default HomeCard;

import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const FeaturedEventStyles = styled.section`
  position: relative;
  margin: 213px 0 120px;
  margin: ${(props) =>
    props.isSpecialOffers ? '132px 0 120px' : '213px 0 120px'};
  @media only screen and (max-width: 921px) {
    margin: 115px 0 69px;
  }
  @media only screen and (max-width: 553px) {
    margin: 100px 0;
  }
  .container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    padding: 0px 30px;
    @media only screen and (max-width: 921px) {
      max-width: 509px;
    }
    @media only screen and (max-width: 553px) {
      padding: 0px 22px;
    }
    .image-box {
      position: relative;
      width: 100%;
      max-width: 495px;
      height: 656px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 921px) {
        max-width: none;
        height: auto;
        margin-bottom: 40px;
      }

      span,
      img {
        max-height: 100%;
        max-width: 100%;
      }
      /* img {
        object-fit: cover;
      } */
    }
    .content-wrap {
      position: relative;
      padding-left: 40px;
      flex: 1 1 350px;
      @media only screen and (max-width: 921px) {
        padding-left: 0px;
      }
      .small-headline {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #777b7e;
        text-transform: uppercase;
        font-family: 'source-sans-pro';
      }
      .large-headline {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: -0.35px;
        text-align: left;
        margin-bottom: 6px;
        @media only screen and (max-width: 553px) {
          font-size: 34px;
          line-height: 40px;
          letter-spacing: -0.3px;
        }
      }
      .content {
        margin-top: 30px;
        font-family: source-sans-pro, sans-serif;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #4e5255;
      }
    }
    .cta-button {
      background: ${Colors.primary.claytonBlue.standard};
      color: white;
      border-radius: 2px;
      padding: 17px 17px;
      font: 500 12px/100% 'source-sans-pro', sans-serif;
      margin-top: 30px;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.2s;
      text-transform: uppercase;
      font-weight: 700;
      display: inline-flex;
      align-self: center;
      &:hover {
        background: rgb(23, 158, 255);
      }
    }
  }
`;

export default FeaturedEventStyles;

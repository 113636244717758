import PropTypes from 'prop-types';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import nextBtn from '../../../images/svg/next-btn.svg';
import prevBtn from '../../../images/svg/prev-btn.svg';
import { fireSegmentEvent } from '../../../utils';
import SaleHomeTile from '../../CuratedCollections/SaleHomeTile/SaleHomeTile';
import SaleHomeSliderStyles from './SaleHomeSlider.styled';

export const SaleHomeSlider = ({
  largeHeadline,
  saleHomes,
  buttonText,
  buttonURL,
  aTagLink,
  gaClass,
  dealer,
  isPromo,
  retailEventLabel,
}) => {
  return (
    <SaleHomeSliderStyles className={isPromo ? 'promo-sale-homes-slider' : ''}>
      <div className="container">
        <h2 className={isPromo ? 'promo-headline headline' : 'headline'}>
          {largeHeadline}
        </h2>
        <Carousel
          className="sale-home-slider"
          showArrows={true}
          autoPlay={false}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          swipeable={false}
          emulateTouch={true}
          infiniteLoop={true}
          isFocusWithinTheCarousel={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="salehome-btn-prev slider-btns slider-btn-prev"
              >
                <img src={prevBtn} alt="next slide" />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="salehome-btn-next slider-btns slider-btn-next"
              >
                <img src={nextBtn} alt="next slide" />
              </button>
            )
          }
        >
          {saleHomes &&
            saleHomes.map((home, i) => (
              <div className="sale-home-slide" key={i}>
                <SaleHomeTile retailHomeObject={home} dealer={dealer} />
              </div>
            ))}
        </Carousel>
        <div className="cta-button-wrap">
          {buttonURL && buttonText && !aTagLink && (
            <Link
              to={buttonURL}
              className={'cta-button ' + (gaClass ? gaClass : '')}
              onClick={() => {
                fireSegmentEvent('CTA Clicked', {
                  isNav: false,
                  type: 'button',
                  text: buttonText ? buttonText : '',
                  eventContext: retailEventLabel ? retailEventLabel : '',
                });
              }}
            >
              {buttonText}
            </Link>
          )}
          {buttonURL && buttonText && aTagLink && (
            <a
              href={buttonURL}
              className={'cta-button ' + (gaClass ? gaClass : '')}
              onClick={() => {
                fireSegmentEvent('CTA Clicked', {
                  isNav: false,
                  type: 'button',
                  text: buttonText ? buttonText : '',
                  eventContext: retailEventLabel ? retailEventLabel : '',
                });
              }}
            >
              {buttonText}
            </a>
          )}
        </div>
      </div>
    </SaleHomeSliderStyles>
  );
};

SaleHomeSlider.props = {
  largeHeadline: PropTypes.string,
  saleHomes: PropTypes.array,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
  aTagLink: PropTypes.bool,
  gaClass: PropTypes.string,
  retailEventLabel: PropTypes.string,
};

export default SaleHomeSlider;

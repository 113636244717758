import React from 'react';
import styled from 'styled-components';

const HaveItMadeHouseConfirmation = ({}) => {
  const Logo = styled.svg`
    height: auto;

    @media only screen and (min-width: 320px) {
      max-width: 120px;
    }

    @media only screen and (min-width: 768px) {
      max-width: 150px;
    }

    @media only screen and (min-width: 1025px) {
      max-width: 157px;
      min-height: 154px;
    }
  `;

  return (
    <Logo
      className="have-it-made-house"
      viewBox="0 0 158 155"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M14.0683 44.3601H64.2957C67.6945 44.3601 70.338 41.5874 70.338 38.3105V6.54968C70.338 3.14672 67.5687 0.5 64.2957 0.5H14.0683C10.6694 0.5 8.02588 3.27278 8.02588 6.54968V38.1845C8.02588 41.5874 10.7954 44.3601 14.0683 44.3601Z"
        fill="#06292F"
      />
      <path
        d="M31.0627 41.8395C31.0627 41.8395 25.0204 44.9903 24.1392 50.0317C23.8874 51.166 22.3768 51.6703 21.6216 50.788C19.6074 48.7714 17.2156 44.7383 17.2156 37.3022"
        fill="#06292F"
      />
      <path
        d="M25.6497 26.3371C27.805 26.3371 29.5521 24.5879 29.5521 22.43C29.5521 20.2722 27.805 18.5229 25.6497 18.5229C23.4945 18.5229 21.7473 20.2722 21.7473 22.43C21.7473 24.5879 23.4945 26.3371 25.6497 26.3371Z"
        fill="white"
      />
      <path
        d="M39.2449 26.3371C41.4001 26.3371 43.1473 24.5879 43.1473 22.43C43.1473 20.2722 41.4002 18.5229 39.2449 18.5229C37.0897 18.5229 35.3425 20.2722 35.3425 22.43C35.3426 24.5879 37.0897 26.3371 39.2449 26.3371Z"
        fill="white"
      />
      <path
        d="M52.8404 26.3371C54.9956 26.3371 56.7428 24.5879 56.7428 22.43C56.7428 20.2722 54.9956 18.5229 52.8404 18.5229C50.6852 18.5229 48.938 20.2722 48.938 22.43C48.9381 24.5879 50.6852 26.3371 52.8404 26.3371Z"
        fill="white"
      />
      <path
        d="M36.9312 126.323C26.0006 128.345 15.2835 131.52 5.01466 135.776C2.59483 136.78 -0.190147 138.37 0.0102159 141.126C0.0998012 142.359 0.833757 143.456 1.84655 144.164C3.83156 145.552 6.23207 145.526 8.53688 145.482C30.7813 145.06 53.0257 144.638 75.27 144.216C77.1296 144.181 77.6915 146.744 75.9799 147.472C74.4806 148.11 73.0584 148.841 72.829 150.301C72.6796 151.252 73.1458 152.195 73.8972 152.797C75.1736 153.819 76.8018 153.891 78.3606 153.946C99.7484 154.7 121.415 155.43 142.298 150.749C145.939 149.933 149.637 148.91 152.65 146.71C155.664 144.509 157.891 140.886 157.472 137.178C157.125 134.105 155.025 131.442 152.457 129.72C149.888 127.997 146.874 127.082 143.889 126.274C115.263 118.518 85.1894 118.499 55.5308 118.532"
        fill="#06292F"
      />
      <path
        d="M125.404 66.9053H19.4158V69.9455H125.404V66.9053Z"
        fill="#06292F"
      />
      <path
        d="M98.9345 30.7583L50.3789 66.9054H19.4158L67.5806 30.7583H98.9345Z"
        fill="#06292F"
      />
      <path
        d="M94.7775 33.7983H67.7634V53.8981L52.1638 65.505V137.978H137.391V65.505L94.7775 33.7983Z"
        fill="#E2F7FF"
      />
      <path
        d="M67.7636 33.7983L25.1499 65.505V137.978H110.377V65.505L67.7636 33.7983Z"
        fill="white"
      />
      <path
        d="M79.3046 89.9023H56.2222V137.979H79.3046V89.9023Z"
        fill="#06292F"
      />
      <path
        d="M137.391 67.7437L99.1174 39.0197H101.795L94.7775 33.7983H67.7637V39.0197V39.0197L116.319 75.1667H137.391V67.7437Z"
        fill="#E2F7FF"
      />
      <path
        d="M67.7634 30.7583L116.319 66.9054H147.282L99.1173 30.7583H67.7634Z"
        fill="#51748B"
      />
      <path
        d="M67.7634 30.7583V33.7985L116.319 69.9455V66.9054L67.7634 30.7583Z"
        fill="#06292F"
      />
      <path
        d="M147.282 66.9053H116.319V69.9455H147.282V66.9053Z"
        fill="#06292F"
      />
      <path
        d="M25.1499 65.505V70.7433L67.7637 39.0197L110.377 70.7433V65.505L67.7637 33.7983L25.1499 65.505Z"
        fill="#E2F7FF"
      />
      <path
        d="M99.5214 92.4265H90.2703V112.591H99.5214V92.4265Z"
        fill="#E2F7FF"
      />
      <path
        d="M105.31 89.9023H99.5215V112.592H105.31V89.9023Z"
        fill="#06292F"
      />
      <path
        d="M90.2702 89.9023H84.4814V112.592H90.2702V89.9023Z"
        fill="#06292F"
      />
      <path
        d="M104.319 91.2637H100.487V100.528H104.319V91.2637Z"
        stroke="#51748B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.2919 91.2637H85.46V100.528H89.2919V91.2637Z"
        stroke="#51748B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.319 101.964H100.487V111.229H104.319V101.964Z"
        stroke="#51748B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.2919 101.964H85.46V111.229H89.2919V101.964Z"
        stroke="#51748B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.2703 104.564V89.9023H96.6961V98.1383C96.6961 101.687 93.8192 104.564 90.2703 104.564Z"
        fill="#51748B"
      />
      <path
        d="M99.5213 104.564V89.9023H93.0955V98.1383C93.0955 101.687 95.9724 104.564 99.5213 104.564Z"
        fill="#51748B"
      />
      <path
        d="M128.875 92.4265H119.624V112.591H128.875V92.4265Z"
        fill="white"
      />
      <path
        d="M134.664 89.9023H128.875V112.592H134.664V89.9023Z"
        fill="#06292F"
      />
      <path
        d="M119.624 89.9023H113.835V112.592H119.624V89.9023Z"
        fill="#06292F"
      />
      <path
        d="M133.673 91.2637H129.841V100.528H133.673V91.2637Z"
        stroke="#51748B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.645 91.2637H114.813V100.528H118.645V91.2637Z"
        stroke="#51748B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.673 101.964H129.841V111.229H133.673V101.964Z"
        stroke="#51748B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.645 101.964H114.813V111.229H118.645V101.964Z"
        stroke="#51748B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.624 104.564V89.9023H126.05V98.1383C126.05 101.687 123.173 104.564 119.624 104.564Z"
        fill="#51748B"
      />
      <path
        d="M128.875 104.564V89.9023H122.449V98.1383C122.449 101.687 125.326 104.564 128.875 104.564Z"
        fill="#51748B"
      />
      <path
        d="M45.4901 91.2637H36.239V112.591H45.4901V91.2637Z"
        fill="#E2F7FF"
      />
      <path
        d="M51.279 89.9023H45.4902V112.592H51.279V89.9023Z"
        fill="#06292F"
      />
      <path
        d="M36.239 89.9023H30.4502V112.592H36.239V89.9023Z"
        fill="#06292F"
      />
      <path
        d="M50.288 91.2637H46.4561V100.528H50.288V91.2637Z"
        stroke="#51748B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.2606 91.2637H31.4287V100.528H35.2606V91.2637Z"
        stroke="#51748B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.288 101.964H46.4561V111.229H50.288V101.964Z"
        stroke="#51748B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.2606 101.964H31.4287V111.229H35.2606V101.964Z"
        stroke="#51748B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.239 104.564V89.9023H42.6649V98.1383C42.6649 101.687 39.7879 104.564 36.239 104.564Z"
        fill="#51748B"
      />
      <path
        d="M45.4903 104.564V89.9023H39.0645V98.1383C39.0645 101.687 41.9415 104.564 45.4903 104.564Z"
        fill="#51748B"
      />
      <path
        d="M79.3046 80.4919H56.2222V85.8883H79.3046V80.4919Z"
        fill="#51748B"
      />
      <path
        d="M76.0529 116.674C76.824 116.674 77.4491 116.049 77.4491 115.278C77.4491 114.507 76.824 113.882 76.0529 113.882C75.2818 113.882 74.6567 114.507 74.6567 115.278C74.6567 116.049 75.2818 116.674 76.0529 116.674Z"
        fill="white"
      />
      <path
        d="M28.2214 71.7622H107.305"
        stroke="#06292F"
        strokeMiterlimit="10"
      />
      <path
        d="M66.4049 93.4131H59.1753V110.291H66.4049V93.4131Z"
        stroke="#E2F7FF"
        strokeMiterlimit="10"
      />
      <path
        d="M76.0531 93.4131H68.8235V110.291H76.0531V93.4131Z"
        stroke="#E2F7FF"
        strokeMiterlimit="10"
      />
      <path
        d="M67.7637 62.8745C71.0958 62.8745 73.7971 60.1732 73.7971 56.841C73.7971 53.5089 71.0958 50.8076 67.7637 50.8076C64.4315 50.8076 61.7302 53.5089 61.7302 56.841C61.7302 60.1732 64.4315 62.8745 67.7637 62.8745Z"
        stroke="#06292F"
        strokeMiterlimit="10"
      />
      <path
        d="M95.6256 129.493C95.6256 129.493 91.9299 131.796 93.9688 138.17"
        stroke="#06292F"
        strokeMiterlimit="10"
      />
      <path
        d="M90.0764 128.245C90.0764 128.245 93.1998 130.429 93.2777 137.682"
        stroke="#06292F"
        strokeMiterlimit="10"
      />
      <path
        d="M96.8003 131.176C95.0985 132.268 93.8101 133.988 93.2408 135.928C92.301 134.368 90.6585 133.251 88.8625 132.95C88.7025 132.923 90.1679 136.575 90.4207 136.917C91.0972 137.833 92.4867 139.434 93.7568 139.384C96.5674 139.276 97.0306 133.105 96.8003 131.176Z"
        fill="#51748B"
      />
      <path
        d="M95.7598 130.039C96.3628 130.039 96.8517 129.55 96.8517 128.947C96.8517 128.344 96.3628 127.855 95.7598 127.855C95.1568 127.855 94.668 128.344 94.668 128.947C94.668 129.55 95.1568 130.039 95.7598 130.039Z"
        fill="#51748B"
      />
      <path
        d="M90.0765 129.337C90.6795 129.337 91.1683 128.848 91.1683 128.245C91.1683 127.642 90.6795 127.153 90.0765 127.153C89.4735 127.153 88.9846 127.642 88.9846 128.245C88.9846 128.848 89.4735 129.337 90.0765 129.337Z"
        fill="#51748B"
      />
      <path
        d="M43.639 129.493C43.639 129.493 39.9434 131.796 41.9822 138.17"
        stroke="#06292F"
        strokeMiterlimit="10"
      />
      <path
        d="M38.0901 128.245C38.0901 128.245 41.2134 130.429 41.2914 137.682"
        stroke="#06292F"
        strokeMiterlimit="10"
      />
      <path
        d="M44.814 131.176C43.1122 132.268 41.8237 133.988 41.2544 135.928C40.3147 134.368 38.6721 133.251 36.8762 132.95C36.7162 132.923 38.1816 136.575 38.4344 136.917C39.1109 137.833 40.5004 139.434 41.7705 139.384C44.581 139.276 45.0442 133.105 44.814 131.176Z"
        fill="#51748B"
      />
      <path
        d="M43.7735 130.039C44.3765 130.039 44.8653 129.55 44.8653 128.947C44.8653 128.344 44.3765 127.855 43.7735 127.855C43.1705 127.855 42.6816 128.344 42.6816 128.947C42.6816 129.55 43.1705 130.039 43.7735 130.039Z"
        fill="#51748B"
      />
      <path
        d="M38.0901 129.337C38.6931 129.337 39.182 128.848 39.182 128.245C39.182 127.642 38.6931 127.153 38.0901 127.153C37.4871 127.153 36.9983 127.642 36.9983 128.245C36.9983 128.848 37.4871 129.337 38.0901 129.337Z"
        fill="#51748B"
      />
      <path
        d="M127.023 129.493C127.023 129.493 123.328 131.796 125.366 138.17"
        stroke="#06292F"
        strokeMiterlimit="10"
      />
      <path
        d="M121.475 128.245C121.475 128.245 124.598 130.429 124.676 137.682"
        stroke="#06292F"
        strokeMiterlimit="10"
      />
      <path
        d="M128.198 131.176C126.496 132.268 125.208 133.988 124.639 135.928C123.699 134.368 122.056 133.251 120.26 132.95C120.1 132.923 121.566 136.575 121.819 136.917C122.495 137.833 123.885 139.434 125.155 139.384C127.965 139.276 128.429 133.105 128.198 131.176Z"
        fill="#51748B"
      />
      <path
        d="M127.158 130.039C127.761 130.039 128.25 129.55 128.25 128.947C128.25 128.344 127.761 127.855 127.158 127.855C126.555 127.855 126.066 128.344 126.066 128.947C126.066 129.55 126.555 130.039 127.158 130.039Z"
        fill="#51748B"
      />
      <path
        d="M121.475 129.337C122.078 129.337 122.567 128.848 122.567 128.245C122.567 127.642 122.078 127.153 121.475 127.153C120.872 127.153 120.383 127.642 120.383 128.245C120.383 128.848 120.872 129.337 121.475 129.337Z"
        fill="#51748B"
      />
      <path
        d="M149.738 87.1454H111.195C108.584 87.1454 106.594 85.0292 106.594 82.5395V58.141C106.594 55.527 108.708 53.5352 111.195 53.5352H149.738C152.349 53.5352 154.339 55.6513 154.339 58.141V82.415C154.339 85.0291 152.349 87.1454 149.738 87.1454Z"
        fill="#06292F"
      />
      <path
        d="M136.684 85.278C136.684 85.278 141.408 87.7676 142.03 91.6266C142.154 92.4979 143.398 92.8715 144.02 92.249C145.511 90.6307 147.377 87.6431 147.377 81.917"
        fill="#06292F"
      />
      <path
        d="M120.023 74.0748C121.671 74.0748 123.007 72.7372 123.007 71.0872C123.007 69.4372 121.671 68.0996 120.023 68.0996C118.374 68.0996 117.039 69.4372 117.039 71.0872C117.039 72.7372 118.374 74.0748 120.023 74.0748Z"
        fill="white"
      />
      <path
        d="M130.467 74.0748C132.114 74.0748 133.45 72.7372 133.45 71.0872C133.45 69.4372 132.114 68.0996 130.467 68.0996C128.818 68.0996 127.483 69.4372 127.483 71.0872C127.483 72.7372 128.818 74.0748 130.467 74.0748Z"
        fill="white"
      />
      <path
        d="M140.91 74.0748C142.559 74.0748 143.894 72.7372 143.894 71.0872C143.894 69.4372 142.559 68.0996 140.91 68.0996C139.263 68.0996 137.927 69.4372 137.927 71.0872C137.927 72.7372 139.263 74.0748 140.91 74.0748Z"
        fill="white"
      />
    </Logo>
  );
};

export default HaveItMadeHouseConfirmation;

import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const HeaderWithoutImageStyles = styled.section`
  position: relative;
  background-color: ${Colors.primary.claytonBlue.standard};
  padding: 55px 0px;
  min-height: 271px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media only screen and (max-width: 771px) {
    justify-content: center;
    text-align: center;
    min-height: 217px;
  }
  .container {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    z-index: 3;
    padding: 0px 25px;
  }
  .large-headline {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.35px;
    color: white;
    position: relative;
    z-index: 4;
  }
  .left-graphic {
    width: 204px;
    position: absolute;
    left: 20px;
    bottom: 0;
    @media only screen and (max-width: 771px) {
      display: none;
    }
  }
  .right-graphic {
    width: 333px;
    position: absolute;
    right: 0;
    top: 0;
    @media only screen and (max-width: 771px) {
      display: none;
    }
  }
`;

export default HeaderWithoutImageStyles;

import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import App from '../../App';
import ContructionTextWithImage from '../../images/ContructionTextWithImage.jpg';
import OurContructionBackground from '../../images/OurContructionBackground.jpg';
import ecobeeBackground from '../../images/ecobeeBackground.jpg';
import EnergySmartZeroLogo from '../../images/promo-images/energy-smart-zero-cta-logo.png';
import InventoryClearanceLogo from '../../images/promo-images/inventory-clearance-event-logo.svg';
import EnergySmartIcon from '../../images/svg/EnergySmart.svg';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import { findFeaturedHomes, findTempoHomes } from '../../utils/utils';
import CrossModBanner from '../Shared/CrossModBanner/CrossModBanner';
import FAQThreeUp from '../Shared/FAQThreeUp/FAQThreeUp';
import FeaturedHomes from '../Shared/FeaturedHomes/FeaturedHomes';
import HeaderWithImage from '../Shared/HeaderWithImage/HeaderWithImage';
import HouseSmartCTA from '../Shared/HouseSmartCTA/HouseSmartCTA';
import LargeCTA from '../Shared/LargeCTA/LargeCTA';
import TextWithImage from '../Shared/TextWithImage/TextWithImage';
import TextWithImageRight from '../Shared/TextWithImageRight/TextWithImageRight';
import XLCTA from '../Shared/XLCTA/XLCTA';
import ConstructionStyles from './Construction.styled';
import useWindowSize from '../../hooks/useWindowSize';

const Construction = ({ dealer, homes, homesOnSale }) => {
  const featuredHomes = findFeaturedHomes(homes);
  const tempoHomes = findTempoHomes(homes);
  const location = useLocation();
  const history = useHistory();
  const [width, height] = useWindowSize();
  const isMobile = width <= 1099;

  useEffect(() => {
    if (location.state === undefined) {
      history.replace({ state: { dealer, homes } });
    }
  }, []);

  if (dealer?.websiteTemplateDescription === 'BuilderModel') {
    return <Redirect to="/" />;
  }

  return (
    <App dealer={dealer} homes={homes}>
      <ConstructionStyles>
        <Helmet>
          <title>
            How Our Homes Are Constructed |{' '}
            {formatDealerName(dealer?.dealerName)}
          </title>
          <meta
            name="description"
            content={`Learn more about how Clayton Homes build and constructs their homes to give you the most beautiful, efficient and sturdy home possible.`}
          />
          <meta
            name="og:description"
            content={`Learn more about how Clayton Homes build and constructs their homes to give you the most beautiful, efficient and sturdy home possible.`}
          />
        </Helmet>

        <HeaderWithImage
          smallHeadline={'Strength & Durability'}
          largeHeadline={'Our Construction'}
          content={
            'Our advanced construction process has been carefully engineered to give you a strong, beautiful home from the foundation to the roof, and everything in between.'
          }
          contentMaxWidth="560px"
          image={{
            source: OurContructionBackground,
            alt: 'construction background image',
          }}
        />
        <TextWithImage
          smallHeadline={'Built Smart'}
          largeHeadline={'How We Build'}
          content={
            'Our homes are built inside climate-controlled facilities using high quality materials and handcrafted with structural strength and durability. Every detail of your home is inspected to ensure your home is built to the best quality that can last a lifetime.'
          }
          image={{
            source: ContructionTextWithImage,
            alt: 'construction workers',
          }}
        />
        <HouseSmartCTA />
        <CrossModBanner
          dealer={dealer}
          homes={homes}
          gaClass={'ga-construction-crossmod-find-out-more'}
        />
        <section className="spacer"></section>
        {dealer?.websiteTemplateDescription !== 'NoSpecialOffers' && (
          <LargeCTA
            key="large-cta"
            buttonText={isMobile ? 'View Sale Homes' : 'View Deals'}
            buttonURL="/sale-homes"
            backgroundColor="inventory-clearance-event"
            linkState={(dealer, homes)}
            smallText={isMobile && 'Inventory Clearance Event'}
            largeText={
              isMobile ? (
                <p className="h2 large-text">
                  Shop now <br /> before they're gone!
                </p>
              ) : (
                <>
                  <p className="h2 large-text" style={{ maxWidth: '500px' }}>
                    <b className="bold">SAVE THOUSANDS</b>* on select in-stock
                    homes.
                  </p>
                </>
              )
            }
            disclaimerText={
              !isMobile &&
              '*Sale homes will vary by retailer and state. See local retailer for list of available homes.'
            }
            backgroundImage={{
              source: require('../../images/promo-images/inventory-clearance-event-large-cta.jpg'),
              srcSet: [
                {
                  source: require('../../images/webp/inventory-clearance-event-large.webp'),
                },
              ],
              alt: 'Inventory Clearance Event Promo Banner Image',
            }}
            backgroundPosition={'top center'}
            gaClass="ga-sale-homes-seasonal-campaign"
            dealer={dealer}
            homes={homes}
            absoluteLogo={InventoryClearanceLogo}
            absoluteLogoPositionRight={false}
            logoText={''}
            retailEventLabel={'construction_banner_campaign'}
          />
        )}
        <TextWithImageRight
          smallHeadline={'Save Energy and Money'}
          icon={EnergySmartIcon}
          content={
            'Save money daily in an Energy Smart home with features like the programmable ecobee thermostat, upgraded insultation, Low- E windows and Rheem® dual element water heater that all work together to keep your home temperatures regulated in the most efficient way. With all these energy efficient features, you can save resources and reduce energy costs.'
          }
          image={{
            source: ecobeeBackground,
            alt: 'ecobee image',
          }}
          buttonText={'Learn More'}
          buttonURL={'/energysmart'}
          aTagLink={true}
          gaClass="ga-construction-energy-smart-learn-more"
        />
        {(featuredHomes || tempoHomes) && (
          <FeaturedHomes
            featuredHomes={tempoHomes ? tempoHomes : featuredHomes}
            dealer={dealer}
            homes={homes}
            buttonText={'View All Homes'}
            buttonURL={'/homes'}
            secondButtonText={
              homesOnSale && homesOnSale.length > 0 ? 'View Sale Homes' : null
            }
            secondButtonURL={
              homesOnSale && homesOnSale.length > 0 ? '/sale-homes' : null
            }
            gaClass={'ga-construction-view-all-homes'}
            gaSecondClass={'ga-construction-view-featured-sale-homes'}
            gaClassTwo={'ga-construction-home-item'}
            retailEventLabel={'construction_view_all_homes_featured_homes'}
            retailEventLabelTwo={'construction_all_sale_homes_featured_homes'}
          />
        )}
        <FAQThreeUp dealer={dealer} homes={homes} />
        <XLCTA
          largeText="Find Your Perfect Home"
          dealer={dealer}
          homes={homes}
          buttons={[
            {
              buttonText: 'Get More Information',
              buttonURL: '/contact-us',
              gaClass: 'ga-construction-get-more-information',
              openForm: 'requestInfo',
            },
            {
              buttonText: 'Schedule a Visit',
              buttonURL: '/contact-us',
              gaClass: 'ga-construction-schedule-a-visit',
              openForm: 'scheduleVisit',
            },
          ]}
        />
      </ConstructionStyles>
    </App>
  );
};

Construction.getInitialProps = async (ctx) => {
  const { dealer, homes, homesOnSale } = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  );

  try {
    if (dealer?.websiteTemplateDescription === 'NoSpecialOffers') {
      return { redirectTo: '/404' };
    }
  } catch (e) {
    console.error(e);
  }

  return { dealer, homes, homesOnSale };
};

export default Construction;

import styled from 'styled-components';
import Colors from '../../colors';

const TextAreaStyles = styled.div`
  @keyframes label-up-transition {
    0% {
      font-size: 14px;
      top: 13px;
    }

    100% {
      font-size: 11px;
      top: -5px;
    }
  }

  @keyframes label-fade-transition {
    0% {
      font-size: 14px;
      left: 0;
      opacity: 1;
      top: 13px;
    }

    22% {
      left: 0;
    }

    45% {
      font-size: 14px;
      left: -25%;
      opacity: 0;
      top: 13px;
    }
    55% {
      font-size: 11px;
      left: -25%;
      opacity: 0;
      top: -5px;
    }
    77% {
      left: -25%;
    }
    100% {
      font-size: 11px;
      left: 0;
      opacity: 1;
      top: -5px;
    }
  }

  font-family: 'source-sans-pro', sans-serif;

  label {
    color: #4e5255 !important;
    display: block;
    font-size: 15px;
    line-height: 25px;
  }
  textarea {
    background-color: ${Colors.accent.grey6};
    border: solid 1px ${(props) => (props.error ? '#f00' : '#ccc')};
    box-sizing: border-box;
    color: ${(props) =>
      props.usePlaceholder
        ? Colors.accent.grey1.standard
        : Colors.primary.black.standard};
    font-family: 'source-sans-pro', sans-serif;
    font-size: 16px;
    height: 100px;
    line-height: 1.56;
    letter-spacing: -0.3px;
    padding: 9px 33px 8px 13px;
    text-align: left;
    width: ${(props) => (props.fillSpace ? '100%' : null)};

    &:active,
    &:focus {
      border: solid 1px
        ${(props) => (props.error ? '#f00' : props.animated ? '#ccc' : '#00f')};
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .error {
    color: #4e5255;
    font-size: 12px;
    height: 22px;
    letter-spacing: -0.2px;
    line-height: 1.83;
    margin-top: 0;
    text-align: left;
  }

  &.animated {
    min-height: 129px;
    position: relative;
    overflow-x: hidden;

    label {
      color: ${Colors.accent.grey2.standard};
      font-size: 16px;
      padding-left: 1px;
      position: absolute;
      opacity: 1;
      top: 13px;
    }

    .error-text-area {
      border-bottom: 1px solid #ef4b5d;
    }

    textarea {
      border-color: transparent;
      border-bottom: solid 1px ${Colors.accent.grey2.standard};
      padding: 0;
      padding-top: 10px;
      width: 100%;
      resize: none;

      &:focus {
        & ~ .focus-bar {
          width: 100%;
        }
      }
    }
    .error textarea:focus {
      & ~ .focus-bar {
        width: 0;
        height: 100%;
      }
    }
    .error {
      height: auto;
      padding-left: 1px;
    }

    textarea:focus,
    textarea:valid {
      & + label {
        animation: label-fade-transition 0.3s linear;
        font-size: 11px;
        display: block;
        top: -5px;
      }
    }
  }
`;

export default TextAreaStyles;

import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeft = ({ color, width, height, onClick, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    onClick={onClick}
    className={className}
  >
    <path
      fill={color}
      d="M9.35355 4.35355C9.54882 4.15829 9.54882 3.84171 9.35355 3.64645C9.15829 3.45118 8.84171 3.45118 8.64645 3.64645L4.64645 7.64645C4.45118 7.84171 4.45118 8.15829 4.64645 8.35355L8.64645 12.3536C8.84171 12.5488 9.15829 12.5488 9.35355 12.3536C9.54882 12.1583 9.54882 11.8417 9.35355 11.6464L5.70711 8L9.35355 4.35355Z"
    />
  </svg>
);

ArrowLeft.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ArrowLeft.defaultProps = {
  color: '#25292D',
  width: '16',
  height: '16',
};

export default ArrowLeft;

import styled from 'styled-components';
import Colors from '../../../colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const FAQThreeUpStyles = styled.section`
  position: relative;
  background: #f3f5f7;
  padding: 90px 0px;
  @media only screen and (max-width: 400px) {
    padding: 60px 0px;
  }
  .title {
    margin-bottom: 32px;
  }
  .container {
    position: relative;
    max-width: 1056px;
    margin: 0 auto;
    padding: 0px 20px;
    @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
      max-width: 526px;
    }
  }
  .faq-grid {
    position: relative;
    display: flex;
    gap: 44px;
    .faq-item {
      flex: 1 1 33.3333%;
    }
    @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
      flex-direction: column;
      gap: 60px;
    }
  }
  .question {
    margin-bottom: 12px;
  }
  .dash {
    position: relative;
    display: flex;
    height: 1px;
    width: 40px;
    background: #cacccf;
    margin-bottom: 15px;
  }
  .answer {
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .read-more {
    color: ${Colors.primary.claytonBlue.standard};
    text-decoration: none;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default FAQThreeUpStyles;

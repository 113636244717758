import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PlayBtnSmall from '../../../images/svg/icons/PlayBtnSmall';
import { fireSegmentEvent } from '../../../utils';
import Modal from '../SmokeyTransitional/components/Modal/Modal';
import WistiaVideoEmbed from '../SmokeyTransitional/components/WistiaVideoEmbed/WistiaVideoEmbed';
import VideoStyles from './Video.styled';

export const Video = ({
  smallHeadline,
  largeHeadline,
  videoID,
  image,
  mobileImage,
  gaClass,
  retailEventLabel,
  wistiaOptions,
  noSaleHomes,
  isSpecialOffers,
  isPromoBackgroundImg,
  isPromo,
}) => {
  const [ModalShowing, setModalShowing] = useState(false);
  const [PlayVideo, setPlayVideo] = useState(true);
  return (
    <VideoStyles
      noSaleHomes={noSaleHomes}
      isSpecialOffers={isSpecialOffers}
      className={isPromoBackgroundImg ? 'promo' : ''}
    >
      <div
        className={gaClass ? 'inner ' + gaClass : 'inner'}
        onClick={() => {
          (PlayVideo ? setModalShowing(true) + setPlayVideo(false) : null) +
            fireSegmentEvent('Video Started', {
              eventContext: retailEventLabel ? retailEventLabel : '',
              videoId: videoID ? videoID : '',
            });
        }}
      >
        <div className="content-wrap">
          {smallHeadline && (
            <span className="small-headline">{smallHeadline}</span>
          )}
          {largeHeadline && <h2 className="large-headline">{largeHeadline}</h2>}
          <PlayBtnSmall />
        </div>
        {image && (
          <div
            className={
              isPromoBackgroundImg ? 'promo-image-box image-box' : 'image-box'
            }
          >
            <LazyLoadImage alt={image.alt} effect="blur" src={image.source} />
          </div>
        )}
        {mobileImage && (
          <div className="mobile-image-box">
            <LazyLoadImage
              alt={mobileImage.alt}
              effect="blur"
              src={mobileImage.source}
            />
          </div>
        )}
      </div>
      {ModalShowing && (
        <Modal
          onClose={() =>
            setModalShowing(false) +
            setTimeout(() => {
              setPlayVideo(true);
            }, 500)
          }
        >
          <div className="video-embed-wrap">
            <WistiaVideoEmbed
              wistiaID={videoID}
              wistiaOptions={
                wistiaOptions
                  ? wistiaOptions
                  : 'silentAutoPlay=false endVideoBehavior=loop autoPlay=true'
              }
            />
          </div>
        </Modal>
      )}
    </VideoStyles>
  );
};

Video.props = {
  smallHeadline: PropTypes.string,
  largeHeadline: PropTypes.string,
  wistiaOptions: PropTypes.string,
  videoID: PropTypes.string,
  gaClass: PropTypes.string,
  retailEventLabel: PropTypes.string,
  image: PropTypes.string.isRequired,
};

export default Video;

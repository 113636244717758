import { getBaseApiUrl } from './utils/config';
import xHttp from './utils/xHttp';
import { getGAClientId } from './utils/config';

export const getLeadsApiEndpoint = () => {
  return `${getBaseApiUrl()}api/leads`;
};

//Need to pass in lotNumber eventually
export const getHomesApiEndpoint = () => {
  return `${getBaseApiUrl()}api/inventory/retailsites/58`;
};

export const getRecentlyViewedHomesApiEndpoint = () => {
  return `${getBaseApiUrl()}api/recentlyviewed`;
};

export const getRecentlyViewedHomes = (vt) => {
  const recentEndpoint = getRecentlyViewedHomesApiEndpoint();
  const params = {
    numberOfReturnedRecords: 4,
    visitorToken: vt,
  };
  if (vt) {
    return xHttp.getWithParams(recentEndpoint, params);
  }
  return null;
};

export const addRecentlyViewedHome = (data) => {
  const endpoint = getRecentlyViewedHomesApiEndpoint();
  return xHttp.post(endpoint, { ...data });
};

export const getSalesAgents = async (dealerId) => {
  let url = `${getBaseApiUrl()}api/dealers/${dealerId}/salesagents`;
  return xHttp.get(url);
};

export const testimonialFormSubmit = async (data, lotNumber) => {
  const testimonialEndpoint = `${getBaseApiUrl()}api/dealerwebsite/${lotNumber}/testimonial`;
  const headers = {};

  return xHttp.postWithParams(
    testimonialEndpoint,
    { ...data },
    { lotNumber: lotNumber },
    headers
  );
};

export const siteFeedbackFormSubmit = async (data, lotNumber) => {
  const siteFeedbackEndpoint = `${getBaseApiUrl()}api/dealerwebsite/${lotNumber}/sitefeedback`;
  const headers = {};

  return xHttp.postWithParams(
    siteFeedbackEndpoint,
    { ...data },
    { lotNumber: lotNumber },
    headers
  );
};

export const contactFormSubmit = async (data) => {
  const gaClientId = getGAClientId() || '';

  const leadsEndpoint =
    sessionStorage.getItem('_le') || (await getLeadsApiEndpoint());
  const headers = {};
  return xHttp.post(leadsEndpoint, { ...data, gaClientId }, headers);
};

export const getNewsAndEventsPost = (lotNumber, postSlug) => {
  let url = `${getBaseApiUrl()}/api/dealerwebsite/${lotNumber}/websitecontent/${postSlug}`;
  return xHttp.get(url);
};

export const getNewsAndEvents = (lotNumber) => {
  let url = `${getBaseApiUrl()}api/dealerwebsite/${lotNumber}/websitecontent`;
  return xHttp.get(url);
};

export const getTestimonials = () => {
  let url = `https://api.claytonhomes.com/v1/contentful/dpc/6wpD4mqm3BInmaq2ERYMk1`;
  return xHttp.get(url);
};

export default {};

import styled from 'styled-components';
import Colors from '../../../colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const SpecialOffersHeroStyles = styled.section`
  position: relative;
  overflow: hidden;

  .wrapper {
    display: flex;
    flex-direction: column;
    background: ${Colors.accent.bedtime.standard};
    width: 100%;
    overflow: hidden;
    min-height: 883px;
    @media only screen and (max-width: 1500px) {
      min-height: 663px;
    }
    @media only screen and (max-width: 1436px) {
      min-height: 643px;
      justify-content: flex-start;
      .container {
        padding: 223px 0 0;
      }
    }

    @media only screen and (max-width: 767px) {
      .container {
        padding: 124px 0 0;
      }
    }

    @media only screen and (max-width: 376px) {
      min-height: 553px;
    }
  }

  .content-disclaimer {
    position: relative;
    margin-top: 30px;
    margin-bottom: 12px;
    p {
      color: ${Colors.primary.white.standard};
      font-size: 20px;
      line-height: 1.25;
      font-family: 'source-sans-pro', sans-serif;
      font-weight: 700;
      @media only screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 17px;
      }
      span {
        font-family: 'source-sans-pro', sans-serif;
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .container {
    position: relative;
    width: 1320px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 50px;

    @media only screen and (max-width: 1000px) {
      padding: 0px 25px;
      flex-direction: column-reverse;
      padding-top: 70px;

      .image {
        display: none;
      }
      .content-wrap {
        /* text-align: center; */
        max-width: 511px;
      }
      .content-disclaimer {
        max-width: 244px;
      }
    }
    @media only screen and (max-width: 376px) {
      .large-sub-headline {
        font-size: 26px;
        line-height: 31px;
      }
    }
  }

  .images-wrap {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    img {
      max-width: 100%;
    }
    @media only screen and (max-width: 1000px) {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .content-wrap {
    position: relative;
    z-index: 9;
    flex: 1;
    top: 145px;
    left: 8.5%;
    width: 51%;
    max-width: 774px;

    @media only screen and (max-width: 1436px) {
      flex: 1 0 auto;
      width: 78%;
    }

    @media only screen and (max-width: 1024px) {
      top: 65px;
    }

    @media only screen and (max-width: 767px) {
      width: 87%;
      top: 32px;
      left: 7%;
    }
  }
  .small-headline,
  .large-headline,
  .large-sub-headline,
  .content {
    color: #ffffff;
  }
  .large-sub-headline {
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
    text-transform: uppercase;
    font-family: 'source-sans-pro';
    @media only screen and (max-width: 767px) {
      line-height: 24px;
      font-size: 20px;
    }
  }
  .large-headline {
    margin-bottom: 4px;
    font-size: 60px;
    font-family: 'acumin-pro';
    font-weight: 700;
    line-height: 68px;
    letter-spacing: -0.52px;
    @media only screen and (max-width: 1436px) {
      line-height: 55px;
      font-size: 48px;
    }
    @media only screen and (max-width: 767px) {
      line-height: 40px;
      font-size: 34px;
      letter-spacing: -0.35px;
    }
    @media only screen and (max-width: 645px) {
      display: inline;
      margin-right: 20px;
    }
  }
  .content {
    font-family: 'source-sans-pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
  }
  .small-headline {
    text-transform: uppercase;
    font-family: 'source-sans-pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.5px;
  }
  .cta-button {
    background: ${Colors.accent.darkClearanceRed.standard};
    color: ${Colors.primary.white.standard};
    border-radius: 2px;
    padding: 17px 17px;
    font: 500 12px/100% 'source-sans-pro', sans-serif;
    margin-top: 49px;
    letter-spacing: 1px;
    text-decoration: none;
    transition: 0.2s;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-flex;

    &:hover {
      opacity: 0.9;
    }

    @media only screen and (max-width: 335px) {
      margin-top: 0;
    }
  }

  .image-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${Colors.accent.grey5.standard};
    z-index: 1;
    span,
    img {
      height: 100%;
      width: 100%;
    }
    img {
      object-position: center;
      object-fit: cover;

      @media only screen and (max-width: 767px) {
        object-position: -140px 0;
      }
    }
    .promo-logo {
      max-width: 459px;
      position: absolute;
      bottom: 85px;
      right: 85px;
      height: auto;
      object-position: unset;
      object-fit: unset;
      width: auto;
      @media only screen and (max-width: 1024px) {
        max-width: 370px;
        bottom: 50px;
        right: 85px;
      }
      @media only screen and (max-width: 767px) {
        max-width: 251px;
        bottom: 45px;
        right: 15px;
      }
    }

    .promo-tag-line {
      position: absolute;
      bottom: 41px;
      right: 51px;
      max-width: 496px;
      color: ${Colors.primary.white.standard};
      font: 400 32px/100% 'acumin-pro', sans-serif;
      text-align: right;
      letter-spacing: -0.35px;

      @media only screen and (max-width: 767px) {
        max-width: 321px;
        bottom: 40px;
        right: 31px;
        font-size: 21px;
      }

      @media only screen and (max-width: 360px) {
        font-size: 16px;
        bottom: 10px;
      }

      .bold {
        font-weight: 800;
      }
    }
  }
`;

export default SpecialOffersHeroStyles;

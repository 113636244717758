import React from 'react';
import PropTypes from 'prop-types';
import { homeListingsPageStructuredData } from '../../utils/structuredData';

const HomeListingStructuredData = ({ dealer, homes }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(homeListingsPageStructuredData(dealer, homes)),
      }}
    ></script>
  );
};

export default HomeListingStructuredData;

HomeListingStructuredData.propTypes = {
  dealer: PropTypes.shape({
    firtVanityUrl: PropTypes.string,
  }).isRequired,
  homes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from '../../../colors';

export const BannerStyles = styled.section`
  position: relative;
  background: ${Colors.primary.claytonBlue.standard};
  color: white;
  padding: 10px;
  display: flex;
  z-index: 2013;
  height: 48px;
  justify-content: center;
  align-items: center;
  .content {
    padding-left: 24px;
    padding-right: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-decoration: none;
    p {
      color: white;
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
    #bigArrowRight {
      margin-left: 7px;
      transform: translateY(5px);
      height: 16px;
      width: 16px;
    }
  }
  .arrow-container {
    display: inline-block;
  }
  .close-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  @media only screen and (min-width: 776px) {
    .close-icon-wrap {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      margin: auto;
    }
  }
  @media only screen and (max-width: 775px) {
    .close-icon-wrap {
      margin-left: 10px;
      #CloseIcon {
        width: 20px;
      }
    }
  }
  @media only screen and (max-width: 558px) {
    .content {
      padding-left: 10px;
      p {
        #bigArrowRight {
          width: 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 800px) {
    height: auto;
  }
`;

export default BannerStyles;

import styled from 'styled-components';

const WinterPromoStyles = styled.div`
  .spacer {
    position: relative;
    margin: 80px 0px;
    display: flex;
    @media only screen and (max-width: 775px) {
      margin: 40px 0px;
    }
  }
`;

export default WinterPromoStyles;

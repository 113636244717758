import styled from 'styled-components';

const PromoSlideStyles = styled.div`
  @media only screen and (min-width: 320px) {
  }

  @media only screen and (min-width: 768px) {
  }

  @media only screen and (min-width: 1024px) {
  }

  .promo {
    padding: 10px;
  }

  .card-container {
    border: 0px solid transparent;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;

    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
      @media screen and (min-width: 1024px) {
        height: 263px;
      }
    }
  }

  .promo-link {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
    cursor: pointer;

    p {
      font-family: 'acumin-pro';
      font-style: normal;
      font-weight: bold;
      font-size: 16px !important;
      line-height: 20px;
      letter-spacing: -0.3px;
      width: 67%;
      color: #25292d;
    }
  }
  .energy-smart-promo-link {
    p {
      width: 90% !important;
    }
    img {
      margin-top: 6px;
    }
  }
`;

export default PromoSlideStyles;

import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
  >
    <path
      fill={color}
      fillRule="nonzero"
      transform="translate(5 5)"
      d="M.147.146a.5.5 0 0 1 .707 0l2.13 2.131L5.115.147a.5.5 0 0 1 .638-.058l.07.057a.5.5 0 0 1 0 .708L3.69 2.984l2.132 2.131a.5.5 0 0 1 .058.638l-.058.07a.5.5 0 0 1-.707 0L2.984 3.69.854 5.823a.5.5 0 0 1-.638.057l-.07-.057a.5.5 0 0 1 0-.708l2.131-2.131L.147.854A.5.5 0 0 1 .089.216z"
    />
  </svg>
);

CloseIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

CloseIcon.defaultProps = {
  color: '#ffffff',
  width: '16',
  height: '16',
};

export default CloseIcon;

import React from 'react';
import PropTypes from 'prop-types';
import { homePageStructuredData } from '../../utils/structuredData';

const StructuredData = ({ dealer, salesAgents, homes }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          homePageStructuredData(dealer, salesAgents, homes)
        ),
      }}
    ></script>
  );
};

export default StructuredData;

// StructuredData.propTypes = {
//   dealer: PropTypes.shape({
//     welcomeHomeImageReferences: PropTypes.array,
//     dealerName: PropTypes.string,
//     longitude: PropTypes.number,
//     latitude: PropTypes.number,
//     address1: PropTypes.string,
//     city: PropTypes.string,
//     stateProvince: PropTypes.string,
//     postalCode: PropTypes.string,
//     firtVanityUrl: PropTypes.string,
//     phoneNumber: PropTypes.string,
//     weekdayHours: PropTypes.string,
//     fridayHours: PropTypes.string,
//     saturdayHours: PropTypes.string,
//     sundayHours: PropTypes.string,
//   }).isRequired,
//   salesAgents: PropTypes.shape({
//     avatarImageReference: PropTypes.string,
//     firstName: PropTypes.string,
//     lastName: PropTypes.string,
//     email: PropTypes.string,
//     salesAgentTitle: PropTypes.string,
//   }),
//   homes: PropTypes.shape([
//     {
//       priceSort: PropTypes.string,
//     },
//   ]).isRequired,
// };

import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from '../../../colors';

export const AccordionStyles = styled.div`
  ul {
    list-style-type: none;
    list-style-image: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s ease-out;

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      display: block;
      max-height: none;
      overflow: auto;
    }
  }

  .active {
    max-height: ${(props) =>
      props.menuHeight !== '0px' ? props.menuHeight : '0'};
    margin-bottom: 20px;

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      max-height: none;
      margin-bottom: 0;
    }
  }
`;

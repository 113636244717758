import React from 'react';
import styled from 'styled-components';
import Colors from '../../colors';
import PromoBannerMobile from '../../images/promo-images/summer-promo/ebuilt-banner-mobile.webp';
import PromoBannerTablet from '../../images/promo-images/summer-promo/ebuilt-banner-tablet.webp';
import PromoBannerDesktop from '../../images/promo-images/summer-promo/ebuilt-banner-desktop.webp';

export const StyledBanner = styled.div`
  width: 100%;

  @media only screen and (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
  }

  .banner {
    background: url(${PromoBannerMobile}) no-repeat top center;
    background-size: cover;
    color: ${Colors.primary.white.standard};
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 29px 24px 11px;
    letter-spacing: -0.35px;

    @media only screen and (min-width: 390px) {
      height: 440px;
    }

    @media only screen and (min-width: 768px) {
      background: url(${PromoBannerTablet}) no-repeat bottom center;
      height: 234px;
      padding: 41px 20px 15px 21px;
      .bold {
        font-weight: 900;
      }
    }

    @media only screen and (min-width: 1025px) {
      height: auto;
      width: 50%;
      background: url(${PromoBannerDesktop}) no-repeat center;
      background-size: cover;
      padding: 56px 19px 34px 19px;
    }

    @media only screen and (min-width: 1500px) {
      min-height: 930px;
    }

    @media only screen and (min-width: 1900px) {
      min-height: 1050px;
    }

    h3 {
      font-size: 50px;
      font-weight: 400;
      line-height: 50px;
      font-family: acumin-pro, sans-serif;
      max-width: 650px;
      color: ${Colors.primary.white.standard};
      @media only screen and (min-width: 768px) {
        font-size: 64px;
        line-height: 64px;
        letter-spacing: 0;
        padding: 0 12px 0 1px;
      }
      @media only screen and (min-width: 1025px) {
        color: ${Colors.accent.eBuiltTextBlue.standard};
        font-size: 70px;
        line-height: 70px;
        letter-spacing: -0.35px;
        padding: 0;
      }
    }
    .disclaimer {
      color: ${Colors.primary.white.standard};
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      max-width: 681px;
      width: 94%;
      a {
        text-decoration: underline;
        cursor: pointer;
        z-index: 5;
        position: relative;
        font-family: 'source-sans-pro', sans-serif;
      }
      @media screen and (min-width: 768px) {
        color: ${Colors.primary.white.standard};
      }
      @media only screen and (min-width: 1025px) {
        color: ${Colors.primary.white.standard};
        font-size: 14px;
      }
    }

    .banner-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

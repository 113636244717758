import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({ color, width, height }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="25 25 50 50"
    width={width}
  >
    <circle
      cx="50"
      cy="50"
      r="20"
      fill="none"
      strokeWidth="5"
      stroke={color}
      strokeLinecap="round"
      strokeDashoffset="0"
      strokeDasharray="100, 200"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 50 50"
        to="360 50 50"
        dur="2.5s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="strokeDashoffset"
        values="0;-30;-124"
        dur="1.25s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="strokeDasharray"
        values="0,200;110,200;110,200"
        dur="1.25s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

Loading.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Loading.defaultProps = {
  color: '#0075C9',
};

export default Loading;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RecentlyViewedHomesStyles from './RecentlyViewedHomes.styled';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import HomeTile from '../../HomeList/HomeTile/HomeTile';
import useWindowSize from '../../../hooks/useWindowSize';

export const RecentlyViewedHomes = ({
  homes,
  dealer,
  homesOffset,
  filters,
}) => {
  const [width, height] = useWindowSize();
  const [recentHomes, setRecentHomes] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage?.getItem('recentlyViewedHomes'));

    if (items) {
      let filterByLotNumber = items.filter(
        (e) => e?.lotNumber === dealer?.dealerId
      );
      let filterByInventory = filterByLotNumber?.filter((item) =>
        homes.find(
          (h) =>
            item.serialNumber === h.serialNumber &&
            item.modelNumber === h.modelNumber
        )
      );
      let filteredHomes = filterByInventory?.map((item) =>
        homes?.find(
          (home) =>
            home.serialNumber === item.serialNumber &&
            home.modelNumber === item.modelNumber
        )
      );

      let finalList = filteredHomes.slice(0, 4);

      setRecentHomes(finalList);
    }
  }, []);

  const isMobile = width <= 1023;

  if (!recentHomes || recentHomes?.length === 0) {
    return <></>;
  }

  return (
    <RecentlyViewedHomesStyles>
      <div className="recently-viewed-container">
        <h3>{isMobile ? 'Recently Viewed' : 'Your recently viewed homes'}</h3>
        <div className="recently-viewed-homes">
          {isMobile && (
            <Carousel
              showArrows={false}
              autoPlay={false}
              showIndicators={true}
              showStatus={false}
              showThumbs={false}
              swipeable={true}
              emulateTouch={true}
              infiniteLoop={true}
            >
              {recentHomes?.map((home, i) => {
                return (
                  <div className="home" key={i}>
                    <HomeTile
                      recentlyViewedHomeObject={home}
                      showStickers={false}
                      useSlideshow={false}
                      showPrice={false}
                      dealer={dealer}
                      cardLinkState={{
                        dealer: dealer,
                        homes: homes,
                        homesOffset: homesOffset,
                        filters: filters,
                      }}
                    />
                  </div>
                );
              })}
            </Carousel>
          )}
          {!isMobile && (
            <>
              {recentHomes?.map((home, i) => {
                return (
                  <div className="home" key={i}>
                    <HomeTile
                      recentlyViewedHomeObject={home}
                      showStickers={false}
                      useSlideshow={false}
                      showPrice={false}
                      dealer={dealer}
                      cardLinkState={{
                        dealer: dealer,
                        homes: homes,
                        homesOffset: homesOffset,
                        filters: filters,
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </RecentlyViewedHomesStyles>
  );
};

RecentlyViewedHomes.props = {
  homes: PropTypes.array,
};

export default RecentlyViewedHomes;

import styled from 'styled-components';
import colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import zIndices from '../SmokeyTransitional/components/zIndex';

export const ModelListStyles = styled.div`
  position: absolute;
  z-index: ${zIndices.scrollToTopButton};
  background-color: white;
  border: 1px solid ${colors.accent.grey5.standard};
  top: 175px;

  &.model-location-container {
    overflow-y: auto;
    max-height: 250px;
    position: absolute;
  }

  &.model-location-container::-webkit-scrollbar,
  &.model-location-container::-webkit-scrollbar,
  &.model-location-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }
  &.model-location-container::-webkit-scrollbar-thumb,
  &.model-location-container::-webkit-scrollbar-thumb,
  &.model-location-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: lightgray;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .model-search-results {
    position: relative;
    width: 235px;
    ul {
      padding-inline-start: 0;
      list-style-type: none;

      li {
        background-color: white;
        font-size: 12px;
        border-bottom: 1px solid ${colors.accent.grey5.standard};

        line-height: 20px;
        height: auto;
        width: 100%;
        text-align: left;
        letter-spacing: normal;
        text-transform: none;

        a {
          padding: 5px 15px;
          cursor: pointer;
          display: block;
        }
      }

      li:hover,
      .model-selected {
        background-color: ${colors.accent.darkBlue.standard};
        a {
          color: #fff;
        }
      }
    }
  }

  @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
    &.model-location-container {
      max-height: 328px;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

const HeartOutline = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.71525 3.02999L9.77955 1.9657C11.4474 0.296393 14.1508 0.29636 15.8187 1.9656C17.4865 3.63344 17.4864 6.33534 15.8188 8.00295L14.3023 9.52049L9.1473 14.448C8.9057 14.6789 8.52518 14.6789 8.28358 14.448L3.11831 9.51045L1.61225 8.00314C-0.0555803 6.33531 -0.0555803 3.63333 1.61186 1.96589C3.27853 0.296296 5.9836 0.296296 7.6497 1.96532L8.71525 3.02999ZM2.49631 7.11944L3.9923 8.6169L8.71545 13.1316L13.4283 8.62694L14.9347 7.11925C16.1144 5.93958 16.1144 4.02906 14.9346 2.84919C13.7549 1.66853 11.8435 1.66853 10.6636 2.84939L9.15738 4.35564C8.91337 4.59964 8.51779 4.59972 8.27368 4.35582L6.76561 2.849C5.58728 1.66859 3.67485 1.66859 2.49613 2.84939C1.31646 4.02906 1.31646 5.93958 2.49631 7.11944Z"
      fill="#25292D"
    />
  </svg>
);

export default HeartOutline;

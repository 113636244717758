import React from 'react';
import PropTypes from 'prop-types';

const Land = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9433 9.54306C21.9433 11.1727 20.6235 12.4924 18.9939 12.4924C17.3628 12.4924 16.0426 11.1731 16.0426 9.54306C16.0426 7.913 17.3628 6.59369 18.9939 6.59369C20.6235 6.59369 21.9433 7.91342 21.9433 9.54306ZM20.0683 9.54306C20.0683 8.94896 19.588 8.46869 18.9939 8.46869C18.3981 8.46869 17.9177 8.9488 17.9177 9.54306C17.9177 10.1373 18.3981 10.6174 18.9939 10.6174C19.588 10.6174 20.0683 10.1372 20.0683 9.54306ZM3.98643 23.5449L11.8371 9.95115C12.1979 9.32625 13.1 9.32629 13.4608 9.95123L17.8979 17.6362L19.6856 14.5377C20.0463 13.9126 20.9486 13.9124 21.3095 14.5374L26.5108 23.5449C26.8717 24.1699 26.4206 24.9513 25.6989 24.9513H20.4976H4.79827C4.07653 24.9513 3.62549 24.1699 3.98643 23.5449ZM21.0389 23.0763L18.9806 19.5114L20.498 16.8816L24.075 23.0763H21.0389ZM18.8738 23.0763L12.6488 12.2948L6.42231 23.0763H18.8738Z"
      fill={color}
    />
  </svg>
);

Land.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Land.defaultProps = {
  color: '#0075C9',
  width: '31',
  height: '31',
};

export default Land;

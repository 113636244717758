import styled from 'styled-components';

const NewsEventsStyles = styled.div`
  .loading-block {
    min-height: calc(100vh - 89px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default NewsEventsStyles;

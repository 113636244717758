import React from 'react';
import styled from 'styled-components';
import Colors from '../../colors';

export const FormContainer = styled.div`
  background-color: ${Colors.accent.bedtime.standard};
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1025px) {
    max-width: none;
    width: 50%;
    margin: unset;
  }

  .form-container {
    padding: 64px 0;
    max-width: 496px;
    width: 87%;

    @media screen and (min-width: 768px) {
      padding-bottom: 96px;
      width: 69%;
    }

    @media screen and (min-width: 1025px) {
      padding: 80px 0;
    }
  }

  .form-headline {
    box-sizing: border-box;

    h1 {
      font-weight: 700;
      font-family: 'acumin-pro', sans-serif;
      letter-spacing: -0.35px;
      color: ${Colors.primary.white.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      font-size: 40px;
      line-height: 48px;

      @media only screen and (min-width: 768px) {
        font-size: 40px;
        line-height: 48px;
      }
    }

    h4 {
      font-weight: 400;
      font-family: 'source-sans-pro', sans-serif;
      color: ${Colors.primary.white.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 4px 0;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;

      @media only screen and (min-width: 768px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .promo-disclaimer {
      font-weight: 400;
      font-family: 'source-sans-pro', sans-serif;
      color: ${Colors.primary.white.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      font-size: 12px;
      line-height: 16px;
      span {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 400;
        font-family: 'source-sans-pro', sans-serif;
        color: ${Colors.primary.white.standard};
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 12px;
        line-height: 16px;
      }

      @media only screen and (min-width: 768px) {
        font-size: 12px;
        line-height: 16px;
      }

      @media only screen and (min-width: 1025px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .divider {
      background: ${Colors.primary.white.standard};
      width: 64px;
      margin: 32px 0;
      height: 1px;
    }

    .form-offer-text {
      font: 400 20px/24px 'acumin-pro', sans-serif;
      letter-spacing: -0.35px;
      color: ${Colors.primary.white.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 0 auto;
    }
  }

  .bold {
    font-weight: bold;
  }

  .group {
    @media only screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }

  form {
    margin: 0 auto;
    padding: 20px 0 0px;

    @media only screen and (min-width: 768px) {
      padding-bottom: 0px;
    }

    @media only screen and (min-width: 1025px) {
      padding-bottom: 0px;
    }
  }

  .input-container {
    position: relative;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 100%;

      input {
        max-width: none;
      }
    }
  }

  .half-width-input {
    @media only screen and (min-width: 768px) {
      width: 47%;
    }
  }

  .full-width-input {
    width: 100%;
  }

  .error-message {
    color: ${Colors.primary.white.standard};
    font-size: 10px;
    margin-top: 2px;
  }

  .no-margin {
    margin: 0;
  }

  .submit-btn {
    width: 100%;
    background-color: ${Colors.accent.hoseWater.standard};
    color: ${Colors.accent.bedtime.standard};
    border: none;
    font-weight: 700;

    &:hover {
      opacity: 0.8;
    }
  }

  .agree-terms {
    font: 400 12px/17px 'source-sans-pro', sans-serif;
    color: ${Colors.primary.white.standard};
    width: 100%;
    margin: 27px 0 30px;

    span {
      font: 400 12px/17px 'source-sans-pro', sans-serif;
    }

    .capitalize-first-letter {
      text-transform: capitalize;
    }

    a {
      text-decoration: underline;
      color: ${Colors.primary.white.standard};
      font-family: 'source-sans-pro', sans-serif;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

const HomeIcon = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2356 24.2656V18.56H14.2844V24.2656C14.2844 24.7834 13.8646 25.2031 13.3469 25.2031H7.75C7.23223 25.2031 6.8125 24.7834 6.8125 24.2656V12.9031C6.8125 12.6179 6.94232 12.3482 7.16525 12.1703L15.1752 5.77845C15.5173 5.50549 16.0027 5.50549 16.3448 5.77845L24.3547 12.1703C24.5777 12.3482 24.7075 12.6179 24.7075 12.9031V24.2656C24.7075 24.7834 24.2878 25.2031 23.77 25.2031H18.1731C17.6554 25.2031 17.2356 24.7834 17.2356 24.2656ZM22.8325 13.3544L15.76 7.71064L8.6875 13.3544V23.3281H12.4094V17.6225C12.4094 17.1047 12.8291 16.685 13.3469 16.685H18.1731C18.6909 16.685 19.1106 17.1047 19.1106 17.6225V23.3281H22.8325V13.3544Z"
      fill={color}
    />
  </svg>
);

HomeIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

HomeIcon.defaultProps = {
  color: '#0075C9',
  width: '31',
  height: '31',
};

export default HomeIcon;

import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const HeaderWithHeroStyles = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: gray;
  @media only screen and (min-width: 769px) {
    min-height: 663px;
  }
  @media only screen and (max-width: 768px) {
    min-height: 643px;
    justify-content: ${(props) =>
      props.lowerTextOnMobile ? 'flex-end' : 'center'};
    padding-bottom: ${(props) => (props.lowerTextOnMobile ? '100px' : '0px')};
  }
  @media only screen and (max-width: 553px) {
    min-height: 553px;
    padding-bottom: ${(props) => (props.lowerTextOnMobile ? '30px' : '0px')};
  }

  .hide-dropdown {
    display: none;
  }
  .shows-form {
    margin-top: -153px;
    @media only screen and (max-width: 768px) {
      margin-top: -225px;
    }
    @media only screen and (max-width: 370px) {
      margin-top: -190px;
    }
  }
  .inner {
    position: relative;
    z-index: 5;
    display: flex;
    text-align: center;
    padding: 30px 20px;
    .container {
      position: relative;
      max-width: ${(props) =>
        props.containerWidth ? props.containerWidth : '610px'};
      @media only screen and (max-width: 768px) {
        max-width: 512px;
      }
    }

    .pre-headline-image {
      img {
        @media only screen and (max-width: 768px) {
          width: 174px;
          height: 125px;
        }
      }
    }
    .small-headline,
    .large-headline,
    .content {
      color: white;
    }
    .large-headline {
      margin-bottom: 17px;
      font-size: 60px;
      font-family: 'acumin-pro';
      font-weight: 700;
      @media only screen and (max-width: 768px) {
        font-size: '40px';
      }
      @media only screen and (max-width: 553px) {
        font-size: 38px;
        line-height: 48px;
      }
    }
    .content {
      font-family: 'acumin-pro';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
    }
    .small-headline {
      font-family: 'acumin-pro';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.3px;
    }
    .cta-button {
      background: ${Colors.primary.claytonBlue.standard};
      color: white;
      border-radius: 2px;
      padding: 17px 17px;
      font: 500 12px/100% 'source-sans-pro', sans-serif;
      margin-top: 30px;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.2s;
      text-transform: uppercase;
      font-weight: 700;
      display: inline-flex;
      &:hover {
        background: rgb(23, 158, 255);
      }
    }
  }
  .shows-form {
    .container {
      max-width: 473px;

      .small-headline {
        text-transform: none;
        font-size: 16px;
      }
    }
  }

  .image-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin-bottom: -100px;
    background-color: ${Colors.accent.grey5.standard};
    z-index: 1;
    span,
    img {
      height: 100%;
      width: 100%;
    }
    img {
      object-position: center;
      object-fit: cover;
      @media only screen and (max-width: 768px) {
        object-position: ${(props) =>
          props.backgroundPosition ? props.backgroundPosition : 'center'};
      }
    }
  }
  .curtain {
    z-index: 2;
    opacity: 0.5;
    background: #000000;
  }
  .disclaimer {
    position: absolute;
    bottom: 25px;
    text-align: center;
    z-index: 10;
    max-width: 765px;
    @media only screen and (max-width: 768px) {
      max-width: 328px;
    }
    p {
      font-size: 12px;
      line-height: 14px;
      color: white;
      @media only screen and (max-width: 768px) {
        font-size: 11px;
      }
      a {
        color: white;
        font-family: inherit;
      }
    }
  }
  .home-filter-form-box {
    position: absolute;
    width: calc(100vw - 30px);
    max-width: 824px;
    margin: 0 auto;
    left: 0;
    bottom: -25px;
    right: 0;
    z-index: 7;
    background: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: 0px solid transparent;
    padding: 38px 25px 55px;
    @media only screen and (max-width: 768px) {
      max-width: 512px;
      bottom: -55px;
      padding: 34px 18px;
    }
    @media only screen and (max-width: 553px) {
      bottom: -50px;
      padding: 34px 16px;
    }
    @media only screen and (max-width: 392px) {
      bottom: -85px;
    }
    .h3 {
      text-align: center;
      margin-bottom: 25px;
      @media only screen and (max-width: 553px) {
        font-size: 20px;
      }
    }
    .home-filter-form {
      position: relative;
      max-width: 662px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
      .dropdown {
        position: relative;
        background: white;
        border: 1px solid #cacccf;
        border-radius: 4px;
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:nth-child(2) {
          margin: 0px 12px;
          @media only screen and (max-width: 768px) {
            margin: 12px 0px;
          }
        }
        .dropdown-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 10px;
          width: 100%;
          height: 100%;
          user-select: none;
          min-height: 50px;
          img {
            transition: 0.2s;
          }
          .closed {
            transform: rotate(180deg);
          }
        }
      }
      .submit-btn {
        background: ${Colors.primary.claytonBlue.standard};
        color: white;
        border-radius: 2px;
        padding: 17px 17px;
        font: 500 12px/100% 'source-sans-pro', sans-serif;
        letter-spacing: 1px;
        text-decoration: none;
        transition: 0.2s;
        text-transform: uppercase;
        font-weight: 700;
        display: inline-flex;
        align-self: center;
        box-shadow: none;
        border: 0px solid transparent;
        cursor: pointer;
        flex-shrink: 0;
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background: rgb(23, 158, 255);
        }
      }
      .filter-wrap {
        position: absolute;
        top: 100%;
        left: -1px;
        right: 0;
        width: calc(100% + 2px);
        background: white;
        border-left: 1px solid #cacccf;
        border-right: 1px solid #cacccf;
        border-bottom: 1px solid #cacccf;
        margin: -1px auto auto;
        box-shadow: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
`;

export default HeaderWithHeroStyles;

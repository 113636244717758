import styled from 'styled-components';
import Colors from '../../colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const HomeDetailsStyles = styled.section`
  position: relative;
  padding: 80px 0px;
  @media only screen and (max-width: 500px) {
    padding: 20px 0px;
  }
  .container {
    display: flex;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0px 30px;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
      flex-direction: column;
    }
  }
  .graphic {
    position: relative;
    padding: 0px 15px;
    flex-shrink: 1;
    img {
      width: 695px;
      max-width: 100%;
      @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
        margin-top: 30px;
      }
    }
  }
  .content {
    position: relative;
    max-width: 100%;
    width: 410px;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
      width: 100%;
    }
  }
  .intro {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
    span {
      color: ${Colors.accent.grey2.standard};
      text-transform: uppercase;
      font-family: 'source-sans-pro', sans-serif;
      font-weight: 900;
      font-size: 12px;
      line-height: 12px;
    }
    h1 {
      margin-bottom: 7px;
    }
    p {
      color: ${Colors.primary.black.standard};
    }
    @media only screen and (max-width: 500px) {
      h1 {
        font-size: 25px;
      }
    }
  }
  .links {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    list-style: none;
    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      max-width: 327px;
      flex-direction: column;
    }
    @media only screen and (min-width: 500px) {
      border: 1px solid ${Colors.accent.grey4.standard};
    }
    li {
      position: relative;
      width: 100%;
      &:not(:last-child) {
        @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
          border-bottom: 1px solid ${Colors.accent.grey4.standard};
        }
        @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
          border-right: 1px solid ${Colors.accent.grey4.standard};
        }
      }
      &:hover {
        a {
          color: ${Colors.accent.bigSky.standard};
        }
        svg {
          path {
            fill: ${Colors.accent.bigSky.standard};
          }
        }
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: ${Colors.primary.claytonBlue.standard};
        text-decoration: none;
        transition: 0.3s;
        text-transform: uppercase;
        text-align: center;
        padding: 10px 0px;
        @media only screen and (min-width: 500px) {
          height: 90px;
        }
        svg {
          margin-bottom: 5px;
        }
        span {
          font-family: 'source-sans-pro', sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          @media only screen and (max-width: 500px) {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }
`;

export default HomeDetailsStyles;

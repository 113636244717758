import React from 'react';
import PropTypes from 'prop-types';
import TextWithImageStyles from './TextWithImage.styled';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const TextWithImage = ({
  smallHeadline,
  largeHeadline,
  content,
  image,
  citation,
  buttonText,
  buttonURL,
  aTagLink,
  imageOnTop,
  overlapImage,
  headlineWidth,
}) => {
  return (
    <TextWithImageStyles
      overlapImage={overlapImage}
      headlineWidth={headlineWidth}
    >
      <div className={'container ' + (imageOnTop ? 'image-on-top' : '')}>
        <div className="text-wrap">
          <div className="text">
            {smallHeadline && (
              <span className="small-headline">{smallHeadline}</span>
            )}
            {largeHeadline && (
              <h2 className="large-headline">{largeHeadline}</h2>
            )}
            {content && <p className="content">{content}</p>}
            {citation && <small>{citation}</small>}
            {!overlapImage && <div className="line"></div>}
            {buttonURL && buttonText && !aTagLink && (
              <Link to={buttonURL} className={'cta-button'}>
                {buttonText}
              </Link>
            )}
            {buttonURL && buttonText && aTagLink && (
              <a href={buttonURL} className={'cta-button'}>
                {buttonText}
              </a>
            )}
          </div>
        </div>
        {image && (
          <div className="image-box">
            <LazyLoadImage alt={image.alt} effect="blur" src={image.source} />
          </div>
        )}
      </div>
    </TextWithImageStyles>
  );
};

TextWithImage.props = {
  smallHeadline: PropTypes.string,
  largeHeadline: PropTypes.string,
  content: PropTypes.string,
  citation: PropTypes.string,
  image: PropTypes.string,
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
  aTagLink: PropTypes.bool,
  imageOnTop: PropTypes.bool,
  headlineWidth: PropTypes.string,
};

export default TextWithImage;

import React, { useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import ChevronDwnSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-dwn.svg';

import { AccordionStyles } from './Accordion.styled';

const Accordion = ({
  accordionTitle,
  navLinks,
  weekdayHours,
  fridayHours,
  saturdayHours,
  sundayHours,
}) => {
  const [active, setActive] = useState(false);
  const [menuHeight, setMenuHeight] = useState('0px');
  const [setMenuOpen, setMenuOpenState] = useState('');
  const { pathname } = useLocation();

  const content = useRef(null);

  const toggleMenu = (e) => {
    setMenuOpenState(setMenuOpen === '' ? 'menu-open' : '');
    setActive(!active);
    setMenuHeight(
      setMenuOpen === 'menu-open' ? '0px' : `${content.current.scrollHeight}px`
    );
  };

  const navContent =
    navLinks &&
    navLinks.map((link, i) => {
      return (
        <React.Fragment key={link?.path || 'link' + i}>
          {link && (
            <li key={i}>
              {link.type == 'link' && (
                <Link
                  to={{ pathname: link?.path, state: link?.state }}
                  className="footer-link reactLink"
                  onClick={() => {
                    if (
                      pathname === link?.path &&
                      typeof window !== 'undefined'
                    ) {
                      window?.scrollTo({ top: 0 });
                    }
                  }}
                >
                  {link?.text}
                </Link>
              )}
              {link.type !== 'link' && (
                <a href={link?.path} className="footer-link">
                  {link?.text}
                </a>
              )}
            </li>
          )}
        </React.Fragment>
      );
    });

  return (
    <AccordionStyles
      menuHeight={menuHeight}
      className={`menu ${accordionTitle}`}
    >
      <span className="accordion-title h5" onClick={toggleMenu}>
        {accordionTitle}
        <ReactSVG className={active ? 'up' : 'down'} src={ChevronDwnSvg} />
      </span>

      {accordionTitle === 'hours' ? (
        <ul
          className={active ? 'active hours-menu' : 'hours-menu'}
          ref={content}
        >
          {weekdayHours && (
            <li className="footer-link">
              Mon &ndash; Thurs <span className="right">{weekdayHours}</span>
            </li>
          )}
          {fridayHours && (
            <li className="footer-link">
              Friday <span className="right">{fridayHours}</span>
            </li>
          )}
          {saturdayHours && (
            <li className="footer-link">
              Saturday <span className="right">{saturdayHours}</span>
            </li>
          )}
          {sundayHours && (
            <li className="footer-link">
              Sunday <span className="right">{sundayHours}</span>
            </li>
          )}
        </ul>
      ) : (
        <ul className={active ? 'active shop-menu' : 'shop-menu'} ref={content}>
          {navContent}
        </ul>
      )}
    </AccordionStyles>
  );
};

export default Accordion;

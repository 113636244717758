import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import HouseSmartGraphic from '../../../images/svg/HouseSmart-Icons.svg';
import { fireSegmentEvent } from '../../../utils';
import HouseSmartCTAStyles from './HouseSmartCTA.styled';

export const HouseSmartCTA = ({ gaClass, retailEventLabel }) => {
  return (
    <HouseSmartCTAStyles>
      <div className="container">
        <div className="box">
          <div className="content-wrap">
            <span className="small-headline h6">Homes with you in mind</span>
            <p className="large-headline h2">HouseSmart®</p>
            <p className="content">
              Building homes takes hard work, but what about building homes that
              are beautiful and strong? Homes that are affordable and energy
              efficient? That doesn't take just hard work. You have to be smart,
              too. HouseSmart® is how we approach everything we do.
            </p>
            <div className="buttons">
              <Link
                to={'/housesmart'}
                className={gaClass ? 'cta-button ' + gaClass : 'cta-button'}
                onClick={() => {
                  fireSegmentEvent('CTA Clicked', {
                    isNav: false,
                    type: 'button',
                    text: 'Explore HouseSmart',
                    eventContext: retailEventLabel ? retailEventLabel : '',
                  });
                }}
              >
                Explore HouseSmart
              </Link>
            </div>
          </div>
          <div className="graphic">
            <img
              loading="lazy"
              src={HouseSmartGraphic}
              alt="HouseSmart Graphic"
            />
          </div>
        </div>
      </div>
    </HouseSmartCTAStyles>
  );
};

HouseSmartCTA.props = {
  retailEventLabel: PropTypes.string,
};

export default HouseSmartCTA;

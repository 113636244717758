import React from 'react';
import StyledDisclaimer from './PromoDisclaimer.Style';

const PromoDisclaimer = ({ disclaimer }) => {
  return (
    <StyledDisclaimer>
      <p>{disclaimer}</p>
    </StyledDisclaimer>
  );
};

export default PromoDisclaimer;

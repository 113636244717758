import PropTypes from 'prop-types';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import useWindowSize from '../../../hooks/useWindowSize';
import nextBtn from '../../../images/svg/next-btn.svg';
import prevBtn from '../../../images/svg/prev-btn.svg';
import { fireSegmentEvent } from '../../../utils';
import HomeTile from '../../HomeList/HomeTile/HomeTile';
import FeaturedHomesStyles from './FeaturedHomes.styled';

export const FeaturedHomes = ({
  homes,
  featuredHomes,
  buttonText,
  buttonURL,
  secondButtonText,
  secondButtonURL,
  aTagLink,
  gaClass,
  gaClassTwo,
  gaSecondClass,
  retailEventLabel,
  retailEventLabelTwo,
  dealer,
}) => {
  const [width, height] = useWindowSize();
  const isMobile = width <= 1000;
  const featuredHomesSegmentEvent = (buttonTxt, label) => {
    return fireSegmentEvent('CTA Clicked', {
      isNav: false,
      type: 'button',
      text: buttonTxt ? buttonTxt : '',
      eventContext: label ? label : '',
    });
  };
  return (
    <FeaturedHomesStyles>
      <div className="container">
        {dealer?.websiteTemplateDescription === 'BuilderModel' ? (
          <p className="h3 headline">Available Properties</p>
        ) : (
          <p className="h3 headline">Featured Homes</p>
        )}
        {isMobile ? (
          <Carousel
            className="featured-home-slider"
            showArrows={true}
            autoPlay={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            swipeable={false}
            emulateTouch={true}
            infiniteLoop={true}
            isFocusWithinTheCarousel={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  className="featured-home-btn-prev slider-btns slider-btn-prev"
                >
                  <img src={prevBtn} alt="next slide" />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  className="featured-home-btn-next slider-btns slider-btn-next"
                >
                  <img src={nextBtn} alt="next slide" />
                </button>
              )
            }
          >
            {featuredHomes &&
              featuredHomes.map((home, i) => (
                <div
                  className={gaClassTwo ? gaClassTwo : ''}
                  key={home.serialNumber ? home.serialNumber : home.modelNumber}
                >
                  <HomeTile
                    key={i}
                    retailHomeObject={home}
                    isFeatured={true}
                    dealer={dealer}
                  />
                </div>
              ))}
          </Carousel>
        ) : (
          <div className="featured-home-grid">
            {featuredHomes.map((home, i) => (
              <div
                className={
                  'featured-home-item ' + (gaClassTwo ? gaClassTwo : '')
                }
                key={home.serialNumber ? home.serialNumber : home.modelNumber}
              >
                <HomeTile
                  retailHomeObject={home}
                  isFeatured={true}
                  dealer={dealer}
                />
              </div>
            ))}
          </div>
        )}
        <div className="cta-button-wrap">
          {buttonURL && buttonText && !aTagLink && (
            <Link
              to={{ pathname: buttonURL, state: { dealer, homes } }}
              className={'cta-button ' + (gaClass ? gaClass : '')}
              onClick={() =>
                featuredHomesSegmentEvent(buttonText, retailEventLabel)
              }
            >
              {buttonText}
            </Link>
          )}
          {buttonURL && buttonText && aTagLink && (
            <a
              href={buttonURL}
              className={'cta-button ' + (gaClass ? gaClass : '')}
              onClick={() =>
                featuredHomesSegmentEvent(buttonText, retailEventLabel)
              }
            >
              {buttonText}
            </a>
          )}
          {dealer?.websiteTemplateDescription !== 'BuilderModel' && (
            <>
              {secondButtonURL && secondButtonText && !aTagLink && (
                <Link
                  to={{ pathname: secondButtonURL, state: { dealer, homes } }}
                  className={
                    'cta-button white-cta-btn ' +
                    (gaSecondClass ? gaSecondClass : '')
                  }
                  onClick={() =>
                    featuredHomesSegmentEvent(
                      secondButtonText,
                      retailEventLabelTwo
                    )
                  }
                >
                  {secondButtonText}
                </Link>
              )}
              {secondButtonURL && secondButtonText && aTagLink && (
                <a
                  href={secondButtonURL}
                  className={
                    'cta-button white-cta-btn ' +
                    (gaSecondClass ? gaSecondClass : '')
                  }
                  onClick={() =>
                    featuredHomesSegmentEvent(
                      secondButtonText,
                      retailEventLabelTwo
                    )
                  }
                >
                  {secondButtonText}
                </a>
              )}
            </>
          )}
        </div>
      </div>
    </FeaturedHomesStyles>
  );
};

FeaturedHomes.props = {
  featuredHomes: PropTypes.array,
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
  secondButtonURL: PropTypes.string,
  secondButtonText: PropTypes.string,
  aTagLink: PropTypes.bool,
  gaClass: PropTypes.string,
  gaSecondClass: PropTypes.string,
  gaClassTwo: PropTypes.string,
  retailEventLabel: PropTypes.string,
  retailEventLabelTwo: PropTypes.string,
};

export default FeaturedHomes;

import React from 'react';
import PropTypes from 'prop-types';
import TextWithImageRightStyles from './TextWithImageRight.styled';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const TextWithImageRight = ({
  smallHeadline,
  largeHeadline,
  icon,
  content,
  image,
  buttonText,
  buttonURL,
  aTagLink,
  gaClass,
}) => {
  return (
    <TextWithImageRightStyles>
      <div className="container">
        <div className="text-wrap">
          <div className="text">
            {icon && (
              <div className="icon">
                <LazyLoadImage alt="icon" effect="blur" src={icon} />
              </div>
            )}
            {largeHeadline && (
              <h2 className="large-headline">{largeHeadline}</h2>
            )}
            {smallHeadline && (
              <p className="h5 small-headline">{smallHeadline}</p>
            )}
            {content && <p className="content">{content}</p>}
            {buttonURL && buttonText && !aTagLink && (
              <Link
                to={buttonURL}
                className={'cta-button ' + (gaClass ? gaClass : '')}
              >
                {buttonText}
              </Link>
            )}
            {buttonURL && buttonText && aTagLink && (
              <a
                href={buttonURL}
                className={'cta-button ' + (gaClass ? gaClass : '')}
              >
                {buttonText}
              </a>
            )}
          </div>
        </div>
        {image && (
          <div className="image-box">
            <LazyLoadImage alt={image.alt} effect="blur" src={image.source} />
          </div>
        )}
      </div>
    </TextWithImageRightStyles>
  );
};

TextWithImageRight.props = {
  smallHeadline: PropTypes.string,
  largeHeadline: PropTypes.string,
  gaClass: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
  aTagLink: PropTypes.bool,
};

export default TextWithImageRight;

import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

export const BuilderModelFormStyles = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 100px 0px;
  @media only screen and (max-width: 500px) {
    padding: 60px 0px;
  }
  .builder-model-form-container {
    position: relative;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0px 15px;
  }
  .builder-model-form-outer {
    position: relative;
    background: white;
    padding: 32px 0px;
    border: 0px transparent solid;
    border-radius: 4px;
  }
  .builder-model-form-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: white;
    max-width: 648px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .builder-model-form-background {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .small-headline {
    display: block;
    color: white;
    font-family: 'source-sans-pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 17px;
  }
  .large-headline {
    margin: 0px auto 12px;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.35px;
    color: white;
    @media only screen and (max-width: 500px) {
      font-size: 31px;
    }
  }
  form {
    @media only screen and (max-width: 500px) {
      .animated {
        margin-bottom: 20px;
      }
    }
  }
  .line {
    display: flex;
    margin-top: 15px;
    margin-bottom: 30px;
    width: 105px;
    height: 1px;
    background: white;
  }
  .button-disclaimer-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    .button {
      order: 2;
      margin-bottom: 0px !important;
      margin-top: 20px;
    }
  }
  .request-info-form-wrapper {
    padding: 0px;
  }
  form > div:not(.half) {
    margin-bottom: 2px;
  }
`;

export default BuilderModelFormStyles;

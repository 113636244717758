import styled from 'styled-components';
import Colors from '../../../colors';
import LeafBackground from '../../../images/leaf-cost-savings-background.png';
import LeafBackgroundPromo from '../../../images/esz-promo-background.png';

const EnergySmartZeroCostSavingsStyles = styled.section`
  background: ${Colors.secondary.grey6.standard};
  padding-top: 63px;
  @media screen and (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
  }

  .savings-content {
    max-width: 87%;
    text-align: center;
    margin: 0 auto;

    @media screen and (min-width: 1025px) {
      background: url(${LeafBackground}) top center/cover no-repeat;
      height: 584px;
      width: 396px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      max-width: none;
      margin: 0;

      &.promo {
        background: url(${LeafBackgroundPromo}) top center/cover no-repeat !important;
        width: 473px;
        align-items: normal;
        justify-content: flex-start;

        .savings-content-headline {
          @media screen and (min-width: 1025px) {
            margin: 200px 0 0 72px;
          }
        }
        .savings-content-text {
          @media screen and (min-width: 1025px) {
            margin: 0 0 0 72px;
          }
        }
      }
    }

    .savings-content-headline {
      color: #00b0ac;
      letter-spacing: -0.35px;
      font-weight: 700;
      font-size: 38px;
      line-height: 1.19;
      max-width: 326px;
      margin: 0 auto;

      @media screen and (min-width: 768px) {
        max-width: none;
      }

      @media screen and (min-width: 1025px) {
        font-size: 32px;
        letter-spacing: -0.35px;
        color: ${Colors.primary.white.standard};
        max-width: 259px;
        margin: 0;
      }

      &.promo-headline {
        font-size: 35px;

        .promo-asterisks {
          font-size: 20px;
          position: relative;
          top: -12px;
        }
      }

      .asterisks {
        font-size: 32px;
      }
    }

    .divider {
      width: 27px;
      height: 2px;
      background-color: #d6d7d9;
      margin: 18px auto 18px 72px;
      display: none;

      @media screen and (min-width: 1025px) {
        display: block;
      }
    }

    .savings-content-text {
      color: ${Colors.primary.white.standard};
      font-weight: 400;
      max-width: 259px;
      display: none;

      @media screen and (min-width: 1025px) {
        display: block;
      }

      .bold {
        font-weight: 900;
      }
    }
  }

  .location-cost-container {
    width: 87%;
    position: relative;
    margin: 32px auto 0;
    text-align: center;
    padding-bottom: 41px;

    @media screen and (min-width: 768px) {
      width: 83%;
    }

    @media screen and (min-width: 1025px) {
      width: 65%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0;
      padding-bottom: 20px;
      padding-top: 42px;
      text-align: left;
      max-width: 986px;
      padding-left: 1.5%;
      padding-right: 1.5%;
    }

    @media screen and (min-width: 1440px) {
      max-width: none;
      width: 72.5%;
    }

    &.promo-cost-container {
      padding-top: 35px;
    }

    .cost-savings-item {
      max-width: 310px;
      margin: 0 auto;
      @media screen and (min-width: 1025px) {
        max-width: 300px;
        width: 32%;
        text-align: center;
        margin: 0;
      }

      @media screen and (min-width: 1440px) {
        width: 29%;
      }

      .leaf-icon {
        max-width: 80px;
      }

      .location {
        text-transform: uppercase;
        font: 700 24px/1 'acumin-pro', sans-serif;
        color: ${Colors.accent.midGrey.standard};
        letter-spacing: -0.35px;
        margin-top: 21px;

        @media screen and (min-width: 1025px) and (max-width: 1439px) {
          font-size: 18px;
        }
      }

      .location-price {
        text-transform: uppercase;
        font: 900 21px/1 'acumin-pro', sans-serif;
        color: ${Colors.primary.white.standard};
        letter-spacing: 1px;
        background-color: ${Colors.accent.midGrey.standard};
        padding-top: 11px;
        box-sizing: border-box;
        width: 220px;
        height: 49px;
        margin: 24px auto 31px;
      }

      .versus-icon {
        max-width: 41px;
      }

      .traditional {
        font: 700 18px/1 'acumin-pro', sans-serif;
        color: ${Colors.accent.midGrey.standard};
        letter-spacing: -0.35px;
        margin-top: 25px;
        margin-bottom: 25px;
      }

      .traditional-price {
        text-transform: uppercase;
        font: 500 24px/1 'acumin-pro', sans-serif;
        color: ${Colors.accent.midGrey.standard};
        letter-spacing: -0.35px;
      }
    }

    .border {
      border-top: 3px solid #d6d7d9;
      width: 100px;
      margin: 53px auto 49px;

      @media screen and (min-width: 1025px) {
        border-right: 3px solid #d6d7d9;
        width: auto;
        height: 350px;
        margin: 0;
        position: relative;
        top: 25%;
        transform: translateY(-25%);
        border-top: none;
      }
    }

    .cost-savings-disclaimer {
      font-size: 12px;
      line-height: 1.45;
      color: ${Colors.accent.grey1.standard};
      font-weight: 600;
      margin: 52px auto 0;
      text-align: left;
      @media screen and (min-width: 768px) {
        max-width: 80%;
      }
      @media screen and (min-width: 1025px) {
        margin: 31px auto 0;
        flex: 1 0 64%;
        max-width: 667px;
        font-weight: 400;
        color: ${Colors.accent.midGrey.standard};
      }
    }

    .trademark-disclaimer {
      font-size: 12px;
      line-height: 1.45;
      font-weight: 700;
      color: ${Colors.accent.midGrey.standard};
      flex: 1 0 64%;
      max-width: 667px;
      margin: 5px auto 0;
      display: none;

      @media screen and (min-width: 1025px) {
        display: block;
      }
    }
  }
`;

export default EnergySmartZeroCostSavingsStyles;

import Tippy from '@tippyjs/react';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import 'tippy.js/dist/tippy.css';
import { DXAccountContext } from '../../../contexts/DXAccountContext';
import useWindowSize from '../../../hooks/useWindowSize';
import closeBtn from '../../../images/svg/close.svg';
import infoCircle from '../../../images/svg/iconography-16x16/info-circle.svg';
import phoneIcon from '../../../images/svg/iconography-16x16/phone.svg';
import SaleHomeCard from '../../Shared/SaleHomeCard/SaleHomeCard';
import SaleHomeTileStyles from './SaleHomeTile.styled';
import SaleHomeTileModel from './SaleHomeTileModel';

const SaleHomeTile = ({
  retailHomeObject,
  dealer,
  homes,
  showFloorPlans,
  ...props
}) => {
  const accountContext = useContext(DXAccountContext);
  const favoriteList = accountContext?.state.favorites
    ? accountContext?.state.favorites
    : [];
  const homeObject = SaleHomeTileModel.initWithRetailHomeObject(
    retailHomeObject,
    dealer,
    homes,
    favoriteList
  );

  const [width, height] = useWindowSize();
  const isMobile = width <= 1023;

  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const showTooltip = () => setVisibleTooltip(true);
  const hideTooltip = () => setVisibleTooltip(false);

  const generatePriceOverride = () => {
    // create pricing area markup based on price
    if (homeObject.price.includes('Was')) {
      const parts = homeObject.price.split(' ');
      const oldPrice = parts[1].slice(0, parts[1].length - 1);
      const newPrice = parts[3];
      return (
        <div className="price-wrap">
          <div
            className="price"
            onClick={
              homeObject &&
              homeObject.price &&
              !homeObject.price.includes('Contact Us')
                ? visibleTooltip
                  ? hideTooltip
                  : showTooltip
                : null
            }
          >
            {newPrice + ' '}
            <Tippy
              interactive
              theme="clayton"
              visible={visibleTooltip}
              onClickOutside={hideTooltip}
              maxWidth={width > 1024 ? '373px' : '305px'}
              content={
                <>
                  <img
                    className="close-btn"
                    onClick={visibleTooltip ? hideTooltip : showTooltip}
                    src={closeBtn}
                    alt="close tooltip"
                  />
                  <p>
                    Base model starting prices do not include available options,
                    required delivery & installation or taxes. Installed price
                    will be higher. See home details for pricing details and
                    home information.
                  </p>
                </>
              }
            >
              <img
                className="info-circle"
                src={infoCircle}
                alt="information circle"
              />
            </Tippy>
            <span className="price-detail">
              WAS <del>{oldPrice}</del>
            </span>
          </div>
        </div>
      );
    }

    if (homeObject.price.includes('Before')) {
      const beforeOptionsPrice = homeObject.price;
      return (
        <div className="price-wrap">
          <div
            className="price before-options-price"
            onClick={
              homeObject &&
              homeObject.price &&
              !homeObject.price.includes('Contact Us')
                ? visibleTooltip
                  ? hideTooltip
                  : showTooltip
                : null
            }
          >
            {beforeOptionsPrice}
            <Tippy
              interactive
              theme="clayton"
              visible={visibleTooltip}
              onClickOutside={hideTooltip}
              maxWidth={width > 1024 ? '373px' : '305px'}
              content={
                <>
                  <img
                    className="close-btn"
                    onClick={visibleTooltip ? hideTooltip : showTooltip}
                    src={closeBtn}
                    alt="close tooltip"
                  />
                  <p>
                    Base model starting prices do not include available options,
                    required delivery & installation or taxes. Installed price
                    will be higher. See home details for pricing details and
                    home information.
                  </p>
                </>
              }
            >
              <img
                className="info-circle"
                src={infoCircle}
                alt="information circle"
              />
            </Tippy>
          </div>
        </div>
      );
    }

    if (homeObject.price.includes('Contact Us')) {
      return (
        <Link
          to={{ pathname: '/contact-us', state: { dealer, homes } }}
          className="price-contact-us"
        >
          <img className="phone-icon" src={phoneIcon} alt="phone icon" />
          {homeObject.price}
        </Link>
      );
    }

    if (homeObject.price.startsWith('$')) {
      return (
        <div
          className="price"
          onClick={
            homeObject &&
            homeObject.price &&
            !homeObject.price.includes('Contact Us')
              ? visibleTooltip
                ? hideTooltip
                : showTooltip
              : null
          }
        >
          {homeObject.price}{' '}
          <Tippy
            interactive
            theme="clayton"
            visible={visibleTooltip}
            onClickOutside={hideTooltip}
            maxWidth={width > 1024 ? '373px' : '305px'}
            content={
              <>
                <img
                  className="close-btn"
                  onClick={visibleTooltip ? hideTooltip : showTooltip}
                  src={closeBtn}
                  alt="close tooltip"
                />
                <p>
                  Base model starting prices do not include available options,
                  required delivery & installation or taxes. Installed price
                  will be higher. See home details for pricing details and home
                  information.
                </p>
              </>
            }
          >
            <img
              className="info-circle"
              src={infoCircle}
              alt="information circle"
            />
          </Tippy>
        </div>
      );
    }
  };
  homeObject.priceOverride = generatePriceOverride;

  return (
    <SaleHomeTileStyles>
      <SaleHomeCard
        key={homeObject.id}
        className="sale-home-card"
        {...props}
        homeObject={homeObject}
        isInStock={true}
        showStockIndicator={true}
        showFavoritingHeart={true}
      />
    </SaleHomeTileStyles>
  );
};

export default SaleHomeTile;

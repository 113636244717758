import React from 'react';
import EnergySmartZeroCostSavingsStyles from './EnergySmartZeroCostSavings.styled';
import EnergySmartZeroCostSavingsContent from './EnergySmartZeroCostSavingsContent';
import LeafIcon from '../../../images/svg/leaf-icon.svg';
import VersusIcon from '../../../images/svg/versus-icon.svg';
import { formatDealerBrand } from '../../../utils';
import useWindowSize from '../../../hooks/useWindowSize';

const EnergySmartZeroCostSavings = ({
  dealer,
  isPromoBackgroundImg,
  isPromo,
}) => {
  const { zone } = dealer;

  const [width, height] = useWindowSize();
  const isMobile = width <= 1024;

  const locationCostContent = () => {
    if (zone === 1) {
      return EnergySmartZeroCostSavingsContent.zoneOne.map((item, i) => {
        return (
          <React.Fragment key={item.locationPrice}>
            <div className="cost-savings-item" key={i}>
              <img
                loading="lazy"
                src={LeafIcon}
                className="leaf-icon"
                alt="leaf icon"
              />
              <p className="location">{item.location}</p>
              <p className="location-price">{item.locationPrice}</p>
              <img
                loading="lazy"
                src={VersusIcon}
                className="versusp-icon"
                alt="vs icon"
              />
              <p className="traditional">Traditional Off-Site Home</p>
              <p className="traditional-price">{item.traditionalPrice}</p>
            </div>
            {i === 0 && <div className="border"></div>}
            {i === 1 && <div className="border"></div>}
          </React.Fragment>
        );
      });
    }
    if (zone === 2 || zone === 3) {
      return EnergySmartZeroCostSavingsContent.zoneTwo.map((item, i) => {
        return (
          <React.Fragment key={item.locationPrice}>
            <div className="cost-savings-item" key={i}>
              <img
                loading="lazy"
                src={LeafIcon}
                className="leaf-icon"
                alt="leaf icon"
              />
              <p className="location">{item.location}</p>
              <p className="location-price">{item.locationPrice}</p>
              <img
                loading="lazy"
                src={VersusIcon}
                className="versus-icon"
                alt="vs icon"
              />
              <p className="traditional">Traditional Off-Site Home</p>
              <p className="traditional-price">{item.traditionalPrice}</p>
            </div>
            {i === 0 && <div className="border"></div>}
            {i === 1 && <div className="border"></div>}
          </React.Fragment>
        );
      });
    }
    if (zone === 4) {
      return EnergySmartZeroCostSavingsContent.zoneFour.map((item, i) => {
        return (
          <React.Fragment key={item.locationPrice}>
            <div className="cost-savings-item" key={i}>
              <img
                loading="lazy"
                src={LeafIcon}
                className="leaf-icon"
                alt="leaf icon"
              />
              <p className="location">{item.location}</p>
              <p className="location-price">{item.locationPrice}</p>
              <img
                loading="lazy"
                src={VersusIcon}
                className="versus-icon"
                alt="vs icon"
              />
              <p className="traditional">Traditional Off-Site Home</p>
              <p className="traditional-price">{item.traditionalPrice}</p>
            </div>
            {i === 0 && <div className="border"></div>}
            {i === 1 && <div className="border"></div>}
          </React.Fragment>
        );
      });
    }
  };
  return (
    <EnergySmartZeroCostSavingsStyles>
      <div
        className={
          isPromoBackgroundImg ? 'promo savings-content' : 'savings-content'
        }
      >
        <h3
          className={
            isPromo
              ? 'promo-headline savings-content-headline'
              : 'savings-content-headline'
          }
        >
          {isPromo ? (
            <>
              How much can you save on monthly energy bills
              <span className="promo-asterisks">**</span>?
            </>
          ) : (
            <>
              How much can you save
              {isMobile && <span className="asterisks">**</span>}?
            </>
          )}
        </h3>
        {!isPromo && (
          <>
            <div className="divider"></div>
            <p className="savings-content-text">
              Even if you don’t add solar panels right away, our EnergySmart
              Zero™ homes are{' '}
              <span className="bold">up to 50% more energy efficient**</span>{' '}
              than a typical new home. Who knew saving money could be so easy?
            </p>
          </>
        )}
      </div>
      <div
        className={
          isPromo
            ? 'promo-cost-container location-cost-container'
            : 'location-cost-container'
        }
      >
        {locationCostContent()}
        <p className="cost-savings-disclaimer">
          ** Energy savings are based on electrical energy consumption using
          NREL® BEopt® to estimate annual electrical energy consumption of a
          home certified as a DOE Zero Energy Ready Home™ compared to the same
          home built only to industry and HUD standards in the cities listed.
          Estimates will vary based on home size, geographical location, home
          operation and other factors.
        </p>
      </div>
    </EnergySmartZeroCostSavingsStyles>
  );
};

export default EnergySmartZeroCostSavings;

import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import TextField from 'pubweb-smokey/dist/components/TextField/TextField';
import React, { useEffect, useRef, useState } from 'react';
import { fireSegmentEvent } from '../../../utils';
import ModelList from '../../Shared/ModelList/ModelList';
import NameFilterStyles from './NameFilter.styled';

export const NameFilter = ({
  values,
  updateName,
  applyFilter,
  clearFilter,
  className,
  homes,
}) => {
  const defaultFocusElement = useRef(null);
  const [modelResults, setModelResults] = useState(null);
  //Starting selected index at -1 to not have it select anything on dropdown reveal.
  const [selectedIndex, setSelectedIndex] = useState(-1);
  //Last search is compared to see if there any changes (only used to check length right now for delete key usage.)
  const [lastSearch, setLastSearch] = useState('');

  const AUTOCOMPLETE_SEARCH_MIN_CHARS = 3;

  useEffect(() => {
    // auto focus when class is set to show
    if (className === 'show') {
      defaultFocusElement.current.focus();
    }
  }, [className]);

  const autoCompleteSearch = () => {
    const searchTerm = defaultFocusElement.current.value;
    setLastSearch(searchTerm);

    setModelResults(
      homes &&
        homes.filter((home) => {
          if (home.name || home.modelNumber) {
            return (
              home.name?.toLowerCase().indexOf(searchTerm?.toLowerCase()) !=
                -1 ||
              home.modelNumber
                ?.toLowerCase()
                .indexOf(searchTerm?.toLowerCase()) != -1
            );
          }
        })
    );
  };

  const onKeyUp = (ev, value) => {
    //Get the Char Code for the selected state. We are doing this instead of ev.keyCode because of
    //how android virtual keyboards sometime act in situations, such as sending over a processing
    //key code every time. (KeyCode 229)
    let keyCode = ev.target.value
      .charAt(ev.target.selectionStart - 1)
      .charCodeAt();
    let inputValue = defaultFocusElement.current.value;
    var eventKeyCode = String.fromCharCode(ev.keyCode);

    //Check if the value is a letter or number or if the value has changed. If the value hasn't changed,
    //let's ignore it!
    if (
      //Backspace or Delete Key
      ev.keyCode === 8 ||
      (ev.keyCode === 46 && lastSearch.length !== inputValue.length) ||
      ((typeof inputValue === 'undefined' || value !== inputValue) &&
        //Check if a valid character was typed.
        /[a-zA-Z0-9-_ \b]/.test(eventKeyCode) &&
        ((keyCode >= 48 && keyCode <= 57) ||
          (keyCode >= 65 && keyCode <= 90) ||
          (keyCode >= 97 && keyCode <= 122) ||
          keyCode === 32 ||
          keyCode === 189))
    ) {
      if (inputValue.length >= AUTOCOMPLETE_SEARCH_MIN_CHARS) {
        autoCompleteSearch();
      }
    }
  };

  const onKeyDown = (ev, value) => {
    if (ev.keyCode === 13 && modelResults !== null) {
      let useIndex = selectedIndex !== -1 ? selectedIndex : 0;
      let selectedItem = modelResults !== null && modelResults[useIndex];

      if (selectedItem) {
        updateName(selectedItem.name);
      }
    }

    //Arrow Up
    if (ev.keyCode === 38) {
      moveIndex(-1);
    }

    //Arrow Down
    if (ev.keyCode === 40) {
      moveIndex(1);
    }

    if (ev.keyCode === 13) {
      applyFilter();

      fireSegmentEvent('Facility Model List Filtered', {
        list: 'Homes List',
        filters: [
          {
            type: 'Name',
            value: values.text ? values.text : '',
          },
        ],
        eventContext: 'available_homes_filter_name',
      });
    }
  };

  const moveIndex = (i) => {
    if (modelResults !== null) {
      let temp = selectedIndex + i;

      if (temp < 0) {
        temp = 0;
      } else if (temp >= modelResults.length) {
        let listIndex = modelResults.length;
        temp = listIndex - 1;
      }

      setSelectedIndex(temp);
    }
  };

  let modelText =
    modelResults !== null && modelResults.length > 0 ? (
      <ModelList
        indexHighlighted={selectedIndex}
        autoCompleteOnSelection={(item) => {
          updateName(item.name);
          setModelResults(item);
        }}
        modelResults={modelResults}
        updateName={updateName}
      />
    ) : null;

  return (
    <NameFilterStyles className={className}>
      <div className="content-area">
        <TextField
          ref={defaultFocusElement}
          tabIndex="0"
          textFieldStyle="compact"
          label="Name or Model#"
          value={values.text}
          onChange={(e) => {
            updateName(e.target.value);
          }}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          automationId="nameFilterTextInput"
        />
        {modelText}
      </div>
      <div className="button-area">
        <span
          className="clear-btn"
          tabIndex="0"
          onClick={() => {
            clearFilter('text');
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              clearFilter('text');
            }
          }}
          to={'/'}
        >
          CLEAR
        </span>
        <Button
          className="ga-filter-name"
          tabIndex="0"
          automationId="nameFilterBtn"
          onClick={() => {
            applyFilter();
            fireSegmentEvent('Facility Model List Filtered', {
              list: 'Homes List',
              filters: [
                {
                  type: 'Name',
                  value: values.text ? values.text : '',
                },
              ],
              eventContext: 'available_homes_filter_name',
            });
          }}
        >
          APPLY
        </Button>
      </div>
    </NameFilterStyles>
  );
};

export default NameFilter;

import EnergySmart from '../images/svg/three-up/2_land.svg';
import BuiltSmart from '../images/svg/three-up/4_build.svg';
import HouseSmart from '../images/svg/three-up/5_construction.svg';

const threeUpItems = [
  {
    image: HouseSmart,
    alt: 'HouseSmart graphic',
    headline: 'HouseSmart®',
    content:
      'We create beautiful, affordable and strong manufactured homes with energy-efficient features. See for yourself why our homes are the smart choice.',
    buttonText: 'Explore HouseSmart®',
    buttonURL: '/housesmart',
    gaClass: 'ga-home-housesmart-btn',
    retailEventLabel: 'home_built_to_last_housesmart',
  },
  {
    image: EnergySmart,
    alt: 'EnergySmart graphic',
    headline: 'EnergySmart Zero™',
    content:
      'Enjoy a whole new level of home performance and energy savings with our EnergySmart Zero™ homes that can save you tens of thousands** over the life of your home.',
    buttonText: 'Start Saving',
    buttonURL: '/energysmart',
    gaClass: 'ga-home-energysmart-btn',
    retailEventLabel: 'home_built_to_last_energysmart',
  },
  {
    image: BuiltSmart,
    alt: 'BuiltSmart graphic',
    headline: 'BuiltSmart®',
    content:
      "A building process is only as good as what goes into it. For us, that means quality products from the world's most trusted brands, so your home is built to last a lifetime.*",
    buttonText: 'Find Out More',
    buttonURL: '/housesmart#BuiltSmart',
    gaClass: 'ga-home-builtsmart-btn',
    retailEventLabel: 'home_built_to_last_builtsmart',
  },
];

export default threeUpItems;

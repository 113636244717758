import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import {
  formatDealerName,
  formatPhoneNumberFromNumber,
} from 'pubweb-smokey/dist/utils/formatters';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import App from '../../App';
import Colors from '../../colors';
import EnvelopeClosed from '../../images/svg/icons/EnvelopeClosed';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { fireSegmentEvent, parseInitialPropsContext } from '../../utils';
import { findFeaturedHomes, findTempoHomes } from '../../utils/utils';
import FeaturedHomes from '../Shared/FeaturedHomes/FeaturedHomes';
import ContactStyles from './Contact.styled';
import ContactStructuredData from './ContactStructuredData';
import RequestInfoForm from './RequestInfoForm/RequestInfoForm';
import ScheduleVisitForm from './ScheduleVisitForm/ScheduleVisitForm';

const Contact = ({ dealer, homes, homesOnSale, isLoading }) => {
  const [showRequestInfo, setShowRequestInfo] = useState(false);
  const [showScheduleYourVisit, setShowScheduleYourVisit] = useState(false);
  const [hideBtns, setHideBtns] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location?.state?.contactForm) {
      let contactForm = location.state.contactForm;

      if (contactForm == 'requestInfo') {
        setShowScheduleYourVisit(false);
        setShowRequestInfo(true);
        history.replace({ state: { dealer, homes } });
      } else if (contactForm == 'scheduleVisit') {
        setShowScheduleYourVisit(true);
        setShowRequestInfo(false);
        history.replace({ state: { dealer, homes } });
      } else {
      }
    }
  }, []);

  const handleRequestInfoToggle = () => {
    setShowRequestInfo(!showRequestInfo) ||
      (showScheduleYourVisit &&
        setShowScheduleYourVisit(false) &&
        setShowRequestInfo(true));
  };

  const handleScheduleYourVisit = () => {
    setShowScheduleYourVisit(!showScheduleYourVisit) ||
      (showRequestInfo &&
        setShowRequestInfo(false) &&
        setShowScheduleYourVisit(true));
  };

  const clearThankYouPage = () => {
    (showRequestInfo && setShowRequestInfo(!showRequestInfo)) ||
      (showScheduleYourVisit &&
        setShowScheduleYourVisit(!showScheduleYourVisit));
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setHideBtns(false);
  };

  const showThankYou = () => {
    setHideBtns(true);
  };

  const featuredHomes = findFeaturedHomes(homes);
  const tempoHomes = findTempoHomes(homes);

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <ContactStyles>
        <Helmet>
          <title>Contact Us | {formatDealerName(dealer?.dealerName)}</title>
          <meta
            property="og:description"
            content={`Contact ${formatDealerName(
              dealer.dealerName
            )} today. We offer beautifully designed homes with long lasting durability at an incredible value. Come visit us today and find out why we’re one of America’s top home builders.`}
          />
        </Helmet>
        <ContactStructuredData dealer={dealer} homes={homes} />
        <h1>Contact Us</h1>
        <div className="contact-us-container">
          <div className="form-container">
            <div className="message-us">
              <EnvelopeClosed
                color={Colors.primary.black.standard}
                width="24px"
                height="24px"
              />
              <p className="message-headline">Send us a message</p>
            </div>
            {!hideBtns && (
              <div className="help-buttons">
                <p>How can we help?</p>
                <div className="button-container">
                  <Button
                    text="I have a general inquiry"
                    buttonStyle="outlined"
                    onClick={handleRequestInfoToggle}
                    className={`ga-contact-inquiry contact-us-btn ${
                      showRequestInfo ? 'selected' : ''
                    }`}
                    automationId="generalInquryButton"
                  />
                  <Button
                    text="I want to schedule a visit"
                    buttonStyle="outlined"
                    onClick={handleScheduleYourVisit}
                    className={`ga-contact-schedule contact-us-btn ${
                      showScheduleYourVisit ? 'selected' : ''
                    }`}
                    automationId="contactScheduleButton"
                  />
                </div>
              </div>
            )}
            {showRequestInfo && !showScheduleYourVisit && (
              <RequestInfoForm
                dealer={dealer}
                clearThankYouPage={clearThankYouPage}
                showThankYouCallBack={showThankYou}
              />
            )}
            {!showRequestInfo && showScheduleYourVisit && (
              <ScheduleVisitForm
                dealer={dealer}
                clearThankYouPage={clearThankYouPage}
                showThankYouCallBack={showThankYou}
              />
            )}
          </div>
          <div className="info-contact-container">
            <div className="contact-text">
              <h3>Let's Talk</h3>
              <p>
                Ready to find your new manufactured or modular home? Give us a
                call, stop by, or fill out this form and a representative will
                contact you soon.
              </p>
            </div>
            {dealer && (
              <div className="hc-info-container">
                <h5>{formatDealerName(dealer?.dealerName)}</h5>
                <div className="address-hours-container">
                  <div className="address">
                    <p className="hc-info-header">Address</p>
                    <p className="hc-info-text">
                      {dealer?.address1.toLowerCase()} <br />
                      {`${dealer?.city.toLowerCase()}, ${
                        dealer?.stateProvince
                      } ${dealer?.postalCode}`}
                    </p>
                    <p className="hc-info-header phone">Phone</p>
                    <a
                      className="phone-link ga-contact-phone"
                      href={`tel:${dealer?.phoneNumber}`}
                      onClick={() => {
                        fireSegmentEvent('Phone Number Link Clicked', {
                          link: dealer?.phoneNumber
                            ? formatPhoneNumberFromNumber(dealer.phoneNumber)
                            : '',
                          eventContext: 'contact_us_phone',
                        });
                      }}
                    >
                      {formatPhoneNumberFromNumber(dealer?.phoneNumber)}
                    </a>
                  </div>
                  <div className="hours">
                    <p className="hc-info-header">Hours</p>
                    <ul>
                      <li className="hc-info-text">
                        Mon-Thur <span>{dealer && dealer?.weekdayHours}</span>
                      </li>
                      <li className="hc-info-text">
                        Friday <span>{dealer && dealer?.fridayHours}</span>
                      </li>
                      <li className="hc-info-text">
                        Saturday <span>{dealer && dealer?.saturdayHours}</span>
                      </li>
                      <li className="hc-info-text">
                        Sunday <span>{dealer && dealer?.sundayHours}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ContactStyles>
      {(featuredHomes || tempoHomes) && (
        <FeaturedHomes
          featuredHomes={tempoHomes ? tempoHomes : featuredHomes}
          dealer={dealer}
          homes={homes}
          buttonText={'View All Homes'}
          buttonURL={'/homes'}
          secondButtonText={
            homesOnSale && homesOnSale.length > 0 ? 'View Sale Homes' : null
          }
          secondButtonURL={
            homesOnSale && homesOnSale.length > 0 ? '/sale-homes' : null
          }
          gaClass={'ga-contact-view-all-homes'}
          gaClassTwo={'ga-contact-home-item'}
          gaSecondClass={'ga-contact-view-sale-homes'}
          retailEventLabel={'contact_us_featured_homes_view_all'}
          retailEventLabelTwo={'contact_us_featured_homes_view_sale'}
        />
      )}
    </App>
  );
};

Contact.getInitialProps = async ({
  req,
  res,
  match,
  history,
  location,
  ...ctx
}) => {
  const { dealer, homes, homesOnSale } = await parseInitialPropsContext(
    { req, location },
    dealerService,
    homesService
  ).catch((e) => {
    console.error(e);
    return { redirectTo: '/error' };
  });

  return { dealer, homes, homesOnSale };
};

export default Contact;

import React from 'react';
import styled from 'styled-components';
import Colors from '../../colors';
import DesktopPromoBanner from '../../images/promo-images/welcome-home-banner-no-logo.jpg';

export const StyledWinterPromoThankYou = styled.div`
  .ty-message-banner {
    width: 100%;

    @media only screen and (min-width: 1025px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .loading-block {
    min-height: calc(100vh - 89px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner {
    background-color: ${Colors.primary.white.standard};
    position: relative;

    @media only screen and (min-width: 1025px) {
      height: auto;
      width: 50%;
    }

    .banner-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .have-it-made-logo {
      top: 24px;
      left: 6%;
      position: absolute;
      width: 118px;

      @media screen and (min-width: 768px) {
        width: 167px;
        top: 48px;
      }

      @media screen and (min-width: 1025px) {
        width: 228px;
        top: 7%;
        left: 9%;
      }
    }

    .have-it-made-banner {
      display: block;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }

    .have-it-made-banner-tablet {
      display: none;
      @media only screen and (max-width: 1024px) {
        display: block;
      }
      @media only screen and (max-width: 580px) {
        display: none;
      }
    }
    .have-it-made-banner-mobile {
      display: none;
      @media only screen and (max-width: 580px) {
        display: block;
      }
    }
  }
  .confirmation-message {
    background-color: ${Colors.primary.white.standard};
    box-shadow: 0 5px 21px 0 rgba(109, 110, 113, 0.13);
    width: 100%;
    margin: auto;
    padding: 80px 0 80px;

    @media screen and (min-width: 768px) {
      padding: 154px 0 157px;
    }

    @media screen and (min-width: 1025px) {
      max-width: none;
      width: 50%;
      margin: unset;
      padding: 90px 0 157px;
    }

    @media screen and (min-width: 1380px) {
      padding-top: 150px;
    }

    @media screen and (min-width: 1600px) {
      padding-top: 243px;
    }
  }

  .message-container {
    h3 {
      font-size: 25px;
      line-height: 30px;
      font-weight: 700;
      font-family: 'acumin-pro', sans-serif;
      color: ${Colors.primary.black.standard};
      letter-spacing: -0.35px;
      text-align: center;
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 0 auto 71px;

      @media only screen and (min-width: 768px) {
        font-size: 25px;
        line-height: 30px;
        letter-spacing: -0.35px;
      }
    }

    .house-container {
      max-height: 154px;
      max-width: 157px;
      margin: 0 auto 23px;
      text-align: center;
    }

    p {
      font: 400 18px/29px 'SourceSans-Pro Regular', sans-serif;
      color: ${Colors.accent.grey1.standard};
      text-align: center;
      margin: 0 auto 90px;
      width: 87%;

      @media only screen and (min-width: 768px) {
        width: auto;
      }

      br {
        display: none;

        @media only screen and (min-width: 768px) {
          display: block;
        }
      }
    }

    .button {
      font: 600 14px/100% 'Source Sans Pro', sans-serif;
      color: ${Colors.accent.bedtime.standard};
      text-align: center;
      width: 87%;
      border-radius: 2px;
      background: ${Colors.accent.hoseWater.standard};
      display: block;
      padding: 18px 0;
      margin: 0 auto;
      text-decoration: none;
      text-transform: uppercase;
      max-width: 327px;

      @media only screen and (min-width: 768px) {
        max-width: none;
        width: 392px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
`;

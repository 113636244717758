import React from 'react';

const ModelItem = ({ itemText, onSelection, highlighted = false }) => {
  let classNames = 'model-info';
  if (highlighted) {
    classNames += ' model-selected';
  }

  return (
    <li className={classNames}>
      <a onClick={onSelection}>{itemText}</a>
    </li>
  );
};

export default ModelItem;

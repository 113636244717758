import React, { useEffect, useState } from 'react';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import BedBathsFilterStyles from './BedsBathsFilter.styled';
import ItemPicker from 'pubweb-smokey/dist/components/ItemPicker';

export const BedsBathsFilter = ({
  bathsHigherLimit,
  bedsHigherLimit,
  bedsLowerLimit,
  bathsLowerLimit,
  minBeds,
  minBaths,
  maxBeds,
  maxBaths,
  updateMinBeds,
  updateMaxBeds,
  updateMinBaths,
  updateMaxBaths,
  updateBedBathLabel,
  closeFilter,
}) => {
  const generateBedItemList = () => {
    let bedsArray = [];
    let bedList = [];

    for (let beds = 1; beds <= maxBeds; beds += 1) {
      bedsArray.push(beds);
    }

    bedsArray.forEach(function (item, i) {
      bedList.push({ text: item + '+', value: item });
    });

    return bedList;
  };

  const generateBathItemList = () => {
    let bathsArray = [];
    let bathList = [];

    for (let baths = 1; baths <= maxBaths; baths += 1) {
      bathsArray.push(baths);
    }

    bathsArray.forEach(function (item, i) {
      bathList.push({ text: item + '+', value: item });
    });

    return bathList;
  };

  return (
    <BedBathsFilterStyles>
      <div className="content-area">
        <div className="content-row">
          <h6>Beds</h6>
          <ItemPicker
            id="minBeds"
            items={generateBedItemList()}
            selectedValue={minBeds === 0 ? { value: 0 } : minBeds}
            onChange={(e) => {
              updateMinBeds(parseInt(e.value));
            }}
          />
        </div>
        <div className="content-row">
          <h6>Baths</h6>
          <ItemPicker
            id="minBaths"
            items={generateBathItemList()}
            selectedValue={minBaths === 0 ? { value: 0 } : minBaths}
            onChange={(e) => {
              updateMinBaths(parseInt(e.value));
            }}
          />
        </div>
      </div>
      <div className="button-area">
        <span
          className="clear-btn"
          tabIndex="0"
          onClick={(e) => {
            e.preventDefault();
            updateBedBathLabel('Beds & Baths');
            // updateMaxBaths(bathsHigherLimit);
            // updateMaxBeds(bedsHigherLimit);
            updateMinBaths(0);
            updateMinBeds(0);
            closeFilter(false);
          }}
        >
          CLEAR
        </span>
        <Button
          tabIndex="0"
          className="ga-filter-beds-baths"
          automationId="bedBathFilter"
          onClick={(e) => {
            e.preventDefault();
            updateBedBathLabel(
              (minBeds === 0 ? '' : minBeds + '+ Beds') +
                (minBeds > 0 && minBaths > 0 ? ', ' : '') +
                (minBaths === 0 ? '' : minBaths + '+ Baths')
            );
            closeFilter(false);
          }}
        >
          APPLY
        </Button>
      </div>
    </BedBathsFilterStyles>
  );
};

export default BedsBathsFilter;

import styled from 'styled-components';

const FeaturesFilterItemStyles = styled.div`
  width: 253.5px;
  height: 37.42px;
  background: ${(props) =>
    props.isSelected ? 'rgba(0, 117, 201, 0.1)' : 'white'};
  border-radius: 4px;
  display: flex;
  justify-content: start;
  height: 37px;
  margin-bottom: 5px;
  align-items: center;

  @media screen and (max-width: 768px) {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .items-container {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  .icon {
    max-height: 37px;
    margin-right: 5px;
  }

  .label {
    font-family: 'source-sans-pro';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #4e5255;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .check-container {
    width: 42px;
    img {
      width: 100%;
    }
    flex-shrink: 0;
    margin-left: auto;
  }
`;

export default FeaturesFilterItemStyles;

import React from 'react';
import { StyledPromoContent } from './PromoContent.style';
import TradeInTradeUpLogo from '../../images/svg/tradein-tradeup-logo.svg';
import TradeInTradeUpGreenLogo from '../../images/svg/tradein-tradeup-logo-green.svg';
import ArrowCircleIcon from '../../images/svg/arrow-circle-up.svg';
import { Link } from 'react-router-dom';
import { fireSegmentEvent } from '../../utils';

const PromoContent = ({
  dealer,
  formatDealerName,
  isSeasonalCampaign,
  homes,
}) => {
  return (
    <StyledPromoContent className={isSeasonalCampaign ? 'campaign' : ''}>
      <div
        className={
          isSeasonalCampaign
            ? 'campaign-container promo-content-container'
            : 'promo-content-container'
        }
      >
        <div
          className={
            isSeasonalCampaign
              ? 'campaign-logo logo-container'
              : 'logo-container'
          }
        >
          <img
            src={
              isSeasonalCampaign ? TradeInTradeUpGreenLogo : TradeInTradeUpLogo
            }
            alt="Trade-In and Trade-up"
            loading="lazy"
          />
        </div>
        <div
          className={
            isSeasonalCampaign
              ? 'campaign-promo-content promo-content'
              : 'promo-content'
          }
        >
          <h1
            className={
              isSeasonalCampaign
                ? 'campaign promo-content-headline'
                : 'promo-content-headline'
            }
          >
            Trade up to your new home.
          </h1>
          <p
            className={
              isSeasonalCampaign ? 'campaign promo-text' : 'promo-text'
            }
          >
            Time for a change? Maybe you’re looking for more space in a home
            with modern, energy efficient features. Or maybe you’re planning to
            move to a new location. Whatever your needs are,{' '}
            {formatDealerName(dealer?.dealerName)} makes it easy with the option
            to {isSeasonalCampaign ? 'trade in' : 'trade-in'} your current
            manufactured home.
          </p>
          {isSeasonalCampaign && (
            <p className="disclaimer">
              ***Value of current manufactured home trade in toward purchase of
              a new manufactured home is determined by resale values in your
              area and final evaluation determination by home center manager.
              Deposit funds in addition to trade in may be required.
            </p>
          )}
          {isSeasonalCampaign && (
            <Link
              to={{
                pathname: '/special-offers/trade-in',
                state: { dealer, homes },
              }}
              className="learn-more"
              onClick={() => {
                fireSegmentEvent('CTA Clicked', {
                  isNav: false,
                  type: 'button',
                  text: 'Learn More',
                  eventContext: 'campaign_trade',
                });
              }}
            >
              <img src={ArrowCircleIcon} alt="circle icon" />
              Learn More
            </Link>
          )}
        </div>
      </div>
    </StyledPromoContent>
  );
};

export default PromoContent;

import { STICKER_TYPES } from '../../../utils/config';

export default class SaleHomeTileModel {
  static initWithRetailHomeObject(retailHomeObject, dealer, homes, favorites) {
    const res = new SaleHomeTileModel();

    if (!retailHomeObject) return res;

    res.id = retailHomeObject.id
      ? retailHomeObject.id
      : retailHomeObject.inventoryId;
    res.description = retailHomeObject.name
      ? retailHomeObject.name
      : retailHomeObject.description;
    res.altTextDescription = retailHomeObject.name;
    res.beds = retailHomeObject.beds;
    res.baths = retailHomeObject.baths;
    res.squareFeet = retailHomeObject.squareFeet;
    res.price = retailHomeObject.priceLabel;
    res.serialNumber = retailHomeObject.serialNumber;
    res.modelNumber = retailHomeObject.modelNumber;
    // res.favorites = favorites ? favorites : null;
    res.isFavorited = favorites
      ? favorites.some((element) => {
          if (
            element.modelNumber === retailHomeObject.modelNumber &&
            element.serialNumber === retailHomeObject.serialNumber
          ) {
            return true;
          } else {
            return false;
          }
        })
      : false;
    // if the stickerTypeId is -1 (sale home) , use the 'sale' sticker,
    // otherwise use the 'in stock' sticker
    const staticStickers =
      retailHomeObject.stickerTypeId === -1
        ? [STICKER_TYPES[-1]]
        : retailHomeObject.stickerTypeId === -6
        ? [STICKER_TYPES[-6]]
        : [STICKER_TYPES[-7]];
    // if the stickerTypeId is not 0 (blank) and not -1 (sale home)
    // use the sticker that coresponds with the stickerTypeId
    const additionalStickers =
      retailHomeObject.stickerTypeId !== 0 &&
      retailHomeObject.stickerTypeId !== -1 &&
      retailHomeObject.stickerTypeId !== -6
        ? [STICKER_TYPES[retailHomeObject.stickerTypeId]]
        : [];
    res.inventoryFeatures = retailHomeObject.inventoryFeatures
      ? retailHomeObject.inventoryFeatures
      : [];
    res.modelFeatures = retailHomeObject.modelFeatures
      ? retailHomeObject.modelFeatures
      : [];
    res.stickers = retailHomeObject.serialNumber
      ? [...staticStickers, ...additionalStickers]
      : [...additionalStickers];
    res.images = retailHomeObject.images ? retailHomeObject.images : [];

    const cardLinkPath = retailHomeObject.serialNumber
      ? `/homes/${retailHomeObject.serialNumber}`
      : `/homes/model/${retailHomeObject.modelNumber}`;

    res.cardLink = { pathname: cardLinkPath, state: { dealer, homes } };
    return res;
  }

  constructor() {
    this.id = -1;
    this.description = '';
    this.altTextDescription = '';
    this.beds = 0;
    this.baths = 0;
    this.squareFeet = 0;
    this.price = '';
    this.stickers = null;
    this.images = null;
    this.floorPlanImage = null;
    this.cardLink = '';
  }
}

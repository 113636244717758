import React from 'react';
import PropTypes from 'prop-types';
import { eventsStructuredData } from '../../utils/structuredData';

const EventsStructuredData = ({ newsAndEvents, dealer }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(eventsStructuredData(newsAndEvents, dealer)),
      }}
    ></script>
  );
};

export default EventsStructuredData;

EventsStructuredData.propTypes = {
  newsAndEvents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dealer: PropTypes.shape({
    dealerName: PropTypes.string,
    address1: PropTypes.string,
    city: PropTypes.string,
    stateProvince: PropTypes.string,
    postalCode: PropTypes.string,
    firstVanityUrl: PropTypes.string,
  }).isRequired,
};

import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const NavbarStyles = styled.div`
  .navbar {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2.5rem;
    position: absolute;
    z-index: 1;
    width: 100%;
  }

  .navbar-sticky {
    display: flex;
    justify-content: space-between;
    background: #0c1e34;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 1px 1px 1px #222;
    animation: moveDown 0.5s ease-in-out;
    padding-bottom: 20px;
    padding-top: 20px;
    max-height: 71px;
    white-space: nowrap;
    z-index: 100;

    @media only screen and (max-width: 720px) {
      justify-content: space-evenly;
    }
  }

  .portal-sticky {
    @media only screen and (max-width: 1099px) {
      top: 58px;
    }
  }

  .navbar--logo {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    animation: rotate 0.7s ease-in-out 0.5s;
  }

  @keyframes moveDown {
    from {
      transform: translateY(-5rem);
    }
    to {
      transform: translateY(0rem);
    }
  }

  @keyframes rotate {
    0% {
      transform: rotateY(360deg);
    }
    100% {
      transform: rotateY(0rem);
    }
  }

  .left-items-container {
    display: flex;
    align-items: center;
    min-width: 0;
    @media only screen and (max-width: 1076px) {
      margin-right: 30px;
    }
    @media only screen and (max-width: 720px) {
      display: none;
    }

    .home-title {
      font-family: acumin-pro;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: white;
      letter-spacing: -0.3px;
      margin-bottom: 12px !important;
      text-align: center;
      margin-bottom: 0 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      margin-right: 10px;
      font-family: source-sans-pro;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #ffffff;
    }

    .spacer {
      margin-left: 42px;
      margin-right: 42px;
      height: 31px;
      border: 0.5px solid #3e76ac;

      @media only screen and (max-width: 1076px) {
        display: none;
      }
    }

    .menu-items {
      @media only screen and (max-width: 1076px) {
        display: none;
      }
      span {
        margin-right: 25px;
        cursor: pointer;
      }
    }
  }

  .right-items-container {
    display: flex;

    .button {
      height: 32px;
      padding: 9px 14px;
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      display: inline;
      color: white;

      img {
        display: inline-block;
      }
    }

    .white-button {
      margin-right: 10px;
      background: white;
      color: #25292d;

      img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        vertical-align: middle;
        filter: invert(11%) sepia(12%) saturate(768%) hue-rotate(164deg)
          brightness(91%) contrast(86%);
      }
    }

    .brochure-button {
      text-decoration: none;
      @media only screen and (max-width: 500px) {
        display: none;
      }
    }
  }
`;

export default NavbarStyles;

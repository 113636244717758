import React from 'react';
import PropTypes from 'prop-types';
import HeaderWithImageStyles from './HeaderWithImage.styled';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const HeaderWithImage = ({
  smallHeadline,
  largeHeadline,
  content,
  image,
  contentMaxWidth,
}) => {
  return (
    <HeaderWithImageStyles>
      <div className="inner">
        <div className="container">
          <div className="content-wrap">
            {smallHeadline && (
              <span className="small-headline">{smallHeadline}</span>
            )}
            {largeHeadline && (
              <h1 className="large-headline">{largeHeadline}</h1>
            )}
            {content && (
              <p
                className="content"
                style={
                  contentMaxWidth && {
                    maxWidth: contentMaxWidth,
                  }
                }
              >
                {content}
              </p>
            )}
          </div>
          {image && (
            <div className="image-box">
              <img alt={image.alt} src={image.source} />
            </div>
          )}
        </div>
      </div>
    </HeaderWithImageStyles>
  );
};

HeaderWithImage.props = {
  smallHeadline: PropTypes.string,
  largeHeadline: PropTypes.string,
  contentMaxWidthsty: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string.isRequired,
};

export default HeaderWithImage;

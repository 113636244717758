import React from 'react';
import HeaderWithoutImageStyles from './HeaderWithoutImage.styled';
import PropTypes from 'prop-types';
import leftGraphic from '../../../images/svg/headerWithoutImageLeftGraphic.svg';
import rightGraphic from '../../../images/svg/headerWithoutImageRightGraphic.svg';

export const HeaderWithoutImage = ({ largeHeadline }) => {
  return (
    <HeaderWithoutImageStyles>
      <div className="container">
        {largeHeadline && <h1 className="large-headline">{largeHeadline}</h1>}
      </div>
      <img className="left-graphic" src={leftGraphic} alt="graphic" />
      <img className="right-graphic" src={rightGraphic} alt="graphic" />
    </HeaderWithoutImageStyles>
  );
};

HeaderWithoutImage.props = {
  largeHeadline: PropTypes.string,
};

export default HeaderWithoutImage;

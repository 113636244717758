import React from 'react';
import PropTypes from 'prop-types';

function ContactHouse() {
  return (
    <svg
      width="400"
      height="300"
      viewBox="0 0 187 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.6619 91.8441C30.4096 90.9598 30.1572 89.9492 30.1572 88.9385C30.4096 87.1698 31.6714 85.7802 33.0594 84.7695C34.4474 83.7588 35.9615 82.8745 37.0972 81.6112C40.2517 78.0738 39.3684 72.5152 41.0088 68.0935C41.7659 71.1255 42.3968 74.1575 42.3968 77.1895C42.3968 80.2215 41.7659 83.3798 39.9993 86.0328C37.7281 89.4438 34.9521 91.9705 33.3117 95.7605C31.6714 95.1288 31.1667 93.3601 30.6619 91.8441Z"
        fill="#058293"
      />
      <path
        d="M20.1887 95.2552C17.6651 93.8655 14.763 93.7392 11.987 94.2445C9.21101 94.7498 6.56121 95.8868 4.0376 97.0238C6.68739 97.1502 9.33719 97.4028 11.6084 98.6661C13.6273 99.8031 15.0153 101.572 16.7819 102.961C19.0531 104.604 28.2643 107.257 30.0308 103.467C31.1665 101.193 22.2076 96.2658 20.1887 95.2552Z"
        fill="#058293"
      />
      <path
        d="M37.4755 94.1181C38.6111 91.3388 41.0086 89.1912 43.1537 87.1698C42.5228 91.7178 42.3966 96.5185 42.7751 101.066C42.9013 102.835 43.1537 104.604 42.6489 106.246C41.0086 111.931 38.2326 107.509 37.3493 104.477C36.4661 101.066 36.0875 97.4028 37.4755 94.1181Z"
        fill="#058293"
      />
      <path
        d="M29.5262 108.141C28.012 108.267 26.624 108.773 25.1098 109.404C21.9553 110.668 18.8008 111.931 15.7725 113.321C19.6841 113.573 23.5957 113.321 27.3811 112.436C28.2644 112.184 29.2738 112.057 30.1571 112.184C31.9236 112.436 33.1854 113.952 34.4472 115.216C37.2232 117.616 44.2893 117.237 40.7563 112.815C38.7374 110.162 32.6807 107.888 29.5262 108.141Z"
        fill="#058293"
      />
      <path
        d="M15.268 86.4118C13.6276 84.7695 12.1134 83.0008 11.3563 80.8532C10.4731 78.7055 10.3469 76.3052 11.2302 74.2838C11.6087 76.3052 11.9873 78.4528 13.5014 79.8425C14.5109 80.8532 15.8989 81.3585 17.2868 81.9902C20.8199 83.7588 24.7315 87.5488 25.2362 91.7178C21.7032 91.8441 17.6654 88.6858 15.268 86.4118Z"
        fill="#058293"
      />
      <path
        d="M23.4694 70.8729C23.848 68.8515 24.8574 67.0829 25.8669 65.1879C26.4978 64.0509 27.2549 62.7876 28.5167 62.2822C28.2643 65.0616 28.0119 67.8409 27.6334 70.7465C27.5072 72.5152 27.2549 74.2839 27.5072 76.1789C27.7596 78.3265 28.0119 84.8958 24.3527 80.9795C22.7123 78.9582 23.0909 73.1469 23.4694 70.8729Z"
        fill="#058293"
      />
      <path
        d="M14.1323 68.8515C13.6275 66.3249 13.6275 63.6719 12.4919 61.2715C10.8516 57.6079 6.93995 55.4602 3.15452 54.0706C2.01889 53.5652 0.504723 53.0599 0 51.7966C3.02834 52.3019 6.18286 52.8072 9.08502 53.9442C11.9872 55.0812 14.7632 56.8499 16.4035 59.5029C18.5486 62.7875 19.558 68.3462 18.8009 72.2625C17.6653 77.9475 14.7632 72.0099 14.1323 68.8515Z"
        fill="#058293"
      />
      <path
        d="M15.394 64.5562C16.1511 66.9565 16.782 69.3569 17.2868 71.7572C17.6653 73.5259 18.0438 75.4209 18.8009 77.0632C19.8104 79.4635 21.4507 81.4848 23.0911 83.6325C26.498 88.3068 29.5263 93.3602 31.6714 98.7925C32.5546 100.687 33.1855 102.709 34.4473 104.477C36.971 108.394 41.1349 110.794 43.7847 114.584C45.9298 117.742 47.0654 121.532 47.3178 125.322C47.444 127.849 47.3178 130.376 47.6963 132.902C48.0749 135.429 48.9581 137.956 50.7247 139.724"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3625 70.7465C23.8483 75.4208 25.2363 80.6005 24.353 85.5275"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.4757 84.7695C36.7186 85.5275 35.7091 85.9065 35.0782 86.6645C34.6997 87.1698 34.5735 87.6751 34.3211 88.1805C33.8164 89.6965 33.1855 91.3388 33.1855 92.9811C33.1855 94.1181 33.3117 95.3815 33.3117 96.5185C33.3117 97.6554 33.0593 99.0451 32.3022 99.8031"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.9994 110.541C40.3779 108.52 40.3779 106.372 39.9994 104.225C39.4947 101.698 38.4852 99.1714 38.6114 96.6448C38.6114 95.8868 38.8638 95.0025 39.3685 94.3708"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4033 97.9081C18.9269 100.182 22.2076 101.193 25.4883 101.951C26.624 102.203 27.6334 102.456 28.769 102.456C29.5261 102.456 30.157 102.203 30.9141 102.33C31.7974 102.33 32.6806 102.709 33.3115 103.214"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9331 110.794C34.952 111.047 36.9709 111.678 38.6112 112.942C39.4945 113.573 40.3778 114.458 41.3872 114.963C41.7658 115.216 42.1443 115.342 42.5228 115.342C42.9014 115.342 43.4061 115.216 43.5323 114.837"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1514 84.2642C18.5488 85.9065 20.9462 87.8015 23.0913 89.6965C23.7222 90.3281 24.3531 90.9598 25.1102 91.3388C25.8673 91.7178 26.8767 91.8441 27.6338 91.3388"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.959 116.353C156.464 115.974 156.969 115.468 157.221 114.963C157.726 113.952 157.473 112.689 157.095 111.678C156.716 110.668 156.212 109.531 155.959 108.52C155.454 105.488 157.978 102.709 158.609 99.6768C157.095 101.066 155.707 102.456 154.571 104.225C153.436 105.867 152.805 107.888 152.805 109.91C152.931 112.689 153.562 114.963 153.057 117.616C154.193 117.869 155.076 117.111 155.959 116.353Z"
        fill="#058293"
      />
      <path
        d="M160.502 122.038C162.395 122.164 164.035 123.175 165.423 124.438C166.811 125.701 167.821 127.217 168.83 128.86C167.316 127.975 165.802 127.217 164.035 127.091C162.521 126.965 161.133 127.47 159.619 127.596C157.852 127.723 151.796 125.954 152.174 123.175C152.3 121.406 158.988 121.911 160.502 122.038Z"
        fill="#058293"
      />
      <path
        d="M151.417 115.216C151.796 113.321 151.165 111.299 150.66 109.404C149.398 112.184 147.758 114.837 145.865 117.237C145.234 118.121 144.477 119.006 144.098 120.143C142.963 123.933 146.117 122.417 147.632 121.027C149.398 119.511 150.912 117.616 151.417 115.216Z"
        fill="#058293"
      />
      <path
        d="M150.786 125.828C151.543 126.459 152.174 127.217 152.805 128.102C154.067 129.87 155.328 131.765 156.59 133.534C154.319 132.271 152.3 130.755 150.407 128.986C150.029 128.607 149.524 128.102 149.019 127.849C148.01 127.344 146.748 127.849 145.612 127.975C143.215 128.354 139.43 125.575 142.963 124.438C144.982 123.68 149.146 124.564 150.786 125.828Z"
        fill="#058293"
      />
      <path
        d="M166.306 118.879C167.82 118.627 169.208 118.121 170.47 117.237C171.732 116.353 172.615 115.089 172.867 113.573C171.984 114.584 170.975 115.595 169.587 115.847C168.703 116.1 167.694 115.847 166.685 115.721C164.161 115.468 160.628 116.226 158.861 118.248C160.88 119.511 164.287 119.258 166.306 118.879Z"
        fill="#058293"
      />
      <path
        d="M167.316 107.383C167.821 106.12 167.947 104.73 167.947 103.467C167.947 102.582 168.073 101.572 167.568 100.94C166.685 102.582 165.928 104.225 165.045 105.867C164.54 106.878 163.909 108.015 163.278 108.899C162.395 110.036 159.871 113.573 163.278 112.689C164.792 112.184 166.685 108.773 167.316 107.383Z"
        fill="#058293"
      />
      <path
        d="M173.12 109.531C174.256 108.267 175.265 106.878 176.653 105.867C178.924 104.477 181.827 104.604 184.35 105.235C185.233 105.488 186.243 105.614 187 105.109C185.107 104.351 183.215 103.467 181.196 103.088C179.177 102.709 177.032 102.709 175.139 103.593C172.742 104.604 170.344 107.383 169.335 109.783C167.947 113.447 171.732 111.047 173.12 109.531Z"
        fill="#058293"
      />
      <path
        d="M174.003 106.751C172.741 107.762 171.606 108.899 170.344 110.036C169.461 110.92 168.577 111.805 167.568 112.436C166.18 113.447 164.54 113.952 162.899 114.584C159.366 115.974 155.959 117.742 152.805 119.89C151.669 120.648 150.534 121.532 149.272 122.038C146.496 123.301 143.341 123.175 140.565 124.312C138.294 125.196 136.275 126.965 134.887 128.986C133.878 130.376 132.994 131.765 131.985 133.029C130.849 134.292 129.588 135.429 127.947 135.682"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.306 106.625C165.423 109.783 162.9 112.184 161.638 115.089"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.698 110.036C154.824 110.668 155.203 111.299 155.329 111.931C155.329 112.31 155.329 112.689 155.203 113.068C154.95 114.079 154.698 115.216 154.193 116.1C153.815 116.732 153.31 117.363 152.805 117.995C152.427 118.627 152.048 119.385 152.174 120.269"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.099 123.427C144.603 122.164 145.36 120.901 146.244 119.89C147.379 118.627 148.893 117.616 149.651 116.226C149.903 115.721 150.155 115.216 150.029 114.71"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.637 124.817C159.492 125.196 157.221 124.564 155.202 123.806C154.571 123.554 153.814 123.301 153.183 122.922C152.805 122.669 152.426 122.29 152.048 122.038C151.543 121.785 150.912 121.659 150.407 121.659"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.01 126.08C146.874 125.449 145.486 125.196 144.098 125.322C143.341 125.322 142.584 125.575 141.827 125.449C141.575 125.449 141.322 125.322 141.07 125.196C140.818 125.07 140.691 124.817 140.691 124.564"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.558 117.363C164.666 117.363 162.647 117.616 160.754 117.995C160.249 118.121 159.618 118.248 158.988 118.121C158.357 117.995 157.852 117.742 157.6 117.237"
        stroke="#00B0AC"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2266 43.9639H74.5728C77.9796 43.9639 80.6294 41.1846 80.6294 37.8999V6.06399C80.6294 2.65299 77.8535 0 74.5728 0H24.2266C20.8197 0 18.1699 2.77933 18.1699 6.06399V37.7736C18.1699 41.1846 20.9459 43.9639 24.2266 43.9639Z"
        fill="#FFC40A"
      />
      <path
        d="M41.2612 41.4372C41.2612 41.4372 35.2046 44.5956 34.3213 49.6489C34.0689 50.7859 32.5548 51.2912 31.7977 50.4069C29.7788 48.3856 27.3813 44.3429 27.3813 36.8893"
        fill="#FFC40A"
      />
      <path
        d="M35.8354 25.8983C37.9958 25.8983 39.747 24.1449 39.747 21.9819C39.747 19.819 37.9958 18.0656 35.8354 18.0656C33.6751 18.0656 31.9238 19.819 31.9238 21.9819C31.9238 24.1449 33.6751 25.8983 35.8354 25.8983Z"
        fill="white"
      />
      <path
        d="M49.4629 25.8983C51.6232 25.8983 53.3745 24.1449 53.3745 21.9819C53.3745 19.819 51.6232 18.0656 49.4629 18.0656C47.3026 18.0656 45.5513 19.819 45.5513 21.9819C45.5513 24.1449 47.3026 25.8983 49.4629 25.8983Z"
        fill="white"
      />
      <path
        d="M63.0903 25.8983C65.2506 25.8983 67.0019 24.1449 67.0019 21.9819C67.0019 19.819 65.2506 18.0656 63.0903 18.0656C60.93 18.0656 59.1787 19.819 59.1787 21.9819C59.1787 24.1449 60.93 25.8983 63.0903 25.8983Z"
        fill="white"
      />
      <path
        d="M33.4377 133.534C23.3432 133.155 13.2487 137.198 6.68732 143.641C3.40662 146.925 5.93023 151.979 10.9775 152.358C57.412 155.011 103.847 153.747 150.281 153.242C187.252 152.737 177.284 144.02 171.101 139.851C163.025 134.292 159.997 134.418 152.805 133.155C119.872 127.849 85.9289 126.712 52.491 129.87"
        fill="#E5EEF3"
      />
      <path
        d="M134.887 71.5045H29.5259V74.5365H134.887V71.5045Z"
        fill="#024390"
      />
      <path
        d="M108.515 35.6259L60.314 71.5045H29.5259L77.3484 35.6259H108.515Z"
        fill="#024390"
      />
      <path
        d="M108.515 35.6259V38.6579L60.314 74.5365V71.5045L108.515 35.6259Z"
        fill="#012A66"
      />
      <path
        d="M104.351 38.6579H77.6008V58.6185L62.0806 70.1148V142.251H146.748V70.1148L104.351 38.6579Z"
        fill="#0454A2"
      />
      <path
        d="M77.6009 38.6579L35.2041 70.1148V142.251H119.871V70.1148L77.6009 38.6579Z"
        fill="#0384D5"
      />
      <path
        d="M89.0831 94.3708H66.1182V142.251H89.0831V94.3708Z"
        fill="#0454A2"
      />
      <path
        d="M146.748 72.3888L108.767 43.8376H111.417L104.351 38.6579H77.6006V43.8376L125.802 79.7161H146.748V72.3888Z"
        fill="#024390"
      />
      <path
        d="M77.6006 38.6579L125.802 74.5365H156.59L108.767 38.6579H77.6006Z"
        fill="#024390"
      />
      <path
        d="M77.6006 35.6259L125.802 71.5045H156.59L108.767 35.6259H77.6006Z"
        fill="#0454A2"
      />
      <path
        d="M77.6006 35.6259V38.6579L125.802 74.5365V71.5045L77.6006 35.6259Z"
        fill="#012A66"
      />
      <path
        d="M156.59 71.5045H125.802V74.5365H156.59V71.5045Z"
        fill="#012A66"
      />
      <path
        d="M35.2041 70.1148V75.4208L77.6009 43.8376L119.871 75.4208V70.1148L77.6009 38.6579L35.2041 70.1148Z"
        fill="#0075C8"
      />
      <path
        d="M156.59 73.0205H125.802"
        stroke="#0454A2"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.146 96.8975H99.9346V116.984H109.146V96.8975Z"
        fill="white"
      />
      <path
        d="M114.95 94.3708H109.146V116.984H114.95V94.3708Z"
        fill="#0454A2"
      />
      <path
        d="M99.9347 94.3708H94.1304V116.984H99.9347V94.3708Z"
        fill="#0454A2"
      />
      <path
        d="M113.815 95.7605H110.029V104.983H113.815V95.7605Z"
        stroke="#024390"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.9251 95.7605H95.1396V104.983H98.9251V95.7605Z"
        stroke="#024390"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.815 106.372H110.029V115.595H113.815V106.372Z"
        stroke="#024390"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.9251 106.372H95.1396V115.595H98.9251V106.372Z"
        stroke="#024390"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.9346 109.025V94.4971H106.37V102.709C106.244 106.12 103.468 109.025 99.9346 109.025Z"
        fill="#0075C8"
      />
      <path
        d="M109.146 109.025V94.4971H102.71V102.709C102.71 106.12 105.613 109.025 109.146 109.025Z"
        fill="#0075C8"
      />
      <path
        d="M138.294 96.8975H129.083V116.984H138.294V96.8975Z"
        fill="#E5E5E5"
      />
      <path
        d="M144.098 94.3708H138.294V116.984H144.098V94.3708Z"
        fill="#024390"
      />
      <path
        d="M129.083 94.3708H123.278V116.984H129.083V94.3708Z"
        fill="#024390"
      />
      <path
        d="M142.963 95.7605H139.177V104.983H142.963V95.7605Z"
        stroke="#012A66"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.073 95.7605H124.288V104.983H128.073V95.7605Z"
        stroke="#012A66"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.963 106.372H139.177V115.595H142.963V106.372Z"
        stroke="#012A66"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.073 106.372H124.288V115.595H128.073V106.372Z"
        stroke="#012A66"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.083 109.025V94.4971H135.518V102.709C135.518 106.12 132.616 109.025 129.083 109.025Z"
        fill="#0062B1"
      />
      <path
        d="M138.294 109.025V94.4971H131.858V102.709C131.858 106.12 134.761 109.025 138.294 109.025Z"
        fill="#0062B1"
      />
      <path
        d="M55.3928 95.7605H46.1816V116.984H55.3928V95.7605Z"
        fill="white"
      />
      <path
        d="M61.1969 94.3708H55.3926V116.984H61.1969V94.3708Z"
        fill="#0454A2"
      />
      <path
        d="M46.3077 94.3708H40.5034V116.984H46.3077V94.3708Z"
        fill="#0454A2"
      />
      <path
        d="M60.1878 95.7605H56.4023V104.983H60.1878V95.7605Z"
        stroke="#024390"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.2981 95.7605H41.5127V104.983H45.2981V95.7605Z"
        stroke="#024390"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.1878 106.372H56.4023V115.595H60.1878V106.372Z"
        stroke="#024390"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.2981 106.372H41.5127V115.595H45.2981V106.372Z"
        stroke="#024390"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.1816 109.025V94.4971H52.6169V102.709C52.6169 106.12 49.7147 109.025 46.1816 109.025Z"
        fill="#0075C8"
      />
      <path
        d="M55.3927 109.025V94.4971H48.9575V102.709C49.0837 106.12 51.8597 109.025 55.3927 109.025Z"
        fill="#0075C8"
      />
      <path
        d="M89.0831 85.0221H66.1182V90.3281H89.0831V85.0221Z"
        fill="#0075C8"
      />
      <path
        d="M85.8025 121.027C86.5691 121.027 87.1905 120.405 87.1905 119.637C87.1905 118.87 86.5691 118.248 85.8025 118.248C85.036 118.248 84.4146 118.87 84.4146 119.637C84.4146 120.405 85.036 121.027 85.8025 121.027Z"
        fill="#FFC40A"
      />
      <path
        d="M37.98 76.4315H116.086"
        stroke="#0075C8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.2128 97.9081H69.0205V114.71H76.2128V97.9081Z"
        stroke="#024390"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.8026 97.9081H78.6104V114.71H85.8026V97.9081Z"
        stroke="#024390"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.6006 67.5882C80.9456 67.5882 83.6573 64.8732 83.6573 61.5242C83.6573 58.1751 80.9456 55.4602 77.6006 55.4602C74.2556 55.4602 71.5439 58.1751 71.5439 61.5242C71.5439 64.8732 74.2556 67.5882 77.6006 67.5882Z"
        stroke="#024390"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.339 140.103C115.834 135.808 118.484 131.765 121.638 128.86C124.793 125.954 128.705 123.933 131.733 120.901C132.616 127.849 130.345 135.05 125.802 140.356C125.929 140.356 121.008 146.546 116.339 140.103Z"
        fill="#56D7C1"
      />
      <path
        d="M121.133 141.619C120.25 136.819 117.096 132.144 112.175 128.481C111.922 133.281 113.689 138.208 117.096 142.63L117.979 143.388C118.862 144.146 120.124 144.02 120.755 143.135C121.133 142.63 121.26 142.125 121.133 141.619Z"
        fill="#058192"
      />
      <path
        d="M42.0181 140.103C42.5228 135.808 39.873 131.765 36.7185 128.86C33.564 125.954 29.6524 123.933 26.624 120.901C25.7408 127.849 28.012 135.05 32.5545 140.356C32.5545 140.356 37.4756 146.546 42.0181 140.103Z"
        fill="#56D7C1"
      />
      <path
        d="M37.2231 141.619C38.1064 136.819 41.2609 132.144 46.182 128.481C46.4343 133.281 44.6678 138.208 41.2609 142.63L40.3777 143.388C39.4944 144.146 38.2326 144.02 37.6017 143.135C37.3493 142.63 37.2231 142.125 37.2231 141.619Z"
        fill="#058192"
      />
      <path
        d="M51.9861 134.166C54.005 135.429 55.393 137.45 56.0239 139.598C57.1595 137.829 59.0522 136.566 61.0711 136.187C61.1973 136.187 59.557 140.356 59.3046 140.735C58.5475 141.746 56.9072 143.641 55.5192 143.514C52.2385 143.514 51.7338 136.44 51.9861 134.166Z"
        fill="#56D7C1"
      />
      <path
        d="M107.632 134.166C105.613 135.429 104.225 137.45 103.594 139.598C102.459 137.829 100.566 136.566 98.5469 136.187C98.4208 136.187 100.061 140.356 100.313 140.735C101.071 141.746 102.711 143.641 104.099 143.514C107.38 143.514 107.884 136.44 107.632 134.166Z"
        fill="#56D7C1"
      />
      <path
        d="M157.852 90.7071H118.736C116.086 90.7071 114.067 88.5595 114.067 86.0328V61.2715C114.067 58.6185 116.212 56.5972 118.736 56.5972H157.852C160.502 56.5972 162.521 58.7449 162.521 61.2715V85.9065C162.521 88.5595 160.502 90.7071 157.852 90.7071Z"
        fill="#FFC40A"
      />
      <path
        d="M144.603 88.8121C144.603 88.8121 149.398 91.3388 150.029 95.2551C150.155 96.1394 151.417 96.5184 152.048 95.8868C153.562 94.2444 155.455 91.2124 155.455 85.4011"
        fill="#FFC40A"
      />
      <path
        d="M127.695 77.4422C129.367 77.4422 130.723 76.0847 130.723 74.4102C130.723 72.7356 129.367 71.3782 127.695 71.3782C126.022 71.3782 124.667 72.7356 124.667 74.4102C124.667 76.0847 126.022 77.4422 127.695 77.4422Z"
        fill="white"
      />
      <path
        d="M138.294 77.4422C139.966 77.4422 141.322 76.0847 141.322 74.4102C141.322 72.7356 139.966 71.3782 138.294 71.3782C136.621 71.3782 135.266 72.7356 135.266 74.4102C135.266 76.0847 136.621 77.4422 138.294 77.4422Z"
        fill="white"
      />
      <path
        d="M148.893 77.4422C150.566 77.4422 151.921 76.0847 151.921 74.4102C151.921 72.7356 150.566 71.3782 148.893 71.3782C147.221 71.3782 145.865 72.7356 145.865 74.4102C145.865 76.0847 147.221 77.4422 148.893 77.4422Z"
        fill="white"
      />
    </svg>
  );
}

export default ContactHouse;

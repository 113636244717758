import styled from 'styled-components';

const RecentlyViewedHomesStyles = styled.section`
  padding: 75px 0px;
  border-top: 1px solid #e4e5e7;

  h3 {
    margin-bottom: 28px;
    padding: 0px 10px;
  }

  .recently-viewed-container {
    position: relative;
    @media only screen and (min-width: 320px) {
      width: 90%;
      margin: 0 auto;
      max-width: 325px;
    }

    @media only screen and (min-width: 768px) {
      width: 75%;
      max-width: 573px;
    }

    @media only screen and (min-width: 1024px) {
      width: 95%;
      max-width: 1210px;
    }
    .slide {
      .card-info-left {
        text-align: left !important;
      }
    }
  }

  .carousel-root {
    width: 100%;
  }

  .recently-viewed-homes {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    .home {
      flex: 0 1 25%;
      min-width: 0;
    }
    .home-card {
      /* gap: 10px; */
      /* @media only screen and (min-width: 1024px) {
        max-width: 275px;
      } */
    }
    .carousel-slider {
      overflow: visible !important;
    }
    .control-dots {
      bottom: -30px !important;
      .dot {
        background: #0c1e34;
      }
    }
    /* .home {
      max-width: 274px;

      @media only screen and (min-width: 768px) {
        width: 95%;
      }

      @media only screen and (min-width: 1024px) {
        width: 24%;
      }
    } */

    .slider {
      @media only screen and (min-width: 320px) {
        width: 100%;
      }

      @media only screen and (min-width: 768px) {
        width: 50%;
      }
    }

    /* This is to override default carousel styles */
    .slide {
      @media only screen and (min-width: 320px) {
        background: none;
      }

      @media only screen and (min-width: 1024px) {
        min-width: auto;
      }

      h5 {
        text-align: left; /* fixing side styles centering this */
      }
    }

    .card-image-area {
      .card-image-single {
        width: 100%;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 185px;
        /* @media only screen and (min-width: 1024px) {
          max-height: 192px;
          min-height: 140px;
          height: 15vw;
        } */
      }
    }
  }
`;

export default RecentlyViewedHomesStyles;

import React from 'react';
import BuilderModelFormStyles from './BuilderModelForm.styled';
import RequestInfoForm from '../../Contact/RequestInfoForm/RequestInfoForm';
import BuilderModelBG from '../../../images/BuilderModelBG.jpg';

export const BuilderModelForm = ({ dealer }) => {
  const thanks = () => {
    console.log('homebuildermodelform');
  };

  return (
    <BuilderModelFormStyles>
      <img
        src={BuilderModelBG}
        alt="BuilderModelBG"
        className={'builder-model-form-background'}
      />
      <div class="builder-model-form-container">
        <div className="builder-model-form-content">
          <span className="small-headline">Contact Us</span>
          <h2 className="large-headline">How can we help?</h2>
          <div className="line"></div>
        </div>
        <div class="builder-model-form-outer">
          <RequestInfoForm
            dealer={dealer}
            isBuilderModelForm={true}
            showThankYouCallBack={thanks}
          />
        </div>
      </div>
    </BuilderModelFormStyles>
  );
};

export default BuilderModelForm;

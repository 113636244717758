import React, { forwardRef, useEffect } from 'react';
import TextFieldStyles from './TextField.styled';
import PropTypes from 'prop-types';

const TextField = React.forwardRef(
  (
    {
      automationId,
      className,
      disabled,
      defaultValue,
      errorMessage,
      id,
      type,
      minLength,
      maxLength,
      name = null,
      textFieldStyle = 'primary',
      label,
      IS_PASSWORD = false,
      icon,
      onClick,
      onKeyDown,
      ...props
    },
    ref
  ) => {
    const classes = [className];

    // useEffect(() => {
    //   if (!automationId) {
    //     console.warn('Automation id was not provided.');
    //   }
    // }, []);

    if (textFieldStyle === 'primary-background') {
      classes.push('has-background');
      textFieldStyle = 'primary';
    }

    if (textFieldStyle === 'outlined') {
      classes.push('outlined');
      textFieldStyle = 'primary';
    }

    if (textFieldStyle === 'primary') {
      classes.push('animated');
    }

    if (textFieldStyle === 'compact') {
      classes.push('compact');
    }

    if (errorMessage) {
      classes.push('error');
    }

    if (disabled) {
      classes.push('disabled');
    }

    if (defaultValue || (props.value && disabled)) {
      classes.push('valid');
    }

    return (
      <TextFieldStyles className={classes.join(' ')}>
        <input
          {...props}
          className={props.value ? 'valid' : ''}
          required={textFieldStyle === 'primary'}
          defaultValue={defaultValue}
          type={type ? type : 'text'}
          id={id}
          minLength={minLength ? minLength : ''}
          maxLength={maxLength ? maxLength : ''}
          name={name || id}
          disabled={disabled}
          onClick={onClick}
          onKeyDown={onKeyDown}
          placeholder={
            textFieldStyle.toLowerCase().trim() === 'compact' ? label : null
          }
          key={id || ''}
          ref={ref}
        />

        {textFieldStyle === 'primary' ? (
          <>
            <label htmlFor={id}> {label}</label>
            <span className="focus-bar"></span>
          </>
        ) : (
          <label style={{ display: 'none' }} htmlFor={id}>
            {label}
          </label>
        )}

        {icon && (
          <img src={icon} className="text-field-icon" onClick={onClick} />
        )}

        {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
      </TextFieldStyles>
    );
  }
);

TextField.defaultProps = {
  automationId: '',
  className: '',
  disabled: false,
  defaultValue: '',
  errorMessage: '',
  id: '',
  textFieldStyle: 'primary',
  label: '',
  type: '',
};

TextField.props = {
  automationId: '',
  className: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  textFieldStyle: PropTypes.oneOf([
    '',
    'primary',
    'primary-background',
    'outlined',
    'compact',
  ]),
  label: '',
  type: '',
};

export default TextField;

export const PasswordField = React.forwardRef(({ ...props }, ref) => {
  return <TextField ref={ref} {...props} IS_PASSWORD={true} />;
});

PasswordField.defaultProps = {
  automationId: '',
  className: '',
  disabled: false,
  defaultValue: '',
  errorMessage: '',
  id: '',
  textFieldStyle: 'primary',
  label: '',
};

PasswordField.props = {
  automationId: '',
  className: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  textFieldStyle: PropTypes.oneOf([
    '',
    'primary',
    'primary-background',
    'outlined',
    'compact',
  ]),
  label: '',
};

import React from 'react';
import PropTypes from 'prop-types';
import { newsStructuredData } from '../../utils/structuredData';

const NewsStructuredData = ({ newsAndEvents, dealer }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(newsStructuredData(newsAndEvents, dealer)),
      }}
    ></script>
  );
};

export default NewsStructuredData;

NewsStructuredData.propTypes = {
  newsAndEvents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dealer: PropTypes.shape({
    firstVanityUrl: PropTypes.string,
  }).isRequired,
};

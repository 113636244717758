import styled from 'styled-components';

const LandOptionsFilterStyles = styled.div`
  position: absolute;
  width: 272px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 99;

  .content-area {
    height: 118px;
    display: flex;
    justify-content: center;
    align-items: center;

    .name-input {
      width: 232px;
    }

    .label {
      text-transform: initial;
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #4e5255;
    }
  }
  .button-area {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid #e4e5e7;

    button {
      height: 32px;
    }

    .clear-btn {
      cursor: pointer;
    }

    .clear-btn,
    a:link,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
      color: #4e5255;
      font-family: source-sans-pro;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
`;
export default LandOptionsFilterStyles;

import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

export const EmailSignupPopupStyles = styled.div`
  z-index: 9999;
  .email-modal-form {
    .modal-container {
      max-width: 500px;
      display: flex;

      .modal-content {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 20px;
        max-height: 90vh;
        width: 100%;
        .animated {
          min-height: 45px;
        }
        .h3 {
          margin-bottom: 20px;
          @media screen and (min-width: ${variables.mobile_width_percentage}px) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  /*Mobile View (SM)*/
  @media screen and (min-width: ${variables.mobile_width_percentage}px) {
    .modal-container {
      background-color: #ffffff;
      text-align: center;
      width: 275px;
      display: flex;
      justify-content: center;
    }
    .modal-content {
      display: flex;
      overflow: hidden;
    }
    .emailImage {
      display: none;
    }
    .emailContent {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 20px auto;
      width: 100%;
      padding: 20px;
      svg {
        height: 30px;
        width: 30px;
        path {
          fill: ${Colors.primary.claytonBlue.standard};
        }
      }
      h1 {
        font-family: 'acumin-pro';
        font-size: 21px;
        font-weight: 700;
        margin: -5px 30px 10px 30px;
      }
      h4 {
        color: #4c4c4c;
        font-family: 'source-sans-pro';
        font-size: 13.5px;
        font-weight: 400;
        margin: -7px 20px 15px 20px;
        padding-bottom: 20px;
      }
      a {
        border-radius: 4px;
        font-family: 'source-sans-pro';
        font-size: 8px;
        font-weight: 600;
        height: 30px;
        margin: auto;
        padding: 9px;
        width: 115px;
      }
      p {
        font-family: 'source-sans-pro';
        font-size: 6.5px;
        font-weight: 600;
        padding-top: 5px;
        text-transform: uppercase;
      }
    }
  }

  /*Mobile View (LG)*/
  @media screen and (min-width: ${variables.tablet_breakpoint}px) {
    .modal-container {
      width: 300px;
    }
    .emailContent {
      svg {
        height: 35px;
        width: 35px;
      }
      h1 {
        font-size: 23px;
      }
      h4 {
        font-size: 15px;
      }
      a {
        height: 35px;
        font-size: 9px;
        padding: 10px;
        width: 130px;
      }
      p {
        font-size: 8px;
      }
    }
  }

  /*Tablet View (SM)*/
  @media screen and (min-width: ${variables.tablet_container_maxwidth}px) {
    .modal-container {
      width: 420px;
    }
    .emailContent {
      svg {
        height: 40px;
        width: 40px;
      }
      h1 {
        font-size: 30px;
        margin: 0 50px 20px 50px;
      }
      h4 {
        font-size: 18px;
        margin: -10px 50px 25px 50px;
      }
      a {
        font-size: 13px;
        height: 37.5px;
        padding: 9px;
        width: 160px;
      }
      p {
        font-size: 10.5px;
      }
    }
  }

  /*Tablet View (LG)*/
  @media screen and (min-width: ${variables.desktop_breakpoint}px) {
    .modal-container {
      width: 600px;
    }
    .modal-content {
      flex-direction: row;
    }
    .emailImage {
      width: 100%;
      max-width: 500px;
      height: 100%;
      display: flex;
      img,
      span {
        object-fit: cover;
        object-position: 57%;
        width: 100%;
        height: 100%;
      }
    }
    .emailContent {
      margin: 50px 10px;
      svg {
        height: 25px;
        width: 25px;
      }
      h1 {
        font-size: 22px;
        margin: 0 40px 20px 40px;
      }
      h4 {
        font-size: 15px;
        margin: -10px 20px 25px 20px;
      }
      a {
        font-size: 12px;
        height: 32px;
        padding: 8.5px;
        width: 125px;
      }
      p {
        font-size: 8.5px;
      }
      .dxHeader {
        margin: 0 125px 20px 125px;
      }
      .dxStatement {
        margin: -10px 10px 25px 50px;
      }
    }
  }

  /*Laptop View*/
  @media screen and (min-width: ${variables.desktop_breakpoint_extended}px) {
    .modal-container {
      width: 850px;
    }
    .emailContent {
      margin: 75px 30px;
      svg {
        height: 38px;
        width: 38px;
      }
      h1 {
        font-size: 35px;
      }
      h4 {
        font-size: 21px;
      }
      a {
        font-size: 15px;
        height: 39px;
        width: 180px;
      }
      p {
        font-size: 9.5px;
      }
    }
  }

  /*Desktop View*/
  @media screen and (min-width: ${variables.desktop_container_maxwidth}px) {
    .modal-container {
      width: 1250px;
    }
    .emailContent {
      margin: 100px 85px;
      svg {
        height: 42px;
        width: 42px;
      }
      h1 {
        font-size: 51px;
      }
      h4 {
        font-size: 27px;
        margin: 0 55px 40px 55px;
      }
      a {
        font-size: 15px;
        height: 60px;
        padding: 19px;
        width: 240px;
      }
      p {
        font-size: 10.5px;
      }
    }
  }
`;

import React, { useEffect, useState, useContext } from 'react';
import PortalLayoutStyles from './PortalLayout.styled';
import SideNavigation from './../SideNavigation/SideNavigation';
import XLCTA from '../../../components/Shared/XLCTA/XLCTA';
import { DXAccountContext } from '../../../contexts/DXAccountContext';

export const PortalLayout = ({ dealer, homes, children }) => {
  const dxContext = useContext(DXAccountContext);
  const [updatedUser, setUpdatedUser] = useState({
    userId: null,
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });

  useEffect(() => {
    if (dxContext?.state?.account && !updatedUser.userId) {
      setUpdatedUser({
        userId: dxContext?.state?.account.userId,
        firstName: dxContext?.state?.account.firstName,
        lastName: dxContext?.state?.account.lastName,
        phone: dxContext?.state?.account.phone,
        email: dxContext?.state?.account.email,
      });
    }
    if (
      dxContext?.state?.account &&
      (dxContext?.state?.account?.firstName !== updatedUser?.firstName ||
        dxContext?.state?.account?.lastName !== updatedUser?.lastName ||
        dxContext?.state?.account?.phone !== updatedUser?.phone ||
        dxContext?.state?.account?.email !== updatedUser?.email)
    ) {
      setUpdatedUser({
        firstName: dxContext?.state?.account.firstName,
        lastName: dxContext?.state?.account.lastName,
        phone: dxContext?.state?.account.phone,
        email: dxContext?.state?.account.email,
      });
    }
  }, [dxContext?.state?.account]);

  return (
    <PortalLayoutStyles>
      <div className="portal-layout">
        <SideNavigation dealer={dealer} homes={homes} />
        <main id="maincontent">{children}</main>
      </div>
      <XLCTA
        dealer={dealer}
        dealerInfo={dealer}
        portalForms
        updatedUser={updatedUser}
        homes={homes}
        largeText="Connect with an expert"
        buttons={[
          {
            buttonModal: 'information',
            buttonText: 'Get More Information',
            gaClass: 'ga-portal-get-more-information',
          },
          {
            buttonModal: 'schedule',
            buttonText: 'Schedule a Visit',
            gaClass: 'ga-portal-schedule-a-visit',
          },
        ]}
      />
    </PortalLayoutStyles>
  );
};

export default PortalLayout;

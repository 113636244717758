import React from 'react';
import PropTypes from 'prop-types';

const Clock = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
  >
    <path
      fill={color}
      fillRule="nonzero"
      d="M8.581 3a5.58 5.58 0 0 1 5.581 5.581 5.58 5.58 0 0 1-5.58 5.581A5.58 5.58 0 0 1 3 8.582 5.58 5.58 0 0 1 8.581 3zm0 1a4.58 4.58 0 0 0-4.58 4.581 4.58 4.58 0 0 0 4.58 4.581 4.58 4.58 0 0 0 4.581-4.58A4.58 4.58 0 0 0 8.582 4zm0 1.344a.5.5 0 0 1 .5.5V8.36h2.466a.5.5 0 1 1 0 1H8.581a.5.5 0 0 1-.5-.5V5.844a.5.5 0 0 1 .5-.5z"
    />
  </svg>
);

Clock.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Clock.defaultProps = {
  color: '#ffffff',
  width: '16',
  height: '16',
};

export default Clock;

import galleryImage01 from '../images/galleryImage01.jpg';
import galleryImage02 from '../images/galleryImage02.jpg';
import galleryImage03 from '../images/galleryImage03.jpg';
import galleryImage04 from '../images/galleryImage04.jpg';
import galleryImage05 from '../images/galleryImage05.jpg';
import galleryImage06 from '../images/galleryImage06.jpg';
import galleryImage07 from '../images/galleryImage07.jpg';
import galleryImage08 from '../images/galleryImage08.jpg';

const gallery = [
  galleryImage01,
  galleryImage02,
  galleryImage03,
  galleryImage04,
  galleryImage05,
  galleryImage06,
  galleryImage07,
  galleryImage08,
];

export default gallery;

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { fireSegmentEvent } from '../../../utils';
import LongFormCTAStyles from './LongFormCTA.styled';

export const LongFormCTA = ({
  largeText,
  smallText,
  buttonText,
  buttonUrl,
  backgroundImage,
  noFeaturedEvent,
  gaClass,
  dealer,
  homes,
  retailEventLabel,
}) => {
  return (
    <LongFormCTAStyles noFeaturedEvent={noFeaturedEvent}>
      <div
        style={
          backgroundImage && { backgroundImage: `url(${backgroundImage})` }
        }
        className="cta-container"
      >
        {largeText && <span className="large-text">{largeText}</span>}
        {smallText && <p className="small-text">{smallText}</p>}
        {buttonUrl && buttonText && (
          <Link
            className={'cta-button ' + (gaClass ? gaClass : '')}
            to={{
              pathname: buttonUrl,
              state: { dealer, homes },
            }}
            onClick={() => {
              fireSegmentEvent('CTA Clicked', {
                isNav: false,
                type: 'button',
                text: buttonText ? buttonText : '',
                eventContext: retailEventLabel ? retailEventLabel : '',
              });
            }}
          >
            {buttonText}
          </Link>
        )}
      </div>
    </LongFormCTAStyles>
  );
};

LongFormCTA.props = {
  smallText: PropTypes.string,
  largeText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundColor: PropTypes.string,
  gaClass: PropTypes.string,
  retailEventLabel: PropTypes.string,
};

LongFormCTA.defaultProps = {
  backgroundColor: '#E5F5FF',
};

export default LongFormCTA;

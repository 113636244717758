import React from 'react';
import PropTypes from 'prop-types';

const ChevronDown = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.98614 0.763864C1.71765 0.495379 1.28235 0.495379 1.01386 0.763864C0.745379 1.03235 0.745379 1.46765 1.01386 1.73614L6.51386 7.23614C6.78235 7.50462 7.21765 7.50462 7.48614 7.23614L12.9861 1.73614C13.2546 1.46765 13.2546 1.03235 12.9861 0.763864C12.7177 0.495379 12.2823 0.495379 12.0139 0.763864L7 5.77773L1.98614 0.763864Z"
      fill={color}
    />
  </svg>
);

ChevronDown.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ChevronDown.defaultProps = {
  color: '#0075C9',
  width: '14',
  height: '8',
};

export default ChevronDown;

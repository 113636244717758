import React, { useContext } from 'react';
import SideNavigationStyles from './SideNavigation.styled';
import { NavLink } from 'react-router-dom';
import { DXAccountContext } from '../../../contexts/DXAccountContext';

export const SideNavigation = ({ dealer, homes }) => {
  const dxAccountContext = useContext(DXAccountContext);
  return (
    <SideNavigationStyles>
      <div className="container">
        <div className="sticky">
          <ul className="main-ul">
            <li>
              <NavLink
                exact
                to={{ pathname: '/portal', state: { dealer, homes } }}
              >
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to={{ pathname: '/portal/planner', state: { dealer, homes } }}
              >
                Your Home Planner
              </NavLink>
              <ul>
                <li>
                  <NavLink
                    exact
                    to={{
                      pathname: '/portal/home-buying-journey',
                      state: { dealer, homes },
                    }}
                  >
                    Home Buying Journey
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={{
                      pathname: '/portal/budget',
                      state: { dealer, homes },
                    }}
                  >
                    Budget
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={{
                      pathname: '/portal/land',
                      state: { dealer, homes },
                    }}
                  >
                    Land
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={{
                      pathname: '/portal/my-home-aspirations',
                      state: { dealer, homes },
                    }}
                  >
                    Home Aspirations
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink
                exact
                to={{ pathname: '/portal/favorites', state: { dealer, homes } }}
              >
                My Favorites
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to={{
                  pathname: '/portal/homes-for-you',
                  state: { dealer, homes },
                }}
              >
                Homes For You
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to={{
                  pathname: '/portal/account-details',
                  state: { dealer, homes },
                }}
              >
                Account Details
              </NavLink>
            </li>
            <li>
              <span
                onClick={() => {
                  dxAccountContext?.actions.accountLogout();
                }}
              >
                Log Out
              </span>
            </li>
          </ul>
        </div>
      </div>
    </SideNavigationStyles>
  );
};

export default SideNavigation;

import styled from 'styled-components';
import Colors from '../../../colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const LongFormCTAStyles = styled.div`
  padding: ${(props) => (props.noFeaturedEvent ? '0 25px' : '25px')};
  margin-top: ${(props) => (props.noFeaturedEvent ? '130px' : '0')};
  margin-bottom: 100px;

  @media only screen and (min-width: 768px) {
    margin-top: ${(props) => (props.noFeaturedEvent ? '145px' : '0')};
  }

  @media only screen and (min-width: 1025px) {
    margin-top: ${(props) => (props.noFeaturedEvent ? '155px' : '0')};
  }
  .cta-container {
    padding: 56px 0;
    border-radius: 2px;
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    margin: 0 auto;
    max-width: 1016px;

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      background-position: top center;
    }
    @media only screen and (max-width: 768px) {
      max-width: 511px;
    }
  }

  .large-text {
    font: 700 20px/24px 'acumin-pro';
    letter-spacing: -0.3px;
    text-align: center;
    color: ${Colors.primary.black.standard};
    display: flex;
    justify-content: center;
  }

  .small-text {
    font: 400 16px/27px 'source-sans-pro';
    text-align: center;
    color: ${Colors.accent.grey2.standard};
    margin: 14px auto 31px;
    width: 85%;
    max-width: 479px;

    @media only screen and (min-width: ${variables.tablet_breakpoint}px) {
      width: 91%;
    }

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      width: 47%;
    }
  }

  .cta-button {
    margin: 0 auto;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    justify-content: space-around;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: background-color 450ms ease;
    font-size: 12px;
    padding: 10px 15px;
    text-decoration: none;
    background-color: ${Colors.primary.claytonBlue.standard};
    color: ${Colors.primary.white.standard};
    width: 110px;

    &:hover {
      background-color: #179eff;
      color: ${Colors.primary.white.standard};
    }
  }
`;

export default LongFormCTAStyles;

import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

import Colors from 'pubweb-smokey/dist/colors.js';

export const FooterStyles = styled.footer`
  background-color: ${Colors.secondary.navy1.standard};

  .footer-container {
    margin: 0 auto;
    padding-top: 40px;
    width: 90%;

    @media only screen and (min-width: ${variables.tablet_breakpoint}px) {
      max-width: 1080px;
    }

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      padding: 60px 0 54px 0;
    }

    .top-content {
      @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 54px;
      }

      > div {
        @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
          margin-right: 40px;
          max-width: 182px;
          width: 18%;

          &:nth-of-type(4n) {
            margin-right: 0;
          }
        }
      }
    }

    a:link,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
      transition: color 400ms ease;

      &:hover {
        color: #fff;
      }
    }

    p {
      color: ${Colors.accent.grey3.standard};
    }

    .accordion-title {
      border-bottom: solid 1px ${Colors.accent.navy3.standard};
      color: #fff;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      padding: 10px 0 18px 0;
      text-transform: capitalize;

      @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        margin-bottom: 10px;

        > div {
          display: none;
        }
      }

      > div.up {
        transform: rotate(180deg);
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }

    .store-info {
      margin-top: 40px;

      @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        margin-top: 0;
      }
    }

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      .store-info {
        order: 1;
      }
      .hours {
        order: 2;
      }
      .shop {
        order: 3;
      }
      .discover {
        order: 4;
      }
      .connect {
        order: 5;
      }
    }

    .footer-link {
      font: 400 14px/100% 'source-sans-pro', sans-serif;
      color: ${Colors.accent.grey3.standard};
      letter-spacing: 0;
      margin-bottom: 20px;
      display: block;
      z-index: unset;

      &:hover {
        cursor: pointer;
      }
    }

    .right {
      float: right;
      font-family: 'source-sans-pro', sans-serif;
    }

    .hours {
      font-family: 'source-sans-pro', sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: ${Colors.accent.grey3.standard};
    }

    .hc-address,
    .hc-phone {
      text-transform: capitalize;
      margin-bottom: 10px;
      display: none;

      @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        display: flex;
      }

      a {
        line-height: 150%;
      }

      p {
        margin-right: 7px;
        padding-top: 3px;
      }

      svg {
        width: 22px;
        height: 22px;

        path {
          fill: #fff;
        }
      }
    }

    .contact-btn {
      color: ${Colors.primary.white.standard};
      background: ${Colors.primary.claytonBlue.standard};
      border-radius: 2px;
      padding: 10px 0;
      text-align: center;
      font: 500 12px/100% 'source-sans-pro', sans-serif;
      text-transform: uppercase;
      width: 103px;
      margin-top: 9px;
      display: none;
      letter-spacing: 1px;

      @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        display: block;
      }

      &:hover {
        opacity: 0.9;
      }
    }

    .footer-logo {
      margin: 0 auto 21px;
      display: block;
      width: 83px;

      @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        margin: 0 0 21px;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .tx-logo-wrap {
      position: relative;
      display: block;
      margin-top: 8px;
      p {
        font: 400 12px / 17px 'Source Sans Pro', sans-serif;
        color: #777b7e;
      }
      @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
        margin-top: 0px;
      }
      .tx-logo {
        img {
          margin-left: -4px;
          margin-bottom: 4px;
        }
        @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          flex-direction: column;
          margin-bottom: 10px;
          img {
            align-self: center;
            margin-bottom: 4px;
          }
        }
      }
    }

    .bottom-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        margin-bottom: 0;
      }

      .social-wrap {
        display: flex;
        position: relative;
        flex-direction: column;
        @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
          .tx-logo {
            margin-left: 0px;
            margin-top: 10px;
          }
        }

        @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
          justify-content: center;
          align-items: center;
          width: 100%;
          .footer-social-icons {
            transform: translateX(4px);
            margin-bottom: 10px;
          }
        }
      }

      .footer-social-icons {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;

        @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
          margin: 0;
        }

        svg {
          width: 26px;
          height: 26px;
          margin-right: 20px;

          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }

          path {
            fill: #fff;
          }
        }
      }

      .location-blurb {
        width: 69%;
        max-width: 702px;
        font: 400 12px/17px 'Source Sans Pro', sans-serif;
        color: #777b7e;
        letter-spacing: 0;
        display: none;

        @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
          display: block;
        }
      }
    }
  }
  .legal-privacy {
    background-color: ${Colors.accent.navy2.standard};
    text-align: center;
    color: ${Colors.accent.grey3.standard};
    padding: 14px 0;
    font: 400 12px/150% 'source-sans-pro', sans-serif;

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      line-height: 100%;
    }

    .mobile-wrap {
      display: block;

      @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        display: inline-block;
      }

      a {
        font-family: 'source-sans-pro', sans-serif;
      }
    }

    a {
      border-left: 1px solid ${Colors.accent.grey3.standard};
      padding-left: 10px;
      margin-left: 10px;
      display: inline-block;
      line-height: 10px;
      text-decoration: none;
      color: ${Colors.accent.grey3.standard};
      font-size: 12px;
      line-height: 150%;

      &:first-of-type {
        border-left: none;

        @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
          border-left: 1px solid ${Colors.accent.grey3.standard};
        }
      }

      &:hover {
        opacity: 0.9;
        cursor: pointer;
      }
    }

    .social-icons {
      @media only screen and (min-width: ${variables.tablet_breakpoint}px) {
        margin: 0 auto;
        max-width: 200px;
      }
    }
  }
`;

import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const SideNavigationStyles = styled.nav`
  position: relative;
  flex: 0 0 250px;
  background: white;
  padding: 0px 20px 40px;
  .sticky {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
  }
  .container {
    height: 100%;
    position: relative;
  }
  a,
  span {
    font-family: 'source-sans-pro';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #4e5255;
    text-decoration: none;
  }
  ul {
    list-style: none;
    text-decoration: none;
    li {
      position: relative;
      ul {
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }
  p {
    font-size: 14px;
    color: #a1a3a6;
  }
  .secondary-nav {
    position: relative;
    margin-bottom: 20px;
  }
  .main-ul {
    position: relative;
    margin-bottom: 20px;
    li {
      margin-bottom: 10px;
      a,
      span {
        font-family: 'source-sans-pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        color: #25292d;
        padding: 5px 10px;
        transition: background-color 0.3s;
        border: 0px solid transparent;
        border-radius: 8px;
        &:hover {
          background: rgba(0, 117, 201, 0.1);
          color: #4e5255;
        }
      }
      a.active {
        color: ${Colors.primary.claytonBlue.standard};
      }
      span {
        cursor: pointer;
      }
      ul {
        li {
          margin-bottom: 0px;
          a,
          span {
            font-family: 'source-sans-pro';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 30px;
            color: #4e5255;
            text-decoration: none;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1099px) {
    display: none;
  }
`;

export default SideNavigationStyles;

const EnergySmartCostSavingsContent = {
  zoneOne: [
    {
      location: `charlotte, nc`,
      locationPrice: '$91.59 AVG. / MO.',
      traditionalPrice: '$167.08 AVG. / MO.',
    },
    {
      location: `allentown, pa`,
      locationPrice: '$147.34 AVG. / MO.',
      traditionalPrice: '$268.38 AVG. / MO.',
    },
    {
      location: `richmond, va`,
      locationPrice: '$102.95 AVG. / MO.',
      traditionalPrice: '$194.35 AVG. / MO.',
    },
  ],
  zoneTwo: [
    {
      location: `tallahassee, fl`,
      locationPrice: '$84.84 AVG. / MO.',
      traditionalPrice: '$143.07 AVG. / MO.',
    },
    {
      location: `knoxville, tn`,
      locationPrice: '$103.27 AVG. / MO.',
      traditionalPrice: '$185.43 AVG. / MO.',
    },
    {
      location: `jackson, ms`,
      locationPrice: '$91.90 AVG. / MO.',
      traditionalPrice: '$153.07 AVG. / MO.',
    },
  ],
  zoneFour: [
    {
      location: `dallas, tx`,
      locationPrice: '$95.53 AVG. / MO.',
      traditionalPrice: '$179.43 / MO.',
    },
    {
      location: `sacramento, ca`,
      locationPrice: '$154.10 AVG. / MO.',
      traditionalPrice: '$296.18 AVG. / MO.',
    },
    {
      location: `colorado springs, co`,
      locationPrice: '$111.25 AVG. / MO.',
      traditionalPrice: '$235.73 AVG. / MO.',
    },
  ],
};

export default EnergySmartCostSavingsContent;

import React from 'react';
import PropTypes from 'prop-types';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import ContactThankYouStyles from './ContactThankYou.styled';
import ContactHouse from './ContactHouse';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';

const ContactThankYou = ({ closeFunction, dealer }) => {
  return (
    <ContactThankYouStyles>
      <div className="h3">{formatDealerName(dealer?.dealerName)}</div>
      <ContactHouse />
      <p>
        Thank you for reaching out. A home center consultant will be in touch
        shortly.
      </p>
      <Button
        text="Okay"
        onClick={closeFunction}
        automationId="contactThankYouButton"
      />
      <div className="disclaimer" />
    </ContactThankYouStyles>
  );
};

ContactThankYou.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ContactThankYou;

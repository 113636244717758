import React from 'react';
import PropTypes from 'prop-types';

const PlayBtnSmall = ({ color, width, height }) => (
  <svg
    id="playBtn"
    width={width}
    height={height}
    viewBox="0 0 77 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        d="M38.5 67C55.3447 67 69 53.3447 69 36.5C69 19.6553 55.3447 6 38.5 6C21.6553 6 8 19.6553 8 36.5C8 53.3447 21.6553 67 38.5 67Z"
        fill="white"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="8"
        y="6"
        width="61"
        height="61"
      >
        <path
          d="M38.5 67C55.3447 67 69 53.3447 69 36.5C69 19.6553 55.3447 6 38.5 6C21.6553 6 8 19.6553 8 36.5C8 53.3447 21.6553 67 38.5 67Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.6602 36.5L31.6602 48L31.6602 25L51.6602 36.5Z"
        fill="#0075C9"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="77"
        height="77"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

PlayBtnSmall.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

PlayBtnSmall.defaultProps = {
  color: '#0075C9',
  width: '77',
  height: '77',
};

export default PlayBtnSmall;

import styled from 'styled-components';
import Colors from '../../colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const HomeDetailsStyles = styled.div`
  position: relative;
  box-sizing: border-box;

  .back-btn-portal {
    position: relative;
    margin-bottom: -20px;
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-start;
    img {
      margin-right: 5px;
    }
    p {
      /* font-weight: 400;
      font-size: 14px;
      line-height: 22px; */
      color: #4e5255;
      cursor: pointer;
    }
  }
  .home-image-area {
    .favorite-button {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 8;
      background: white;
      height: 33px;
      width: 33px;
      border: 0px solid transparent;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
      /* box-shadow: 0px 2px 8px rgb(19 19 19 / 39%); */
    }
  }

  .orange-sticker {
    background-color: ${Colors.accent.cheesePuff.standard} !important;
  }

  .purple-sticker {
    background-color: #7e71c1 !important;
  }

  .centered-page-content-container {
    width: 90%;
    max-width: 1015px;
    margin-left: auto;
    margin-right: auto;
  }

  .tippy-content {
    padding: 30px;
    .close-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }

  [data-tippy-root] {
    max-width: 90%;
  }

  [data-theme='clayton'] {
    background-color: ${Colors.primary.claytonBlue.standard};
    p {
      color: white;
      font-size: 14px;
      line-height: 22px;
    }
  }

  [data-theme='clayton'][data-animatefill] {
    background-color: transparent;
  }

  [data-theme='clayton'] .tippy-backdrop {
    background-color: ${Colors.primary.claytonBlue.standard};
  }

  .tippy-arrow {
    color: ${Colors.primary.claytonBlue.standard};
  }

  .top-area-container {
    flex: 1;
    display: flex;
    justify-content: space-between;

    .card-info-area {
      width: 72%;
      margin-top: 45px;

      @media screen and (max-width: 720px) {
        width: 100%;
      }

      .card-heading {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.35px;
        margin-bottom: 12px !important;
      }

      .card-heading,
      .card-home-specs {
        margin: 0;
      }

      .card-home-specs {
        margin-top: 16px;

        @media only screen and (min-width: 1100px) {
          margin-top: 0px;
          margin-left: 24px;
        }

        span {
          font-family: source-sans-pro;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 27px;
          color: #25292d;
          margin-right: 22px;
          @media screen and (max-width: 720px) {
            font-size: 14px;
            margin-right: 15px;
          }
        }

        span > img {
          width: 14px;
          height: 14px;
          margin-right: 6px;
          vertical-align: middle;
          filter: invert(11%) sepia(11%) saturate(844%) hue-rotate(164deg)
            brightness(96%) contrast(87%);
        }
      }

      .home-specs-container {
        display: block;
        .price-top {
          margin-right: 24px;
        }

        @media only screen and (min-width: 1100px) {
          display: flex;
          flex-wrap: wrap;
        }
      }

      .price {
        color: ${Colors.primary.black.standard};
        display: flex;
        margin-top: -3px;

        .phone-icon {
          margin-right: 1px;
          filter: invert(26%) sepia(46%) saturate(4667%) hue-rotate(189deg)
            brightness(95%) contrast(101%);
          width: 15px;
          height: 15px;
          vertical-align: middle;
          display: inline-block;
        }

        .price-contact-us {
          display: block;
          text-decoration: none;
          font-family: 'source-sans-pro';
          font-size: 14px;
          line-height: 16px;
          text-align: right;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #0075c9;
          font-weight: 600;
          width: 104px;
          @media only screen and (min-width: 1100px) {
            margin-right: 24px;
          }
        }

        .large-price {
          font-size: 24px;
        }
        .small-price {
          font-size: 12px;
        }

        .label {
          margin: 0;
          padding: 0;
        }

        h4 {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.3px;

          @media only screen and (min-width: 1100px) {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }

      .spacer {
        border: 1px solid #a1a3a6;
        @media only screen and (max-width: 1100px) {
          display: none;
        }
      }

      .intro {
        margin-top: -3px;
      }

      .info-circle {
        cursor: pointer;
        margin-left: 7px;
        margin-top: 3px;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        filter: invert(11%) sepia(11%) saturate(844%) hue-rotate(164deg)
          brightness(96%) contrast(87%);
      }
    }
  }

  .sticker-container {
    display: flex;

    .sticker {
      background-color: #0075c9;
      border-radius: 30px;
      color: white;
      padding: 4px 7px;
      margin-right: 7px;
      height: 21px;

      font-family: source-sans-pro;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: right;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #ffffff;
    }

    .sticker-sale,
    .sticker-price-drop {
      background-color: #ef4b5d !important;
    }

    .sticker-in-stock {
      background-color: #00a3a0 !important;
    }
  }

  .home-image-area {
    margin-top: 60px;
    /* height: 780px; */
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (min-width: 1100px) {
      margin-bottom: 10px;
    }

    .slide {
      background: transparent;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;

        &.flp {
          object-fit: none;
          max-width: 100%;
          height: auto;
        }
      }
      .slide-inner:has(.flp) {
        display: flex;
        align-items: center;
      }
    }

    .aspect-ratio-sizer {
      width: 100%;
    }

    /* & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    } */

    .thumbs-wrapper {
      margin: 20px 0;

      @media only screen and (min-width: 721px) and (max-width: 1099px) {
        margin-bottom: 0px;
      }
    }

    .thumb {
      height: 86px;
      margin-right: 0px;
      padding: 0;
      opacity: 0.5;

      &:hover {
        border: 2px solid #333;
      }

      &.selected {
        opacity: 1;
        border: none;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;

        &.thumbs-flp {
          object-fit: unset;
          max-width: 100%;
          height: auto;
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &:has(.thumbs-flp) {
        position: relative;
      }
    }

    .slider-btns {
      background-color: transparent;
      border: none;
      cursor: pointer;
      margin: 8px 7px 0 0;
      position: absolute;
      z-index: 2;
      top: calc(50% - 15px);
      width: 56px;
      height: 56px;
      cursor: pointer;

      img {
        height: 56px;
      }
    }

    .slider-btn-prev {
      transform: scaleX(-1);
      left: -30px;
    }

    .slider-btn-next {
      right: -30px !important;
    }

    .carousel-slider {
      overflow: visible !important;
      aspect-ratio: 3/2;
      .slider-wrapper,
      .slider,
      .slide,
      .slide-inner {
        height: 100%;

        .tools {
          .zoom-in {
            background: hsla(0, 0%, 100%, 0.8);
            width: 40px;
            height: 40px;
            border: none;
            outline: 0;
            padding: 0;
            position: absolute;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            position: absolute;
            z-index: 9;
            right: 10px;
            bottom: 10px;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .three-d-tour-desktop {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 17px 0px 0px 15px;
      position: absolute;
      width: 159px;
      height: 50px;
      left: 17px;
      top: 20px;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      font-family: source-sans-pro;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #25292d;
      z-index: 50;
      text-decoration: none;

      img {
        padding-right: 9px;
      }
    }

    .three-d-tour {
      display: none;
      flex-direction: row;
      align-items: flex-start;
      padding: 17px 0px 0px 15px;
      position: absolute;
      width: 159px;
      height: auto;
      /* left: 17px;
      top: 20px; */
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      font-family: source-sans-pro;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #25292d;
      z-index: 50;
      text-decoration: none;

      img {
        padding-right: 9px;
      }
    }

    .three-d-tour:focus {
      outline: 5px auto -webkit-focus-ring-color;
    }

    @media screen and (max-width: 1099px) {
      /* height: 595px; */
      width: 100%;

      .home-details-extra-with-tour {
        justify-content: space-between;
        margin-top: 10px;
      }

      .home-details-extra {
        margin-top: 10px;
      }

      .three-d-tour-desktop {
        display: none;
      }

      .slide {
        img {
          height: 100%;
          width: 100%;
        }
      }

      .three-d-tour {
        width: 127px;
        position: relative;
        /* top: 465px;
        left: 0px; */
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        padding: 9px 8px;
        display: flex;

        img {
          padding-left: 4px;
          width: 24px;
        }
      }
    }

    @media screen and (max-width: 720px) {
      /* height: 439px; */
      width: 100%;

      .slide {
        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 561px) {
      /* height: 286px; */
      width: 100%;

      .image-disclaimer {
        flex: 0 0 200px;
        margin-left: 30px;
        text-align: right;
      }

      .slide {
        img {
          height: 100%;
          width: 100%;
        }
      }

      .three-d-tour {
        width: 127px;
        /* top: 320px;
        left: 0px; */
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        padding: 9px 8px;

        img {
          width: 24px;
          padding-right: 4px;
        }
      }
    }
  }

  .home-details-extra {
    position: relative;
    display: flex;
    margin-bottom: 30px;
    justify-content: flex-end;
  }

  .home-details-extra-with-tour {
    position: relative;
    display: flex;
    margin-bottom: 30px;
    justify-content: flex-end;
  }

  .image-disclaimer {
    font-family: source-sans-pro;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #777b7e;
    /* float: right;
    margin-top: 45px;
    width: 50%; */

    /* @media only screen and (min-width: 562px) {
      margin-top: 30px;
      width: auto;
    }

    @media only screen and (min-width: 721px) {
      float: none;
    }

    @media only screen and (min-width: 721px) and (max-width: 1099px) {
      margin-top: -20px;
    }

    @media only screen and (min-width: 1100px) {
      margin-top: 0;
    } */
  }

  .request-info {
    display: none;

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      display: block;
    }
  }

  .request-info-mobile {
    display: block;

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      display: none;
    }
  }

  .request-info,
  .request-info-mobile {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin-top: 45px;
    height: 83px;
    margin-bottom: 96px;

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      border-left: 1px solid ${Colors.accent.grey4.standard};
      width: 286px;
      padding-left: 66px;
      margin-bottom: 0;
    }

    .request-info-btn {
      margin-bottom: 14px;
      height: 50px;
      width: 100%;
      margin-top: 5px;

      @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        width: 215px;
      }
    }

    .link-container {
      display: flex;
      justify-content: center;

      /* @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        justify-content: space-between;
      } */

      button,
      a {
        font: 600 12px/14px source-sans-pro;
        color: ${Colors.primary.claytonBlue.standard};
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: none;
        display: inline;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;

        &:first-of-type {
          margin-right: 20px;
          @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
            margin-right: 0;
          }
        }

        svg {
          vertical-align: middle;
          margin-right: 2px;
        }

        span {
          font-family: source-sans-pro;
          margin-top: 1px;
        }
      }
    }
  }

  .request-info-mobile {
    display: block;

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      display: none;
    }
  }

  .contact-container {
    text-align: center;
    padding-bottom: 75px;

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      padding-bottom: 0;
    }

    h2 {
      margin: 126px auto 42px;
    }
    .contact-options {
      max-width: 914px;
      margin: 0 auto;

      @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        display: flex;
        width: 90%;
        justify-content: space-between;
      }

      .option {
        border-bottom: 1px solid ${Colors.accent.grey4.standard};
        padding: 54px 0;

        @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
          border-left: 1px solid ${Colors.accent.grey4.standard};
          padding: 0 80px;
          border-bottom: none;
        }

        &:first-of-type {
          border-left: none;
        }
        svg {
          display: block;
          margin: 0 auto 20px;
          width: 30px;
          height: 30px;
        }
        a {
          font: 600 14px/16px source-sans-pro;
          letter-spacing: 1px;
          text-decoration: none;
          color: ${Colors.primary.black.standard};
        }
      }
    }
  }

  .price-disclaimer {
    margin-top: 60px;
    margin-bottom: 61px;
    font: 400 14px/16px source-sans-pro;
    color: #777b7e;
    width: 90%;
    max-width: 1015px;
    margin-left: auto;
    margin-right: auto;
  }

  .home-details-overview,
  .home-details-features,
  .home-details-floorplan,
  .home-details-map {
    position: relative;
    margin-bottom: 40px;
    h4 {
      padding-bottom: 10px;
      margin-bottom: 10px;
      @media only screen and (min-width: 1025px) {
        border-bottom: 1px solid #e4e5e7;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .home-details-floorplan {
    img {
      max-width: 100%;
      height: auto;
    }
    .tools {
      .zoom-in {
        background: hsla(0, 0%, 100%, 0.8);
        width: 40px;
        height: 40px;
        border: none;
        outline: 0;
        padding: 0;
        position: absolute;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: absolute;
        z-index: 9;
        right: 10px;
        bottom: 10px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .home-details-map {
    #map {
      height: 460px;
    }
    p {
      margin-bottom: 20px;
    }
  }
  .home-details-overview {
    p {
      max-width: 800px;
      white-space: pre-line;
    }
  }
  .home-details-features {
    ul {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      max-width: 880px;
      li {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 10px;
        padding-right: 10px;
        @media only screen and (min-width: 561px) {
          width: 50%;
        }
        @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
          width: 33.33%;
        }
        img {
          margin: 7px;
        }
        p {
          line-height: 1.1;
        }
      }
    }
  }
  .home-details-floorplan {
    .floorplan-image {
      position: relative;
      width: 100%;
      max-width: 700px;
    }
  }
  /* .iiz__hint {
    display: none;
  } */

  .iiz {
    width: 100%;
    height: 100%;
  }

  .home-details-brokerage-info {
    background: ${Colors.secondary.grey6.standard};
    padding: 73px 0 93px;
    margin-top: 80px;

    .brokerage-container {
      width: 90%;
      max-width: 1058px;
      margin-left: auto;
      margin-right: auto;

      @media only screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      div {
        width: 100%;

        @media only screen and (min-width: 768px) {
          width: 37%;
        }

        &:first-of-type {
          border-bottom: 1px solid ${Colors.accent.grey5.standard};
          margin-bottom: 30px;

          @media only screen and (min-width: 768px) {
            border-right: 1px solid ${Colors.accent.grey5.standard};
            border-bottom: none;
            margin-bottom: 0;
            width: 50%;
            padding-right: 63px;
          }
        }

        h4 {
          color: ${Colors.accent.grey1.standard};
          margin: 30px 0 25px;
        }

        p {
          font-weight: bold;
          line-height: 1.25;
          color: ${Colors.accent.grey1.standard};
        }

        a {
          color: ${Colors.primary.claytonBlue.standard};
          text-decoration: none;
          font: 400 16px/1.69 source-sans-pro;
        }
      }

      .brokerage-disclaimer {
        font: 400 12px/1.17 source-sans-pro;
        color: ${Colors.accent.grey2.standard};
      }
    }
  }

  .home-details-next-step {
    position: relative;
    padding: 60px 0px;
    h2 {
      text-align: center;
      margin-bottom: 50px;
      @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
        margin-bottom: 20px;
      }
    }
    ul {
      display: flex;
      list-style: none;
      justify-content: center;
      flex-direction: column;
      @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        flex-direction: row;
      }
      li {
        position: relative;

        @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
          padding: 0px 40px;
          &:not(:last-child) {
            border-right: 1px solid #cacccf;
          }
        }
        @media only screen and (min-width: 890px) {
          padding: 0px 70px;
        }
        @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
          padding: 50px 10px;
          border-bottom: 1px solid #e4e5e7;
        }
        button,
        a {
          text-transform: uppercase;
          color: inherit;
          text-decoration: none;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          line-height: 1.1;
          border: none;
          outline: inherit;
          background: none;
          margin: 0 auto;
          cursor: pointer;
          svg {
            margin-bottom: 9px;
          }
          span {
            font-size: 14px;
            font-weight: 600;
            font-family: 'source-sans-pro';
          }
        }
      }
    }
  }
`;

export default HomeDetailsStyles;

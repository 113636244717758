import React, { useRef, useEffect } from 'react';
import FeaturesFilterItemStyles from './FeaturesFilterItem.styled';
import checkMark from '../../../images/svg/iconography-16x16/checkmark.svg';
import defaultFeature from '../../../images/svg/iconography-48x48/default-feature.svg';
import forSaleSign from '../../../images/svg/iconography-48x48/for-sale-sign.svg';
import halfStory from '../../../images/svg/iconography-48x48/half-story.svg';
import nineFootCeiling from '../../../images/svg/iconography-48x48/9ft-ceiling.svg';
import bonusRoom from '../../../images/svg/iconography-48x48/bonus-room.svg';
import breakfastBar from '../../../images/svg/iconography-48x48/breakfast-bar.svg';
import modular from '../../../images/svg/iconography-48x48/modular.svg';
import computerStation from '../../../images/svg/iconography-48x48/computer-station.svg';
import porch from '../../../images/svg/iconography-48x48/porch.svg';
import diningRoom from '../../../images/svg/iconography-48x48/dining-room.svg';
import doubleMasterClosets from '../../../images/svg/iconography-48x48/double-master-closets.svg';
import doubleSink from '../../../images/svg/iconography-48x48/double-sink.svg';
import drywall from '../../../images/svg/iconography-48x48/drywall.svg';
import mediaCenter from '../../../images/svg/iconography-48x48/media-center.svg';
import familyRoom from '../../../images/svg/iconography-48x48/family-room.svg';
import fireplace from '../../../images/svg/iconography-48x48/fireplace.svg';
import foyer from '../../../images/svg/iconography-48x48/foyer.svg';
import garage from '../../../images/svg/iconography-48x48/garage.svg';
import kitchenIsland from '../../../images/svg/iconography-48x48/kitchen-island.svg';
import modularConstruction from '../../../images/svg/iconography-48x48/modular-construction.svg';
import nursery from '../../../images/svg/iconography-48x48/nursery.svg';
import office from '../../../images/svg/iconography-48x48/office.svg';
import openfloorplan from '../../../images/svg/iconography-48x48/open-floorplan.svg';
import pantry from '../../../images/svg/iconography-48x48/pantry.svg';
import bedside from '../../../images/svg/iconography-48x48/bed-side.svg';
import separateShowerBath from '../../../images/svg/iconography-48x48/separate-shower-bath.svg';
import splitBedroom from '../../../images/svg/iconography-48x48/split-bedroom.svg';
import trayCeiling from '../../../images/svg/iconography-48x48/tray-ceiling.svg';
import twoStory from '../../../images/svg/iconography-48x48/two-story.svg';
import utilityRoom from '../../../images/svg/iconography-48x48/utility-room.svg';
import vaultCeiling from '../../../images/svg/iconography-48x48/vault-ceiling.svg';

export const FeaturesFilterItem = ({
  iconUrl,
  label,
  isSelected,
  className,
  onClick,
  ...inputProps
}) => {
  const imageLookUp = {
    default: defaultFeature,
    'move-in ready': forSaleSign,
    '1 1/2 story': halfStory,
    '9’ ceilings': nineFootCeiling,
    'bonus room': bonusRoom,
    'breakfast bar': breakfastBar,
    crossmod: modular,
    'computer station': computerStation,
    'covered porch': porch,
    'dining room': diningRoom,
    'double primary closets': doubleMasterClosets,
    'double sinks in bath': doubleSink,
    drywall: drywall,
    'entertainment unit': mediaCenter,
    'family room': familyRoom,
    fireplace: fireplace,
    foyer: foyer,
    garage: garage,
    'kitchen island': kitchenIsland,
    'cooking island': kitchenIsland,
    'modular construction': modularConstruction,
    nursery: nursery,
    office: office,
    'open floorplan': openfloorplan,
    'open floor plan': openfloorplan,
    pantry: pantry,
    'primary bedroom retreat': bedside,
    'separate tub and shower': separateShowerBath,
    'split bedrooms': splitBedroom,
    'tray ceilings': trayCeiling,
    'two story': twoStory,
    'utility room': utilityRoom,
    'vaulted ceilings': vaultCeiling,
  };

  return (
    <FeaturesFilterItemStyles
      className={className}
      onClick={(e) => onClick(label)}
      isSelected={isSelected}
      {...inputProps}
      onKeyUp={(e) => {
        if (e.keyCode === 13) {
          onClick(label);
        }
      }}
    >
      <div className="items-container" tabIndex="0">
        <img
          className="icon"
          src={
            imageLookUp.hasOwnProperty(label.toLowerCase())
              ? imageLookUp[label.toLowerCase()]
              : imageLookUp['default']
          }
        />
        <div className="label">{label}</div>
        <div className="check-container">
          {isSelected && <img className="check-mark" src={checkMark} />}
        </div>
      </div>
    </FeaturesFilterItemStyles>
  );
};

export default FeaturesFilterItem;

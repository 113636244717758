import styled, { createGlobalStyle } from 'styled-components';
import zIndices from '../zIndex';

export const GlobalStyle = createGlobalStyle`
   html.modal-lock {
        overflow: hidden !important;
    }
`;

const ModalStyles = styled.div`
  background: rgba(0, 0, 0, 0.5);
  left: 0%;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 200;

  .close-modal {
    cursor: pointer;
    position: absolute;
    top: -22px;
    right: -8px;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: #fff;
      }
    }
  }

  .modal-container {
    background-color: white;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    /* @media only screen and (min-height: 768px) and (max-height: 838px) {
      top: 54%;
    } */
  }

  .modal-content {
    max-height: 90vh;
    overflow: hidden auto;
  }
`;

export default ModalStyles;

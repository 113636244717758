//Primary
const claytonBlue = {
	standard: "#0075c9", 
	hover: "#179eff", 
	tint: "#e5f5ff",
	light: "#e2f7ff",
	className:"clayton-blue"
};
const black = {
	standard: "#25292d",
	tint: "#e4e5e7",
	className:"standard-black"
};
const white = {
	standard: "#ffffff", 
	hover: "#e4e5e7", 
	tint: "#4e5255",
	className: "standard-white"
};

//Secondary
const kiddiePool = {
	standard: "#00b0ac", 
	hover: "#1edad5", 
	tint: "#e5f7f7",
	light: "#dafefd",
	className:"kiddie-pool"
};
const navy1 = {
	standard: "#0c1e34",
	className:"navy-1"
};
const grey6 = {
	standard: "#f3f5f7",
	className:"grey-6"
};
const rubberDucky = {
	standard: "#ffc50a", 
	hover: "#ffdc6a", 
	tint: "#fff9e6",
	textColor: "#000000",
	light: "#fff6db",
	className:"rubber-ducky"
};
const summerNight = {
	standard: "#352269", 
	hover: "#6443be", 
	tint: "#eae8f0",
	className:"summer-night"
};

//Others
const grey1 = {
	standard: "#4e5255",
	className:"grey-1"
};
const grey2 = {
	standard: "#777b7e",
	className:"grey-2"
};
const grey3 = {
	standard: "#a1a3a6",
	className:"grey-3"
};
const grey4 = {
	standard: "#cacccf",
	className:"grey-4"
};
const grey5 = {
	standard: "#e4e5e7",
	className:"grey-5"
};
const grey7 = {
	standard: "#f7f9fb"
};
const navy2 = {
	standard: "#1b3453",
	className:"navy-2"
};
const navy3 = {
	standard: "#29476c",
	className:"navy-3"
};
const navy4 = {
	standard: "#4c75a7",
	className:"navy-4"
};
const claytonBlue2 = {
	standard: "#003f8c",
	className:"clayton-blue-2"
};
const claytonGreen = {
	standard: "#97d700",
	hover: "#c1e766",
	light: "#e2ffc6",
	tint: "#f2fae0",
	className:"clatyon-green"
};
const kiddiePool2 = {
	standard: "#017f93",
	className:"kiddie-pool-2"
};
const barnDoor = {
	standard: "#b21f28",
	className:"barn-door"
};
const cheesePuff = {
	standard: "#f68c2c", 
	hover: "#ffb068", 
	tint: "#fef3e9",
	className:"cheese-puff"
};
const ladyBug = {
	standard: "#ef4b5d", 
	hover: "#ff7f8d", 
	tint: "#fdedee",
	light: "#ffe5e6",
	className:"lady-bug"
};
const dusk = {
	standard: "#8d83be",
	light: "#e8e4fc",
	className:"dusk"
};
const hoseWater = {
	standard: "#97d4ca",
	className:"hose-water"
};
const freshGrass = {
	standard: "#4f9e44",
	className:"fresh-grass"
};
const bigSky = {
	standard: "#00a0dd",
	className: "big-sky"
};
const wetCement = {
	standard: "#51748b",
	className:"wet-cement"
};
const bedtime = {
	standard: "#06292f",
	className:"bed-time"
};
const veryLightBlue = {
	standard: "#f1faff",
	className:"very-light-blue"
}
const darkBlue = {
	standard: "#004b8d",
	hover: "#026ecd", 
	tint: "#ccdbe8",
	light: "#b2c9dd",
	className:"dark-blue"
}

const Colors = {
	primary: {
		claytonBlue,
		black,
		white
	},
	secondary: {
		kiddiePool,
		navy1,
		grey6,
		rubberDucky,
		summerNight
	},
	accent: {
		grey1,
		grey2,
		grey3,
		grey4,
		grey5,
		grey7,
		navy2,
		navy3,
		navy4,
		claytonBlue2,
		claytonGreen,
		kiddiePool2,
		barnDoor,
		cheesePuff,
		ladyBug,
		dusk,
		hoseWater,
		freshGrass,
		bigSky,
		wetCement,
		bedtime,
		veryLightBlue,
		darkBlue
	}
};

export default Colors;
import React from 'react';
import PropTypes from 'prop-types';

const BigArrowRight = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="bigArrowRight"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0089 11L11.6825 15.3264C11.4141 15.5948 11.4141 16.0302 11.6825 16.2986C11.951 16.5671 12.3863 16.5671 12.6548 16.2986L18.1548 10.7986C18.4233 10.5302 18.4233 10.0948 18.1548 9.82636L12.6548 4.32636C12.3863 4.05788 11.951 4.05788 11.6825 4.32636C11.4141 4.59485 11.4141 5.03015 11.6825 5.29864L16.0089 9.625H3.9375C3.5578 9.625 3.25 9.9328 3.25 10.3125C3.25 10.6922 3.5578 11 3.9375 11H16.0089Z"
      fill={color}
    />
  </svg>
);

BigArrowRight.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

BigArrowRight.defaultProps = {
  color: 'white',
  width: '23',
  height: '22',
};

export default BigArrowRight;

import React from 'react';
import Colors from '../../colors';
import TextAreaStyles from './TextArea.styled';
import PropTypes from 'prop-types';

const TextArea = ({
  disabled,
  error,
  errorMessage,
  animated,
  inputType,
  fillSpace,
  focusColor,
  inputId,
  inputName,
  label,
  placeholderText,
  usePlaceholder,
  myRef,
  value,
  onChange,
  initialValue,
  inputKey,
  inputMaxLength,
}) => {
  if (animated) {
    usePlaceholder = false;
  }

  const classes = [];
  if (animated) {
    classes.push('animated');
  }

  if (errorMessage && errorMessage.length) {
    classes.push('error');
  }

  return (
    <TextAreaStyles
      focusColor={focusColor}
      animated={animated}
      className={classes.join(' ')}
      error={error}
      fillSpace={fillSpace}
      usePlaceholder={usePlaceholder}
    >
      {!usePlaceholder && !animated ? (
        <label htmlFor={inputId}>{label}</label>
      ) : null}

      <textarea
        className={error ? 'error-text-area' : ''}
        required={animated}
        type={inputType ? inputType : null}
        id={inputId ? inputId : null}
        name={inputName ? inputName : null}
        disabled={disabled}
        placeholder={placeholderText ? placeholderText : null}
        onChange={onChange ? onChange : null}
        key={inputKey ? inputKey : null}
        defaultValue={initialValue ? initialValue : null}
        value={value || ''}
        maxLength={inputMaxLength ? inputMaxLength : '500'}
      />

      {animated ? <label htmlFor={inputId}> {label}</label> : null}
      {animated && !error ? (
        <span className={`focus-bar  ${error ? 'error' : ''}`}></span>
      ) : null}

      {error && errorMessage ? <p className="error">{errorMessage}</p> : null}
    </TextAreaStyles>
  );
};

export default TextArea;

TextArea.defaultProps = {
  disabled: false,
  errorMessage: null,
  animated: true,
  fillSpace: true,
  focusColor: Colors.primary.claytonBlue.standard,
  myRef: 'text-area',
  inputMaxLength: 500,
};

TextArea.props = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  animated: PropTypes.bool,
  fillSpace: PropTypes.bool,
  focusColor: PropTypes.oneOf([
    Colors.primary.claytonBlue,
    Colors.secondary.kiddiePool,
    Colors.secondary.summerNight,
    Colors.accent.ladyBug,
    Colors.accent.cheesePuff,
  ]),
  inputId: PropTypes.string.required,
  inputName: PropTypes.string.required,
  placeholderText: PropTypes.string,
  usePlaceholder: PropTypes.bool,
  myRef: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.object,
  initialValue: PropTypes.any,
  inputKey: PropTypes.string,
  inputMaxLength: PropTypes.number,
};

import { useEffect, useState, useRef, useCallback } from 'react';

// adapted from https://www.ibrahima-ndaw.com/blog/build-a-sticky-nav-with-react/

function useSticky() {
  const [isSticky, setSticky] = useState(false);
  const element = useRef(null);

  const scrollHandler = useCallback(() => {
    debounce(handleScroll());
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [debounce, handleScroll]);

  const handleScroll = () => {
    if (!element || !element.current || !element.current.getBoundingClientRect)
      return;
    window.scrollY > element.current.getBoundingClientRect().bottom
      ? setSticky(true)
      : setSticky(false);
  };

  const debounce = (func, wait = 20, immediate = true) => {
    let timeOut;
    return () => {
      let context = this,
        args = arguments;
      const later = () => {
        timeOut = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeOut;
      clearTimeout(timeOut);
      timeOut = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  return { isSticky, element };
}

export default useSticky;

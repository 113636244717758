import React, { useLayoutEffect, useState, useEffect } from 'react';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  const canUseDOM = typeof window !== 'undefined';
  const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;
  useIsomorphicLayoutEffect(() => {
    function updateSize() {
      setSize([
        Math.min(window.innerWidth, window.screen?.width),
        Math.min(window.innerHeight, window.screen?.height),
      ]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default useWindowSize;

import PropTypes from 'prop-types';
import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import useWindowSize from '../../../hooks/useWindowSize';
import { fireSegmentEvent } from '../../../utils';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import ImageGalleryWithContentStyles from './ImageGalleryWithContent.styled';

export const ImageGalleryWithContent = ({
  smallHeadline,
  largeHeadline,
  content,
  images,
  buttonText,
  buttonURL,
  gaClass,
  aTagLink,
  dealer,
  homes,
}) => {
  const altText = 'welcome home image';
  const [width, height] = useWindowSize();
  const isTablet = width <= 768;
  const isMobile = width <= 553;

  return (
    <ImageGalleryWithContentStyles>
      <>
        <div className="top-content">
          <div className="container">
            {smallHeadline && (
              <span className="small-headline">{smallHeadline}</span>
            )}
            {largeHeadline && (
              <h2 className="large-headline">{largeHeadline}</h2>
            )}
            {content && (
              <div className="content">
                <p>{content}</p>
              </div>
            )}
            {buttonURL && buttonText && !aTagLink && (
              <Link
                to={{ pathname: buttonURL, state: { dealer, homes } }}
                className={'cta-button ' + (gaClass ? gaClass : '')}
                onClick={() => {
                  fireSegmentEvent('CTA Clicked', {
                    isNav: false,
                    type: 'button',
                    text: buttonText ? buttonText : '',
                    eventContext: 'home_welcome_home_meet_our_homeowners',
                  });
                }}
              >
                {buttonText}
              </Link>
            )}
            {buttonURL && buttonText && aTagLink && (
              <a
                href={buttonURL}
                className={'cta-button ' + (gaClass ? gaClass : '')}
              >
                {buttonText}
              </a>
            )}
          </div>
        </div>
        {images && (
          <div className="image-gallery desktop-gallery">
            <div className="img-col-one">
              <OptimizedImage
                src={images[0] + '?width=600'}
                srcSet={[
                  {
                    source: images[0] + '?fm=webp&width=600',
                  },
                ]}
                alt={altText}
                lazy
              />
            </div>
            <div className="img-col-two">
              <div>
                <OptimizedImage
                  src={
                    isMobile
                      ? images[0] + '?width=400'
                      : images[1] + '?width=600'
                  }
                  srcSet={[
                    {
                      source: isMobile
                        ? images[0] + '?fm=webp&width=500'
                        : images[1] + '?fm=webp&width=600',
                    },
                  ]}
                  alt={altText}
                  lazy
                />
              </div>
              <div>
                <OptimizedImage
                  src={
                    isMobile
                      ? images[1] + '?width=400'
                      : images[2] + '?width=600'
                  }
                  srcSet={[
                    {
                      source: isMobile
                        ? images[1] + '?fm=webp&width=500'
                        : images[2] + '?fm=webp&width=600',
                    },
                  ]}
                  alt={altText}
                  lazy
                />
              </div>
              <div>
                <OptimizedImage
                  src={
                    isMobile
                      ? images[2] + '?width=400'
                      : images[3] + '?width=600'
                  }
                  srcSet={[
                    {
                      source: isMobile
                        ? images[2] + '?fm=webp&width=500'
                        : images[3] + '?fm=webp&width=600',
                    },
                  ]}
                  alt={altText}
                  lazy
                />
              </div>
              <div>
                <OptimizedImage
                  src={
                    isMobile
                      ? images[3] + '?width=400'
                      : images[4] + '?width=600'
                  }
                  srcSet={[
                    {
                      source: isMobile
                        ? images[3] + '?fm=webp&width=500'
                        : images[4] + '?fm=webp&width=600',
                    },
                  ]}
                  alt={altText}
                  lazy
                />
              </div>
            </div>
            <div className="img-col-three">
              <OptimizedImage
                src={
                  isMobile
                    ? images[4] + '?width=400'
                    : isTablet
                    ? images[0] + '?width=600'
                    : images[5] + '?width=600'
                }
                srcSet={[
                  {
                    source: isMobile
                      ? images[4] + '?fm=webp&width=600'
                      : isTablet
                      ? images[0] + '?fm=webp&width=600'
                      : images[5] + '?fm=webp&width=600',
                  },
                ]}
                alt={altText}
                lazy
              />
            </div>
            {!isTablet && (
              <div className="img-col-four">
                <div>
                  <OptimizedImage
                    src={images[6] + '?width=600'}
                    srcSet={[
                      {
                        source: images[6] + '?fm=webp&width=600',
                      },
                    ]}
                    alt={altText}
                    lazy
                  />
                </div>
                <div>
                  <img src={images[7] + '?width=600'} alt={altText} />
                  <OptimizedImage
                    src={images[7] + '?width=600'}
                    srcSet={[
                      {
                        source: images[7] + '?fm=webp&width=600',
                      },
                    ]}
                    alt={altText}
                    lazy
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </>
    </ImageGalleryWithContentStyles>
  );
};

ImageGalleryWithContent.props = {
  smallHeadline: PropTypes.string,
  largeHeadline: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.array,
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
  gaClass: PropTypes.string,
  aTagLink: PropTypes.bool,
};

export default ImageGalleryWithContent;

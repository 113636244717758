import styled from 'styled-components';
import Colors from '../../../colors';

const ScheduleVisitModalFormStyles = styled.div`
  .button {
    margin-top: 23px;
    margin-bottom: 25px !important;

    @media only screen and (min-width: 768px) {
      margin-top: 0;
    }
  }
  #flavor-drop-down-error-button label {
    color: #4e5255;
  }
  button#flavor-drop-down-error-button {
    margin-bottom: -4px !important;
  }

  .info-text {
    font-family: 'source-sans-pro';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #4e5255;
  }

  .dropdown-list {
    button {
      margin-bottom: -4px;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;

    p {
      line-height: 1.93;
    }
  }

  .react-datepicker {
    button:not(.has-value) {
      width: auto !important;
    }
  }

  .date-picker {
    background-color: #fff;
    border: 0;
    border-bottom: solid 1px #777b7e;
    box-sizing: border-box;
    color: #25292d;
    font-family: 'source-sans-pro', sans-serif;
    font-size: 16px;
    height: 42px;
    line-height: 1.56;
    letter-spacing: -0.3px;
    width: 100%;
    margin-bottom: 35px;
    padding-top: 13px;
  }

  .error-message {
    color: #ef4b5d;
    font-size: 12px;
    height: 22px;
    letter-spacing: -0.2px;
    line-height: 1.83;
    margin-top: 0;
    padding-left: 1px;
    text-align: left;
    font-family: 'source-sans-pro', sans-serif;
  }

  .phone {
    color: ${Colors.primary.claytonBlue.standard};
  }
`;

export default ScheduleVisitModalFormStyles;

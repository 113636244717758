import styled from 'styled-components';
import Colors from '../../colors';

const ContactStyles = styled.div`
  background: ${Colors.accent.grey7.standard};
  h1 {
    font-family: 'acumin-pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    background: ${Colors.primary.claytonBlue.standard};
    padding: 75px 0;
    color: ${Colors.primary.white.standard};
  }

  .contact-us-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 75px 0;
    margin: 0 auto;

    @media only screen and (min-width: 320px) {
      width: 87%;
      flex-flow: row wrap;
      max-width: 509px;
    }

    @media only screen and (min-width: 768px) {
      width: 66%;
    }

    @media only screen and (min-width: 1025px) {
      width: 95%;
      max-width: 1003px;
    }

    @media only screen and (min-width: 1450px) {
      width: 69%;
    }

    .hc-info-text {
      font-size: 14px;
      text-transform: capitalize;
      line-height: 157%;
    }

    .form-container {
      max-width: 573px;
      background: ${Colors.primary.white.standard};

      @media only screen and (min-width: 320px) {
        width: 100%;
        margin-bottom: 80px;
      }

      @media only screen and (min-width: 1025px) {
        width: 57%;
        margin-bottom: 0;
      }

      .message-us {
        padding: 25px 0 21px;
        text-align: center;
        border-bottom: 1px solid ${Colors.primary.black.tint};
        display: flex;
        justify-content: center;
        position: relative;

        .message-headline {
          margin-left: 13px;
          line-height: 1.5;
        }
      }

      .help-buttons {
        text-align: center;
        padding: 42px 0 69px;

        p {
          margin-bottom: 10px;
        }

        .button-container {
          display: flex;
          margin: 0 auto;

          @media only screen and (min-width: 320px) {
            width: 90%;
            flex-flow: row wrap;
            justify-content: center;
          }

          @media only screen and (min-width: 562px) {
            justify-content: space-between;
          }

          @media only screen and (min-width: 1025px) {
            width: 80%;
          }

          .contact-us-btn {
            background-color: transparent;
            color: ${Colors.primary.black.standard};
            border: 1px solid ${Colors.primary.black.tint};
            width: 220px;
            text-transform: none;
            font-weight: 400;
            padding: 20px 12px;
            line-height: 16px;
            outline: none;
            max-height: 58px;

            &:first-of-type {
              @media only screen and (min-width: 320px) {
                margin-bottom: 10px;
              }

              @media only screen and (min-width: 562px) {
                margin-bottom: 0;
              }
            }

            &.selected {
              background: rgb(241, 250, 255);
              border: 1px solid transparent;
              box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 9px 0px;
              font-weight: 600;
              color: rgb(0, 117, 201);
            }
          }
        }
      }
    }
  }

  .info-contact-container {
    width: 35%;

    @media only screen and (min-width: 320px) {
      width: 100%;
    }

    @media only screen and (min-width: 1025px) {
      max-width: 345px;
      width: 35%;
    }

    .contact-text {
      border-bottom: 1px solid ${Colors.primary.black.tint};
      padding-bottom: 26px;
      p {
        margin: 9px 0 0;
        font-size: 14px;
        line-height: 157%;
      }
    }

    .hc-info-container {
      margin: 32px 0 26px;
      padding-bottom: 34px;

      h5 {
        margin-bottom: 13px;
      }

      .address-hours-container {
        @media only screen and (min-width: 768px) {
          display: flex;
          justify-content: space-between;
        }

        .hc-info-header {
          font-size: 12px;
          line-height: 125%;
          font-weight: 700;
          letter-spacing: -0.3px;
          color: ${Colors.primary.black.standard};
          font-family: 'acumin-pro';
          margin-bottom: 5px;
        }

        .hc-info-text {
          color: ${Colors.accent.grey1.standard};
          font-family: 'source-sans-pro', sans-serif;
          font-weight: 400;
        }

        .phone {
          margin-top: 11px;
        }

        .phone-link {
          color: ${Colors.primary.claytonBlue.standard};
          text-decoration: none;
          font-family: 'source-sans-pro', sans-serif;
          font-weight: 400;

          &:hover {
            text-decoration: underline;
          }
        }

        .hours {
          margin-top: 33px;
          @media only screen and (min-width: 768px) {
            margin-top: 0;
          }

          ul {
            list-style-type: none;

            li {
              display: flex;
              justify-content: space-between;
              width: 158px;
              span {
                margin-left: 20px;
                color: ${Colors.accent.grey1.standard};
                font-family: 'source-sans-pro', sans-serif;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  .support-container {
    h5 {
      margin-bottom: 6px;
    }

    .hc-info-text {
      text-transform: none;
    }

    a {
      color: ${Colors.primary.claytonBlue.standard};
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      text-transform: none;
      font-family: 'source-sans-pro', sans-serif;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export default ContactStyles;

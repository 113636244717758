import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors.js';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const XLCTAStyles = styled.div`
  .xl-cta {
    position: relative;
    background: ${Colors.primary.claytonBlue.standard};
    padding: 20px;
    min-height: 370px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    @media only screen and (min-width: 900px) {
      min-height: 430px;
    }
    .content {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .call-text {
      font-size: 34px;
      line-height: 40px;
      font-weight: 700;
      letter-spacing: -0.3px;
    }
    .number {
      font-size: 34px;
      line-height: 40px;
      font-weight: 700;
      letter-spacing: -0.3px;
    }
    .buttons {
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .cta-button {
        background: transparent;
        color: white;
        border: 1.5px solid white;
        border-radius: 2px;
        padding: 17px 17px;
        font: 500 12px/100% 'source-sans-pro', sans-serif;
        margin-top: 9px;
        letter-spacing: 1px;
        text-decoration: none;
        transition: 0.2s;
        text-transform: uppercase;
        font-weight: 700;
        cursor: pointer;
        margin: 10px 5px 0px;

        @media screen and (max-width: 396px) {
          width: 190px;
          text-align: center;
        }
        &:first-child {
          background: ${Colors.primary.white.standard};
          color: ${Colors.primary.claytonBlue.standard};
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
    .phone-text {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: -30px;
      .number {
        color: white;
        text-decoration: none;
        padding: 0px 10px;
      }
    }
    .icon {
      max-width: 68px;
      margin: 0 auto 6px;
    }
    .logo {
      max-width: 239px;
      margin: 0 auto 6px;
    }
    .large-text {
      font-size: 40px;
      letter-spacing: 0.35px;
      font-weight: 700;
      color: white;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 20px;
      font-style: normal;
      font-family: 'acumin-pro';
      text-decoration: none;
      @media only screen and (max-width: 900px) {
        margin-bottom: 20px;
        font-size: 34px;
        line-height: 1.3;
      }
    }
    .bold {
      font-weight: 900;
    }
    .small-text {
      font-size: 18px;
      font-weight: 400;
      color: white;
      line-height: 1.61;
      text-align: center;
      margin-bottom: 20px;
      font-style: normal;
      font-family: 'source-sans-pro', sans-serif;
      text-decoration: none;
      @media only screen and (max-width: 900px) {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 1.3;
      }
    }
    .pattern-left {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      max-width: 90%;
    }
    .pattern-right {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      visibility: hidden;
      @media only screen and (min-width: 900px) {
        visibility: visible;
      }
    }
  }
  .has-disclaimer {
    margin-top: -30px;
  }
  .xlcta-disclaimer {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    @media only screen and (max-width: 541px) {
      font-size: 12px;
      bottom: 4px;
    }
  }
`;

export default XLCTAStyles;

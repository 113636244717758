import React from 'react';
import PropTypes from 'prop-types';

const ArrowRight = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.646447 8.64645C0.451184 8.84171 0.451184 9.15829 0.646447 9.35355C0.841709 9.54882 1.15829 9.54882 1.35355 9.35355L5.35355 5.35355C5.54882 5.15829 5.54882 4.84171 5.35355 4.64645L1.35355 0.646446C1.15829 0.451184 0.841708 0.451184 0.646446 0.646446C0.451184 0.841708 0.451184 1.15829 0.646446 1.35355L4.29289 5L0.646447 8.64645Z"
      fill={color}
    />
  </svg>
);

ArrowRight.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ArrowRight.defaultProps = {
  color: '#A1A3A6',
  width: '6',
  height: '10',
};

export default ArrowRight;

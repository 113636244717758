import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextContentStyles from './TextContent.styled';

export const TextContent = ({
  smallHeadline,
  largeHeadline,
  content,
  backgroundColor,
  mobileBackgroundColor,
  largePadding,
  overlapBelowVideo,
  smallDisclaimer,
  aboveVideoContent,
  links,
  isPromo,
}) => {
  return (
    <TextContentStyles
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      largePadding={largePadding}
      overlapBelowVideo={overlapBelowVideo}
      aboveVideoContent={aboveVideoContent}
      customHeadlineWidth={'560px'}
    >
      <div className={isPromo ? 'promo container' : 'container'}>
        <div className="inner">
          {smallHeadline && (
            <span className="small-headline">{smallHeadline}</span>
          )}
          {largeHeadline && <h2 className="large-headline">{largeHeadline}</h2>}
          {content && <p className="content">{content}</p>}
          {!aboveVideoContent && <div className="line"></div>}
          {links &&
            links.length > 0 &&
            links.map((link, i) => (
              <div key={i} className="link-content">
                {link.linkContent}
              </div>
            ))}
          {smallDisclaimer && (
            <p className="small-disclaimer">{smallDisclaimer}</p>
          )}
        </div>
      </div>
    </TextContentStyles>
  );
};

TextContent.props = {
  smallHeadline: PropTypes.string,
  largeHeadline: PropTypes.string,
  content: PropTypes.string,
  backgroundColor: PropTypes.string,
  largePadding: PropTypes.bool,
  overlapBelowVideo: PropTypes.bool,
};

TextContent.defaultProps = {
  backgroundColor: 'white',
  largePadding: false,
};

export default TextContent;

import styled from 'styled-components';
import Colors from '../../../colors';

const ScheduleVisitFormStyles = styled.div`
  padding: 0 0 41px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  width: 90%;
  @media screen and (min-width: 768px) {
    height: auto;
    padding: 0 0 67px;
    width: 80%;
  }
  @media only screen and (min-width: 1025px) {
    padding: 0 0 58px;
  }
  div {
    &.label {
      text-align: center;
      margin-bottom: 4px;
    }
    &.h3 {
      text-align: center;
      margin-bottom: 25px;
      @media screen and (min-width: 400px) {
        margin-bottom: 30px;
      }
      @media screen and (min-width: 768px) {
        margin-bottom: 50px;
      }
    }
  }
  form {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0 auto;
    margin-bottom: auto;

    & > div {
      flex: 1 1 100%;

      &.half {
          flex: 0 1 47%;
        }
      @media screen and (max-width: 399px) {
        &,
        &.animated {
          min-height: 65px;
        }
      }
      @media screen and (min-width: 400px) {
        margin-bottom: 20px;
      }
      @media screen and (min-width: 768px) {
        margin-bottom: 30px;
        
      }
    }
    .dropdown-list {
      .dropdown-selected-text,
      .error-message {
        font-family: 'source-sans-pro', sans-serif;
      }
    }
  }
  .button {
    width: 100%;
    svg {
      width: 100px;
      height: 19px;
    }
    @media screen and (min-width: 375px) {
      margin-bottom: 5px;
    }
    @media screen and (min-width: 768px) {
      margin-bottom: 35px;
    }
  }
  flex: 0 1 auto;
  .disclaimer {
    font-size: 12px;
    line-height: 17px;
    min-height: 50px;
    @media screen and (min-width: 768px) {
      min-height: 60px;
    }
  }
  .button {
    margin-top: 23px;
    margin-bottom: 25px !important;

    @media only screen and (min-width: 768px) {
      margin-top: 0;
    }
  }
  .info-text {
    font-family: 'source-sans-pro';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #4e5255;

    a {
      color: ${Colors.primary.claytonBlue.standard};
      text-decoration: none;
      font-family: 'source-sans-pro', sans-serif;
      &:hover {
        text-decoration: underline;
      }
  }

  .dropdown-list {
    button {
      margin-bottom: -4px;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;

    p {
      line-height: 1.93;
    }
  }

  .react-datepicker {
    button:not(.has-value) {
      width: auto !important;
    }
  }

  .date-picker {
    background-color: #fff;
    border: 0;
    border-bottom: solid 1px #777b7e;
    box-sizing: border-box;
    color: #25292d;
    font-family: 'source-sans-pro', sans-serif;
    font-size: 16px;
    height: 42px;
    line-height: 1.56;
    letter-spacing: -0.3px;
    width: 100%;
    margin-bottom: 35px;
    padding-top: 13px;
  }

  .error-message {
    color: #ef4b5d;
    font-size: 12px;
    height: 22px;
    letter-spacing: -0.2px;
    line-height: 1.83;
    margin-top: 0;
    padding-left: 1px;
    text-align: left;
    font-family: 'source-sans-pro', sans-serif;
  }
`;

export default ScheduleVisitFormStyles;

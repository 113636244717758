import styled from 'styled-components';

const StyledToggle = styled.label`
  align-items: center;
  position: relative;
  cursor: pointer;
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  @media only screen and (min-width: 320px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .toggle-label {
    font: 700 12px/100% source-sans-pro, sans-serif;
    line-height: 2;

    @media only screen and (min-width: 320px) {
      display: none;
    }
    @media only screen and (min-width: 768px) {
      display: block;
    }
    @media only screen and (min-width: 1024px) {
      margin-right: 15px;
      margin-top: 0;
    }
  }
  .mobile-toggle-label {
    font-family: source-sans-pro, sans-serif;
    font-weight: 700;
    line-height: 2;
    @media only screen and (min-width: 320px) {
      display: block;
      font-size: 10px;
    }
    @media only screen and (min-width: 768px) {
      display: none;
      font-size: 12px;
    }
  }
  .toggle-wrapper {
    width: 62px;
    display: inline-flex;
    z-index: 0;
    position: relative;
    @media only screen and (max-width: 768px) {
      width: 57px;
    }
    @media only screen and (min-width: 1024px) {
      /* margin-left: auto; */
    }
  }
  .toggle-outer {
    width: ${(props) => (props.size === 'large' ? '48px' : '40px')};
    height: ${(props) => (props.size === 'large' ? '48px' : '40px')};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: ${(props) => {
      if (props.size === 'large') {
        return props.checked ? 'translateX(24px)' : 'translateX(-10px)';
      }
      return props.checked ? 'translateX(22px)' : 'translateX(0px)';
    }};
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .toggle-inner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .toggle-circle {
    width: ${(props) => (props.size === 'large' ? '22px' : '16px')};
    height: ${(props) => (props.size === 'large' ? '22px' : '16px')};
    border-radius: 50%;
    background-color: #fff;
  }
  .toggle-bar {
    width: ${(props) => (props.size === 'large' ? '62px' : '44px')};
    height: ${(props) => (props.size === 'large' ? '28px' : '22px')};

    background-color: ${(props) => (props.checked ? '#00b0ac' : '#e4e5e7')};
    border: none;
    opacity: 1;
    margin-top: ${(props) => (props.size === 'large' ? '-14px' : '-11px')};
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    margin-left: ${(props) => (props.size === 'large' ? '-31px' : '-22px')};
    border-radius: 14px;
    top: 50%;
    left: 50%;
    z-index: -1;
    position: absolute;
  }
  input[type='checkbox'] {
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
  }
`;

export default StyledToggle;

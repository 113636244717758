import styled from 'styled-components';
import Colors from '../../../colors';

const HomeTileStyles = styled.div`
  .home-card {
    padding: 10px;

    .sticker {
      color: white;
      background-color: #00a3a0;
      font-family: source-sans-pro;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 12px;

      .in-stock-dot {
        display: none;
      }
    }

    .price-tooltip {
      flex-shrink: 0;
    }

    .card-image-area {
      margin-bottom: 0;
    }

    .card-heading {
      font-family: 'acumin-pro';
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.3px;
    }

    .card-home-specs {
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #4e5255;
      .wordspan {
        font-family: 'source-sans-pro';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #4e5255;
        display: inline-flex;
      }
    }

    .price-top {
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
      text-align: right;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #25292d;
    }

    .price {
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 29px;
      text-align: right;
      color: #25292d;
    }

    .price-bottom {
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 29px;
      text-align: right;
      color: #25292d;
    }

    .price-contact-us {
      display: block;
      font-family: 'source-sans-pro';
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #0075c9;
      font-weight: 600;
      width: 104px;
      flex-shrink: 0;
      margin-left: 10px;
    }

    .sticker {
      color: white !important;
      background-color: #0075c9 !important;
    }

    .sticker-sale,
    .sticker-price-drop {
      background-color: #ef4b5d !important;
    }

    .sticker-in-stock {
      background-color: #00a3a0 !important;
    }

    .orange-sticker {
      background-color: ${Colors.accent.cheesePuff.standard} !important;
    }

    .purple-sticker {
      background-color: #7e71c1 !important;
    }

    .slider-wrapper {
      border-radius: 6px;
    }

    .info-circle {
      margin-left: 0px;
      width: 14px;
      height: 14px;
      vertical-align: middle;
      filter: invert(11%) sepia(11%) saturate(844%) hue-rotate(164deg)
        brightness(96%) contrast(87%);
    }

    .phone-icon {
      margin-right: 1px;
      filter: invert(26%) sepia(46%) saturate(4667%) hue-rotate(189deg)
        brightness(95%) contrast(101%);
      width: 15px;
      height: 15px;
      vertical-align: middle;
    }

    .control-dots {
      bottom: -4px;

      .selected {
        opacity: 1 !important;
      }

      .dot {
        width: 7px;
        height: 7px;
        margin: 0 6px;
        opacity: 0.6;
        box-shadow: 1px 1px 4px #838383;

        .selected {
          opacity: 1 !important;
        }
      }
    }
  }
`;

export default HomeTileStyles;

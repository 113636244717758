import React, { useRef, useEffect } from 'react';
import CheckBox from '../SmokeyTransitional/components/CheckBox/CheckBox';
import RadioButtonGroup from 'pubweb-smokey/dist/components/RadioButton/RadioButtonGroup';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import AvailabilityFilterStyles from './AvailabilityFilter.styled';
import { fireSegmentEvent } from '../../../utils';

export const AvailabilityFilter = ({
  values,
  updateAvailability,
  applyFilter,
  clearFilter,
  className,
}) => {
  const availabilityItems = [
    { value: 'move in ready', text: 'Move-In Ready' },
    { value: 'in stock', text: 'In Stock' },
    { value: 'land only', text: 'Land Only' },
  ];

  let selectedOption;

  if (
    values.isOnLand == null &&
    values.isInStock == null &&
    values.isLand == null
  ) {
    selectedOption = null;
  }

  if (values.isOnLand === true) {
    selectedOption = 'move in ready';
  }

  if (values.isInStock === true) {
    selectedOption = 'in stock';
  }

  if (values.isLand === true) {
    selectedOption = 'land only';
  }

  return (
    <AvailabilityFilterStyles className={className}>
      <div className="content-area">
        <div className="content-row">
          <RadioButtonGroup
            id="availability-radio"
            items={availabilityItems}
            defaultValue={selectedOption}
            selectedValue={selectedOption}
            onChange={(e) => {
              updateAvailability(e.value);
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                applyFilter();
              }
            }}
          />
        </div>
      </div>
      <div className="button-area">
        <span
          className="clear-btn"
          tabIndex="0"
          onClick={() => {
            clearFilter('isInStock');
            clearFilter('isOnLand');
            clearFilter('isLand');
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              clearFilter('isInStock');
              clearFilter('isOnLand');
              clearFilter('isLand');
            }
          }}
          to={'/'}
        >
          CLEAR
        </span>
        <Button
          className="ga-filter-avail"
          tabIndex="0"
          automationId="availBtn"
          onClick={() => {
            applyFilter();
            fireSegmentEvent('Facility Model List Filtered', {
              list: 'Homes List',
              filters: [
                {
                  type: 'availability',
                  value:
                    values.isInStock && values.isInStock == true
                      ? 'in stock'
                      : values.isOnLand && values.isOnLand == true
                      ? 'move in ready'
                      : values.isLand && values.isLand == true
                      ? 'land only'
                      : '',
                },
              ],
              eventContext: 'available_homes_filter_availability',
            });
          }}
        >
          APPLY
        </Button>
      </div>
    </AvailabilityFilterStyles>
  );
};

export default AvailabilityFilter;

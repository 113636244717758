import React, { useRef } from 'react';
import App from '../../App';
import { StyledWinterPromoThankYou } from './WinterPromoThankYou.Style';
import HaveItMadeHouseConfirmation from '../../images/svg/promo-logos/HaveItMadeHouseConfirmation';
import SaleHomeSlider from '../Shared/SaleHomeSlider/SaleHomeSlider';
import TextContent from '../Shared/TextContent/TextContent';
import Video from '../Shared/Video/Video';
import PromoDisclaimer from './PromoDisclaimer';
import XLCTA from '../Shared/XLCTA/XLCTA';
import HouseSmartPromoGrid from '../Shared/HouseSmartPromoGrid/HouseSmartPromoGrid';
import videoBackgroundImage from '../../images/promo-images/have-it-made-video-banner.jpg';
import { Link } from 'react-router-dom';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import useWindowSize from '../../hooks/useWindowSize';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PromoBannerClaytonDesktopA from '../../images/promo-images/have-it-made-price-a-banner.jpg';
import PromoBannerClaytonTabletA from '../../images/promo-images/have-it-made-price-a-banner-tablet.jpg';
import PromoBannerClaytonMobileA from '../../images/promo-images/have-it-made-price-a-banner-mobile.jpg';
import PromoBannerClaytonDesktopB from '../../images/promo-images/have-it-made-price-b-banner.jpg';
import PromoBannerClaytonTabletB from '../../images/promo-images/have-it-made-price-b-banner-tablet.jpg';
import PromoBannerClaytonMobileB from '../../images/promo-images/have-it-made-price-b-banner-mobile.jpg';
import HaveItMadeLogo from '../../images/promo-images/have-it-made-logo.svg';
import { Helmet } from 'react-helmet';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';

const WinterPromoThankYou = ({ isClayton, dealer, homesOnSale, homes }) => {
  const [width, height] = useWindowSize();

  const saleHomesRef = useRef();
  const disclaimerRef = useRef(null);

  const higherStates = () => {
    const states = dealer?.stateProvince.toLowerCase();
    switch (states) {
      case 'ca':
        return true;
      case 'co':
        return true;
      case 'id':
        return true;
      case 'nv':
        return true;
      case 'or':
        return true;
      case 'wa':
        return true;
      case 'wy':
        return true;
      default:
        return false;
    }
  };
  return (
    <App dealer={dealer} homes={homes}>
      <StyledWinterPromoThankYou>
        <Helmet>
          <title>
            Have It Made Sales Event - Discounted Mobile, Manufactured Homes |{' '}
            {formatDealerName(dealer?.dealerName)}
          </title>
          <meta
            name="description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
          <meta
            name="og:description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
        </Helmet>
        <div className="ty-message-banner">
          <div className="banner">
            <LazyLoadImage
              src={HaveItMadeLogo}
              alt="Have it Made Sales Event"
              className="have-it-made-logo"
            />
            <LazyLoadImage
              src={
                !higherStates
                  ? PromoBannerClaytonDesktopA
                  : PromoBannerClaytonDesktopB
              }
              alt="Have it Made Sales Event"
              className="have-it-made-banner banner-img"
            />
            <LazyLoadImage
              src={
                !higherStates
                  ? PromoBannerClaytonTabletA
                  : PromoBannerClaytonTabletB
              }
              alt="Have it Made Sales Event"
              className="have-it-made-banner-tablet banner-img"
            />
            <LazyLoadImage
              src={
                !higherStates
                  ? PromoBannerClaytonMobileA
                  : PromoBannerClaytonMobileB
              }
              alt="Have it Made Sales Event"
              className="have-it-made-banner-mobile banner-img"
            />
          </div>
          <div className="confirmation-message">
            <div className="message-container">
              <h3>Thank You!</h3>
              <div className="house-container">
                <HaveItMadeHouseConfirmation />
              </div>
              <p>
                Thank you for reaching out. A home center
                <br /> consultant will be in touch shortly.
              </p>
              <Link
                to={{
                  pathname: '/sale-homes',
                  state: { dealer, homes },
                }}
                id="viewHomes"
                className="button"
              >
                View Homes
              </Link>
            </div>
          </div>
        </div>
        <TextContent
          smallHeadline={'have it made sales event'}
          largeHeadline={'Your new home is waiting.'}
          content={`We’ll build your house, so you can turn it into your home. From storage and design options to energy efficient features and more, there are endless ways you can Have It Made in a new manufactured home. Visit ${formatDealerName(
            dealer?.dealerName
          )} to see our selection of floor plans you can personalize.`}
          backgroundColor={'#f3f5f7'}
          largePadding={true}
        />
        {homesOnSale && homesOnSale.length > 0 && (
          <SaleHomeSlider
            largeHeadline="Explore Sale Homes"
            saleHomes={homesOnSale}
            buttonText={'View All Sale Homes'}
            buttonURL={'/sale-homes'}
            dealer={dealer}
            gaClass={'ga-seasonal-campaign-view-sale-homes'}
          />
        )}
        <Video
          gaClass={'ga-seasonal-campaign-video'}
          image={{ source: videoBackgroundImage }}
          videoID={'9ucusyax20'}
          wistiaOptions={'silentAutoPlay=false autoPlay=true'}
          noSaleHomes={!homesOnSale || homesOnSale.length <= 0}
        />
        <HouseSmartPromoGrid />
        <div ref={disclaimerRef}>
          <PromoDisclaimer
            disclaimer={
              higherStates()
                ? '*$149,900 sales price is for the multi-section Tempo series home only. Sales price available in CA, CO, ID, NV, OR, WA and WY. Sales price does not include delivery and installation or other costs such as taxes, title fees, insurance premiums, filing or recording fees, land or improvements to the land, optional home features, optional delivery or installation services, wheels and axles, community or homeowner association fees, or any other items not shown on your Sales Agreement, Retailer Closing Agreement, and related documents (your SA/RCA). If you purchase a home, your SA/RCA will show the details of your purchase. Available with a purchase agreement signed May 1, 2023 through August 31, 2023. Available only at participating Clayton Family of Brands retailers.'
                : '*$129,900 sales price is for the multi-section Tempo series home only. Sales price available in all other states except for CA, CO, ID, NV, OR, WA and WY. Sales price does not include delivery and installation or other costs such as taxes, title fees, insurance premiums, filing or recording fees, land or improvements to the land, optional home features, optional delivery or installation services, wheels and axles, community or homeowner association fees, or any other items not shown on your Sales Agreement, Retailer Closing Agreement, and related documents (your SA/RCA). If you purchase a home, your SA/RCA will show the details of your purchase. Available with a purchase agreement signed May 1, 2023 through August 31, 2023. Available only at participating Clayton Family of Brands retailers.'
            }
          />
        </div>
        {dealer && (
          <XLCTA
            phoneNumber={dealer.phoneNumber}
            customCallText={'Find Your Perfect Home'}
            gaPhoneClass={'ga-seasonal-campaign-phone-number'}
            buttons={[
              {
                buttonText: 'Get More Information',
                buttonURL: '/contact-us',
                gaClass: 'ga-seasonal-campaign-get-more-information',
              },
              {
                buttonText: 'Schedule a Visit',
                buttonURL: '/contact-us',
                gaClass: 'ga-seasonal-campaign-schedule-a-visit',
              },
            ]}
          />
        )}
      </StyledWinterPromoThankYou>
    </App>
  );
};

WinterPromoThankYou.getInitialProps = async (ctx) => {
  return await parseInitialPropsContext(ctx, dealerService, homesService);
};

export default WinterPromoThankYou;

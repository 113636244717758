import React, { useState } from 'react';
import TextField from 'pubweb-smokey/dist/components/TextField/TextField';
import { Link } from 'react-router-dom';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import PriceFilterStyles from './PriceFilter.styled';
import { formatCurrency } from '../../../../utils';

export const PriceFilter = ({
  updatePriceLabel,
  updateMaxPrice,
  updateMinPrice,
  closeFilter,
  maxPrice,
  minPrice,
  higherLimit,
  lowerLimit,
}) => {
  const selectablePriceIncrement = 25000;

  const generateMinPriceValues = () => {
    let content = [];
    for (
      let price = lowerLimit;
      price < maxPrice;
      price += selectablePriceIncrement
    ) {
      content.push(
        <option value={price} key={price}>
          {formatCurrency(price)}
        </option>
      );
    }

    return content;
  };

  const generateMaxPriceValues = () => {
    let content = [];
    for (
      let price = minPrice + selectablePriceIncrement;
      price <= higherLimit;
      price += selectablePriceIncrement
    ) {
      content.push(
        <option value={price} key={price}>
          {formatCurrency(price)}
        </option>
      );
    }

    return content;
  };

  return (
    <PriceFilterStyles>
      <div className="content-area">
        <select
          tabIndex="0"
          name="minPrice"
          aria-label="min price"
          id="minPrice"
          value={minPrice}
          onChange={(e) => {
            updateMinPrice(parseInt(e.target.value));
          }}
        >
          {generateMinPriceValues()}
        </select>
        <div className="dash"></div>
        <select
          tabIndex="0"
          name="maxPrice"
          aria-label="max price"
          id="maxPrice"
          value={maxPrice}
          onChange={(e) => {
            updateMaxPrice(parseInt(e.target.value));
          }}
        >
          {generateMaxPriceValues()}
        </select>
      </div>
      <div className="button-area">
        <span
          className="clear-btn"
          tabIndex="0"
          onClick={(e) => {
            e.preventDefault();
            updatePriceLabel('Price');
            updateMaxPrice(higherLimit);
            updateMinPrice(lowerLimit);
            closeFilter(false);
          }}
        >
          CLEAR
        </span>
        <Button
          className="ga-filter-price"
          tabIndex="0"
          automationId="priceFilterButton"
          onClick={(e) => {
            e.preventDefault();
            updatePriceLabel(
              'Price: ' +
                formatCurrency(minPrice) +
                ' - ' +
                formatCurrency(maxPrice)
            );
            closeFilter(false);
          }}
        >
          APPLY
        </Button>
      </div>
    </PriceFilterStyles>
  );
};

export default PriceFilter;

import styled from 'styled-components';
import Colors from '../../../colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const NewsEventsStyles = styled.section`
  padding: 132px 0 72px;
  width: 87%;
  margin: 0 auto;
  @media only screen and (max-width: 767px) {
    margin: 0 auto !important;
    width: 100% !important;
    max-width: 475px;
  }

  @media only screen and (min-width: 768px) {
    max-width: 475px;
    width: auto;
  }

  @media only screen and (min-width: 1025px) {
    max-width: 1210px;
  }

  .news-events-home {
    padding-top: 0;
  }

  .carousel {
    .slide {
      background: none;
      text-align: left;
    }
  }

  .center-card-grid {
    justify-content: center;
  }

  .card-item {
    position: relative;
    padding: 20px;
    width: 33.33%;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .card {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-decoration: none;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: 0px solid transparent;
    height: 100%;
    overflow: hidden;
    .top {
      position: relative;
      height: 229px;
      background-color: ${Colors.primary.white.standard};
      display: flex;
      align-items: center;
      overflow: hidden;
      span,
      img {
        height: 100%;
        width: 100%;
      }
      img {
        object-fit: cover;
      }
    }
    .bottom {
      background: white;
      padding: 27px;
      .type {
        color: #73777a;
        text-transform: uppercase;
        font-family: 'source-sans-pro';
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 7px;
        display: flex;
      }
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.3px;
        margin-bottom: 13px;
      }
      .summary {
        font-family: 'source-sans-pro';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
      .disclaimer {
        font-size: 12px;
        line-height: 14px;
        margin-top: 10px;
        display: flex;
        display: -webkit-box;
        position: relative;

        .asterisk {
          position: absolute;
          left: -10px;
          top: 10px;
        }

        .disclaimer-text {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow-y: hidden;
          font-size: 12px;
          line-height: 14px;
          margin-top: 10px;
        }
      }
    }
  }

  .carousel.carousel-slider {
    display: flex;
  }

  .news-event-slider-btn-next,
  .news-event-slider-btn-prev {
    border: none !important;
    background: none !important;
    outline: none;
    cursor: pointer;
    width: 60px;
    @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
      width: 34px;
    }
  }
  .news-event-slider-btn-prev {
    @media only screen and (max-width: 553px) {
      left: 0px;
      z-index: 8;
    }
  }
  .news-event-slider-btn-next {
    @media only screen and (max-width: 553px) {
      right: 0px;
    }
  }

  .header-label {
    font-family: 'source-sans-pro';
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.5px;
    color: ${Colors.accent.grey2.standard};
    margin-bottom: 12px;
  }

  .section-headline {
    font-family: 'acumin-pro';
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    letter-spacing: -0.35px;
    color: ${Colors.primary.black.standard};
    margin-bottom: 9px;
  }

  .view-all-link {
    font-family: 'source-sans-pro';
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${Colors.primary.claytonBlue.standard};
    text-decoration: none;
    display: block;
    margin-bottom: 14px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .loading-block {
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default NewsEventsStyles;

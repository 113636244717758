import React from 'react';
import { modelNameTransformer } from 'pubweb-smokey/dist/utils/modelNameTransformer';
import { ModelListStyles } from './ModelList.styled';
import ModelItem from './ModelItem';

const ModelList = ({
  modelResults,
  indexHighlighted,
  autoCompleteMaxItems = null,
  onCloseClick,
  autoCompleteOnSelection,
  updateName,
}) => {
  const onSelection = (ev, item) => {
    ev.preventDefault();
    ev.stopPropagation();
    updateName(item.name);

    autoCompleteOnSelection(item);

    if (onCloseClick) {
      onCloseClick(ev, false);
    }
  };

  let models = autoCompleteMaxItems
    ? modelResults.slice(0, autoCompleteMaxItems)
    : modelResults;

  let uniqueModels = models.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => t.modelNumber === value.modelNumber && t.name === value.name
      )
  );

  let modelNodes =
    uniqueModels.length > 0
      ? uniqueModels.map((temp, i) => {
          let highlighted = indexHighlighted === i;
          return (
            <ModelItem
              itemText={`${modelNameTransformer(temp.name)} (${
                temp.modelNumber
              })`}
              highlighted={highlighted}
              key={`${temp.modelNumber}-${i}`}
              onSelection={(ev) => {
                onSelection(ev, temp);
              }}
            />
          );
        })
      : null;

  let result =
    modelNodes && modelNodes.length > 0 ? (
      <div className="model-search-results">
        <div className="model-list">
          <ul>{modelNodes}</ul>
        </div>
      </div>
    ) : null;

  return (
    <ModelListStyles className="model-location-container">
      {result}
    </ModelListStyles>
  );
};

export default ModelList;

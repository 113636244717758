import styled from 'styled-components';
import Colors from '../../colors';

const StyledDisclaimer = styled.div`
  p {
    font: 400 12px/14px 'SourceSans-Pro Regular', sans-serif;
    color: #000000;
    width: 87%;
    margin: 0 auto 128px;
    max-width: 1224px;

    @media only screen and (min-width: 768px) {
      width: 67%;
    }

    @media screen and (min-width: 1025px) {
      width: 85%;
    }
  }
`;

export default StyledDisclaimer;

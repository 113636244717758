import React from 'react';
import PropTypes from 'prop-types';
import FeaturedEventStyles from './FeaturedEvent.styled';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import { removeTags } from '../../../utils';

export const FeaturedEvent = ({
  featuredEvent,
  buttonText,
  smallHeadline,
  isSpecialOffers,
  gaClass,
  aTagLink,
}) => {
  const {
    content,
    endDate,
    startDate,
    linkUrl,
    slug,
    subtitle,
    title,
    mainImage,
    featuredImage,
  } = featuredEvent;

  return (
    <FeaturedEventStyles isSpecialOffers={isSpecialOffers}>
      <div className="container">
        {slug && (
          <Link
            to={`/event/${slug}`}
            aria-label={title ? title + ' image' : 'event image'}
          >
            <div className="image-box">
              {featuredImage && (
                <LazyLoadImage
                  alt={title ? title + ' image' : 'event image'}
                  effect="blur"
                  src={featuredImage}
                />
              )}
              {mainImage && !featuredImage && (
                <LazyLoadImage
                  alt={title ? title + ' image' : 'event image'}
                  effect="blur"
                  src={mainImage}
                />
              )}
            </div>
          </Link>
        )}

        <div className="content-wrap">
          {smallHeadline && (
            <span className="small-headline">{smallHeadline}</span>
          )}
          {title && <h2 className="large-headline">{title}</h2>}
          {subtitle && <p className="date-time h4">{subtitle}</p>}
          {content && (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: removeTags(content) }}
            ></div>
          )}
          {slug && buttonText && !aTagLink && (
            <Link
              to={`/event/${slug}`}
              className={'cta-button ' + (gaClass ? gaClass : '')}
            >
              {buttonText}
            </Link>
          )}
          {linkUrl && buttonText && aTagLink && (
            <a
              href={linkUrl}
              className={'cta-button ' + (gaClass ? gaClass : '')}
            >
              {buttonText}
            </a>
          )}
        </div>
      </div>
    </FeaturedEventStyles>
  );
};

FeaturedEvent.props = {
  smallHeadline: PropTypes.string,
  largeHeadline: PropTypes.string,
  dateTime: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
  gaClass: PropTypes.string,
  aTagLink: PropTypes.bool,
};

export default FeaturedEvent;

import xHttp from '../utils/xHttp';
import { getBaseApiUrl, ENVIRONMENT } from '../utils/config';

/**
 * Get dealer info for pages
 * Trying to keep logic in one place rather than repeating in every page
 * @param {string} lotNumber lot number for the dealer
 * @param {string} url current url the user is using to access the site
 * @returns {Promise}
 */
export const getDealer = (lotNumber, url) => {
  if (lotNumber) {
    return getDealerByLotNumber(lotNumber);
  } else {
    // if not lot number is given, use a default one for dev and qua
    if (
      ENVIRONMENT.indexOf('terraformEnvCode') !== -1 ||
      ENVIRONMENT.indexOf('dev') !== -1 ||
      ENVIRONMENT.indexOf('itg') !== -1 ||
      ENVIRONMENT.indexOf('qua') !== -1
    ) {
      return getDealerByLotNumber(58);
    } else {
      return getDealerByVanityUrl(url);
    }
  }
};

/**
 * Get dealer info by lot number
 * @param {string} lotNumber lot number for the dealer
 * @returns {Promise}
 */
export const getDealerByLotNumber = (lotNumber) => {
  let url = `${getBaseApiUrl()}/api/dealers/id?lotNumber=${lotNumber}&includeContactUsPageData=true`;
  return xHttp.get(url);
};

/**
 * Get dealer info by vanity url
 * @param {string} vanityUrl the vanity url for the given home center
 * @returns {Promise}
 */
export const getDealerByVanityUrl = (vanityUrl) => {
  let url = `${getBaseApiUrl()}/api/dealers/id?url=${vanityUrl}&includeContactUsPageData=true`;
  return xHttp.get(url);
};

import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const CardGridStyles = styled.section`
  position: relative;
  padding: 100px 0px 30px;
  @media only screen and (max-width: 1000px) {
    padding: 70px 0px 30px;
  }
  .no-results {
    text-align: center;
  }
  .container {
    position: relative;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 25px;
    @media only screen and (max-width: 1000px) {
      max-width: 760px;
    }
    @media only screen and (max-width: 775px) {
      max-width: 438px;
    }
  }
  .center-card-grid {
    justify-content: center;
  }
  .card-grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-item {
    position: relative;
    padding: 20px;
    width: 33.33%;
    @media only screen and (max-width: 1000px) {
      width: 50%;
    }
    @media only screen and (max-width: 775px) {
      width: 100%;
    }
  }
  .card {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-decoration: none;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: 0px solid transparent;
    height: 100%;
    overflow: hidden;
    .top {
      position: relative;
      height: 229px;
      background-color: ${Colors.primary.white.standard};
      display: flex;
      align-items: center;
      overflow: hidden;
      span,
      img {
        height: 100%;
        width: 100%;
      }
      img {
        object-fit: cover;
      }
    }
    .bottom {
      background: white;
      padding: 27px;
      .type {
        color: #73777a;
        text-transform: uppercase;
        font-family: 'source-sans-pro';
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 7px;
        display: flex;
      }
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.3px;
        margin-bottom: 13px;
      }
      .summary {
        font-family: 'source-sans-pro';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
      .disclaimer {
        font-size: 12px;
        line-height: 14px;
        margin-top: 10px;
        display: flex;
        position: relative;

        .asterisk {
          position: absolute;
          left: -10px;
          top: 10px;
        }

        .disclaimer-text {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow-y: hidden;
          font-size: 12px;
          line-height: 14px;
          margin-top: 10px;
        }
      }
    }
  }
`;

export default CardGridStyles;

import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import RadioButtonGroup from 'pubweb-smokey/dist/components/RadioButton/RadioButtonGroup';
import React, { useEffect, useRef } from 'react';
import { fireSegmentEvent } from '../../../utils';
import SizeFilterStyles from './SizeFilter.styled';

export const SizeFilter = ({
  values,
  updateMinSquareFeet,
  updateMaxSquareFeet,
  updateSections,
  updateMinAcres,
  updateMaxAcres,
  applyFilter,
  clearFilter,
  className,
}) => {
  const defaultFocusElement = useRef(null);
  const singleSectionCheckBox = useRef(null);
  const multiSectionCheckBox = useRef(null);
  const selectableSquareFeetIncrement = 250;
  const selectableAcresIncrement = 1;

  useEffect(() => {
    // auto focus when class is set to show
    if (className === 'show') {
      defaultFocusElement.current.focus();
    }
  }, [className]);

  const generateMinSquareFeetValues = () => {
    let returnValues = [];

    for (
      let squareFeet = values.squareFeet.lowerLimit;
      squareFeet <= values.squareFeet.max + selectableSquareFeetIncrement;
      squareFeet += selectableSquareFeetIncrement
    ) {
      returnValues.push(squareFeet);
    }

    return returnValues;
  };

  const generateMaxSquareFeetValues = () => {
    let returnValues = [];

    for (
      let squareFeet = values.squareFeet.min + selectableSquareFeetIncrement;
      squareFeet <=
      values.squareFeet.higherLimit + selectableSquareFeetIncrement;
      squareFeet += selectableSquareFeetIncrement
    ) {
      returnValues.push(squareFeet);
    }

    return returnValues;
  };

  // go ahead and gen min/max values based on desired increment
  const minSquareFeetValues = generateMinSquareFeetValues();
  const maxSquareFeetValues = generateMaxSquareFeetValues();

  // since increment can make max value higher than squareFeet upper limit,
  // lets calc the correct default max value to select
  const getMaxSquareFeetSelectedValue = () => {
    if (maxSquareFeetValues.indexOf(values.squareFeet.max) == -1) {
      return maxSquareFeetValues.length > 0
        ? maxSquareFeetValues[maxSquareFeetValues.length - 1]
        : values.squareFeet.max;
    } else {
      return values.squareFeet.max;
    }
  };

  // same for acres
  const generateMinAcresValues = () => {
    let returnValues = [];

    for (
      let acres = Math.ceil(values.acres.lowerLimit);
      acres <= values.acres.max + selectableAcresIncrement;
      acres += selectableAcresIncrement
    ) {
      returnValues.push(acres);
    }

    return returnValues;
  };

  const generateMaxAcresValues = () => {
    let returnValues = [];

    for (
      let acres = Math.ceil(values.acres.min) + selectableAcresIncrement;
      acres <= values.acres.higherLimit + selectableAcresIncrement;
      acres += selectableAcresIncrement
    ) {
      returnValues.push(acres);
    }

    return returnValues;
  };

  // go ahead and gen min/max values based on desired increment
  const minAcresValues = generateMinAcresValues();
  const maxAcresValues = generateMaxAcresValues();

  if (
    minAcresValues[minAcresValues.length - 1] ===
    maxAcresValues[maxAcresValues.length - 1]
  ) {
    minAcresValues.pop();
  }

  // since increment can make max value higher than acres upper limit,
  // lets calc the correct default max value to select
  const getMaxAcresSelectedValue = () => {
    if (maxAcresValues.indexOf(values.acres.max) == -1) {
      return maxAcresValues.length > 0
        ? maxAcresValues[maxAcresValues.length - 1]
        : values.acres.max;
    } else {
      return values.acres.max;
    }
  };

  const sectionItems = [
    { value: 'all', text: 'All' },
    { value: 'single', text: 'Single' },
    { value: 'multi', text: 'Multi' },
    { value: 'crossmod', text: 'CrossMod' },
  ];

  let selectedOption;

  if (
    values.isCrossMod == null &&
    values.isMultiSection == null &&
    values.isSingleSection == null
  ) {
    selectedOption = 'all';
  }

  if (values.isCrossMod == true) {
    selectedOption = 'crossmod';
  }

  if (values.isMultiSection == true) {
    selectedOption = 'multi';
  }

  if (values.isSingleSection == true) {
    selectedOption = 'single';
  }

  return (
    <SizeFilterStyles className={className}>
      <div className="content-area">
        <div className="content-row">
          <h6>Square Feet</h6>
          <div className="select-area">
            <select
              ref={defaultFocusElement}
              tabIndex="0"
              name="squareFeetMin"
              id="squareFeetMin"
              value={values.squareFeet.min}
              onChange={(e) => {
                updateMinSquareFeet(parseInt(e.target.value));
              }}
            >
              {minSquareFeetValues.map((squareFeet, i) => {
                return (
                  <option value={squareFeet} key={squareFeet + i}>
                    {squareFeet.toLocaleString() + ' sq. ft.'}
                  </option>
                );
              })}
            </select>
            <div className="dash"></div>
            <select
              tabIndex="0"
              name="maxSquareFeet"
              id="maxSquareFeet"
              value={getMaxSquareFeetSelectedValue()}
              onChange={(e) => {
                updateMaxSquareFeet(parseInt(e.target.value));
              }}
            >
              {maxSquareFeetValues.map((squareFeet, i) => {
                return (
                  <option value={squareFeet} key={squareFeet + i}>
                    {squareFeet.toLocaleString() + ' sq. ft.'}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="content-row">
          <h6>Sections</h6>
          <div className="sections-area">
            <RadioButtonGroup
              id="section"
              items={sectionItems}
              defaultValue={selectedOption}
              selectedValue={selectedOption}
              onChange={(e) => {
                updateSections(e.value);
                //selectedOption = e.value;
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  applyFilter();
                }
              }}
            />
          </div>
        </div>

        <div className="content-row">
          <h6>Lot Size</h6>
          <div className="select-area">
            <select
              ref={defaultFocusElement}
              tabIndex="0"
              name="acresMin"
              id="acresMin"
              value={values.acres.min}
              onChange={(e) => {
                updateMinAcres(parseInt(e.target.value));
              }}
            >
              {minAcresValues.map((acres, i) => {
                return (
                  <option value={acres} key={acres + i}>
                    {acres.toLocaleString() + ' acres'}
                  </option>
                );
              })}
            </select>
            <div className="dash"></div>
            <select
              tabIndex="0"
              name="maxAcres"
              id="maxAcres"
              value={getMaxAcresSelectedValue()}
              onChange={(e) => {
                updateMaxAcres(parseInt(e.target.value));
              }}
            >
              {maxAcresValues.map((acres, i) => {
                return (
                  <option value={acres} key={acres + i}>
                    {acres.toLocaleString() + ' acres'}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="button-area">
        <span
          className="clear-btn"
          tabIndex="0"
          onClick={() => {
            clearFilter('squareFeet');
            clearFilter('isMultiSection');
            clearFilter('isSingleSection');
            clearFilter('isCrossMod');
            clearFilter('acres');
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              clearFilter('squareFeet');
              clearFilter('isMultiSection');
              clearFilter('isSingleSection');
              clearFilter('isCrossMod');
              clearFilter('acres');
            }
          }}
        >
          CLEAR
        </span>
        <Button
          className="ga-filter-size"
          tabIndex="0"
          automationId="sizeFilterBtn"
          onClick={() => {
            applyFilter();
            fireSegmentEvent('Facility Model List Filtered', {
              list: 'Homes List',
              filters: [
                {
                  type: 'Size',
                  value:
                    values?.squareFeet?.min +
                    '-' +
                    values?.squareFeet?.max +
                    'sqft / ' +
                    selectedOption +
                    ' / ' +
                    values?.acres?.min +
                    '-' +
                    values?.acres?.max +
                    ' acres',
                },
              ],
              eventContext: 'available_homes_filter_size',
            });
          }}
        >
          APPLY
        </Button>
      </div>
    </SizeFilterStyles>
  );
};

export default SizeFilter;

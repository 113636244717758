import PropTypes from 'prop-types';
import ChevronDwnSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-dwn.svg';
import React, { useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import BedsBathsFilter from './BedsBathsFilter/BedsBathsFilter';
import HeaderWithHeroStyles from './HeaderWithHero.styled';
import PriceFilter from './PriceFilter/PriceFilter';
import { fireSegmentEvent } from '../../../utils';

export const HeaderWithHero = ({
  smallHeadline,
  largeHeadline,
  containerWidth,
  backgroundPosition,
  lowerTextOnMobile,
  content,
  preHeadlineImage,
  image,
  buttonText,
  buttonURL,
  aTagLink,
  gaClass,
  disclaimer,
  hideDisclaimer,
  showHomesForm,
  highestPricedHome,
  highestBedCount,
  highestBathCount,
  lowestBedCount,
  lowestBathCount,
  showLogo,
  isBuilderModel,
  isNoSpecialOffersNoEnergySmart,
}) => {
  const selectablePriceIncrement = 25000;
  const [priceDropdown, setPriceDropdown] = useState(false);
  const [bedBathDropdown, setBedBathDropdown] = useState(false);
  const [priceLabel, setPriceLabel] = useState('Price');
  const [bedBathLabel, setBedBathLabel] = useState('Beds & Baths');

  let higherLimit =
    Math.ceil(highestPricedHome / selectablePriceIncrement) *
    selectablePriceIncrement;
  let lowerLimit = 0;
  const [maxPrice, setMaxPrice] = useState(
    Math.ceil(higherLimit / selectablePriceIncrement) * selectablePriceIncrement
  );
  const [minPrice, setMinPrice] = useState(0);
  const [minBeds, setMinBeds] = useState(lowestBedCount);
  const [minBaths, setMinBaths] = useState(lowestBathCount);
  const [maxBeds, setMaxBeds] = useState(highestBedCount);
  const [maxBaths, setMaxBaths] = useState(highestBathCount);

  return (
    <HeaderWithHeroStyles
      containerWidth={containerWidth}
      backgroundPosition={backgroundPosition}
      lowerTextOnMobile={lowerTextOnMobile}
    >
      <div className={showHomesForm ? 'inner shows-form' : 'inner'}>
        <div className="container">
          <div className="content-wrap">
            {showLogo && preHeadlineImage && (
              <div className="pre-headline-image">
                <img src={preHeadlineImage.source} alt={preHeadlineImage.alt} />
              </div>
            )}
            {showLogo && smallHeadline && (
              <span className="small-headline">{smallHeadline}</span>
            )}

            {largeHeadline && (
              <h1 className="large-headline">{largeHeadline}</h1>
            )}
            {isBuilderModel && (
              <p className="content">Specializing in move-in ready homes.</p>
            )}
            {isNoSpecialOffersNoEnergySmart && (
              <p className="content">Modern manufactured homes.</p>
            )}
            {content && <p className="content">{content}</p>}
            {buttonURL && buttonText && !aTagLink && (
              <a
                href={buttonURL}
                className={'cta-button ' + (gaClass ? gaClass : '')}
              >
                {buttonText}
              </a>
            )}
            {buttonURL && buttonText && aTagLink && (
              <a
                href={buttonURL}
                className={'cta-button ' + (gaClass ? gaClass : '')}
              >
                {buttonText}
              </a>
            )}
          </div>
        </div>
      </div>
      {image && (
        <>
          <div className="image-box curtain"></div>
          <div className="image-box">
            <OptimizedImage
              src={image?.source}
              srcSet={image?.srcSet}
              alt={image?.alt}
              imgClass={'fill-container landscape'}
            />
          </div>
        </>
      )}
      {hideDisclaimer ||
        (hideDisclaimer == false && (
          <div className="disclaimer">
            <p>
              *Save $2,000 on the purchase of select new homes when you add any
              attached or detached garage or carport to your purchase. Available
              with a purchase agreement signed January 1, 2021 through June 30,
              2021. Sale homes will vary by retailer and state. See local
              retailer for a list of eligible homes.
            </p>
          </div>
        ))}
      {showHomesForm && (
        <div className="home-filter-form-box">
          <p className="h3">Find your dream home today</p>
          <form className="home-filter-form" action="/homes">
            <div className="dropdown price-dropdown">
              <div
                className="dropdown-title"
                onClick={() => {
                  setPriceDropdown(!priceDropdown);
                  setBedBathDropdown(false);
                }}
              >
                <p>{priceLabel}</p>
                <img
                  src={ChevronDwnSvg}
                  alt="chevron icon"
                  className={priceDropdown ? 'closed' : 'opened'}
                />
              </div>
              <div
                className={
                  priceDropdown ? 'filter-wrap' : 'filter-wrap hide-dropdown'
                }
              >
                <PriceFilter
                  updateMinPrice={setMinPrice}
                  updateMaxPrice={setMaxPrice}
                  updatePriceLabel={setPriceLabel}
                  maxPrice={maxPrice}
                  minPrice={minPrice}
                  higherLimit={higherLimit}
                  lowerLimit={lowerLimit}
                  closeFilter={setPriceDropdown}
                />
              </div>
            </div>
            <div className="dropdown">
              <div
                className="dropdown-title"
                onClick={() => {
                  setBedBathDropdown(!bedBathDropdown);
                  setPriceDropdown(false);
                }}
              >
                <p>{bedBathLabel}</p>
                <img
                  src={ChevronDwnSvg}
                  alt="chevron icon"
                  className={bedBathDropdown ? 'closed' : 'opened'}
                />
              </div>
              <div
                className={
                  bedBathDropdown ? 'filter-wrap' : 'filter-wrap hide-dropdown'
                }
              >
                <BedsBathsFilter
                  bedsHigherLimit={highestBedCount}
                  bathsHigherLimit={highestBathCount}
                  bedsLowerLimit={lowestBedCount}
                  bathsLowerLimit={lowestBathCount}
                  updateMinBeds={setMinBeds}
                  updateMaxBeds={setMaxBeds}
                  updateMinBaths={setMinBaths}
                  updateMaxBaths={setMaxBaths}
                  minBeds={minBeds}
                  minBaths={minBaths}
                  maxBeds={maxBeds}
                  maxBaths={maxBaths}
                  closeFilter={setBedBathDropdown}
                  updateBedBathLabel={setBedBathLabel}
                />
              </div>
            </div>
            <button
              className="submit-btn ga-homepage-search-homes"
              onClick={() => {
                fireSegmentEvent('CTA Clicked', {
                  isNav: false,
                  type: 'button',
                  text: 'Search Homes',
                  eventContext: 'home_hero_search_homes',
                });
              }}
            >
              Search Homes
            </button>
          </form>
        </div>
      )}
    </HeaderWithHeroStyles>
  );
};

HeaderWithHero.props = {
  smallHeadline: PropTypes.string,
  largeHeadline: PropTypes.string,
  containerWidth: PropTypes.string,
  backgroundPosition: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string.isRequired,
  aTagLink: PropTypes.bool,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  disclaimer: PropTypes.string,
  gaClass: PropTypes.string,
  applyDarkOverlay: PropTypes.bool,
  expensiveHome: PropTypes.string,
  hideDisclaimer: PropTypes.bool,
  showHomesForm: PropTypes.bool,
  isBuilderModel: PropTypes.bool,
};

export default HeaderWithHero;

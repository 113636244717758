import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../../colors';
import SelectableCardStyles from './SelectableCard.styled';

const SelectableCard = React.forwardRef(
  (
    {
      text,
      id,
      isDisabled,
      primaryColor,
      isSelected,
      value,
      automationID,
      className,
      onCardClick,
      cardID,
      errorMsg,
      onBlur,
      icon,
      ...props
    },
    ref
  ) => {
    const propertyClasses = [];

    propertyClasses.push(primaryColor.className);
    propertyClasses.push(isSelected ? 'selected' : '');
    propertyClasses.push(isDisabled ? 'disabled' : '');
    propertyClasses.push(className ? className : '');

    return (
      <SelectableCardStyles
        className={propertyClasses}
        isSelected={isSelected}
        id={automationID ? 'automation-id-' + automationID : cardID}
        onClick={onCardClick}
        isDisabled={isDisabled}
        errorMsg={errorMsg}
        onBlur={onBlur ? onBlur : null}
      >
        <p className="card-text" {...props}>
          {text}
        </p>
        {icon && <div className="card-icon">{icon}</div>}
      </SelectableCardStyles>
    );
  }
);

export default SelectableCard;

SelectableCard.defaultProps = {
  text: '',
  className: '',
  primaryColor: Colors.primary.claytonBlue,
  isSelected: false,
  isDisabled: false,
};

SelectableCard.props = {
  text: PropTypes.string.isRequired,
  primaryColor: PropTypes.oneOf([
    Colors.primary.claytonBlue,
    Colors.secondary.kiddiePool,
    Colors.accent.summerNight,
    Colors.accent.ladyBug,
    Colors.accent.cheesePuff,
    Colors.secondary.rubberDucky,
  ]),
  value: PropTypes.string,
  isSelected: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  cardID: PropTypes.string,
  automationID: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMsg: PropTypes.string,
};

import React, { useRef } from 'react';
import App from '../../App';
import { StyledSummerPromoThankYou } from './SummerPromoThankYou.Style';
import SaleHomeSlider from '../Shared/SaleHomeSlider/SaleHomeSlider';
import TextContent from '../Shared/TextContent/TextContent';
import EnergySmartZeroCostSavings from '../Shared/EnergySmartZeroCostSavings/EnergySmartZeroCostSavings';
import Video from '../Shared/Video/Video';
import PromoContent from '../Promo/PromoContent';
import PromoDisclaimer from './PromoDisclaimer';
import XLCTA from '../Shared/XLCTA/XLCTA';
import VideoBackgroundImage from '../../images/promo-images/summer-promo/PickTheTempoVideoBanner.jpg';
import { Link } from 'react-router-dom';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import useWindowSize from '../../hooks/useWindowSize';
import EBuiltConfirmationLogo from '../../images/promo-images/summer-promo/ebuilt-thank-you.svg';
import { Helmet } from 'react-helmet';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import Colors from '../../colors';

const promoIsInactive = false;

const SummerPromoThankYou = ({ dealer, homesOnSale, homes }) => {
  const [width, height] = useWindowSize();

  const handleScroll = () => {
    smoothScroll('#PromoDisclaimer', -90);
  };

  const isClayton = () => {
    if (dealer?.brandAcronym.toLowerCase() === 'cla') {
      return true;
    }
    return false;
  };

  return (
    <App dealer={dealer} homes={homes}>
      <StyledSummerPromoThankYou>
        <Helmet>
          <title>
            Opening Doors Sales Event - Affordable eBuilt Manufactured, Mobile
            Homes | {dealer ? formatDealerName(dealer?.dealerName) : ''}
          </title>
          <meta
            name="description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
          <meta
            name="og:description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
        </Helmet>
        <div className="ty-message-banner">
          <div className="banner"></div>
          <div className="confirmation-message">
            <div className="message-container">
              <h3>Thank You!</h3>
              <div className="house-container">
                <img src={EBuiltConfirmationLogo} />
              </div>
              <p>
                Thank you for reaching out. A home <br />
                specialist will be in touch shortly.
              </p>
              <Link
                to={{
                  pathname: '/homes',
                  state: { dealer, homes },
                }}
                id="viewHomes"
                className="button"
              >
                View Homes
              </Link>
            </div>
          </div>
        </div>

        <TextContent
          smallHeadline={'OPENING DOORS SALES EVENT'}
          largeHeadline={'Welcome to eBuilt®.'}
          content={
            <>
              We’re changing the way homes are made and strive to make them
              affordable in the process. Introducing high-performing, energy
              efficient eBuilt® homes that can save you up to 50%{' '}
              <span className="superscript">1</span> on annual energy costs. For
              a limited time, you can also save thousands* on these modern
              manufactured homes.
            </>
          }
          links={[
            {
              linkContent: (
                <>
                  <p className="link-text">
                    <span className="bold">1</span> Homes are built to the U.S.
                    DOE Zero Energy Ready Home® standards.
                  </p>
                  <a
                    href="https://www.energy.gov/sites/default/files/2022-11/ZERH%20Name%20and%20Logo%20Use%20Guidelines_0.pdf"
                    target="_blank"
                  >
                    ZERH Logo Use Guidelines (energy.gov)
                  </a>
                </>
              ),
            },
          ]}
          smallDisclaimer={
            <>
              * Sales price does not include required taxes, delivery, and
              installation. For complete pricing details{' '}
              <a onClick={handleScroll}>click here</a>.
            </>
          }
          backgroundColor={Colors.primary.white.standard}
          largePadding={true}
          isPromo={true}
        />
        <EnergySmartZeroCostSavings
          dealer={dealer}
          isPromoBackgroundImg={true}
          isPromo={true}
        />
        <Video
          gaClass={'ga-seasonal-campaign-video'}
          image={{ source: VideoBackgroundImage }}
          videoID={isClayton() ? 'impt0zivef' : '1g6vi7x1lf'}
          wistiaOptions={'silentAutoPlay=false autoPlay=true'}
          isPromoBackgroundImg={true}
          isPromo={true}
          noSaleHomes={!homesOnSale || homesOnSale.length <= 0}
        />
        {homesOnSale && homesOnSale.length > 0 && (
          <SaleHomeSlider
            sliderId="promoSlider"
            largeHeadline={
              !promoIsInactive ? 'Explore More Ways to Save' : 'Explore Homes'
            }
            saleHomes={homesOnSale}
            buttonText={'View All Sale Homes'}
            buttonURL={'/sale-homes'}
            gaClass={'ga-seasonal-campaign-view-sale-homes'}
            dealer={dealer}
            isPromo={true}
          />
        )}
        <PromoContent
          isSeasonalCampaign={true}
          formatDealerName={formatDealerName}
          dealer={dealer}
          homes={homes}
        />
        {dealer && (
          <XLCTA
            phoneNumber={dealer.phoneNumber}
            customCallText="Call Us Today"
            gaPhoneClass={'ga-seasonal-campaign-phone-number'}
            buttons={[
              {
                buttonText: 'Get More Information',
                buttonURL: '/contact-us',
                gaClass: 'ga-seasonal-campaign-get-more-information',
              },
              {
                buttonText: 'Schedule a Visit',
                buttonURL: '/contact-us',
                gaClass: 'ga-seasonal-campaign-schedule-a-visit',
              },
            ]}
          />
        )}
      </StyledSummerPromoThankYou>
    </App>
  );
};

SummerPromoThankYou.getInitialProps = async (ctx) => {
  return await parseInitialPropsContext(ctx, dealerService, homesService);
};

export default SummerPromoThankYou;

import PropTypes from 'prop-types';
import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import { fireSegmentEvent } from '../../../utils';
import SpecialOffersHeroStyles from './SpecialOffersHero.styled';

export const SpecialOffersHero = ({
  smallHeadline,
  largeHeadline,
  largeSubHeadline,
  promoTagLine,
  image,
  tabletImage,
  mobileImage,
  buttonText,
  buttonURL,
  aTagLink,
  promoLogo,
  gaClass,
  retailEventLabel,
  disclaimer,
  homesOnSale,
  dealer,
  homes,
  handleScroll,
  promoLogoExtended,
  promoLogoExtendedInline,
}) => {
  return (
    <SpecialOffersHeroStyles>
      <div className="wrapper">
        <div className="image-box">
          {image && (
            <img className="image" alt={image.alt} src={image.source} />
          )}
          {tabletImage && (
            <img
              className="tablet-image"
              alt={tabletImage.alt}
              src={tabletImage.source}
            />
          )}
          {mobileImage && (
            <img
              className="mobile-image"
              alt={mobileImage.alt}
              src={mobileImage.source}
            />
          )}
          {promoLogo && (
            <img
              className="promo-logo"
              src={promoLogo.source}
              alt={promoLogo.alt}
            />
          )}
          {promoLogoExtended && (
            <img
              className="promo-logo-extended"
              src={promoLogoExtended.source}
              alt={promoLogoExtended.alt}
            />
          )}
          {promoTagLine && <p className="promo-tag-line">{promoTagLine}</p>}
        </div>
        <div className="content-wrap">
          {smallHeadline && (
            <span className="small-headline">{smallHeadline}</span>
          )}
          {largeHeadline && (
            <div className="headline-wrap">
              <h1 className="large-headline">{largeHeadline}</h1>
              {promoLogoExtendedInline && (
                <img
                  className="promo-logo-extended-inline"
                  src={promoLogoExtendedInline.source}
                  alt={promoLogoExtendedInline.alt}
                />
              )}
            </div>
          )}
          {largeSubHeadline && (
            <p className="large-sub-headline">{largeSubHeadline}</p>
          )}
          <div className="content-disclaimer">
            <p>{disclaimer}</p>
          </div>
          {buttonURL && buttonText && !aTagLink && (
            <Link
              to={{ pathname: buttonURL, state: { dealer, homes } }}
              className={'cta-button ' + (gaClass ? gaClass : '')}
            >
              {buttonText}
            </Link>
          )}
          {buttonURL && buttonText && aTagLink && (
            <a
              href={buttonURL}
              className={'cta-button ' + (gaClass ? gaClass : '')}
              onClick={() => {
                fireSegmentEvent('CTA Clicked', {
                  isNav: false,
                  type: 'button',
                  text: buttonText ? buttonText : '',
                  eventContext: retailEventLabel ? retailEventLabel : '',
                });
              }}
            >
              {buttonText}
            </a>
          )}
        </div>
      </div>
    </SpecialOffersHeroStyles>
  );
};

SpecialOffersHero.props = {
  smallHeadline: PropTypes.string,
  largeHeadline: PropTypes.string,
  largeSubHeadline: PropTypes.string,
  disclaimer: PropTypes.string,
  image: PropTypes.string,
  mobileImage: PropTypes.string,
  aTagLink: PropTypes.bool,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  disclaimer: PropTypes.string,
  gaClass: PropTypes.string,
};

export default SpecialOffersHero;

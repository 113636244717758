import styled from 'styled-components';

const PriceFilterStyles = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  z-index: 99;

  @media screen and (max-width: 768px) {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    left: 0;
  }

  .content-area {
    height: 118px;
    display: flex;
    justify-content: center;
    align-items: center;

    .name-input {
      width: 232px;
    }

    .dash {
      width: 10px;
      height: 0px;
      border-top: 1px solid #777b7e;
      margin: 0 4px;
    }
  }
  .button-area {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid #e4e5e7;

    button {
      padding: 10px 30px;
    }

    .clear-btn {
      cursor: pointer;
    }

    .clear-btn,
    a:link,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
      color: #4e5255;
      font-family: source-sans-pro;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
`;
export default PriceFilterStyles;

import styled from 'styled-components';
import colors from 'pubweb-smokey/dist/colors';

export const StyledPromoContent = styled.div`
  background: #00a6a2;
  padding: 80px 0;
  box-sizing: border-box;

  &.campaign {
    background: #f3f5f7;
  }

  @media only screen and (min-width: 768px) {
    padding: 104px 0;
  }

  .promo-content-container {
    margin: 0 auto;
    width: 87%;

    @media only screen and (min-width: 768px) {
      width: 67%;
      max-width: 512px;
    }

    @media only screen and (min-width: 1025px) {
      width: 56%;
      max-width: 808px;
      display: flex;
      justify-content: space-between;
    }

    &.campaign-container {
      max-width: 836px;
      width: 58%;
    }

    .logo-container {
      margin-bottom: 15px;
      @media only screen and (min-width: 320px) {
        margin: 0 auto 15px;
        max-width: 144px;
      }

      @media only screen and (min-width: 1025px) {
        margin: 0 64px 0 0;
        max-width: none;
      }

      &.campaign-logo {
        max-width: 187px;

        @media only screen and (min-width: 768px) {
          max-width: 241px;
        }

        @media only screen and (min-width: 1025px) {
          margin: 0 77px 0 0;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .promo-content {
      max-width: 550px;
      text-align: left;

      &.campaign-promo-content {
        text-align: center;

        @media only screen and (min-width: 1025px) {
          text-align: left;
          max-width: 534px;
        }
      }

      .promo-content-headline {
        font-weight: 700;
        letter-spacing: -0.35px;
        margin: 0 auto 20px;
        font-size: 34px;
        line-height: 40px;
        font-family: 'acumin-pro', sans-serif;
        color: ${colors.primary.white.standard};

        @media only screen and (min-width: 768px) {
          font-size: 40px;
          line-height: 48px;
        }

        @media only screen and (min-width: 1025px) {
          text-align: left;
        }

        &.campaign {
          color: #00b1ad;
        }
      }

      .promo-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
        margin: 0 auto;
        font-family: 'source-sans-pro', sans-serif;
        color: ${colors.primary.white.standard};

        &.campaign {
          color: #00b1ad;
          font-weight: 700;
          font-size: 16px;
          line-height: 27px;
          max-width: 518px;
        }
      }

      .disclaimer {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        font-family: 'source-sans-pro', sans-serif;
        color: #000000;
        margin-top: 20px;
      }

      .learn-more {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        font-family: 'source-sans-pro', sans-serif;
        color: #00b1ad;
        cursor: pointer;
        text-decoration: none;
        margin-top: 20px;
        display: flex;
        justify-content: center;

        @media only screen and (min-width: 1025px) {
          justify-content: start;
        }

        img {
          margin-right: 15px;
        }
      }
    }
  }
`;

import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import Modal from 'pubweb-smokey/dist/components/Modal/Modal';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import {
  getLocalStorage,
  setLocalStorage,
  fireSegmentEvent,
} from '../../../utils';
import { EmailSignupPopupStyles } from './EmailSignupPopup.Styled';
import EmailSignupPopupImage from './EmailSignupPopupImage.jpg';
import EmailPopupForm from './EmailPopupForm/EmailPopupForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import HomeIcon from '../../../images/svg/iconography-16x16/home.svg';

const EmailSignupPopup = ({ onCloseEmailPopup, dealer }) => {
  const location = getLocalStorage('emailPopupClosed');

  const [showScheduleYourVisit, setShowScheduleYourVisit] = useState(false);
  const [showFormTitle, setShowFormTitle] = useState(true);

  const clearThankYouPage = () => {
    setLocalStorage('emailPopupClosed', true);
    setShowScheduleYourVisit(false);
    onCloseEmailPopup();
  };

  const showThankYou = () => {
    setShowFormTitle(false);
  };

  if (getLocalStorage('emailPopupClosed') !== true) {
    return (
      <EmailSignupPopupStyles>
        {!showScheduleYourVisit && (
          <div className="email-modal-main">
            <Modal
              onClose={() => {
                setLocalStorage('emailPopupClosed', true);
                onCloseEmailPopup();
              }}
            >
              <div className="emailImage">
                <LazyLoadImage
                  alt={'Realtor showing family home.'}
                  effect="blur"
                  src={EmailSignupPopupImage}
                />
              </div>
              <div className="emailContent">
                <ReactSVG src={HomeIcon} />
                <h1>Ready to explore in person?</h1>
                <h4>See for yourself why our homes are the smart choice.</h4>
                <Button
                  onClick={() => {
                    setShowScheduleYourVisit(true) +
                      fireSegmentEvent('CTA Clicked', {
                        isNav: false,
                        type: 'button',
                        text: 'SCHEDULE A TOUR',
                        eventContext: 'pop_up_schedule',
                      });
                  }}
                  className="ga-popup-cta"
                  automationId="emailSignUpBtnOne"
                >
                  <span>SCHEDULE A TOUR</span>
                </Button>
              </div>
            </Modal>
          </div>
        )}
        {showScheduleYourVisit && (
          <div className="email-modal-form">
            <Modal
              onClose={() => {
                setLocalStorage('emailPopupClosed', true);
                onCloseEmailPopup();
                setShowScheduleYourVisit(false);
              }}
            >
              {showFormTitle && <div className="h3">Schedule a Tour</div>}

              <EmailPopupForm
                dealer={dealer}
                clearThankYouPage={clearThankYouPage}
                showThankYouCallBack={showThankYou}
              />
            </Modal>
          </div>
        )}
      </EmailSignupPopupStyles>
    );
  } else return null;
};

export default EmailSignupPopup;

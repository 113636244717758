import React from 'react';
import PropTypes from 'prop-types';

const Phone = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.3365 22.8328C26.175 23.6653 25.7141 24.2844 24.8326 25.1358C24.8418 25.1269 24.3653 25.5812 24.2499 25.6966C22.1618 27.7789 16.3649 25.5913 11.5589 20.7889C6.75584 15.9876 4.56843 10.1868 6.65085 8.10151C6.76466 7.98734 7.21517 7.51364 7.20715 7.52197C8.05863 6.63821 8.67691 6.17637 9.50958 6.01366C10.6532 5.7902 11.7489 6.33412 12.7853 7.62076C14.5819 9.85429 14.6443 11.3244 13.2927 12.7873C13.1914 12.897 13.0845 13.0063 12.9425 13.147L12.8943 13.1946C12.7492 13.3374 12.6623 13.423 12.5977 13.4875C12.4982 13.5871 13.4877 15.1085 15.3634 16.9879C17.2361 18.8607 18.7593 19.8501 18.859 19.7508C18.9194 19.6903 19.0012 19.6072 19.1332 19.4731L19.197 19.4083C19.3361 19.268 19.4447 19.1617 19.5535 19.0611C21.0192 17.7053 22.4915 17.7648 24.7262 19.5617C26.0129 20.5949 26.5581 21.6894 26.3365 22.8328ZM19.595 24.0738C21.2895 24.7133 22.5666 24.7273 22.926 24.3689L22.925 24.3698C23.0581 24.2367 23.5541 23.7639 23.5299 23.7872C24.1459 23.1923 24.4311 22.8092 24.4957 22.4759C24.5673 22.1069 24.3447 21.6601 23.5517 21.0233C21.9989 19.7747 21.5623 19.757 20.8267 20.4375C20.7415 20.5163 20.6505 20.6054 20.5289 20.728L20.4746 20.7832C20.3421 20.9178 20.253 21.0083 20.1844 21.0771C18.8245 22.4309 16.913 21.1892 14.0369 18.3131C11.1509 15.4212 9.91401 13.5196 11.2724 12.1612C11.3438 12.0899 11.4381 11.997 11.5825 11.8549L11.6226 11.8154C11.7467 11.6923 11.8362 11.6008 11.9155 11.515C12.5914 10.7834 12.573 10.3483 11.3247 8.79645C10.6864 8.00394 10.2386 7.78167 9.86917 7.85386C9.53558 7.91905 9.1527 8.20504 8.55741 8.82291C8.57957 8.7999 8.11093 9.29266 7.9782 9.42581C7.62091 9.78359 7.63457 11.0606 8.27312 12.754C9.06925 14.8652 10.6555 17.2347 12.8844 19.4627C15.1142 21.6908 17.4843 23.2773 19.595 24.0738Z"
      fill={color}
    />
  </svg>
);

Phone.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Phone.defaultProps = {
  color: '#0075C9',
  width: '31',
  height: '31',
};

export default Phone;

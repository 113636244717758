import React from 'react';
import PropTypes from 'prop-types';
import { contactUsPageStructuredData } from '../../utils/structuredData';

const ContactStructuredData = ({ dealer, homes }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(contactUsPageStructuredData(dealer, homes)),
      }}
    ></script>
  );
};

export default ContactStructuredData;

ContactStructuredData.propTypes = {
  dealer: PropTypes.shape({
    dealerName: PropTypes.string,
    firtVanityUrl: PropTypes.string,
    address1: PropTypes.string,
    city: PropTypes.string,
    stateProvince: PropTypes.string,
    postalCode: PropTypes.string,
    phoneNumber: PropTypes.string,
    weekdayHours: PropTypes.string,
    fridayHours: PropTypes.string,
    saturdayHours: PropTypes.string,
    sundayHours: PropTypes.string,
    emailAddress: PropTypes.string,
    logoReference: PropTypes.string,
  }).isRequired,
  homes: PropTypes.arrayOf(
    PropTypes.shape({
      priceSort: PropTypes.number,
    })
  ).isRequired,
};

import React from 'react';
import styled from 'styled-components';
import Colors from '../../colors';

export const FormContainer = styled.div`
  background: ${Colors.accent.eBuiltprimaryBlue.standard};
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1025px) {
    max-width: none;
    width: 50%;
    margin: unset;
  }

  .form-container {
    padding: 0 0 60px;
    max-width: 327px;

    @media screen and (min-width: 768px) {
      margin-top: -39px;
      padding-bottom: 51px;
      max-width: 512px;
    }

    @media screen and (min-width: 1025px) {
      margin-top: -33px;
      max-width: 496px;
    }
  }

  .logo {
    max-width: 289px;
    @media screen and (min-width: 768px) {
      max-width: 420px;
      margin-left: 33px;
    }
  }

  .form-content {
    box-sizing: border-box;

    .headline {
      font-weight: 700;
      font-family: 'acumin-pro', sans-serif;
      letter-spacing: -0.35px;
      color: ${Colors.primary.black.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      margin-bottom: 16px;
      font-size: 40px;
      line-height: 48px;

      @media screen and (min-width: 1025px) {
        margin-bottom: 10px;
      }
    }

    .subheadline {
      font: 400 20px/24px 'source-sans-pro', sans-serif;
      text-transform: uppercase;
      color: ${Colors.primary.black.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 0;
      letter-spacing: normal;
    }

    .disclaimer {
      font: 400 12px/17px 'source-sans-pro', sans-serif;
      color: ${Colors.primary.black.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 0 auto;

      span {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 400;
        font-family: 'source-sans-pro', sans-serif;
      }
    }

    .divider {
      background: ${Colors.primary.black.standard};
      width: 64px;
      margin: 21px 0 18px;
      height: 1px;

      @media only screen and (min-width: 768px) {
        width: 32px;
        margin: 32px 0;
      }

      @media screen and (min-width: 1025px) {
        width: 64px;
        margin: 21px 0 18px;
      }
    }

    .form-offer-text {
      font: 700 18px/22px 'acumin-pro', sans-serif;
      letter-spacing: -0.35px;
      color: ${Colors.primary.white.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      text-align: center;

      @media only screen and (min-width: 768px) {
        font-size: 22px;
        line-height: 26px;
        padding: 0 35px;
        margin-top: -22px;
      }
    }
  }

  .submission-error-msg {
    color: #ff0000;
    box-sizing: border-box;
    max-width: 464px;
    margin: 0 auto 20px;
  }

  .bold {
    font-weight: bold;
  }

  .group {
    @media only screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }

  form {
    margin: 0 auto;
    padding: 20px 0 0px;

    @media only screen and (min-width: 768px) {
      padding-bottom: 0px;
    }

    @media only screen and (min-width: 1025px) {
      padding-bottom: 0px;
    }
  }

  .input-container {
    position: relative;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 100%;

      input {
        max-width: none;
      }
    }
  }

  .has-error {
    input {
      border: 1px solid red;
    }
  }

  .half-width-input {
    @media only screen and (min-width: 768px) {
      width: 47%;
    }
  }

  .full-width-input {
    width: 100%;
  }

  .no-margin {
    margin: 0;
  }

  .agree-terms {
    font: 400 11px/15.5px 'source-sans-pro', sans-serif;
    color: ${Colors.primary.white.standard};
    width: 100%;
    margin: 0 0 30px;

    @media only screen and (min-width: 768px) {
      margin-top: 23px;
    }

    span {
      font-family: 'source-sans-pro', sans-serif;
    }

    .capitalize-first-letter {
      text-transform: capitalize;
    }

    a {
      text-decoration: none;
      font: 400 11px/15.5px 'source-sans-pro', sans-serif;
      color: ${Colors.primary.white.standard};
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .submit-btn {
    background-color: ${Colors.accent.eBuiltButtonBlue.standard};
    color: ${Colors.primary.white.standard};

    &:hover {
      opacity: 0.8;
      cursor: pointer;
      background-color: ${Colors.accent.eBuiltButtonBlue.standard};
      color: ${Colors.primary.white.standard};
    }
  }
`;

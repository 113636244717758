import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const ImageGalleryWithContentStyles = styled.section`
  position: relative;

  .top-content {
    padding: 80px 25px;
    position: relative;
    background: #f3f5f7;
    .container {
      max-width: 500px;
      position: relative;
      margin: 0 auto;
      text-align: center;
    }
  }
  .small-headline {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #777b7e;
    text-transform: uppercase;
    font-family: 'source-sans-pro';
  }
  .large-headline {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.35px;
    margin-bottom: 6px;
    @media only screen and (max-width: 553px) {
      font-size: 34px;
      line-height: 40px;
      letter-spacing: -0.3px;
    }
  }
  .content {
    color: #25292d;
    p {
      color: #25292d;
    }
  }
  .cta-button {
    background: ${Colors.primary.claytonBlue.standard};
    color: white;
    border-radius: 2px;
    padding: 17px 17px;
    font: 500 12px/100% 'source-sans-pro', sans-serif;
    margin-top: 30px;
    letter-spacing: 1px;
    text-decoration: none;
    transition: 0.2s;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-flex;
    align-self: center;
    &:hover {
      background: rgb(23, 158, 255);
    }
  }
  .image-gallery {
    display: flex;
    height: 28vw;
    .img-col-one {
      aspect-ratio: 1/1;
      width: 28vw;
      overflow: hidden;
      position: relative;
    }
    .img-col-two {
      width: 28vw;
      aspect-ratio: 1/1;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      div {
        height: 50%;
        width: 50%;
        position: relative;
      }
    }
    .img-col-three {
      width: 28vw;
      aspect-ratio: 1/1;
      overflow: hidden;
      position: relative;
    }
    .img-col-four {
      width: 16vw;
      aspect-ratio: 1/1;
      overflow: hidden;
      position: relative;
      div {
        height: 50%;
        position: relative;
      }
    }
    @media only screen and (max-width: 768px) {
      height: 50vw;
      .img-col-one,
      .img-col-four {
        display: none;
      }
      .img-col-two,
      .img-col-three {
        width: 50vw;
      }
    }
    @media only screen and (max-width: 553px) {
      height: 100vw;
      .img-col-two {
        width: 100vw;
      }
      .img-col-three {
        display: none;
      }
    }
    img {
      object-fit: cover;
      aspect-ratio: 1/1;
      width: 100%;
      height: 100%;
    }
  }
`;

export default ImageGalleryWithContentStyles;

import { STICKER_TYPES } from '../../../utils/config';

export default class HomeTileModel {
  static initWithRetailHomeObject(retailHomeObject, cardLinkData, favorites) {
    const res = new HomeTileModel();

    if (!retailHomeObject) return res;

    res.id = retailHomeObject.id
      ? retailHomeObject.id
      : retailHomeObject.inventoryId;
    res.description = retailHomeObject.name
      ? retailHomeObject.name
      : retailHomeObject.description;
    res.altTextDescription = retailHomeObject.name;
    res.beds = retailHomeObject.beds;
    res.baths = retailHomeObject.baths;
    res.squareFeet = retailHomeObject.squareFeet;
    res.acres = retailHomeObject.acres;
    res.address1 = retailHomeObject.address1;
    res.city = retailHomeObject.city;
    res.state = retailHomeObject.stateProvince;
    res.postalCode = retailHomeObject.postalCode;
    res.isLand = retailHomeObject.isLand;
    res.isOnLand = retailHomeObject.isOnLand;
    res.serialNumber = retailHomeObject.serialNumber;
    res.modelNumber = retailHomeObject.modelNumber;
    // res.favorites = favorites ? favorites : null;
    res.isFavorited = favorites
      ? favorites.some((element) => {
          if (
            element.modelNumber === retailHomeObject.modelNumber &&
            element.serialNumber === retailHomeObject.serialNumber
          ) {
            return true;
          } else {
            return false;
          }
        })
      : false;
    res.isOnThirdPartyLand = retailHomeObject.isOnThirdPartyLand;
    res.price = retailHomeObject.priceLabel;
    res.brokerageName = retailHomeObject.brokerageName;
    res.brokeragePhone = retailHomeObject.brokeragePhone;
    res.brokerageEmail = retailHomeObject.brokerageEmail;
    // if the stickerTypeId is -1 (sale home) , use the 'sale' sticker,
    // otherwise use the 'in stock' sticker
    const staticStickers =
      retailHomeObject.stickerTypeId === -1
        ? [STICKER_TYPES[-1]]
        : retailHomeObject.stickerTypeId === -6
        ? [STICKER_TYPES[-6]]
        : [STICKER_TYPES[-7]];
    // if the stickerTypeId is not 0 (blank) and not -1 (sale home)
    // use the sticker that coresponds with the stickerTypeId
    const additionalStickers =
      retailHomeObject.stickerTypeId !== 0 &&
      retailHomeObject.stickerTypeId !== -1 &&
      retailHomeObject.stickerTypeId !== -6
        ? [STICKER_TYPES[retailHomeObject.stickerTypeId]]
        : [];
    res.inventoryFeatures = retailHomeObject.inventoryFeatures
      ? retailHomeObject.inventoryFeatures
      : [];
    res.modelFeatures = retailHomeObject.modelFeatures
      ? retailHomeObject.modelFeatures
      : [];
    res.stickers = retailHomeObject.serialNumber
      ? [...staticStickers, ...additionalStickers]
      : [...additionalStickers];
    res.images = retailHomeObject.images ? retailHomeObject.images : [];
    res.floorPlanImage = retailHomeObject.floorPlanImage;

    const cardLinkPath = retailHomeObject.isAvailableFloorPlan
      ? `/homes/model/${retailHomeObject.modelNumber}`
      : retailHomeObject.isLand
      ? `/land/${retailHomeObject.stockNumber}`
      : `/homes/${
          retailHomeObject.serialNumber ||
          retailHomeObject.inventoryId ||
          retailHomeObject.stockNumber
        }`;

    res.cardLink = {
      pathname: cardLinkPath,
      state: cardLinkData,
    };
    return res;
  }

  static initWithRecentlyViewedHomeObject(
    recentlyViewedHomeObject,
    cardLinkData,
    favorites
  ) {
    const res = new HomeTileModel();

    if (!recentlyViewedHomeObject) return res;

    const thumbImgObj = {
      reference: recentlyViewedHomeObject.thumbnailImage,
      caption: recentlyViewedHomeObject.imageCaption,
    };

    res.id = recentlyViewedHomeObject.id
      ? recentlyViewedHomeObject.id
      : recentlyViewedHomeObject.inventoryId;
    res.description = recentlyViewedHomeObject.name
      ? recentlyViewedHomeObject.name
      : recentlyViewedHomeObject.description;
    res.altTextDescription = recentlyViewedHomeObject.name;
    res.beds = recentlyViewedHomeObject.beds;
    res.baths = recentlyViewedHomeObject.baths;
    res.squareFeet = recentlyViewedHomeObject.squareFeet;
    res.price = recentlyViewedHomeObject.priceLabel;
    res.serialNumber = recentlyViewedHomeObject.serialNumber;
    res.modelNumber = recentlyViewedHomeObject.modelNumber;
    // res.favorites = favorites ? favorites : null;
    res.isFavorited = favorites
      ? favorites.some((element) => {
          if (
            element.modelNumber === recentlyViewedHomeObject.modelNumber &&
            element.serialNumber === recentlyViewedHomeObject.serialNumber
          ) {
            return true;
          } else {
            return false;
          }
        })
      : false;
    // res.isFavorite = res.images = [thumbImgObj];
    res.images = recentlyViewedHomeObject.images
      ? recentlyViewedHomeObject.images
      : [];
    // const cardLinkPath = recentlyViewedHomeObject.modelNumber
    //   ? `/homes/model/${recentlyViewedHomeObject.modelNumber}`
    //   : `/homes/${
    //       recentlyViewedHomeObject.serialNumber ||
    //       recentlyViewedHomeObject.inventoryId ||
    //       recentlyViewedHomeObject.stockNumber
    //   }`;

    const cardLinkPath = recentlyViewedHomeObject.isAvailableFloorPlan
      ? `/homes/model/${recentlyViewedHomeObject.modelNumber}`
      : recentlyViewedHomeObject.isLand
      ? `/land/${recentlyViewedHomeObject.stockNumber}`
      : `/homes/${
          recentlyViewedHomeObject.serialNumber ||
          recentlyViewedHomeObject.inventoryId ||
          recentlyViewedHomeObject.stockNumber
        }`;

    res.cardLink = {
      pathname: cardLinkPath,
      state: cardLinkData,
    };
    return res;
  }

  constructor() {
    this.id = -1;
    this.description = '';
    this.altTextDescription = '';
    this.beds = 0;
    this.baths = 0;
    this.squareFeet = 0;
    this.price = '';
    // this.favorites = [];
    this.stickers = null;
    this.images = null;
    this.floorPlanImage = null;
    this.cardLink = '';
    this.cardLinkData = {};
  }
}

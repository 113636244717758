import React from 'react';
import PropTypes from 'prop-types';

const ArrowDown = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
  >
    <path
      fill={color}
      fillRule="nonzero"
      d="M8.5 2a.5.5 0 0 1 .5.5v8.779l3.146-3.146a.5.5 0 0 1 .638-.058l.07.058a.5.5 0 0 1 0 .707l-4 4a.51.51 0 0 1-.09.07l-.025.015a.446.446 0 0 1-.056.027l-.02.007a.46.46 0 0 1-.163.027l.077-.006a.503.503 0 0 1-.056.006H8.5a.509.509 0 0 1-.077-.006l-.021-.004a.46.46 0 0 1-.065-.017l-.02-.007a.448.448 0 0 1-.082-.042.484.484 0 0 1-.089-.07l.052.044a.503.503 0 0 1-.04-.033l-.012-.011-4-4a.5.5 0 1 1 .708-.707L8 11.279V2.5a.5.5 0 0 1 .41-.492z"
    />
  </svg>
);

ArrowDown.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ArrowDown.defaultProps = {
  color: '#ffffff',
  width: '16',
  height: '16',
};

export default ArrowDown;

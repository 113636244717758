import React, { useRef, useEffect } from 'react';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import BedBathsFilterStyles from './BedsBathsFilter.styled';
import { fireSegmentEvent } from '../../../utils';
import ItemPicker from 'pubweb-smokey/dist/components/ItemPicker';

export const BedsBathsFilter = ({
  values,
  updateMinBeds,
  updateMaxBeds,
  updateMinBaths,
  updateMaxBaths,
  applyFilter,
  clearFilter,
  className,
}) => {
  const defaultFocusElement = useRef(null);
  const lowestSelectablePrice = 25000;
  const highestSelectablePrice = 200000;
  const selectablePriceIncrement = 25000;

  useEffect(() => {
    // auto focus when class is set to show
    if (className === 'show') {
      defaultFocusElement.current.focus();
    }
  }, [className]);

  const generateBedItemList = () => {
    let bedsArray = [];
    let bedList = [];

    for (let beds = 1; beds <= values.beds.max; beds += 1) {
      bedsArray.push(beds);
    }

    bedsArray.forEach(function (item, i) {
      bedList.push({ text: item + '+', value: item });
    });

    return bedList;
  };

  const generateBathItemList = () => {
    let bathsArray = [];
    let bathList = [];

    for (let baths = 1; baths <= values.baths.max; baths += 1) {
      bathsArray.push(baths);
    }

    bathsArray.forEach(function (item, i) {
      bathList.push({ text: item + '+', value: item });
    });

    return bathList;
  };

  return (
    <BedBathsFilterStyles className={className}>
      <div className="content-area">
        <div className="content-row">
          <h6>Beds</h6>
          <ItemPicker
            id="minBeds"
            items={generateBedItemList()}
            selectedValue={values.beds.min}
            onChange={(e) => {
              updateMinBeds(parseInt(e.value));
            }}
          />
        </div>
        <div className="content-row">
          <h6>Baths</h6>
          <ItemPicker
            id="minBaths"
            items={generateBathItemList()}
            selectedValue={values.baths.min}
            onChange={(e) => {
              updateMinBaths(parseInt(e.value));
            }}
          />
        </div>
      </div>
      <div className="button-area">
        <span
          className="clear-btn"
          tabIndex="0"
          onClick={() => {
            clearFilter('beds');
            clearFilter('baths');
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              clearFilter('beds');
              clearFilter('baths');
            }
          }}
        >
          CLEAR
        </span>
        <Button
          tabIndex="0"
          className="ga-filter-beds-baths"
          automationId="bedBathBtn"
          onClick={() => {
            applyFilter();
            fireSegmentEvent('Facility Model List Filtered', {
              list: 'Homes List',
              filters: [
                {
                  type: 'Beds & Baths',
                  value:
                    'Beds: ' +
                    values?.beds?.min +
                    '-' +
                    values?.beds?.max +
                    ' Baths: ' +
                    values?.baths?.min +
                    '-' +
                    values?.baths?.max,
                },
              ],
              eventContext: 'available_homes_filter_beds',
            });
          }}
        >
          APPLY
        </Button>
      </div>
    </BedBathsFilterStyles>
  );
};

export default BedsBathsFilter;

import React, { useState, useEffect } from 'react';
import NewsEventsStyles from './NewsEventsShared.styled';
import CardGrid from '../../Shared/CardGrid/CardGrid';
import { Carousel } from 'react-responsive-carousel';
import nextBtn from '../../../images/svg/next-btn.svg';
import prevBtn from '../../../images/svg/prev-btn.svg';
import { Link } from 'react-router-dom';
import useWindowSize from '../../../hooks/useWindowSize';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PropTypes from 'prop-types';

export const NewsEvents = ({
  homes,
  dealer,
  linkText,
  headerlabel,
  headline,
  newsAndEvents,
}) => {
  const [width, height] = useWindowSize();
  const isMobile = width <= 1024;

  return (
    <NewsEventsStyles>
      {newsAndEvents && (
        <>
          <div className="news-events-section">
            <p className="header-label">{headerlabel}</p>
            <h2 className="section-headline">{headline}</h2>
            <Link
              to={{ pathname: '/news-events', state: { dealer, homes } }}
              className="view-all-link"
            >
              {linkText}
            </Link>
            {isMobile ? (
              <Carousel
                className="news-event-slider"
                showArrows={true}
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                swipeable={false}
                emulateTouch={true}
                infiniteLoop={true}
                isFocusWithinTheCarousel={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      className="news-event-slider-btn-prev slider-btns slider-btn-prev"
                    >
                      <img src={prevBtn} alt="next slide" />
                    </button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      className="news-event-slider-btn-next slider-btns slider-btn-next"
                    >
                      <img src={nextBtn} alt="next slide" />
                    </button>
                  )
                }
              >
                {newsAndEvents &&
                  newsAndEvents.map((item, i) => (
                    <div
                      className="container"
                      key={item.title || 'newsItem' + i}
                    >
                      <div
                        className={
                          newsAndEvents.length <= 2
                            ? 'card-grid center-card-grid'
                            : 'card-grid'
                        }
                      >
                        <div className="card-item" key={i}>
                          <Link
                            className="card"
                            to={
                              item.homeCenterWebsiteContentType == 1
                                ? '/event/' + item.slug
                                : '/news/' + item.slug
                            }
                          >
                            <div className="top">
                              {item.mainImage && (
                                <div>
                                  <LazyLoadImage
                                    alt={item.title}
                                    effect="blur"
                                    src={item.mainImage}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="bottom">
                              {item.homeCenterWebsiteContentType && (
                                <span className="type">
                                  {item.homeCenterWebsiteContentType == 1
                                    ? 'Event'
                                    : 'News'}
                                </span>
                              )}
                              {item.title && (
                                <h3 className="title">{item.title}</h3>
                              )}
                              {item.summary && (
                                <p className="summary">{item.summary}</p>
                              )}
                              {item.disclaimer && (
                                <p className="disclaimer">
                                  <span className="asterisk">&#42;</span>
                                  <p className="disclaimer-text">
                                    {item.disclaimer}
                                  </p>
                                </p>
                              )}
                            </div>
                          </Link>
                        </div>
                      </div>
                      {newsAndEvents.length === 0 && (
                        <h4 className="no-results">No news or events.</h4>
                      )}
                    </div>
                  ))}
              </Carousel>
            ) : (
              <CardGrid className="news-events-home" items={newsAndEvents} />
            )}
          </div>
        </>
      )}
    </NewsEventsStyles>
  );
};

NewsEvents.props = {
  homes: PropTypes.array,
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
  newsAndEvents: PropTypes.array,
  aTagLink: PropTypes.bool,
  gaClass: PropTypes.string,
  gaClassTwo: PropTypes.string,
};

export default NewsEvents;

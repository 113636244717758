import ChevronRightSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-right.svg';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DXAccountContext } from '../../../contexts/DXAccountContext';
import { fireSegmentEvent } from '../../../utils';
import PromoSlideStyles from './PromoSlide.styled';

export const PromoSlide = ({
  promoSlide,
  promoText,
  promoUrl,
  gaClass,
  reactLink,
  portalLink,
  dealer,
  homes,
  customClass,
  retailEventLabel,
}) => {
  const accountContext = useContext(DXAccountContext);

  const promoSegmentEvent = () => {
    return fireSegmentEvent('CTA Clicked', {
      isNav: false,
      type: 'image',
      text: promoText || '',
      eventContext: retailEventLabel || '',
    });
  };

  return (
    <PromoSlideStyles>
      <div className={'promo ' + gaClass}>
        {reactLink ? (
          <>
            <Link
              to={{
                pathname: promoUrl,
                state: { dealer, homes },
              }}
              className="card-container"
              onClick={() => {
                promoSegmentEvent();
              }}
            >
              <img
                src={promoSlide}
                alt="promo"
                className="aspect-ratio-sizer"
              />
            </Link>
            <Link
              to={{
                pathname: promoUrl,
                state: { dealer, homes },
              }}
              className={`promo-link ` + customClass}
              onClick={() => {
                promoSegmentEvent();
              }}
            >
              <p>{promoText}</p>
              <img src={ChevronRightSvg} />
            </Link>
          </>
        ) : portalLink ? (
          <>
            {accountContext?.state.isAuthenticated ? (
              <>
                <Link
                  to={{
                    pathname: promoUrl,
                    state: { dealer, homes },
                  }}
                  className="card-container"
                  onClick={() => {
                    promoSegmentEvent();
                  }}
                >
                  <img
                    src={promoSlide}
                    alt="promo"
                    className="aspect-ratio-sizer"
                  />
                </Link>
                <Link
                  to={{
                    pathname: promoUrl,
                    state: { dealer, homes },
                  }}
                  className="promo-link"
                  onClick={() => {
                    promoSegmentEvent();
                  }}
                >
                  <p>{promoText}</p>
                  <img src={ChevronRightSvg} />
                </Link>
              </>
            ) : (
              <>
                <div
                  onClick={() =>
                    accountContext.actions.showLogin('/portal/planner') +
                    promoSegmentEvent()
                  }
                  className="card-container"
                >
                  <img
                    src={promoSlide}
                    alt="promo"
                    className="aspect-ratio-sizer"
                  />
                </div>
                <div
                  onClick={() =>
                    accountContext.actions.showLogin('/portal/planner') +
                    promoSegmentEvent()
                  }
                  className="promo-link"
                >
                  <p>{promoText}</p>
                  <img src={ChevronRightSvg} />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <a
              href={promoUrl}
              className="card-container"
              onClick={() => {
                promoSegmentEvent();
              }}
            >
              <img
                src={promoSlide}
                alt="promo"
                className="aspect-ratio-sizer"
              />
            </a>
            <a
              href={promoUrl}
              className="promo-link"
              onClick={() => {
                promoSegmentEvent();
              }}
            >
              <p>{promoText}</p>
              <img src={ChevronRightSvg} />
            </a>
          </>
        )}
      </div>
    </PromoSlideStyles>
  );
};

export default PromoSlide;

import React from 'react';
import PropTypes from 'prop-types';

const Heart = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2932 1.9657L9.22893 3.02999L8.16337 1.96532C6.49727 0.296296 3.7922 0.296296 2.12553 1.96589C0.458092 3.63333 0.458092 6.33531 2.12592 8.00314L3.63199 9.51045L8.79725 14.448C9.03885 14.6789 9.41937 14.6789 9.66097 14.448L14.816 9.52049L16.3325 8.00295C18.0001 6.33534 18.0001 3.63344 16.3324 1.9656C14.6645 0.29636 11.9611 0.296393 10.2932 1.9657Z"
      fill="#EF4B5D"
    />
  </svg>
);

export default Heart;

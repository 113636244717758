import PriceTag from '../../images/svg/tag-w-blue-bground-icon.svg';
import DollarSign from '../../images/svg/dollar-sign-w-blue-bground-icon.svg';
import MoveInReadyIcon from '../../images/svg/move-in-house-w-blue-bground-icon.svg';
import HODHouseIcon from '../../images/svg/hod-house-w-blue-bground-icon.svg';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';

export async function getContent(path, dealer) {
  const CuratedCollectionsContent = {
    'sale-homes': {
      titleTag: 'Manufactured Homes Discounted',
      pageTitle: 'Sale Homes',
      iconSrc: PriceTag,
      iconAlt: 'Sale Homes',
      copyText:
        'Looking for affordable, modern manufactured homes? Browse our limited-time deals below or request info to learn about other floor plans and specials offers available.',
      originWebFormCategory: 'sale-homes-info',
      originWebPageType: 'retail-sale-homes',
      eventContextText: 'sale_homes_hero_request',
      retailEventLabel: 'sale_homes_form_submit_request',
    },
    'mobile-manufactured-homes-on-display': {
      titleTag: 'On Display Mobile Homes',
      pageTitle: 'Homes On Display',
      iconSrc: HODHouseIcon,
      iconAlt: 'Homes in stock',
      copyText: `With a variety of modern manufactured and mobile homes in stock and on display, ${formatDealerName(
        dealer?.dealerName
      )} is ready to help you find your forever home. Schedule an appointment today for a personalized tour with our expert Home Consultants.`,
      originWebFormCategory: 'homes-on-display-schedule',
      originWebPageType: 'retail-homes-on-display',
      eventContextText: 'homes_on_display_hero_schedule',
      retailEventLabel: 'homes_on_display_form_submit_schedule',
    },
    'mobile-manufactured-homes-with-land': {
      titleTag: 'Move-In Ready Mobile Homes',
      pageTitle: 'Move-In Ready Homes',
      iconSrc: MoveInReadyIcon,
      iconAlt: 'Move-in ready homes',
      copyText:
        'Looking for a home that is ready for immediate occupancy? Skip the building process and move into a new manufactured home. Explore a variety of new modular and mobile homes on land that are move-in ready. With no need to wait for construction or land acquisition, finding your dream home just got simpler.',
      originWebFormCategory: 'move-in-ready-homes-info',
      originWebPageType: 'retail-move-in-ready-homes',
      eventContextText: 'move_in_ready_homes_hero_request',
      retailEventLabel: 'move_in_ready_homes_form_submit_request',
    },
    'mobile-manufactured-homes-under-150k': {
      titleTag: 'Mobile Homes Under 150k',
      pageTitle: 'Homes Under $150K',
      iconSrc: DollarSign,
      iconAlt: 'Homes under $150K',
      copyText:
        'We’re changing the way homes are built and making beautiful, affordable housing. Browse this collection of modular and mobile homes under $150k and discover the perfect blend of space, modern design and quality construction.',
      originWebFormCategory: 'homes-under-price-info',
      originWebPageType: 'retail-homes-under-price',
      eventContextText: 'homes_under_150k_hero_request',
      retailEventLabel: 'homes_under_150k_form_submit_request',
      titleTag: 'Mobile Homes Under 150k',
    },
  };
  return CuratedCollectionsContent[path];
}

import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Carousel } from 'react-responsive-carousel';
import { Link, useHistory, useLocation } from 'react-router-dom';
import 'tippy.js/dist/tippy.css';
import { DXAccountContext } from '../../../contexts/DXAccountContext';
import useWindowSize from '../../../hooks/useWindowSize';
import closeBtn from '../../../images/svg/close.svg';
import bath from '../../../images/svg/iconography-16x16/bath.svg';
import bed from '../../../images/svg/iconography-16x16/bed.svg';
import homeIcon from '../../../images/svg/iconography-16x16/home.svg';
import Heart from '../../../images/svg/icons/Heart';
import HeartOutline from '../../../images/svg/icons/HeartOutline';
import Loading from '../../../images/svg/icons/Loading';
import nextBtn from '../../../images/svg/next-btn.svg';
import prevBtn from '../../../images/svg/prev-btn.svg';
import { getBaseAwsApiUrl } from '../../../utils/config';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import { formatNumberWithCommas } from '../SmokeyTransitional/utils/formatters';
import { modelNameTransformer } from '../SmokeyTransitional/utils/modelNameTransformer';
import { SaleHomeCardStyles } from './SaleHomeCard.styled';
import aspectRatioSizer from './aspect-ratio-sizer.gif';

const SaleHomeCard = ({
  homeObject,
  useSlideshow = true,
  showFavoritingHeart,
  stickers = [],
  stickerTemplate = null,
  showStickers = true,
  showSpecs = true,
  showPrice = true,
  isFavorited,
  handleFavoriteClick,
  maxSlides = 5,
  className,
}) => {
  const accountContext = useContext(DXAccountContext);
  const [width, height] = useWindowSize();
  const isMobile = width <= 1023;
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const [visibleFeaturesTooltip, setVisibleFeaturesTooltip] = useState(false);
  const seeMoreLimit = width <= 1023 ? (width <= 500 ? 3 : 5) : 6;
  const singleImageRef = useRef(null);
  const [singleImageRefVisible, setSingleImageRefVisible] = useState(false);
  const showTooltip = () => setVisibleTooltip(true);
  const hideTooltip = () => setVisibleTooltip(false);
  const showFeaturesTooltip = () => setVisibleFeaturesTooltip(true);
  const hideFeaturesTooltip = () => setVisibleFeaturesTooltip(false);
  let urlOverride = `${getBaseAwsApiUrl()}images/`;
  const location = useLocation();
  const redirectPath = location.pathname;
  const history = useHistory();

  useEffect(() => {
    if (!singleImageRefVisible) {
      return;
    }
  }, [singleImageRefVisible]);

  const allFeatures = () => {
    if (
      !homeObject ||
      (homeObject.modelFeatures.length === 0 &&
        homeObject.inventoryFeatures.length === 0)
    ) {
      return null;
    }

    if (homeObject.modelFeatures.length === 0) {
      return homeObject.inventoryFeatures;
    }

    if (homeObject.inventoryFeatures.length === 0) {
      return homeObject.modelFeatures;
    }

    return [...homeObject.modelFeatures, ...homeObject.inventoryFeatures];
  };

  let altText =
    homeObject && homeObject.description
      ? modelNameTransformer(homeObject.description)
      : '';

  //For homeObjects that are not a straight up string description ("Save your favorite homes")
  if (homeObject && typeof homeObject.altTextDescription !== 'undefined') {
    altText = homeObject.altTextDescription;
  }

  const favoriteCheck = (home) => {
    let favCheck = accountContext?.state?.favorites.some((element) => {
      if (
        element?.modelNumber === home?.modelNumber &&
        element?.serialNumber === home?.serialNumber
      ) {
        return true;
      } else {
        return false;
      }
    });

    return favCheck;
  };

  return (
    <SaleHomeCardStyles className={className}>
      <div className="card-image-area">
        {showStickers &&
          homeObject &&
          homeObject.stickers &&
          homeObject.stickers.length > 0 && (
            <div className="sticker-container">
              {homeObject.stickers.map((sticker, i) => {
                return typeof sticker !== 'undefined' ? (
                  <div
                    className={
                      sticker.className
                        ? 'sticker label ' + sticker.className
                        : 'sticker label'
                    }
                    key={i}
                  >
                    <div className="in-stock-dot">&nbsp;</div> {sticker.title}
                  </div>
                ) : null;
              })}
            </div>
          )}
        {showFavoritingHeart && (
          <>
            <span className="favoriting-heart-container">
              {accountContext?.state.isAuthenticated ? (
                <span
                  className="favorite-button"
                  onClick={() =>
                    accountContext.actions.toggleFavorite(
                      homeObject?.modelNumber,
                      homeObject?.serialNumber
                    )
                  }
                >
                  {favoriteCheck(homeObject) ? <Heart /> : <HeartOutline />}
                </span>
              ) : (
                <Link
                  className="favorite-button"
                  to={{
                    pathname: '/myhome-account-login',
                    state: {
                      redirectPage: redirectPath,
                      queuedFavorite: {
                        modelNumber: homeObject?.modelNumber,
                        serialNumber: homeObject?.serialNumber,
                      },
                    },
                  }}
                >
                  <HeartOutline />
                </Link>
              )}
            </span>
          </>
        )}

        <img src={aspectRatioSizer} alt="" className="aspect-ratio-sizer" />
        {useSlideshow && homeObject.images && homeObject.images.length > 1 ? (
          <Carousel
            showArrows={isMobile ? false : true}
            autoPlay={false}
            showIndicators={true}
            showStatus={false}
            showThumbs={false}
            swipeable={true}
            emulateTouch={true}
            infiniteLoop={true}
            isFocusWithinTheCarousel={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  className="slider-btns slider-btn-prev"
                >
                  <img src={prevBtn} alt="prev slide" />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{ right: 15 }}
                  className="slider-btns slider-btn-next"
                >
                  <img src={nextBtn} alt="next slide" />
                </button>
              )
            }
          >
            {homeObject.images.slice(0, maxSlides).map((image, i) => {
              return (
                <div className="slide" key={i}>
                  <OptimizedImage
                    src={`${image.reference}?width=750`}
                    srcSet={[
                      {
                        source: image.reference + `?fm=webp&width=750`,
                        minWidth: '750px',
                      },
                      {
                        source: image.reference + `?fm=webp&width=450`,
                      },
                    ]}
                    alt={
                      image.caption
                        ? image.caption
                        : modelNameTransformer(homeObject.description)
                    }
                    lazy
                  />

                  <Link
                    className="slide-link"
                    to={{
                      pathname: homeObject.cardLink.pathname,
                      state: homeObject.cardLink.state,
                    }}
                  ></Link>
                </div>
              );
            })}
          </Carousel>
        ) : (
          <div className="card-image-single">
            {homeObject.images && homeObject.images[0].reference && (
              <OptimizedImage
                alt={
                  homeObject && homeObject.images[0].caption
                    ? homeObject.images[0].caption
                    : altText
                }
                src={
                  homeObject.images[0].reference.includes('http')
                    ? homeObject.images[0].reference + '?width=750'
                    : urlOverride +
                      homeObject.images[0].reference +
                      '?width=750'
                }
                srcSet={[
                  {
                    source: homeObject.images[0].reference.includes('http')
                      ? homeObject.images[0].reference + '?fm=webp&width=750'
                      : urlOverride +
                        homeObject.images[0].reference +
                        '?fm=webp&width=750',
                    minWidth: '750px',
                  },
                  {
                    source: homeObject.images[0].reference.includes('http')
                      ? homeObject.images[0].reference + '?fm=webp&width=450'
                      : urlOverride +
                        homeObject.images[0].reference +
                        '?fm=webp&width=450',
                  },
                ]}
                imgClass={
                  singleImageRef &&
                  singleImageRef.current &&
                  singleImageRef.current.naturalHeight >
                    singleImageRef.current.naturalWidth
                    ? 'img-vertical'
                    : 'img-horizontal'
                }
                lazy
              />
            )}

            <Link
              className="slide-link"
              to={{
                pathname: homeObject.cardLink.pathname,
                state: homeObject.cardLink.state,
              }}
            ></Link>
          </div>
        )}
      </div>
      <div className="card-info-area">
        <Link
          className="card-info-link"
          aria-label="home link"
          to={{
            pathname: homeObject.cardLink.pathname,
            state: homeObject.cardLink.state,
          }}
        ></Link>
        <div className="card-info-top">
          {showStickers &&
            homeObject &&
            homeObject.stickers &&
            homeObject.stickers.length > 0 && (
              <div className="sticker-container">
                {homeObject.stickers.map((sticker, i) => {
                  return typeof sticker !== 'undefined' ? (
                    <div
                      className={`sticker label ${sticker.className || ''}`}
                      key={i}
                    >
                      <div className="in-stock-dot">&nbsp;</div> {sticker.title}
                    </div>
                  ) : null;
                })}
              </div>
            )}
          {homeObject && homeObject.descriptionOverride && (
            <>{homeObject.descriptionOverride()}</>
          )}
          {homeObject && homeObject.description && (
            <p className="card-heading">
              {modelNameTransformer(homeObject.description)}
            </p>
          )}

          <div
            className="price-tooltip"
            onClick={
              homeObject &&
              homeObject.price &&
              !homeObject.price.includes('Contact Us')
                ? visibleTooltip
                  ? hideTooltip
                  : showTooltip
                : null
            }
          >
            {showPrice &&
              homeObject &&
              homeObject.price &&
              !homeObject.priceOverride && (
                <div className="price">
                  {homeObject.price}

                  <Tippy
                    interactive
                    theme="clayton"
                    visible={visibleTooltip}
                    onClickOutside={hideTooltip}
                    maxWidth={width > 1024 ? '373px' : '305px'}
                    content={
                      <>
                        <img
                          className="close-btn"
                          onClick={visibleTooltip ? hideTooltip : showTooltip}
                          src={closeBtn}
                          alt="close tooltip"
                        />
                        <p>
                          Base model starting prices do not include available
                          options, required delivery & installation or taxes.
                          Installed price will be higher. See home details for
                          pricing details and home information.
                        </p>
                      </>
                    }
                  >
                    <img
                      className="info-circle"
                      src={infoCircle}
                      alt="information icon"
                    />
                  </Tippy>
                </div>
              )}

            {showPrice && homeObject && homeObject.priceOverride && (
              <>{homeObject.priceOverride()}</>
            )}
          </div>
          {showSpecs ? (
            <ul className="card-home-specs caption">
              {homeObject && homeObject.acres && (
                <li>
                  {homeObject.acres == 1.0
                    ? homeObject.acres + ' acre'
                    : homeObject.acres + ' acres'}
                </li>
              )}
              {homeObject && homeObject.beds && (
                <li>
                  <img src={bed} alt="bed icon" />
                  {homeObject.beds > 1
                    ? homeObject.beds + ' beds'
                    : homeObject.beds + ' bed'}
                </li>
              )}
              {homeObject && homeObject.baths && (
                <li>
                  <img src={bath} alt="bath icon" />
                  {homeObject.baths > 1
                    ? homeObject.baths + ' baths'
                    : homeObject.baths + ' bath'}
                </li>
              )}
              {homeObject && homeObject.squareFeet && (
                <li>
                  <img src={homeIcon} alt="home icon" />
                  {homeObject.squareFeet
                    ? formatNumberWithCommas(homeObject.squareFeet) + ' sq. ft.'
                    : null}
                </li>
              )}
            </ul>
          ) : null}
        </div>
        {homeObject &&
          homeObject.modelFeatures &&
          homeObject.inventoryFeatures &&
          allFeatures() !== null &&
          allFeatures().length > 0 && (
            <div className="sale-card-features">
              {homeObject.modelFeatures &&
                homeObject.inventoryFeatures &&
                allFeatures().length > 0 && <p className="h4">Features</p>}
              {homeObject.modelFeatures &&
                homeObject.inventoryFeatures &&
                (allFeatures().length > seeMoreLimit ? (
                  <ul className="features-ul">
                    {allFeatures()
                      .slice(0, seeMoreLimit)
                      .map((feature, i) => {
                        return <li key={feature + '-' + i}>{feature}</li>;
                      })}
                    <li>
                      <Tippy
                        interactive
                        theme="clayton"
                        visible={visibleFeaturesTooltip}
                        onClickOutside={hideFeaturesTooltip}
                        maxWidth={width > 1024 ? '373px' : '305px'}
                        content={
                          <>
                            <img
                              className="close-btn"
                              onClick={
                                visibleFeaturesTooltip
                                  ? hideFeaturesTooltip
                                  : showFeaturesTooltip
                              }
                              src={closeBtn}
                              alt="close tooltip"
                            />
                            <ul className="features-toolip-ul">
                              {allFeatures()
                                .slice(seeMoreLimit)
                                .map((otherFeature, i) => {
                                  return (
                                    <li key={otherFeature + '-' + i}>
                                      {otherFeature}
                                    </li>
                                  );
                                })}
                            </ul>
                          </>
                        }
                      >
                        <span
                          className="see-more-features"
                          onClick={
                            visibleFeaturesTooltip
                              ? hideFeaturesTooltip
                              : showFeaturesTooltip
                          }
                        >
                          View {allFeatures().slice(seeMoreLimit).length} more
                        </span>
                      </Tippy>
                    </li>
                  </ul>
                ) : (
                  <ul className="features-ul">
                    {allFeatures().map((feature, i) => {
                      return <li key={feature + '-' + i}>{feature}</li>;
                    })}
                  </ul>
                ))}
            </div>
          )}
      </div>
    </SaleHomeCardStyles>
  );
};

SaleHomeCard.propTypes = {
  homeObject: PropTypes.shape({
    modelId: PropTypes.number,
    modelNumber: PropTypes.string,
    description: PropTypes.string,
    baths: PropTypes.number,
    beds: PropTypes.number,
    squareFeet: PropTypes.number,
    price: PropTypes.string,
    priceOverride: PropTypes.func,
    altTextDescription: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.object),
    cardLink: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      state: PropTypes.shape({}),
    }),
  }),

  useSlideshow: PropTypes.bool,
  stickers: PropTypes.array,
  showStickers: PropTypes.bool,
  showSpecs: PropTypes.bool,
  showPrice: PropTypes.bool,
};

export default SaleHomeCard;

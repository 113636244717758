import styled from 'styled-components';

const ContactModalThankYouStyles = styled.div`
  min-height: 625px;
  display: flex;
  p {
    text-align: center;
    margin-bottom: auto;
    font-size: 18px;
    font-weight: 400;
    color: #000;
  }

  svg {
    max-width: 200px;
    margin: 15px auto 30px;
    display: block;
    max-height: 160px;
  }
`;

export default ContactModalThankYouStyles;

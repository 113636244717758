import React, { useRef, useEffect } from 'react';
import ModalStyles, { GlobalStyle } from './Modal.styled';

import { useOnClickOutside } from '../../hooks/useOnClickOutside';

import CloseIcon from '../../../../../images/svg/icons/CloseIcon';

const Modal = ({ children, onClose }) => {
  const modalContainerRef = useRef();

  useEffect(() => {
    document.documentElement.classList.add('modal-lock');
  }, []);

  useOnClickOutside(modalContainerRef, () => {
    if (onClose) {
      onClose();
      document.documentElement.classList.remove('modal-lock');
    }
  });

  const closeModal = () => {
    onClose();
    document.documentElement.classList.remove('modal-lock');
  };

  return (
    <ModalStyles>
      <GlobalStyle />
      <div className="modal-container" ref={modalContainerRef}>
        <div className="close-modal">
          <a onClick={closeModal}>
            <CloseIcon />
          </a>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </ModalStyles>
  );
};

export default Modal;

import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const FeaturedHomesStyles = styled.section`
  position: relative;
  padding: 120px 0px 130px;
  .container {
    position: relative;
    max-width: 1272px;
    margin: 0 auto;
    padding: 0 25px;
    @media only screen and (max-width: 1000px) {
      max-width: 800px;
    }
    @media only screen and (max-width: 553px) {
      padding: 0px;
    }
    .headline {
      text-align: center;
      margin-bottom: 30px;
      font-size: 32px;
      @media only screen and (max-width: 768px) {
        font-size: 32px;
      }
      @media only screen and (max-width: 553px) {
        font-size: 26px;
      }
    }
  }

  .cta-button-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    flex-wrap: wrap;
    gap: 15px;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 20px;
      .cta-button {
        margin: 0px;
      }
    }
  }

  .cta-button {
    background: ${Colors.primary.claytonBlue.standard};
    color: white;
    border: 1.5px solid ${Colors.primary.claytonBlue.standard};
    border-radius: 2px;
    padding: 17px 17px;
    font: 500 12px/100% 'source-sans-pro', sans-serif;
    margin-top: 30px;
    letter-spacing: 1px;
    text-decoration: none;
    transition: 0.2s;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-flex;
    align-self: center;
    &:hover {
      background: rgb(23, 158, 255);
      border: 1.5px solid rgb(23, 158, 255);
    }
  }
  .white-cta-btn {
    background: white !important;
    color: ${Colors.primary.claytonBlue.standard} !important;
    border: 1.5px solid ${Colors.primary.claytonBlue.standard};
    &:hover {
      background: white !important;
      color: ${Colors.primary.claytonBlue.standard} !important;
      border: 1.5px solid ${Colors.primary.claytonBlue.standard};
    }
  }

  .featured-home-grid {
    position: relative;
    display: flex;
    justify-content: center;
    .featured-home-item {
      width: 33.3333%;
    }
  }
  .featured-home-slider {
    .close-btn {
      pointer-events: all;
    }
    .carousel.carousel-slider {
      display: flex;
    }
    .featured-home-btn-next,
    .featured-home-btn-prev {
      border: none !important;
      background: none !important;
      outline: none;
      cursor: pointer;
      width: 60px;
      @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
        width: 34px;
      }
    }
    .featured-home-btn-prev {
      @media only screen and (max-width: 553px) {
        left: 0px;
        z-index: 8;
      }
    }
    .featured-home-btn-next {
      @media only screen and (max-width: 553px) {
        right: 0px;
      }
    }

    .slide {
      text-align: inherit !important;
      background: transparent;
      img {
        width: inherit;
        vertical-align: inherit;
      }
      .carousel {
        .slide {
          text-align: center;
          background: transparent;
          img {
            width: 100%;
            vertical-align: top;
            border: 0;
          }
        }
      }
    }
  }
`;

export default FeaturedHomesStyles;

import styled from 'styled-components';

const BedBathsFilterStyles = styled.div`
  /* position: absolute;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 99;

  @media screen and (max-width: 768px) {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  } */

  .content-area {
    height: 188px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .content-row {
      padding-top: 14px;
      width: 100%;
    }

    h6 {
      margin-bottom: -5px;
    }

    .name-input {
      width: 232px;
    }

    .select-area {
      display: flex;
    }

    .dash {
      width: 10px;
      height: 0px;
      border-top: 1px solid #777b7e;
      margin: 0 4px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .button-area {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid #e4e5e7;
    margin-top: 20px;

    button {
      padding: 10px 30px;
    }
    .clear-btn {
      cursor: pointer;
    }

    .clear-btn,
    a:link,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
      color: #4e5255;
      font-family: source-sans-pro;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
`;
export default BedBathsFilterStyles;

import TagIconBlueBground from '../../images/svg/tag-w-blue-bground-icon.svg';
import HODIconBlueBground from '../../images/svg/hod-house-w-blue-bground-icon.svg';
import MoveInReadyIconBlueBground from '../../images/svg/move-in-house-w-blue-bground-icon.svg';
import DollarSignIconBlueBground from '../../images/svg/dollar-sign-w-blue-bground-icon.svg';

const CuratedCollectionsLinks = [
  {
    linkText: 'HOMES UNDER $150K',
    content:
      'Industry leading dependability & efficiency deliver year-round comfort you can count on.',
    icon: DollarSignIconBlueBground,
    pathName: '/mobile-manufactured-homes-under-150k',
    segmentText: 'HOMES UNDER $150K',
    segmentEventContext: 'available_homes_curated_homes_under_150k',
    listName: 'budgetHomes',
  },
  {
    linkText: 'MOVE-IN READY HOMES',
    icon: MoveInReadyIconBlueBground,
    pathName: '/mobile-manufactured-homes-with-land',
    segmentText: 'MOVE-IN READY HOMES',
    segmentEventContext: 'available_homes_curated_move_in_ready',
    listName: 'moveInReadyHomes',
  },
  {
    linkText: 'HOMES ON DISPLAY',
    icon: HODIconBlueBground,
    pathName: '/mobile-manufactured-homes-on-display',
    segmentText: 'HOMES ON DISPLAY',
    segmentEventContext: 'available_homes_curated_homes_on_display',
    listName: 'homesInStock',
  },
  {
    linkText: 'SALE HOMES',
    icon: TagIconBlueBground,
    pathName: '/sale-homes',
    segmentText: 'SALE HOMES',
    segmentEventContext: 'available_homes_curated_sale_homes',
    listName: 'homesOnSale',
  },
];

export default CuratedCollectionsLinks;

import { useEffect, useReducer } from 'react';
import { getSalesAgents } from '../Api';
import { sortSalesAgents } from '../utils/utils';

const salesAgentsReducer = (state, action) => {
  switch (action.type) {
    case 'GET_SALES_AGENTS_INIT': {
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null,
      };
    }
    case 'GET_SALES_AGENTS_SUCCESS': {
      return {
        ...state,
        salesAgents: sortSalesAgents(action.payload),
        isLoading: false,
        isError: false,
        error: null,
      };
    }
    case 'GET_SALES_AGENTS_FAILURE': {
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    }
    case 'SET_ERROR': {
      return {
        ...state,
        isError: !!action.payload,
        error: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const useSalesAgents = (dealerId) => {
  const [state, dispatch] = useReducer(salesAgentsReducer, {});

  useEffect(() => {
    (async () => {
      dispatch({ type: 'GET_SALES_AGENTS_INIT' });

      try {
        const response = await getSalesAgents(dealerId);
        if (!response) {
          dispatch({
            type: 'GET_SALES_AGENTS_FAILURE',
            payload: 'There was an error getting the sales agents.',
          });
        } else {
          dispatch({
            type: 'GET_SALES_AGENTS_SUCCESS',
            payload: response,
          });
        }
      } catch ({ message }) {
        dispatch({
          type: 'GET_SALES_AGENTS_FAILURE',
          payload: message || 'There was an error getting the sales agents',
        });
        return null;
      }
    })();
  }, []);
  const loadSalesAgents = async () => {};

  return { ...state, loadSalesAgents };
};

export default useSalesAgents;

import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const TextWithImageRightStyles = styled.section`
  position: relative;
  padding: 40px 0px;

  .container {
    position: relative;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0px 40px;
    display: flex;
    align-items: center;
    overflow: hidden;
    @media only screen and (max-width: 1000px) {
      max-width: 512px;
      flex-direction: column;
      padding: 0px 23px;
    }
    .text-wrap {
      position: relative;
      display: flex;
      justify-content: center;
      flex: auto;
      max-width: 100%;
      @media only screen and (max-width: 1000px) {
        order: 2;
      }
      .text {
        max-width: 100%;
        width: 520px;
        @media only screen and (min-width: 1000px) {
          padding-right: 30px;
          min-width: 390px;
        }
        .icon {
          position: relative;
          span,
          img {
            max-width: 90%;
          }
        }
        .small-headline {
          position: relative;
          margin-bottom: 10px;
        }
        .large-headline {
          margin-bottom: 20px;
        }
        .content {
          color: #25292d;
        }
        .line {
          display: flex;
          margin-top: 40px;
          width: 105px;
          height: 2px;
          background: ${Colors.accent.grey4.standard};
        }
        small {
          color: ${Colors.accent.grey1.standard};
          font-family: 'source-sans-pro';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0px;
          text-align: left;
          margin-bottom: 0px;
          margin-top: 20px;
          display: block;
        }
      }
    }
    .image-box {
      position: relative;
      height: 657px;
      max-width: 496px;
      width: 100%;
      background-color: ${Colors.accent.grey5.standard};
      span,
      img {
        height: 100%;
        width: 100%;
      }
      img {
        object-fit: cover;
      }
      @media only screen and (max-width: 1000px) {
        margin-bottom: 60px;
      }
      @media only screen and (max-width: 768px) {
        height: 434px;
      }
    }
    .cta-button {
      background: ${Colors.primary.claytonBlue.standard};
      color: white;
      border-radius: 2px;
      padding: 17px 17px;
      font: 500 12px/100% 'source-sans-pro', sans-serif;
      margin-top: 50px;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.2s;
      text-transform: uppercase;
      font-weight: 700;
      display: inline-flex;
      align-self: center;
      &:hover {
        background: rgb(23, 158, 255);
      }
    }
  }
`;

export default TextWithImageRightStyles;

import React from 'react';
import FAQThreeUpStyles from './FAQThreeUp.styled';
import { Link } from 'react-router-dom';

export const FAQThreeUp = ({ dealer, homes }) => {
  const faqs = [
    {
      question:
        'What’s the difference between a manufactured home and a modular home?',
      answer:
        'Manufactured homes are built entirely inside our climate-controlled home building facilities to federal HUD Code regulations and come in three sizes: single wide, double wide and triple wide. They can be placed on a permanent foundation and some models can be placed on a basement, but can be relocated with the help of an experienced contractor if not placed on a permanent foundation. Modular homes are also built inside climate-controlled home building facilities. However, they are built according to all state, local and/or regional codes for where the home will be located. They are typically built in two sections but can be built in up to five sections depending on the size and are constructed on a permanent foundation.',
      id: 12,
    },
    {
      question: 'Can I make changes to the floor plan I choose?',
      answer:
        'Many of our homes can be altered and upgraded to create your ideal dream home. Some homes have more customizable and upgradable options than others, but with countless floor plans available, we are sure we can help you find the perfect floor plan to fit your budget and lifestyle. Let us know which floor plan you’re interested in, and we’ll let you know what is customizable.',
      id: 13,
    },
    {
      question: 'What energy efficient features do you offer in your homes?',
      answer:
        'Our signature Energy Smart Home is loaded with energy saving features designed to give you Year-Round Comfort and a Lifetime of Savings. Some of the features included are low-e windows, energy efficient water heaters and programmable thermostats – just to name a few. Check out the Energy Smart Home page of our site to learn more.',
      id: 7,
    },
  ];

  return (
    <FAQThreeUpStyles>
      <div className="container">
        <h2 className="title">Any Questions?</h2>
        {faqs && (
          <div className="faq-grid">
            {faqs.map((faq, i) => (
              <div className="faq-item" key={i}>
                <p className="h5 question">{faq.question}</p>
                <span className="dash"></span>
                <p className="answer">{faq.answer}</p>
                <Link
                  to={{
                    pathname: '/faq',
                    state: { dealer, homes, activeFAQ: faq.id },
                  }}
                  className="read-more"
                >
                  Read More
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </FAQThreeUpStyles>
  );
};

export default FAQThreeUp;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import HomeListSortStyles from './HomeListSort.styled';
import DropDownList from 'pubweb-smokey/dist/components/DropDownList/DropDownList';
import { fireSegmentEvent } from '../../../utils';

const HomeListSort = ({ updateSortOrder, setCurrentOrder, className }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const params = new URLSearchParams(useLocation().search);
  useEffect(() => {
    if (document) {
      const selectBoxOptions = document.querySelectorAll(
        '.dropdown-display li'
      );
      selectBoxOptions[0].classList.add('ga-sort-low');
      selectBoxOptions[1].classList.add('ga-sort-high');
      selectBoxOptions[2].classList.add('ga-sort-bedrooms');
      selectBoxOptions[3].classList.add('ga-sort-bathrooms');
      selectBoxOptions[4].classList.add('ga-sort-sqft');
    }
    if (params.get('sortOrder')) {
      if (params.get('sortOrder') == 'PriceLowHigh') {
        updateSortOrder('Price (Low to High)');
        setCurrentOrder('Price (Low to High)');
        setSelectedValue('Price (Low to High)');
      } else if (params.get('sortOrder') == 'PriceHighLow') {
        updateSortOrder('Price (High to Low)');
        setCurrentOrder('Price (High to Low)');
        setSelectedValue('Price (High to Low)');
      } else if (params.get('sortOrder') == 'SquareFeet') {
        updateSortOrder('Square Feet');
        setCurrentOrder('Square Feet');
        setSelectedValue('Square Feet');
      } else {
        updateSortOrder(params.get('sortOrder'));
        setCurrentOrder(params.get('sortOrder'));
        setSelectedValue(params.get('sortOrder'));
      }
    } else {
      setCurrentOrder('Default');
    }
  }, []);

  return (
    <HomeListSortStyles className={className}>
      SORT BY:
      <DropDownList
        automationId="homeListSortBtn"
        defaultValue="Default"
        disabled={false}
        dropDownStyle="compact"
        errorMessage=""
        id="flavor-drop-down-error"
        items={[
          'Default',
          'Price (Low to High)',
          'Price (High to Low)',
          'Bedrooms',
          'Bathrooms',
          'Square Feet',
        ]}
        label=""
        onChange={(item) => {
          updateSortOrder(item);
          setSelectedValue(item);
          setCurrentOrder(item);
          fireSegmentEvent('Facility Model List Filtered', {
            list: 'Homes List',
            filters: [
              {
                type: 'Sort By',
                value: item || '',
              },
            ],
            eventContext: 'available_homes_sort',
          });
        }}
        selectedItem={null}
        selectedValue={selectedValue}
        showClearIcon={false}
      />
    </HomeListSortStyles>
  );
};

export default HomeListSort;

import React, { useState, useEffect, useRef } from 'react';
import CardGridStyles from './CardGrid.styled';
import PropTypes from 'prop-types';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';

export const CardGrid = ({ items, className }) => {
  return (
    <CardGridStyles className={className}>
      <div className="container">
        <div
          className={
            items.length <= 2 ? 'card-grid center-card-grid' : 'card-grid'
          }
        >
          {items &&
            items.map((item, i) => (
              <div className="card-item" key={i}>
                <Link
                  className="card"
                  to={
                    item.homeCenterWebsiteContentType == 1
                      ? '/event/' + item.slug
                      : '/news/' + item.slug
                  }
                >
                  <div className="top">
                    {item.mainImage && (
                      <div>
                        <OptimizedImage
                          src={`${item.mainImage}?width=750`}
                          srcSet={[
                            {
                              source: item.mainImage + `?fm=webp&width=750`,
                              minWidth: '750px',
                            },
                            {
                              source: item.mainImage + `?fm=webp&width=450`,
                            },
                          ]}
                          alt={item?.title}
                          lazy
                        />
                      </div>
                    )}
                  </div>
                  <div className="bottom">
                    {item.homeCenterWebsiteContentType && (
                      <span className="type">
                        {item.homeCenterWebsiteContentType == 1
                          ? 'Event'
                          : 'News'}
                      </span>
                    )}
                    {item.title && <h3 className="title">{item.title}</h3>}
                    {item.summary && (
                      <p className="summary">
                        {item.summary}
                        {item.subtitle ? ' ' + item.subtitle : ''}
                      </p>
                    )}
                    {item.disclaimer && (
                      <p className="disclaimer">
                        <span className="asterisk">&#42;</span>
                        <p className="disclaimer-text">{item.disclaimer}</p>
                      </p>
                    )}
                  </div>
                </Link>
              </div>
            ))}
        </div>
        {items.length === 0 && (
          <h4 className="no-results">No news or events.</h4>
        )}
      </div>
    </CardGridStyles>
  );
};

CardGrid.props = {
  items: PropTypes.array,
};

export default CardGrid;

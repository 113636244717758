import styled from 'styled-components';
import Colors from '../../../colors';

export const HomeCardStyles = styled.div`
  &:focus-within {
    .slider-btn-prev,
    .slider-btn-next {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  a {
    text-decoration: none;
  }

  .slide-link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .carousel-root,
  .carousel,
  .slider-wrapper,
  .slider,
  .slide,
  .slide div {
    height: 100%;
  }

  .slider-btns {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 8px 7px 0 0;
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: 38px;
    height: 38px;
    img {
      height: 38px;
      display: block;
    }
  }

  .slider-btn-prev {
    left: 7px;
    margin: 0;
    opacity: 0;
    transition: 0.2s;
    transform: translateX(-10px);
  }

  .slider-btn-next {
    right: 7px !important;
    margin: 0;
    opacity: 0;
    transition: 0.2s;
    transform: translateX(10px);
  }

  .carousel {
    &:hover {
      .slider-btn-prev,
      .slider-btn-next {
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }

  .price-tooltip {
    position: relative;
    cursor: pointer;
  }

  .tippy-content {
    padding: 30px;
    .close-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }

  [data-tippy-root] {
    max-width: 90%;
  }

  [data-theme='clayton'] {
    background-color: ${Colors.primary.claytonBlue.standard};
    p {
      color: white;
      font-size: 14px;
      line-height: 22px;
    }
  }

  [data-theme='clayton'][data-animatefill] {
    background-color: transparent;
  }

  [data-theme='clayton'] .tippy-backdrop {
    background-color: ${Colors.primary.claytonBlue.standard};
  }

  .tippy-arrow {
    color: ${Colors.primary.claytonBlue.standard};
  }

  .card-image-area {
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    margin-bottom: 13px;
    width: 100%;
    height: 100%;
    .slide img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      &.flp {
        object-fit: unset;
        height: auto;
        max-width: 100%;
      }
    }
    .slide:has(.flp) {
      display: flex;
      justify-content: center;
    }
    .aspect-ratio-sizer {
      width: 100%;
    }

    & > div {
      position: absolute;
      top: 0;
      left: 0;
    }

    .card-image-single {
      height: 100%;
      img {
        border-radius: 6px;
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        /*Just going to comment out for now. Breaking images on recently viewed homes*/
        /* &.img-vertical {
          margin-top: -50%;
        } */

        &.flp {
          object-fit: unset;
          height: auto;
          max-width: 100%;
          min-height: none;
        }
      }
      &:has(.flp) {
        display: flex;
        align-items: center;
      }
    }

    .sticker-container {
      position: absolute;
      top: 15px;
      left: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px 7px;
      max-width: 80%;
      .sticker {
        background-color: #fff;
        border-radius: 30px;
        color: ${Colors.primary.black.standard};
        padding: 4px 7px;
        /* margin-right: 7px; */

        .in-stock-dot {
          background-color: ${Colors.accent.claytonGreen.standard};
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin: 4px 3px 4px 2px;
          float: left;
        }
      }
    }

    .favoriting-heart-container {
      position: absolute;
      top: 10px;
      right: 10px;
      left: auto;
      height: 33px;
      width: 33px;
      .favorite-button {
        position: relative;
        background: white;
        height: 33px;
        width: 33px;
        border: 0px solid transparent;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
      }
    }
  }
  .card-info-area {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    position: relative;

    .card-info-left {
      position: relative;
      max-width: 100%;
      flex: 1 1 auto;
      min-width: 0px;
      .card-heading {
        overflow-wrap: break-word;
      }
    }
    .card-info-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .card-preheading {
      font-family: 'source-sans-pro', sans-serif;
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: ${Colors.primary.claytonBlue.standard};
      display: block;
      z-index: 9;
      position: relative;
    }

    .card-heading,
    .card-home-specs {
      margin: 0;
    }

    .bullet {
      color: #cacccf;
      padding: 2px;
      font-family: arial;
    }

    .price {
      color: ${Colors.primary.black.standard};
      text-align: right;
      margin-top: -3px;
      padding-top: 10px;

      .label {
        margin: 0;
        padding: 0;
      }
      .intro {
        margin-top: -3px;
      }
    }
  }
`;

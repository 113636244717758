import styled from 'styled-components';

const ContactModalStyles = styled.div`
  padding: 47px 36px 41px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (min-width: 768px) {
    height: auto;
    padding: 47px 58px 67px;
    width: 512px;
    min-height: auto;
  }
  @media only screen and (min-width: 1025px) {
    padding: 69px 68px;
    width: 527px;
  }
  div {
    &.label {
      text-align: center;
      margin-bottom: 4px;
    }
    &.h3 {
      text-align: center;
      margin-bottom: 25px;
      @media screen and (min-width: 400px) {
        margin-bottom: 30px;
      }
      @media screen and (min-width: 768px) {
        margin-bottom: 50px;
      }
    }
  }
  form {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0 auto;
    margin-bottom: auto;

    & > div {
      flex: 1 1 100%;
      @media screen and (max-width: 399px) {
        &,
        &.animated {
          min-height: 65px;
        }
      }
      @media screen and (min-width: 400px) {
        margin-bottom: 20px;
      }
      @media screen and (min-width: 768px) {
        margin-bottom: 30px;
        &.half {
          flex: 0 1 47%;
        }
      }
    }
    .dropdown-list {
      .dropdown-selected-text,
      .error-message {
        font-family: 'source-sans-pro', sans-serif;
      }
    }
  }
  button:not(.has-value) {
    width: 100%;
    svg {
      width: 100px;
      height: 19px;
    }
    @media screen and (min-width: 375px) {
      margin-bottom: 5px;
    }
    @media screen and (min-width: 768px) {
      margin-bottom: 35px;
    }
  }
  flex: 0 1 auto;
  .disclaimer {
    font-size: 12px;
    line-height: 17px;
    min-height: 50px;
    @media screen and (min-width: 768px) {
      min-height: 60px;
    }
  }
`;

export default ContactModalStyles;

import styled from 'styled-components';
import Colors from '../../../colors';

const ThreeUpStyles = styled.section`
  position: relative;
  padding: 96px 0px 37px;
  .container {
    position: relative;
    max-width: 404px;
    width: 88%;
    margin: 0 auto;
    text-align: center;
    @media only screen and (min-width: 1025px) {
      max-width: 1079px;
      width: auto;
    }
  }
  .three-up-headline {
    width: 90%;
    margin: 0 auto 43px;

    @media only screen and (min-width: 768px) {
      font-size: 32px;
    }
  }
  .three-up-items {
    position: relative;
    display: flex;
    margin-bottom: 35px;
    @media only screen and (max-width: 1025px) {
      flex-direction: column;
      max-width: 511px;
      margin: 0 auto;
    }
    .item {
      flex: 1;
      padding: 0px 20px;
      @media only screen and (max-width: 1025px) {
        margin-bottom: 30px;
        &:not(:last-child) {
          margin-bottom: 80px;
        }
      }
      .logo {
        margin-bottom: 41px;
        max-height: 92px;
        max-width: 100%;
      }
      .headline {
        margin-bottom: 15px;
        @media only screen and (min-width: 768px) {
          font-size: 20px;
        }
      }
      .horizontal-bar {
        width: 39px;
        border-bottom: 1px solid ${Colors.accent.grey4.standard};
        margin: 0 auto 30px;
      }
      .content {
      }
      .btn-wrap {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        position: relative;
      }
      .cta-button {
        color: ${Colors.primary.claytonBlue.standard};
        background: ${Colors.primary.white.standard};
        border-radius: 2px;
        padding: 9px 17px;
        font: 600 12px/100% 'source-sans-pro', sans-serif;
        margin-top: 9px;
        letter-spacing: 1px;
        text-decoration: none;
        transition: 0.2s;
        text-transform: uppercase;
        border: 1.35px solid #0075c9;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        &:hover {
          color: ${Colors.primary.white.standard};
          background: ${Colors.primary.claytonBlue.standard};
        }
      }
    }
  }
  .disclaimer {
    font: 400 14px/114% 'source-sans-pro', sans-serif;
    color: ${Colors.accent.grey1.standard};
    margin: 0 auto 11px;
    max-width: 427px;
  }
`;

export default ThreeUpStyles;

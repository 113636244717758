import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors.js';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import HouseSmartCTABackground from '../../../images/housesmartCTABackground.jpg';

const HouseSmartStyles = styled.section`
  position: relative;
  padding: 60px 0px 100px;
  .container {
    position: relative;
    max-width: 1264px;
    padding: 0px 40px;
    background: #ffffff;
    margin: 0 auto;
    @media only screen and (max-width: 500px) {
      padding: 0px 20px;
    }
  }
  .box {
    position: relative;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: 0px solid;
    padding: 80px 40px 98px;
    display: flex;
    align-items: center;
    background-image: url(${HouseSmartCTABackground});
    background-size: cover;
    background-position: center right;
    justify-content: center;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      padding: 44px 50px 100px;
      background-image: none;
      max-width: 513px;
    }
    @media only screen and (max-width: 500px) {
      padding: 44px 25px 77px;
    }
  }
  .large-headline {
    margin-bottom: 18px;
  }
  .small-headline {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #777b7e;
    text-transform: uppercase;
    font-family: 'source-sans-pro';
  }
  .content-wrap {
    position: relative;
    max-width: 496px;
    flex-shrink: 1;
    width: 100%;
    flex-grow: 1;
    min-width: 272px;
    @media only screen and (max-width: 768px) {
      min-width: auto;
    }
  }
  .content {
    position: relative;
    margin-bottom: 20px;
    color: rgba(37, 41, 45, 1);
  }
  .graphic {
    position: relative;
    display: flex;
    justify-content: center;
    flex-shrink: 1;
    margin-left: 50px;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
    min-width: 0;
    max-width: 363px;
    @media only screen and (max-width: 768px) {
      margin-left: 0px;
      margin-bottom: 50px;
      max-width: 259px;
    }
    img {
      width: 100%;
    }
  }
  .buttons {
    display: flex;
    position: relative;
  }
  .cta-button {
    color: ${Colors.primary.white.standard};
    background: ${Colors.primary.claytonBlue.standard};
    border-radius: 2px;
    padding: 17px 17px;
    font: 500 12px/100% 'source-sans-pro', sans-serif;
    margin-top: 9px;
    letter-spacing: 1px;
    text-decoration: none;
    transition: 0.2s;
    text-transform: uppercase;
    font-weight: 400;

    &:hover {
      opacity: 0.9;
    }
  }
`;

export default HouseSmartStyles;

import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

const RequestInfoModalFormStyles = styled.div`
  .button {
    margin-top: 23px;
    margin-bottom: 25px !important;

    @media only screen and (min-width: 768px) {
      margin-top: 0;
    }
  }
`;

export default RequestInfoModalFormStyles;

import React from 'react';
import Form from './Form';
import PromoBannerClaytonDesktopA from '../../images/promo-images/have-it-made-price-a-banner.jpg';
import PromoBannerClaytonTabletA from '../../images/promo-images/have-it-made-price-a-banner-tablet.jpg';
import PromoBannerClaytonMobileA from '../../images/promo-images/have-it-made-price-a-banner-mobile.jpg';
import PromoBannerClaytonDesktopB from '../../images/promo-images/have-it-made-price-b-banner.jpg';
import PromoBannerClaytonTabletB from '../../images/promo-images/have-it-made-price-b-banner-tablet.jpg';
import PromoBannerClaytonMobileB from '../../images/promo-images/have-it-made-price-b-banner-mobile.jpg';
import HaveItMadeLogo from '../../images/promo-images/have-it-made-logo.svg';
import { StyledBanner } from './Banner.Style';
import useWindowSize from '../../hooks/useWindowSize';

const Banner = ({
  showThankYouCallBack,
  isClayton,
  dealer,
  formHeadline,
  formSubheadline,
  formDisclaimer,
  formOfferText,
  higherStates,
}) => {
  const [width, height] = useWindowSize();
  return (
    <StyledBanner>
      <div className="banner">
        <img
          src={HaveItMadeLogo}
          alt="Have it Made Sales Event"
          className="have-it-made-logo"
        />
        <img
          src={
            !higherStates
              ? PromoBannerClaytonDesktopA
              : PromoBannerClaytonDesktopB
          }
          alt="Have it Made Sales Event"
          className="have-it-made-banner banner-img"
        />
        <img
          src={
            !higherStates
              ? PromoBannerClaytonTabletA
              : PromoBannerClaytonTabletB
          }
          alt="Have it Made Sales Event"
          className="have-it-made-banner-tablet banner-img"
        />
        <img
          src={
            !higherStates
              ? PromoBannerClaytonMobileA
              : PromoBannerClaytonMobileB
          }
          alt="Have it Made Sales Event"
          className="have-it-made-banner-mobile banner-img"
        />
      </div>
      <Form
        formHeadline={formHeadline}
        formSubHeadline={formSubheadline}
        formDisclaimer={formDisclaimer}
        formOfferText={formOfferText}
        showThankYouCallBack={showThankYouCallBack}
        isClayton={isClayton}
        dealer={dealer}
        higherStates={higherStates}
      />
    </StyledBanner>
  );
};

export default Banner;

import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import PromoSliderBackgroundTablet from '../../../images/promo-images/ebuilt-promo-sale-homes-banner-tablet.webp';
import PromoSliderBackgroundMobile from '../../../images/promo-images/ebuilt-promo-sale-homes-banner-mobile.webp';

const SaleHomeSliderStyles = styled.section`
  position: relative;
  padding: 70px 0px 70px;
  overflow: hidden;

  &.promo-sale-homes-slider {
    padding-top: 0;
    padding-bottom: 0%;
    top: -120px;

    @media only screen and (max-width: 1023px) {
      top: unset;
      padding-bottom: 86px;
      .container {
        padding: 0;
      }
    }
  }
  .container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0px 10px;
    @media only screen and (max-width: 553px) {
      padding: 0;
    }

    .headline {
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -0.35px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
      @media only screen and (max-width: 553px) {
        font-size: 26px;
        line-height: 31px;
      }

      &.promo-headline {
        font-size: 48px;
        line-height: 100%;
        margin-bottom: 90px;

        @media only screen and (max-width: 1023px) {
          background: url(${PromoSliderBackgroundTablet}) top center/cover
            no-repeat;
          height: 191px;
          font-size: 40px;
          line-height: 191px;
          width: 100%;
          color: ${Colors.primary.white.standard};
        }

        @media only screen and (max-width: 767px) {
          background: url(${PromoSliderBackgroundMobile}) top center/cover
            no-repeat;
          font-size: 36px;
          line-height: 43px;
          padding: 50px 35px 0 35px;
        }
      }
    }
  }
  .sale-home-slider {
    .card-home-specs {
      @media only screen and (max-width: 553px) {
        li {
          font-size: 14px;
        }
      }
    }

    .close-btn {
      pointer-events: all;
    }
    .carousel.carousel-slider {
      display: flex;
    }
    .salehome-btn-next,
    .salehome-btn-prev {
      border: none !important;
      background: none !important;
      outline: none;
      cursor: pointer;
      width: 60px;
      @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
        width: 40px;
      }
      @media only screen and (max-width: 553px) {
        width: 45px;
        position: absolute;
        top: 0;
        bottom: 0;
        img {
          transform: translateY(-30px);
        }
      }
      @media only screen and (max-width: 370px) {
        img {
          transform: translateY(-40px);
        }
      }
      @media only screen and (max-width: 320px) {
        img {
          transform: translateY(-50px);
        }
      }
    }

    .salehome-btn-prev {
      @media only screen and (max-width: 553px) {
        left: 0px;
        z-index: 8;
      }
    }
    .salehome-btn-next {
      @media only screen and (max-width: 553px) {
        right: 0px;
      }
    }

    .sale-home-slide {
      padding: 14px;
      .sale-home-card {
        margin-bottom: 0px;
      }
    }
    .card-info-area {
      @media screen and (max-width: 900px) {
        min-height: 290px;
      }
      @media screen and (max-width: 550px) {
        min-height: 320px;
      }
    }
    .slide {
      text-align: inherit !important;
      background: transparent;
      img {
        width: inherit;
        vertical-align: inherit;
      }
      .carousel {
        .slide {
          text-align: center;
          background: transparent;
          img {
            width: 100%;
            vertical-align: top;
            border: 0;
          }
        }
      }
    }
  }
  .cta-button-wrap {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .cta-button {
    background: ${Colors.primary.claytonBlue.standard};
    color: white;
    border-radius: 2px;
    padding: 17px 17px;
    font: 500 12px/100% 'source-sans-pro', sans-serif;
    margin-top: 30px;
    letter-spacing: 1px;
    text-decoration: none;
    transition: 0.2s;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-flex;
    align-self: center;
    &:hover {
      background: rgb(23, 158, 255);
    }
  }
`;

export default SaleHomeSliderStyles;

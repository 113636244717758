import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors.js';
import CrossModBannerImage from '../../../images/CrossModBannerImage.jpg';
import CrossModBannerImageMobile from '../../../images/CrossModBannerImageMobile.jpg';

const CrossModBannerStyles = styled.section`
  position: relative;
  background-image: ${`url(${CrossModBannerImage})`};
  background-size: cover;
  background-position: bottom center;
  padding: 92px 0px 0px;
  min-height: 795px;
  height: 54vw;
  @media only screen and (max-width: 768px) {
    padding: 60px 0px 0px;
  }
  @media only screen and (max-width: 500px) {
    background-image: ${`url(${CrossModBannerImageMobile})`};
  }

  @media only screen and (max-width: 321px) {
    padding: 30px 0px 0px;
  }

  .trademark {
    position: relative;
    margin-left: -5px;
  }
  .container {
    position: relative;
    max-width: 750px;
    margin: 0 auto;
    text-align: center;
    padding: 0px 25px;
    @media only screen and (max-width: 768px) {
      max-width: 554px;
    }
    @media only screen and (max-width: 321px) {
      padding: 0px 20px;
    }
  }
  .large-headline {
    margin-bottom: 8px;
  }
  .small-headline {
    margin-bottom: 30px;
  }
  .content {
    margin-bottom: 10px;
  }
  .button-container {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .cta-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 17px;
    background-color: ${Colors.primary.claytonBlue.standard};
    color: white;
    text-decoration: none;
    border: 0px solid transparent;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font: 700 12px/100% 'source-sans-pro', sans-serif;
    margin-top: 10px;
    transition: 0.2s;
    &:hover {
      background: #179eff;
    }
  }
`;

export default CrossModBannerStyles;

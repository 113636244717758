import React from 'react';
import PropTypes from 'prop-types';

const EnvelopeClosed = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
  >
    <path
      fill={color}
      fillRule="nonzero"
      d="M12.534 4c.146 0 .288.02.421.06l.131.045c.043.017.085.035.125.056l.12.067a1.508 1.508 0 0 1 .703 1.272v5.423a1.5 1.5 0 0 1-1.5 1.5H3.5a1.5 1.5 0 0 1-1.5-1.5V5.5a1.5 1.5 0 0 1 .949-1.395l.131-.045a1.49 1.49 0 0 1 .136-.033l.14-.02c.048-.005.096-.007.144-.007zM2.998 6.443L3 10.923a.5.5 0 0 0 .5.5h9.035a.5.5 0 0 0 .5-.5V6.445L8.295 9.633a.5.5 0 0 1-.483.041l-.075-.041-4.74-3.19zM12.534 5H3.5l-.059.003a.5.5 0 0 0-.052.01l.019-.005a.497.497 0 0 0-.09.026l.071-.022a.496.496 0 0 0-.083.027l.012-.005a.498.498 0 0 0-.077.039l.065-.034a.5.5 0 0 0-.089.049l.024-.015a.501.501 0 0 0-.073.054l.05-.04a.503.503 0 0 0-.069.057l.019-.017a.502.502 0 0 0-.062.067l.043-.05a.502.502 0 0 0-.096.132l4.965 3.339 4.964-3.34a.499.499 0 0 0-.034-.057l.028.045a.501.501 0 0 0-.05-.075l.022.03a.503.503 0 0 0-.058-.07l.036.04a.504.504 0 0 0-.058-.061l.022.02a.503.503 0 0 0-.071-.059l.049.039a.503.503 0 0 0-.073-.054l.024.015a.5.5 0 0 0-.075-.043l.051.028a.498.498 0 0 0-.079-.04l.028.012a.496.496 0 0 0-.084-.03l.056.018a.498.498 0 0 0-.18-.033z"
    />
  </svg>
);

EnvelopeClosed.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

EnvelopeClosed.defaultProps = {
  color: '#ffffff',
  width: '16',
  height: '16',
};

export default EnvelopeClosed;

import xHttp from '../utils/xHttp';
import { getBaseApiUrl } from '../utils/config';

/**
 * Gets the homes data for a given retail site by lot number
 * @param {string} lotNumber lot number for the dealer
 * @returns {Promise}
 */
export const getHomesByLotNumber = (lotNumber) => {
  let url = `${getBaseApiUrl()}/api/inventory/retailsites/${lotNumber}`;

  return xHttp.get(url);
};

/**
 * TODO: PLACEHOLDER - update this function when the details API is implemented
 * Gets the homes data for a given home ID
 * @param {string} lotNumber lot number for the dealer
 * @param {string} homeId the target home's ID
 * @returns {Promise}
 */
export const getHomeById = (lotNumber, homeId) => {
  let url = `${getBaseApiUrl()}/api/inventory/retailsites/${lotNumber}/${homeId}`;

  return xHttp.get(url);
};

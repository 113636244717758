import React from 'react';
import PropTypes from 'prop-types';
import CrossModBannerStyles from './CrossModBanner.styled';
import { Link } from 'react-router-dom';

export const CrossModBanner = ({ dealer, homes, gaClass }) => {
  return (
    <CrossModBannerStyles>
      <div className="container">
        <p className="h1 large-headline">
          CrossMod <span className="trademark">&#8482;</span>
        </p>
        <p className="h5 small-headline">
          A new, stylish and innovative category of homes
        </p>
        <p className="content">
          We believe that everyone deserves a chance to achieve the dream of
          homeownership, which is why we helped develop a new category of homes
          called CrossMod™. This new category of off-site built homes is an
          industry movement to offer a more attainable alternative to site-built
          homes for Americans.
        </p>
        <div className="button-container">
          <Link
            className={'cta-button ' + (gaClass ? gaClass : '')}
            to={{
              pathname: '/crossmod',
              state: { dealer, homes },
            }}
          >
            Find Out More
          </Link>
        </div>
      </div>
    </CrossModBannerStyles>
  );
};

export default CrossModBanner;

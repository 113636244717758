import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useLocation } from 'react-router-dom';
import App from '../../App';
import videoBackgroundImage from '../../images/promo-images/have-it-made-video-banner.jpg';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import HouseSmartPromoGrid from '../Shared/HouseSmartPromoGrid/HouseSmartPromoGrid';
import SaleHomeSlider from '../Shared/SaleHomeSlider/SaleHomeSlider';
import { smoothScroll } from '../Shared/SmokeyTransitional/utils/utils';
import TextContent from '../Shared/TextContent/TextContent';
import Video from '../Shared/Video/Video';
import XLCTA from '../Shared/XLCTA/XLCTA';
import Banner from './Banner';
import PromoDisclaimer from './PromoDisclaimer';
import WinterPromoStyles from './WinterPromo.styled';

const WinterPromo = ({ dealer, homes, homesOnSale, isLoading }) => {
  const [showTy, setShowTy] = useState(false);
  const disclaimerRef = useRef(null);

  const higherStates = () => {
    const states = dealer?.stateProvince.toLowerCase();
    switch (states) {
      case 'ca':
        return true;
      case 'co':
        return true;
      case 'id':
        return true;
      case 'nv':
        return true;
      case 'or':
        return true;
      case 'wa':
        return true;
      case 'wy':
        return true;
      default:
        return false;
    }
  };

  const showThankYou = () => {
    setShowTy(true);
  };

  const isClayton = () => {
    if (dealer?.brandAcronym.toLowerCase() === 'cla') {
      return true;
    }
    return false;
  };

  const handleScroll = () => {
    smoothScroll('#PromoDisclaimer', -90);
  };

  const videoId = () => {
    if (isClayton() && !higherStates()) {
      return '0x3orab8eu';
    }
    if (isClayton() && higherStates()) {
      return 'huzeovnn29';
    }
    if (!isClayton() && !higherStates()) {
      return '67vx4g2q5b';
    }
    if (!isClayton() && higherStates()) {
      return '5norrnv9ma';
    }
  };

  const { pathname, hash, key } = useLocation();

  useLayoutEffect(() => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 800);
    }
  }, []);

  if (
    promoIsInactive ||
    dealer?.websiteTemplateDescription === 'NoSpecialOffers'
  ) {
    return <Redirect to="/sale-homes" />;
  }

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <WinterPromoStyles>
        <Helmet>
          <title>
            {' '}
            Have It Made Sales Event - Discounted Mobile, Manufactured Homes |{' '}
            {formatDealerName(dealer?.dealerName)}
          </title>
          <meta
            name="description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
          <meta
            name="og:description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
        </Helmet>
        <Banner
          dealer={dealer}
          homes={homes}
          showThankYouCallBack={showThankYou}
          showTy={showTy}
          isClayton={isClayton()}
          handleScroll={handleScroll}
          formHeadline={`New homes starting under ${
            higherStates() ? '$139,900' : '$69,900'
          }*`}
          formSubheadline={'plus required delivery and installation'}
          formDisclaimer={
            <>
              *<span onClick={handleScroll}>Click here</span> for complete
              pricing details.
            </>
          }
          formOfferText={
            'Complete this form to receive information about special offers.'
          }
          higherStates={higherStates()}
        />
        <TextContent
          smallHeadline={'have it made sales event'}
          largeHeadline={'Your new home is waiting.'}
          content={`We’ll build your house, so you can turn it into your home. From storage and design options to energy efficient features and more, there are endless ways you can Have It Made in a new manufactured home. Visit ${formatDealerName(
            dealer?.dealerName
          )} to see our selection of floor plans you can personalize.`}
          backgroundColor={'#f3f5f7'}
          largePadding={true}
        />
        {homesOnSale && homesOnSale.length > 0 && (
          <SaleHomeSlider
            sliderId="promoSlider"
            largeHeadline="Explore Homes"
            dealer={dealer}
            saleHomes={homesOnSale}
            buttonText={'View All Sale Homes'}
            buttonURL={'/sale-homes'}
            gaClass={'ga-seasonal-campaign-view-sale-homes'}
            retailEventLabel={'seasonal_campaign_view_sale_homes'}
          />
        )}
        <Video
          gaClass={'ga-seasonal-campaign-video'}
          image={{ source: videoBackgroundImage }}
          videoID={videoId()}
          wistiaOptions={'silentAutoPlay=false autoPlay=true'}
          noSaleHomes={!homesOnSale || homesOnSale.length <= 0}
        />
        <HouseSmartPromoGrid />
        <div id="PromoDisclaimer" ref={disclaimerRef}>
          <PromoDisclaimer
            disclaimer={
              higherStates()
                ? '*$149,900 sales price is for the multi-section Tempo series home only. Sales price available in CA, CO, ID, NV, OR, WA and WY. Sales price does not include delivery and installation or other costs such as taxes, title fees, insurance premiums, filing or recording fees, land or improvements to the land, optional home features, optional delivery or installation services, wheels and axles, community or homeowner association fees, or any other items not shown on your Sales Agreement, Retailer Closing Agreement, and related documents (your SA/RCA). If you purchase a home, your SA/RCA will show the details of your purchase. Available with a purchase agreement signed May 1, 2023 through August 31, 2023. Available only at participating Clayton Family of Brands retailers.'
                : '*$129,900 sales price is for the multi-section Tempo series home only. Sales price available in all other states except for CA, CO, ID, NV, OR, WA and WY. Sales price does not include delivery and installation or other costs such as taxes, title fees, insurance premiums, filing or recording fees, land or improvements to the land, optional home features, optional delivery or installation services, wheels and axles, community or homeowner association fees, or any other items not shown on your Sales Agreement, Retailer Closing Agreement, and related documents (your SA/RCA). If you purchase a home, your SA/RCA will show the details of your purchase. Available with a purchase agreement signed May 1, 2023 through August 31, 2023. Available only at participating Clayton Family of Brands retailers.'
            }
          />
        </div>
        <XLCTA
          phoneNumber={dealer.phoneNumber}
          gaPhoneClass={'ga-seasonal-campaign-phone-number'}
          customCallText="Find Your Perfect Home"
          dealer={dealer}
          homes={homes}
          buttons={[
            {
              buttonText: 'Get More Information',
              buttonURL: '/contact-us',
              gaClass: 'ga-seasonal-campaign-get-more-information',
              openForm: 'requestInfo',
              retailEventLabel: 'footer_dual_cta_request',
            },
            {
              buttonText: 'Schedule a Visit',
              buttonURL: '/contact-us',
              gaClass: 'ga-seasonal-campaign-schedule-a-visit',
              openForm: 'scheduleVisit',
              retailEventLabel: 'footer_dual_cta_schedule',
            },
          ]}
        />
      </WinterPromoStyles>
    </App>
  );
};

WinterPromo.getInitialProps = async (ctx) => {
  return await parseInitialPropsContext(ctx, dealerService, homesService);
};

export default WinterPromo;

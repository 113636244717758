import styled from 'styled-components';
import backgroundImage from '../../../images/BluePatternBackground.jpg';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const HeaderWithImageStyles = styled.section`
  position: relative;
  overflow: hidden;
  .inner {
    position: relative;
    background-size: cover;
    background-position: center;
    background-color: ${Colors.primary.claytonBlue.standard};
    margin-bottom: 140px;
    padding: 70px 0px 0px;
    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      background-image: url(${backgroundImage});
    }
  }
  .container {
    position: relative;
    width: 100%;
    max-width: 1304px;
    margin: 0 auto;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
      padding: 0px 20px;
    }
    .content-wrap {
      position: relative;
      max-width: 700px;
      margin: 0 auto 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 125px;
      .small-headline,
      .large-headline,
      .content {
        color: white;
      }
      .large-headline {
        margin-bottom: 10px;
        text-align: center;
      }
      .content {
        font-family: 'source-sans-pro';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: center;
      }
      .small-headline {
        text-transform: uppercase;
        font-family: 'source-sans-pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.5px;
        text-align: center;
      }
    }
    .image-box {
      position: relative;
      height: 420px;
      width: 100%;
      margin-bottom: -100px;
      background-color: ${Colors.accent.grey5.standard};
      box-shadow: 0px 4px 31px rgba(37, 41, 45, 0.25);
      span,
      img {
        height: 100%;
        width: 100%;
      }
      img {
        object-fit: cover;
      }
    }
  }
  @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
    .image-box {
      height: 220px !important;
    }
  }
  @media only screen and (max-width: ${variables.tablet_breakpoint}px) {
  }
`;

export default HeaderWithImageStyles;

import React from 'react';
import PropTypes from 'prop-types';

export const OptimizedImage = ({ srcSet, src, lazy, alt, imgClass }) => {
  return (
    <picture>
      {srcSet &&
        srcSet.map((item, i) => (
          <source
            key={'optimizedImage-' + src?.trim() + i}
            srcSet={item?.source}
            media={`(min-width: ${item?.minWidth || '1px'})`}
          />
        ))}
      <img
        className={imgClass}
        src={src}
        alt={alt}
        loading={lazy ? 'lazy' : 'eager'}
      />
    </picture>
  );
};

OptimizedImage.props = {
  src: PropTypes.string,
  srcSet: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      minWidth: PropTypes.string,
    })
  ),
  alt: PropTypes.string,
  imgClass: PropTypes.string,
  lazy: PropTypes.bool,
};

export default OptimizedImage;

import React, { useState, useEffect } from 'react';
import TextField from '../Shared/SmokeyTransitional/components/TextField/TextField';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import Loader from '../../images/svg/loader';
import { useHistory, useLocation } from 'react-router-dom';
import { contactFormSubmit } from '../../Api';
import { formatPhoneNumber } from '../../utils';
import { fireMNTNConversionPixel } from '../../utils/utils';
import { FormContainer } from './Form.Style';
import Colors from '../../colors';
import { getUTMParametersFromSessionCache } from '../../components/Shared/SmokeyTransitional/utils/utils';

const Form = ({
  formHeadline,
  formSubHeadline,
  formDisclaimer,
  formOfferText,
  isClayton,
  showThankYouCallBack,
  dealer,
}) => {
  let history = useHistory();
  let location = useLocation();
  const dealerBrand = dealer?.dealerName.split(' ');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [firstName, setFirstName] = useState({ value: '', error: null });
  const [lastName, setLastName] = useState({ value: '', error: null });
  const [phone, setPhone] = useState({ value: '', error: null });
  const [email, setEmail] = useState({ value: '', error: null });
  const [zip, setZip] = useState({ value: '', error: null });
  const [isChecked, setIsChecked] = useState({ value: false, error: null });
  const [dealerNumber, setDealerNumber] = useState('');
  const [gaClientId, setGaClientId] = useState('');
  const [canEmail, setCanEmail] = useState(true);
  const [doNotCall, setDoNotCall] = useState(false);
  const [originWebURL, setOriginWebURL] = useState(
    `https://www.${dealer.firstVanityUrl}${location.pathname}${location.search}`
  );
  const [originWebApp, setOriginWebApp] = useState('clayton-retail-website');
  const [originWebPageType, setOriginWebPageType] = useState(
    'promo-retail-sub-page'
  );
  const [originWebFormType, setOriginWebFormType] = useState('lead-form');
  const [originCampaign, setOriginCampaign] = useState(
    isClayton ? 'clayton_tax2023' : 'fob_tax2023'
  );
  const [originWebFormCategory, setOriginWebFormCategory] = useState(
    isClayton ? 'clayton' : `fob-${dealerBrand[0].toLowerCase()}`
  );
  const [dataExtension, setDataExtension] = useState('FormActivityRetailPromo');

  let utmParams;
  try {
    utmParams = getUTMParametersFromSessionCache();
  } catch (error) {
    utmParams = {};
  }

  useEffect(() => {
    getGAClientId();

    if (dealer) {
      setDealerNumber(dealer.dealerNumber);
    }
  });

  const getGAClientId = () => {
    try {
      const tracker = ga.getAll()[0];
      let clientId = tracker.get('clientId');
      setGaClientId(clientId);
    } catch (e) {
      return null;
    }
  };

  const firstNameIsValid = () => {
    let isValid = true;

    if (!firstName.value || firstName.value.length < 2) {
      setFirstName({ ...firstName, error: '*Please enter your first name.' });
      isValid = false;
    } else if (
      !firstName.value.match(/^[a-zA-Z ,.'-]+[^\s]*[a-zA-Z ,.'-]+$/i)
    ) {
      setFirstName({ ...firstName, error: '*Please enter your first name.' });
      isValid = false;
    } else {
      setFirstName({ ...firstName, error: null });
    }

    return isValid;
  };

  const lastNameIsValid = () => {
    let isValid = true;

    if (!lastName.value || lastName.value.length < 2) {
      setLastName({ ...lastName, error: '*Please enter your last name.' });
      isValid = false;
    } else if (!lastName.value.match(/^[a-zA-Z ,.'-]+[^\s]*[a-zA-Z ,.'-]+$/i)) {
      setLastName({ ...lastName, error: '*Please enter your last name.' });
      isValid = false;
    } else {
      setLastName({ ...lastName, error: null });
    }

    return isValid;
  };

  const emailIsValid = () => {
    let isValid = true;
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!email.value) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter your email.' });
    } else if (!pattern.test(email.value)) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter a valid email.' });
    } else {
      setEmail({ ...email, error: '' });
    }

    return isValid;
  };

  const phoneIsValid = () => {
    let isValid = true;

    if (!phone.value) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter your phone number.' });
    } else if (
      !phone.value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i)
    ) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter a valid phone number.' });
    } else {
      setPhone({ ...phone, error: '' });
    }

    return isValid;
  };

  const zipIsValid = () => {
    let isValid = true;
    if (!zip.value) {
      isValid = false;
      setZip({ ...zip, error: '*Please enter your zip code.' });
    } else if (!zip.value.match(/^[0-9]+[^\s].+[^\s]+$/)) {
      isValid = false;
      setZip({ ...zip, error: '*Please enter a valid zip code.' });
    } else {
      setZip({ ...zip, error: '' });
    }
    return isValid;
  };

  const checkedIsValid = () => {
    let isValid = true;

    if (!isChecked.value) {
      isValid = false;
      setIsChecked({
        ...isChecked,
        error: '*You must agree to the terms to receive information.',
      });
    } else {
      setIsChecked({ ...isChecked, error: '' });
    }

    return isValid;
  };

  const validateForm = () => {
    let formIsValid = true;

    //all must be true to submit, so bitwise 'and' it is
    formIsValid &= firstNameIsValid();

    formIsValid &= lastNameIsValid();

    formIsValid &= emailIsValid();

    formIsValid &= phoneIsValid();

    formIsValid &= zipIsValid();

    //uncomment if there is a checkbox
    // formIsValid &= checkedIsValid();

    return formIsValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    let formData = {
      firstname: firstName.value,
      lastname: lastName.value,
      emailAddress: email.value,
      zip: zip.value,
      phoneNumber: phone.value,
      dealerNumber: dealerNumber,
      utmSource: utmParams.utm_source || '',
      utmCampaign: utmParams.utm_campaign || '',
      utmTerm: utmParams.utm_term || '',
      utmContent: utmParams.utm_content || '',
      utmMedium: utmParams.utm_medium || '',
      kenshooId: utmParams.kenshooid || '',
      gaClientId: gaClientId,
      canEmail: canEmail,
      doNotCall: doNotCall,
      originWebURL: originWebURL,
      originWebApp: originWebApp,
      originWebPageType: originWebPageType,
      originWebFormType: originWebFormType,
      originWebFormCategory: originWebFormCategory,
      originCampaign: originCampaign,
      dataExtension: dataExtension,
      googleClickId: utmParams.gclid || '',
      bingClickId: utmParams.msclkid || '',
      facebookClickId: utmParams.fbclid || '',
    };

    setIsSubmitting(true);

    const formSubmitted = await contactFormSubmit(formData);

    if (formSubmitted.status === 200) {
      history.push('/special-offers/have-it-made/thank-you');
      setShowThankYou(true);
      setIsSubmitting(false);
      showThankYouCallBack(showThankYou);
      fireMNTNConversionPixel();
      if (dataLayer) {
        dataLayer.push({ event: 'ga-campaign-form-submit' });
      }
    }
  };

  return (
    <FormContainer>
      <div className="form-container">
        <div className="form-headline">
          <h1>{formHeadline}</h1>
          <h4>{formSubHeadline}</h4>
          <p className="promo-disclaimer">{formDisclaimer}</p>
          <div className="divider" />
          <p className="form-offer-text">{formOfferText}</p>
        </div>
        <form onSubmit={handleSubmit} method="post" noValidate>
          <div className="group">
            <TextField
              className="half-width-input input-container"
              textFieldStyle="primary-background"
              id="firstName"
              placeholder="John"
              tabIndex="1"
              value={firstName.value}
              onChange={(event) => {
                setFirstName({ value: event.target.value.trim(), error: null });
              }}
              onBlur={firstNameIsValid}
              required={true}
              inputClass={firstName.error ? 'has-error' : ''}
              errorMessage={firstName.error}
              label="First name"
              maxLength="40"
            />
            <TextField
              className="half-width-input input-container"
              textFieldStyle="primary-background"
              id="lastName"
              placeholder="Smith"
              tabIndex="2"
              value={lastName.value}
              onChange={(event) => {
                setLastName({ value: event.target.value.trim(), error: null });
              }}
              onBlur={lastNameIsValid}
              required={true}
              inputClass={lastName.error ? 'has-error' : ''}
              errorMessage={lastName.error}
              label="Last name"
              maxLength="50"
            />
          </div>
          <div className="group">
            <TextField
              className="full-width-input input-container"
              textFieldStyle="primary-background"
              id="email"
              placeholder="john@company.com"
              tabIndex="3"
              value={email.value}
              onChange={(event) => {
                setEmail({ value: event.target.value.trim(), error: null });
              }}
              onBlur={emailIsValid}
              required={true}
              inputClass={email.error ? 'has-error' : ''}
              errorMessage={email.error}
              label="Email address"
              maxLength="80"
            />
          </div>
          <div className="group">
            <TextField
              className="half-width-input input-container"
              textFieldStyle="primary-background"
              id="phone"
              placeholder="(___)___-____"
              tabIndex="4"
              value={formatPhoneNumber(phone.value)}
              onChange={(event) => {
                setPhone({ value: event.target.value.trim(), error: null });
              }}
              onBlur={phoneIsValid}
              required={true}
              inputClass={phone.error ? 'has-error' : ''}
              errorMessage={phone.error}
              label="Phone"
              minLength="10"
              maxLength="10"
            />
            <TextField
              className="half-width-input input-container"
              textFieldStyle="primary-background"
              id="zip"
              placeholder="37804"
              tabIndex="5"
              value={zip.value}
              onChange={(event) => {
                setZip({ value: event.target.value.trim(), error: null });
              }}
              onBlur={zipIsValid}
              required={true}
              inputClass={zip.error ? 'has-error' : ''}
              errorMessage={zip.error}
              label="Zip"
              minLength="5"
              maxLength="5"
            />
          </div>
          {/* This is where you would put the Checkbox copmonent if the promo has one.*/}
          <p className="agree-terms">
            By submitting this form, I agree that{' '}
            {isClayton ? (
              'Clayton'
            ) : (
              <span className="capitalize-first-letter">
                {dealerBrand[0].toLowerCase()}
              </span>
            )}{' '}
            Homes may contact me by telephone, provide me with marketing
            communications about{' '}
            {isClayton ? (
              'Clayton'
            ) : (
              <span className="capitalize-first-letter">
                {dealerBrand[0].toLowerCase()}
              </span>
            )}{' '}
            Homes products and services, and share my contact information as
            described in the{' '}
            <a href="https://www.claytonhomes.com/privacy/" target="_blank">
              Website Terms
            </a>
            .
          </p>
          <Button
            text={isSubmitting ? <Loader /> : 'Submit & view homes'}
            onClick={isSubmitting ? () => {} : handleSubmit}
            disabled={isSubmitting ? isSubmitting : ''}
            className="submit-btn"
            tabIndex="6"
            primaryColor={Colors.accent.welcomeHomeYellow.standard}
            automationId="winterBtn"
          />
        </form>
      </div>
    </FormContainer>
  );
};

export default Form;

import styled from 'styled-components';
import Colors from '../../../colors';

const PortalLayoutStyles = styled.div`
  position: relative;

  #LocationInput {
    padding: 9px 13px 8px 13px;

    @media screen and (max-width: 351px) {
      &::placeholder {
        font-size: 12px;
      }
    }
  }

  .DX-TriggerBtn {
    position: relative;
    z-index: 1;
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    font-family: 'source-sans-pro', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    outline: solid 1px #0075c9;
    border-radius: 2px;
    color: #ffffff;
    padding: 17px;
    display: inline-grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    align-items: center;
    opacity: 1;
    text-decoration: none;
    width: max-content;
    transition: 0.3s;
    .background {
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #0075c9;
      opacity: 1;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      border-radius: 2px;
    }
    &:hover {
      opacity: 1;
      filter: brightness(120%);
    }
  }

  .loading-screen-panel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: calc(100vh - 150px);
  }

  .home-card {
    .tippy-content {
      padding: 30px 20px 20px;
      p {
        color: white;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  .PlannerQuestionnaire-previousText {
    @media screen and (max-width: 320px) {
      display: none;
    }
  }

  .DXPlannerField-grid {
    @media screen and (max-width: 600px) {
      display: flex !important;
      justify-content: space-between !important;
      gap: 10px;
      .DXButton {
        /* position: absolute !important; */
        top: auto !important;
        right: auto !important;
        align-items: flex-start !important;
      }
    }
  }

  .DXIconCard-content {
    .DXChip {
      padding: 10px 10px 10px;
      background: #e2f7ff;
    }
    .DXLinkArrow {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .progressBar {
    transform: rotate(-90deg);
    transform-origin: 50% 92px;
    transition: strokeDasharray 0.5s ease-in-out;
  }

  .portal-layout {
    position: relative;
    display: flex;
  }

  #maincontent {
    background: #f5f5f5;
    width: 100%;
  }

  .PlannerQuestionnaire-previous,
  .PlannerQuestionnaire-skip {
    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  .accordion-section {
    .accordion-item.active {
      button {
        svg {
          transform: rotate(90deg) !important;
        }
      }
    }
  }

  .PlannerQuestionnaire {
    box-shadow: none !important;
    @media screen and (max-width: 600px) {
      padding: 0rem !important;
      .PlannerQuestionnaire-navigation {
        height: 22px;
      }
      .PlannerQuestionnaire_navPreviousContainer {
        position: absolute;
        left: 0;
      }
      .PlannerQuestionnaire_navSkipContainer {
        position: absolute;
        right: 0;
      }
    }
    .PlannerQuestion-radio {
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
    .PlannerQuestionnaire-navigation {
      position: relative;
      @media screen and (max-width: 600px) {
        align-items: center;
      }
    }
    .PlannerQuestionnaire-counter {
      @media screen and (max-width: 600px) {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0 auto;
        pointer-events: none;
      }
    }
  }

  .PlannerQuestion-radio {
    &:hover {
      .PlannerQuestion-input {
        border: 0px solid transparent !important;
      }
    }
  }

  .featured-selections-mobile-grid {
    max-width: 100%;
  }

  .FeaturesGrid-mobileNav {
    max-width: 100%;
  }
  .FeaturesGrid {
    width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
    .FeaturesGrid-grid {
      max-width: 100%;

      @media screen and (max-width: 430px) {
        display: flex !important;
        .FeatureCard-icon {
          height: 37px;
        }
        .FeatureCard-label {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }

  .PlannerQuestionnaire .PlannerQuestionnaire-navigation {
    .DXTypography {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .DXPlannerFields {
    position: relative;
    margin-bottom: 40px;
    .DXPlannerFields-subheading {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.5px;
      color: #0075c9;
      padding: 10px 10px 10px;
      background: #e2f7ff;
    }
    h2 {
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      letter-spacing: -0.35px;
      color: #25292d;
    }
    .FeaturesGrid {
      .FeatureCard {
        pointer-events: none;
      }
    }
    .DXPlannerField-content {
      p:first-child {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #4e5255;
      }
    }
    .DXButton {
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      color: #0075c9;
      span {
        font-family: 'source-sans-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }

  .planner-loading-screen {
    position: relative;
    width: 100%;
    aspect-ratio: 2/1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .feature-answer-wrap {
    position: relative;
    margin-top: 20px;
    .FeaturesGrid-mobileNav {
      display: none;
    }
    h2 {
      display: block;
      margin-bottom: 20px;
    }
  }

  .DXItemsGrid-item {
    padding: 10px;
    .sticker {
      color: white;
      background-color: #00a3a0;
      font-family: source-sans-pro;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 12px;

      .in-stock-dot {
        display: none;
      }
    }

    .price-tooltip {
      flex-shrink: 0;
    }

    .card-image-area {
      background: white;
      margin-bottom: 0;
      .slider {
        .slide {
          background-color: white !important;
        }
      }
    }

    .card-heading {
      font-family: 'acumin-pro';
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.3px;
    }

    .card-home-specs {
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #4e5255;
    }

    .price-top {
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
      text-align: right;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #25292d;
    }

    .price {
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 29px;
      text-align: right;
      color: #25292d;
    }

    .price-bottom {
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 29px;
      text-align: right;
      color: #25292d;
    }

    .price-contact-us {
      display: block;
      font-family: 'source-sans-pro';
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #0075c9;
      font-weight: 600;
      width: 104px;
      flex-shrink: 0;
      margin-left: 10px;
    }

    .sticker {
      color: white !important;
      background-color: #0075c9 !important;
    }

    .sticker-sale,
    .sticker-price-drop {
      background-color: #ef4b5d !important;
    }

    .sticker-in-stock {
      background-color: #00a3a0 !important;
    }

    .orange-sticker {
      background-color: ${Colors.accent.cheesePuff.standard} !important;
    }

    .purple-sticker {
      background-color: #7e71c1 !important;
    }

    .slider-wrapper {
      border-radius: 6px;
    }

    .info-circle {
      margin-left: 0px;
      width: 14px;
      height: 14px;
      vertical-align: middle;
      filter: invert(11%) sepia(11%) saturate(844%) hue-rotate(164deg)
        brightness(96%) contrast(87%);
    }

    .phone-icon {
      margin-right: 1px;
      filter: invert(26%) sepia(46%) saturate(4667%) hue-rotate(189deg)
        brightness(95%) contrast(101%);
      width: 15px;
      height: 15px;
      vertical-align: middle;
    }

    .control-dots {
      bottom: -4px;

      .selected {
        opacity: 1 !important;
      }

      .dot {
        width: 7px;
        height: 7px;
        margin: 0 6px;
        opacity: 0.6;
        box-shadow: none !important;

        .selected {
          opacity: 1 !important;
        }
      }
    }
  }

  .CTAPlannerCard {
    width: 100% !important;
    @media screen and (max-width: 900px) {
      width: 100%;
      .CTAPlannerCard-content
        .CTAPlannerCard-textContainer
        .CTAPlannerCard-heading {
        font-size: 32px !important;
      }
    }
    .CTAPlannerCard-content {
      @media screen and (max-width: 900px) {
        .CTAPlannerCard-clickContainer {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }
    }
    .CTAPlannerCard-accent {
      @media screen and (max-width: 900px) {
        bottom: 20% !important;
        display: flex !important;
      }
    }
    .CTAPlannerCard-heading {
      font-weight: 600 !important;
    }
    .CTAPlannerCard-clickContainer {
      margin-left: 30px;
    }
  }

  .CTAPlannerCard.CTAPlannerCard-eapp {
    z-index: 1;
    width: 100%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
    .CTAPlannerCard-accent {
      max-height: 250px !important;
      width: 155px;
      img {
        max-height: 262px !important;
        width: 155px;
      }
      @media screen and (max-width: 900px) {
        bottom: 20% !important;
        display: none !important;
      }
    }
    .CTAPlannerCard-content {
      .CTAPlannerCard-heading {
        font-weight: 600 !important;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.35px;
        font-family: 'source-sans-pro';
        margin-top: 7px;
        color: #25292d !important;
      }
      .CTAPlannerCard-link {
        width: 64px;
        height: 64px;
      }
    }
    .CTAPlannerCard-textContainer {
      padding: 1rem 0rem;
      width: 100%;
      .CTAPlannerCard-chip {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #25292d;
        background-color: #ffc50a;
      }
    }
  }

  .CTAPlannerCard.CTAPlannerCard-teal {
    z-index: 1;
    width: 100%;
    margin-top: 10px;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
    .CTAPlannerCard-accent {
      @media screen and (max-width: 900px) {
        bottom: 20% !important;
        display: flex !important;
      }
    }
    .CTAPlannerCard-content {
      .CTAPlannerCard-heading {
        font-weight: 600 !important;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.35px;
        font-family: 'source-sans-pro';
        margin-top: 7px;
      }
      .CTAPlannerCard-link {
        width: 64px;
        height: 64px;
      }
    }
    .CTAPlannerCard-textContainer {
      padding: 1rem 0rem;
      width: 100%;
      .CTAPlannerCard-chip {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #005f66;
        background-color: rgba(255, 255, 255, 0.75);
      }
    }
  }
`;

export default PortalLayoutStyles;

import React from 'react';
import PropTypes from 'prop-types';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import ContactModalThankYouStyles from './ContactModalThankYou.styled';
import ContactModal from '../ContactModal/ContactModal';
import ContactHouse from './ContactHouse';

const ContactModalThankYou = ({ label, title, closeFunction }) => {
  return (
    <ContactModalThankYouStyles>
      <ContactModal>
        <div className="label">{label}</div>
        {title && <div className="h3">{title}</div>}

        <ContactHouse />

        <p>
          Thank you for reaching out. A home center consultant will be in touch
          shortly.
        </p>

        <Button
          text="Okay"
          onClick={closeFunction}
          automationId="contactModalTYBtn"
        />
        <div className="disclaimer" />
      </ContactModal>
    </ContactModalThankYouStyles>
  );
};

ContactModalThankYou.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ContactModalThankYou;

import React from 'react';
import StyledDisclaimer from './PromoDisclaimer.Style';

const PromoDisclaimer = ({
  otherCompaniesDisclaimer,
  mainDisclaimer,
  disclaimerLinkText,
  disclaimerLink,
}) => {
  return (
    <StyledDisclaimer>
      <p className="other-companies-disclaimer">{otherCompaniesDisclaimer}</p>
      <p className="main-disclaimer">{mainDisclaimer}</p>
      <a href={disclaimerLink} target="_blank">
        {disclaimerLinkText}
      </a>
    </StyledDisclaimer>
  );
};

export default PromoDisclaimer;

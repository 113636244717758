import styled from 'styled-components';
import Colors from '../../colors';

const fontSize = '14px';

const selectableCardColorOptions = [
  Colors.primary.claytonBlue,
  Colors.secondary.kiddiePool,
  Colors.accent.ladyBug,
  Colors.accent.cheesePuff,
  Colors.secondary.rubberDucky,
  Colors.primary.white,
];

const SelectableCardStyles = styled.div.attrs({ className: 'selectable-card' })`
  border-radius: 2px;
  border: solid 1px
    ${(props) =>
      props.errorMsg
        ? Colors.accent.ladyBug.standard
        : Colors.secondary.grey6.standard} !important;
  box-sizing: border-box;
  max-width: 142px;

  .card-text {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: ${fontSize};
    line-height: 1.14;
    color: ${Colors.primary.black.standard};
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 13px 8px;
    white-space: nowrap;
  }

  ${selectableCardColorOptions
    .map(
      (color) =>
        `&.${color.className} {
        &:hover {
            border-color: ${color.standard};     
        }
        &.selected {  
            border-color: ${color.standard};     
        }             
    }`
    )
    .join('\n')}

  &:hover {
    cursor: pointer;
  }

  &.selected {
    background-color: ${Colors.accent.veryLightBlue.standard};

    .card-text {
      font-weight: 700;
      color: ${Colors.primary.claytonBlue.standard};
    }
  }
`;

export default SelectableCardStyles;

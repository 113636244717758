import React from 'react';
import PropTypes from 'prop-types';
import StyledToggle from './Toggle.Style';

const Toggle = ({ onToggle, label, mobileLabel, size, inputProps, title }) => (
  <StyledToggle
    checked={inputProps.checked}
    htmlFor={inputProps.id}
    className={inputProps.disabled ? 'disabled' : ''}
    size={size}
    onClick={(event) => event.stopPropagation()}
    title={title}
  >
    {label && <span className="toggle-label">{label}</span>}
    {mobileLabel && <span className="mobile-toggle-label">{mobileLabel}</span>}
    <div className="toggle-wrapper">
      <div className="toggle-outer">
        <div className="toggle-inner">
          <div className="toggle-circle" />
          <input
            type="checkbox"
            onClick={(event) => event.stopPropagation()}
            onChange={onToggle}
            {...inputProps}
          />
        </div>
      </div>
      <div className="toggle-bar" />
    </div>
  </StyledToggle>
);

Toggle.propTypes = {
  onToggle: PropTypes.func.isRequired,
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    checked: PropTypes.bool.isRequired,
  }).isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
};

Toggle.defaultProps = {
  label: '',
  size: 'small',
  title: '',
};

export default Toggle;

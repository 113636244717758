import styled from 'styled-components';
import Colors from '../../../colors';

import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const MeetOurTeamStyles = styled.section`
  position: relative;
  overflow: hidden;
  .container {
    padding: 50px 0 72px;
    margin: 0 auto;
    max-width: 1164px;
    @media only screen and (max-width: 1024px) {
      max-width: 395px;
      width: auto;
    }
    @media only screen and (max-width: 550px) {
      padding: 10px 20px 80px;
    }
  }

  .meet-our-team-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
  }

  h2 {
    margin-bottom: 17px;
    font-size: 26px;
    line-height: 31px;

    @media only screen and (min-width: 768px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .sm-divider {
    border-bottom: 1px solid ${Colors.accent.grey4.standard};
    width: 73px;
    margin-bottom: 40px;
  }

  .cta-button {
    background: ${Colors.primary.claytonBlue.standard};
    color: ${Colors.primary.white.standard};
    border-radius: 2px;
    padding: 18px 25px;
    box-sizing: border-box;
    font: 600 14px/100% 'source-sans-pro', sans-serif;
    letter-spacing: 1px;
    text-decoration: none;
    transition: 0.2s;
    text-transform: uppercase;
    font-weight: 700;
    height: 50px;
    margin-top: 21px;
    display: block;
    max-width: 215px;
    text-align: center;
    &:hover {
      opacity: 0.9;
    }

    @media only screen and (min-width: 1025px) {
      margin-top: 0;
    }
  }

  .carousel-root {
    max-width: 260px;
    margin: 0 auto;

    @media only screen and (min-width: 550px) {
      max-width: none;
    }

    @media only screen and (min-width: 768px) {
      max-width: none;
      margin: 0;
    }
  }

  .carousel {
    &.carousel-slider {
      overflow: visible;
    }
    button {
      position: absolute;
      z-index: 9;
      top: 40%;
      transform: translateY(-40%);
      border: none;
      background: none;
      width: 34px;
      height: 34px;

      @media only screen and (min-width: 768px) {
        width: 60px;
        height: 60px;
      }
    }

    .slider-btn-prev {
      left: -19px;
      position: absolute;
      transform: scaleX(-1) translateY(-50%);
      cursor: pointer;
      @media only screen and (max-width: 1024px) {
        left: -58px;
      }
      @media only screen and (max-width: 768px) {
        left: -28px;
      }
    }

    .slider-btn-next {
      right: -19px;
      position: absolute;
      cursor: pointer;
      @media only screen and (max-width: 1024px) {
        right: -58px;
      }
      @media only screen and (max-width: 768px) {
        right: -28px;
      }
    }
  }

  .slide {
    background: none !important;
    padding: 10px;
  }

  .slide-container {
    max-width: 375px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    div {
      flex: 1;
    }

    img {
      margin-bottom: 20px;
      aspect-ratio: 1/1;
      object-fit: cover;
    }

    .team-member-info {
      .team-member-title {
        color: ${Colors.accent.grey2.standard};
        text-transform: uppercase;
        font-family: 'source-sans-pro';
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.5px;
        margin-bottom: 6px;
      }
    }

    .team-member-contact-info {
      display: flex;
      max-width: 60px;
      margin-top: auto;
      a {
        padding: 4px;
      }
    }
  }
`;

export default MeetOurTeamStyles;

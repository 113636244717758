import React from 'react';
import styled from 'styled-components';
import Colors from '../../colors';
import PromoBannerMobile from '../../images/promo-images/summer-promo/ebuilt-banner-mobile.jpg';
import PromoBannerTablet from '../../images/promo-images/summer-promo/ebuilt-banner-tablet.jpg';
import PromoBannerDesktop from '../../images/promo-images/summer-promo/ebuilt-banner-desktop.jpg';

export const StyledSummerPromoThankYou = styled.div`
  .ty-message-banner {
    width: 100%;

    @media only screen and (min-width: 1025px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .loading-block {
    min-height: calc(100vh - 89px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner {
    background: url(${PromoBannerMobile}) no-repeat center;
    background-size: cover;
    height: 360px;

    @media only screen and (min-width: 768px) {
      background: url(${PromoBannerTablet}) no-repeat bottom center;
      background-size: cover;
      height: 234px;
    }
    @media only screen and (min-width: 1025px) {
      height: auto;
      width: 50%;
      background: url(${PromoBannerDesktop}) no-repeat center;
      background-size: cover;
    }

    .banner-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .confirmation-message {
    background-color: ${Colors.accent.eBuiltprimaryBlue.standard};
    width: 100%;
    margin: auto;
    padding: 80px 0 80px;

    @media screen and (min-width: 768px) {
    padding: 139px 0 148px;
  }

  @media screen and (min-width: 1025px) {
    max-width: none;
    width: 50%;
    margin: unset;
    padding: 144px 0 150px;
  }

  .message-container {
    h3 {
      font-size: 30px;
      line-height: 36px;
      font-weight: 700;
      font-family: 'acumin-pro', sans-serif;
      color: ${Colors.primary.white.standard};
      letter-spacing: -0.35px;
      text-align: center;
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 0 auto -30px;
    }

    .house-container {
      margin: 0 auto 23px;
      text-align: center;
    }

    p {
      font: 700 20px/30px 'acumin-pro', sans-serif;
      color: ${Colors.primary.white.standard};
      text-align: center;
      margin: -7px auto 67px;
      width: 87%;

      @media only screen and (min-width: 768px) {
        width: auto;
        margin-bottom: 86px;
      }

      br {
        display: none;

        @media only screen and (min-width: 768px) {
          display: block;
        }
      }
    }

    .button {
      font: 600 14px/100% 'source-sans-pro', sans-serif;
      color: ${Colors.primary.white.standard};
      text-align: center;
      width: 87%;
      border-radius: 2px;
      background: ${Colors.accent.eBuiltButtonBlue.standard};
      display: block;
      padding: 18px 0;
      margin: 0 auto;
      text-decoration: none;
      text-transform: uppercase;
      max-width: 327px;

      @media only screen and (min-width: 768px) {
        max-width: none;
        width: 392px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
`;

import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import React from 'react';
import { fireSegmentEvent } from '../../../utils';
import FeaturesFilterStyles from './FeaturesFilter.styled';
import FeaturesFilterItem from './FeaturesFilterItem';

export const FeaturesFilter = ({
  values,
  featureShouldChange,
  applyFilter,
  clearFilter,
  className,
}) => {
  return (
    <FeaturesFilterStyles className={className}>
      <div className="content-area">
        <div className="scroll-area">
          {values.features.available.map((featureName) => {
            return (
              <FeaturesFilterItem
                iconUrl={''}
                label={featureName}
                isSelected={values.features.selected.includes(featureName)}
                onClick={featureShouldChange}
                key={featureName?.trim()}
              />
            );
          })}
        </div>
      </div>
      <div className="button-area">
        <span
          className="clear-btn"
          tabIndex="0"
          onClick={() => {
            clearFilter('features');
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              clearFilter('features');
            }
          }}
          to={'/'}
        >
          CLEAR
        </span>
        <Button
          className="ga-filter-features"
          tabIndex="0"
          automationId="featurFilterBtn"
          onClick={() => {
            applyFilter();
            fireSegmentEvent('Facility Model List Filtered', {
              list: 'Homes List',
              filters: [
                {
                  type: 'Features',
                  value:
                    values.features.selected && values.features.selected.length
                      ? values.features.selected.join(', ')
                      : '',
                },
              ],
              eventContext: 'available_homes_filter_features',
            });
          }}
        >
          APPLY
        </Button>
      </div>
    </FeaturesFilterStyles>
  );
};

export default FeaturesFilter;

import { formatDealerName } from '../components/Shared/SmokeyTransitional/utils/formatters';
import { findFeaturedHomes } from '../utils/utils';
import gallery from '../content/sampleImageGallery';

export const employeeStructuredData = (salesAgents) => {
  return (
    salesAgents &&
    salesAgents.length > 0 &&
    salesAgents.map((agent) => {
      return {
        '@context': 'https://schema.org',
        '@type': 'Person',
        name: `${agent.firstName} ${agent.lastName}`,
        image: agent.avatarImageReference,
        email: `mailto:${agent.email}`,
        jobTitle: agent.salesAgentTitle,
      };
    })
  );
};

export const homesStructuredData = (homes) => {
  return (
    homes &&
    homes.length > 0 &&
    homes.reduce((previous, current) => {
      return current.priceSort < previous.priceSort ? current : previous;
    })
  );
};

export const homePageStructuredData = (
  {
    welcomeHomeImageReferences,
    dealerName,
    latitude,
    longitude,
    contactUsPageData,
    address1,
    city,
    stateProvince,
    postalCode,
    firstVanityUrl,
    phoneNumber,
    weekdayHours,
    fridayHours,
    saturdayHours,
    sundayHours,
  },
  salesAgents,
  homes
) => {
  const dealerFriendlyName = formatDealerName(dealerName) || 'Clayton Homes';
  const openingHours = (hours) => {
    let openHours = hours.split(/,| /);
    return openHours[0];
  };
  const closingHours = (hours) => {
    let closingHours = hours.split(/,| /);
    return closingHours[2];
  };
  const whImgs = () => {
    if (welcomeHomeImageReferences !== null) {
      return welcomeHomeImageReferences;
    }
    return gallery;
  };
  const homePageSchema = {
    '@context': 'https://schema.org',
    '@type': 'HomeAndConstructionBusiness',
    image: whImgs(),
    name: dealerFriendlyName,
    hasMap:
      latitude && longitude
        ? `https://www.google.com/maps/search/?api=1&query=${latitude.toString()},${longitude.toString()}`
        : null,
    description: contactUsPageData ? contactUsPageData.dealerDescription : '',
    address: {
      '@type': 'PostalAddress',
      streetAddress: address1,
      addressLocality: city,
      addressRegion: stateProvince,
      postalCode: postalCode,
      addressCountry: 'US',
    },
    parentOrganization: {
      '@type': 'Organization',
      name: 'Clayton Homes',
    },
    employee:
      salesAgents && salesAgents.length > 0
        ? employeeStructuredData(salesAgents)
        : null,
    geo: {
      '@type': 'GeoCoordinates',
      latitude: latitude ? latitude : '',
      longitude: longitude ? longitude : '',
    },
    url: `https://www.${firstVanityUrl}`,
    priceRange: `Starting at $${homesStructuredData(homes).priceSort}`,
    telephone: phoneNumber,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Monday',
        opens: openingHours(weekdayHours),
        closes: closingHours(weekdayHours),
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Tuesday',
        opens: openingHours(weekdayHours),
        closes: closingHours(weekdayHours),
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Wednesday',
        opens: openingHours(weekdayHours),
        closes: closingHours(weekdayHours),
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Thursday',
        opens: openingHours(weekdayHours),
        closes: closingHours(weekdayHours),
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Friday',
        opens: openingHours(fridayHours),
        closes: closingHours(fridayHours),
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Saturday',
        opens: openingHours(saturdayHours),
        closes: closingHours(saturdayHours),
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Sunday',
        opens: openingHours(sundayHours),
        closes: closingHours(sundayHours),
      },
    ],
  };

  return homePageSchema;
};

export const homeDetailsPageStructuredData = (
  homeUrl,
  homeDescription,
  homeCenterName,
  dealerLogo,
  home
) => {
  const getHomeDetailImgs = (imgs) => {
    let homeDetailImages = [];
    if (!imgs) {
      return null;
    }

    imgs.map((img) => {
      homeDetailImages.push(img?.reference);
    });
    return homeDetailImages;
  };

  const homeDetailsPageSchema = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: home?.name || home?.modelName,
    model: home?.modelNumber,
    productID: home?.serialNumber || home?.inventoryId || home?.stockNumber,
    image: getHomeDetailImgs(home?.images),
    url: homeUrl || null,
    size: home?.squareFeet?.toString() + ' sq. ft.' || null,
    description: homeDescription || null,
    brand: homeCenterName || null,
    logo: dealerLogo || null,
    offers: {
      '@type': 'AggregateOffer',
      lowPrice: home?.priceSort,
      highPrice: home?.priceSort,
      priceCurrency: 'USD',
    },
  };

  return homeDetailsPageSchema;
};

export const homeListingsPageStructuredData = ({ firstVanityUrl }, homes) => {
  const cardLinkPath = (home) => {
    if (home?.isAvailableFloorPlan) {
      return `homes/model/${home?.modelNumber}`;
    }

    if (home?.isLand) {
      return `land/${home?.stockNumber}`;
    }

    return `homes/${home.serialNumber || home.inventoryId || home.stockNumber}`;
  };

  const homeListingsPageSchema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement:
      homes &&
      homes.map((home, i) => {
        return {
          '@type': 'ListItem',
          position: i + 1,
          url: `https://www.${firstVanityUrl}/${cardLinkPath(home)}`,
        };
      }),
  };

  return homeListingsPageSchema;
};

export const faqStructuredData = ({ firstVanityUrl }) => {
  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How much do your homes cost?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: '<p>Pricing varies based on square footage, features and home type. The home site and site improvements also affect the total package price. Visit our home center to ask a home consultant about pricing and see what homes are available within your budget.</p>',
        },
      },
      {
        '@type': 'Question',
        name: 'How does financing work?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: '<p>Our home centers work with many lenders. At the home center you will find a Lender Board with information from national and regional lenders that can help you decide which lender best fits your needs, or you can choose to use another lender that you may already have a relationship with. Choose a lender you are comfortable with, and the lender will be able to answer any of your financing related questions.</p>',
        },
      },
      {
        '@type': 'Question',
        name: 'Do you accept trade-ins?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>Yes, in many cases we do accept trade-ins. <a href="https://www.${firstVanityUrl}/contact-us">Contact us</a> for more information about our trade-in requirements and to schedule your free trade evaluation.</p>`,
        },
      },
      {
        '@type': 'Question',
        name: 'What is the value of my current home?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>Home prices and values are driven by many factors. Call us today to schedule a free trade evaluation. You can also get an estimate of what your home’s current value may be by <a href="https://www.nadaguides.com/Manufactured-Homes/Value-Reports/Online" target="_blank">purchasing a report from NADA.</a> This report either costs $30 or $50, depending on if you order a basic report or a professional report. The values indicated on reports generated by NADA are intended to be used as guidelines only.</p>`,
        },
      },
      {
        '@type': 'Question',
        name: 'Can you help me find land for my new home?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: '<p>Each home center is different, but in most cases we can help you work with local real estate agents to find land for your new home.</p>',
        },
      },
      {
        '@type': 'Question',
        name: 'I live more than 150 miles away from the home center. Can you service my area?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: '<p>In many cases we can work with you to help you find a new home if you are more than 150 miles away from our home center. Give us a call so we can get some more information from you and help you start your home buying journey.</p>',
        },
      },
      {
        '@type': 'Question',
        name: 'What energy efficient features do you offer in your homes?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>Our signature <em>Energy Smart Home</em> is loaded with energy saving features designed to give you Year-Round Comfort and a Lifetime of Savings. Some of the features included are low-e windows, energy efficient water heaters and programmable thermostats – just to name a few. Check out the <a href="https://www.${firstVanityUrl}/energysmart">Energy Smart Home</a> page of our site to learn more.</p>`,
        },
      },
      {
        '@type': 'Question',
        name: 'Where can I find the floor plans for your homes?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>Click <a href="https://www.${firstVanityUrl}/homes" target="_blank">View Homes</a> at the top of the page to see floor plans, pictures and home information about the models we offer.</p>`,
        },
      },
      {
        '@type': 'Question',
        name: 'What models are available for viewing at your home center? Can I order a home that isn’t at your home center?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>You can see homes available for purchase and homes in stock on the <a href="https://www.${firstVanityUrl}/homes" target="_blank">View Homes</a> page of the website. If you see a home on our website that you love but is not in stock, we can still order that home for you. Stop by the home center or <a href="https://www.${firstVanityUrl}/contact-us" target="_blank">contact us</a> to learn more about this process.</p>`,
        },
      },
      {
        '@type': 'Question',
        name: 'Do you have pre-owned homes available?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>We sometimes have pre-owned homes available for purchase at our home centers. <a href="https://www.${firstVanityUrl}/contact-us" target="_blank">Contact us</a> or stop by to learn more about pre-owned homes we may have for sale.</p>`,
        },
      },
      {
        '@type': 'Question',
        name: 'Can your homes be placed on a basement?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: '<p>With the proper home site prep and foundation many of our homes can be placed on a basement. Stop by our home center or give us a call to learn more about homes that can be placed on basement foundations.</p>',
        },
      },
      {
        '@type': 'Question',
        name: 'What’s the difference between a manufactured home and a modular home?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: '<p>Manufactured homes are built entirely inside our climate-controlled home building facilities to federal HUD Code regulations and come in three sizes: single wide, double wide and triple wide. They can be placed on a permanent foundation and some models can be placed on a basement, but can be relocated with the help of an experienced contractor if not placed on a permanent foundation. Modular homes are also built inside climate-controlled home building facilities. However, they are built according to all state, local and/or regional codes for where the home will be located. They are typically built in two sections but can be built in up to five sections depending on the size and are constructed on a permanent foundation.</p>',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I make changes to the floor plan I choose?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: '<p>Many of our homes can be altered and upgraded to create your ideal dream home. Some homes have more customizable and upgradable options than others, but with countless floor plans available, we are sure we can help you find the perfect floor plan to fit your budget and lifestyle. Let us know which floor plan you’re interested in, and we’ll let you know what is customizable.</p>',
        },
      },
      {
        '@type': 'Question',
        name: 'How long does it take to get in a new home?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: '<p>Our home builders complete homes efficiently and in a timely manner after your home order is placed, but there are other factors that could affect how long it takes to get to move-in day. The timeline can be affected by the type of house you are buying as larger homes will take longer to build than smaller homes, the volume of home orders the building facility has received, home inspections, land preparation and more. A home consultant at our home center will be able to give you more information on how long it will take to get into your new home after your home order is placed and will keep you updated on what stage your home is at throughout your buying journey.</p>',
        },
      },
      {
        '@type': 'Question',
        name: 'What’s the down payment amount for a home purchase?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: '<p>Every home purchase is unique, so the amount of down payment required by a lender to finance a home purchase will vary based on several different factors. The lender you choose to finance your home purchase with will be able to give you specific details about our down payment amount.</p>',
        },
      },
    ],
  };

  return faqSchema;
};

export const featuredHomesStructuredData = (homes) => {
  return (
    homes &&
    homes.length > 0 &&
    homes.map((home) => {
      return {
        '@type': 'Offer',
        name: 'Featured Homes',
        itemOffered: {
          '@type': 'Product',
          name: home?.name,
          image: home?.thumbnailImage.reference,
          offers: {
            '@type': 'AggregateOffer',
            lowPrice: home?.priceSort,
            highPrice: home?.priceSort,
            priceCurrency: 'USD',
          },
        },
      };
    })
  );
};

export const contactUsPageStructuredData = (
  {
    dealerName,
    emailAddress,
    firstVanityUrl,
    address1,
    city,
    stateProvince,
    postalCode,
    phoneNumber,
    logoReference,
  },
  homes
) => {
  const featuredHomes = findFeaturedHomes(homes);
  const dealerFriendlyName = formatDealerName(dealerName) || 'Clayton Homes';
  const contactUsPageSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: dealerFriendlyName,
    url: `https://www.${firstVanityUrl}`,
    address: {
      '@type': 'PostalAddress',
      streetAddress: address1,
      addressLocality: city,
      addressRegion: stateProvince,
      postalCode: postalCode,
      addressCountry: 'US',
    },
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: 'Featured Homes',
      itemListElement: featuredHomesStructuredData(featuredHomes),
    },
    contactPoint: [
      {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        telephone: phoneNumber,
        email: `mailto:${emailAddress}`,
      },
    ],
    logo: `${logoReference}?width=250`,
  };

  return contactUsPageSchema;
};

export const aboutUsPageStructuredData = (
  {
    dealerName,
    emailAddress,
    firstVanityUrl,
    address1,
    city,
    stateProvince,
    postalCode,
    phoneNumber,
    logoReference,
  },
  salesAgents,
  description
) => {
  const dealerFriendlyName = formatDealerName(dealerName) || 'Clayton Homes';
  const aboutUsPageSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: dealerFriendlyName,
    url: `https://www.${firstVanityUrl}/about-us`,
    image: `https://www.${firstVanityUrl}/static/media/about-us-header-image.76f13fb3.jpg`,
    description: description,
    logo: `${logoReference}?width=250`,
    address: {
      '@type': 'PostalAddress',
      streetAddress: address1,
      addressLocality: city,
      addressRegion: stateProvince,
      postalCode: postalCode,
      addressCountry: 'US',
    },
    telephone: phoneNumber,
    email: `mailto:${emailAddress}`,
    provider: employeeStructuredData(salesAgents),
  };

  return aboutUsPageSchema;
};

export const newsStructuredData = (news, { firstVanityUrl }) => {
  const newsItems = news.filter(
    (item) => item.homeCenterWebsiteContentType == 2
  );
  if (!newsItems) {
    return '';
  }
  return (
    newsItems &&
    newsItems.length > 0 &&
    newsItems.map((item) => {
      return {
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        headline: item.title || null,
        image: item.mainImage || null,
        datePublished: item.startDate || null,
        articleBody: item.summary || null,
        url: `https://${firstVanityUrl}/news/${item.slug}` || null,
      };
    })
  );
};

export const eventsStructuredData = (
  events,
  { dealerName, firstVanityUrl, address1, city, stateProvince, postalCode }
) => {
  const eventsItems = events.filter(
    (item) => item.homeCenterWebsiteContentType == 1
  );
  if (!eventsItems) {
    return '';
  }
  return (
    eventsItems &&
    eventsItems.length > 0 &&
    eventsItems.map((item) => {
      return {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: item.title,
        image: item.mainImage,
        startDate: item.startDate,
        endDate: item.endDate,
        description: item.summary,
        organizer: {
          '@type': 'Organization',
          name: formatDealerName(dealerName),
          url: `https://${firstVanityUrl}`,
        },
        location: {
          '@type': 'Place',
          name: formatDealerName(dealerName),
          address: {
            '@type': 'PostalAddress',
            streetAddress: address1,
            addressLocality: city,
            postalCode: postalCode,
            addressRegion: stateProvince,
            addressCountry: 'US',
          },
        },
      };
    })
  );
};

export const welcomeHomePageStructuredData = ({
  welcomeHomeImages,
  firstVanityUrl,
}) => {
  const whImgs = () => {
    const filteredImgs = welcomeHomeImages.filter(
      (item) => item.isShownInGallery
    );
    return filteredImgs;
  };
  const formattedName = (fName, lName) => {
    return `${fName} ${lName.substr(0, 1) + '.'}`;
  };
  const welcomeHomePageSchema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: whImgs().map((item, i) => {
      return {
        '@type': 'ListItem',
        position: i + 1,
        item: {
          name: formattedName(item.firstName, item.lastName),
          image: item.imageReference,
          url: `https://www.${firstVanityUrl}//our-homeowners`,
        },
      };
    }),
  };

  return welcomeHomePageSchema;
};

let zIndexKeeper = 25;

const getIndex = () => {
  return (zIndexKeeper += 25);
};

const zIndices = {
  dropDownMenu: getIndex(),
  dropDownMenuModal: getIndex(),
  mobileDropDownMenu: getIndex(),
  scrollToTopButton: getIndex(),
  modal: getIndex(),
};

let _previous;

export const setAbove = (value) => {
  if (!value) {
    value = _previous;
  }

  _previous = value + 1;
  return _previous;
};

export const setBelow = (value) => {
  if (!value) {
    value = _previous;
  }

  _previous = value - 1;
  return _previous;
};

export const setBetween = (value1, value2) => {
  _previous = parseInt(value1 + (value1 - value2) / 2);
  return _previous;
};

export const setSameAs = (value) => {
  if (!value) {
    return _previous;
  }

  _previous = value;
  return _previous;
};

export default zIndices;

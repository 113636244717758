import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { fireSegmentEvent } from '../../../utils';
import ThreeUpStyles from './ThreeUp.styled';

export const ThreeUp = ({
  items,
  threeUpHeadline,
  disclaimer,
  secondDisclaimer,
  dealer,
  homes,
}) => {
  return (
    <ThreeUpStyles>
      <div className="container">
        <h2 className="three-up-headline">{threeUpHeadline}</h2>
        {items && (
          <div className="three-up-items">
            {items.map((item, i) => {
              return (
                <div key={i} className="item">
                  {item.image && (
                    <img
                      className="logo"
                      src={item?.image}
                      alt={item?.alt}
                      loading="lazy"
                    />
                  )}
                  <p className="headline h4">{item.headline}</p>
                  <div className="horizontal-bar" />
                  <p className="content">{item.content}</p>
                  <div className="btn-wrap">
                    {item.buttonURL && item.buttonText && (
                      <Link
                        to={item.buttonURL}
                        className={
                          item.gaClass
                            ? 'cta-button ' + item.gaClass
                            : 'cta-button'
                        }
                        onClick={() => {
                          fireSegmentEvent('CTA Clicked', {
                            isNav: false,
                            type: 'button',
                            text: item.buttonText ? item.buttonText : '',
                            eventContext: item.retailEventLabel
                              ? item.retailEventLabel
                              : '',
                          });
                        }}
                      >
                        {item.buttonText}
                      </Link>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {disclaimer && <p className="disclaimer">{disclaimer}</p>}
        {secondDisclaimer && <p className="disclaimer">{secondDisclaimer}</p>}
      </div>
    </ThreeUpStyles>
  );
};

ThreeUp.props = {
  items: PropTypes.array,
};

export default ThreeUp;

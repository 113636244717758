import styled from 'styled-components';
import Colors from '../../colors';

import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const HomeStyles = styled.div`
  @media only screen and (min-width: ${variables.tablet_breakpoint}px) {
    margin: 0 auto;
  }
  @media only screen and (min-width: 1024px) {
    margin: 0 auto;
  }
  .spacer {
    margin-bottom: 140px;
    @media only screen and (max-width: 775px) {
      margin-bottom: 48px;
    }
  }
`;

export default HomeStyles;

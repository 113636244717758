import React from 'react';
import PropTypes from 'prop-types';
import { homeDetailsPageStructuredData } from '../../utils/structuredData';

const HomeDetailsPageStructuredData = ({
  homeUrl,
  homeDescription,
  homeCenterName,
  dealerLogo,
  home,
}) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          homeDetailsPageStructuredData(
            homeUrl,
            homeDescription,
            homeCenterName,
            dealerLogo,
            home
          )
        ),
      }}
    ></script>
  );
};

export default HomeDetailsPageStructuredData;

import React from 'react';
import Colors from '../../colors';
import PropTypes from 'prop-types';
import CheckBoxStyles from './CheckBox.styled';
import imgCheckmark from './checkmark.svg';

const CheckBox = React.forwardRef(
  (
    {
      label,
      id = 'check-box-id',
      disabled = false,
      touched,
      primaryColor,
      checked,
      value,
      automationID,
      className,
      compact,
      asRadio,
      errorMessage,
      ...inputProps
    },
    ref
  ) => {
    const propertyClasses = [];
    propertyClasses.push(primaryColor.className);

    const size = compact ? 'compact' : 'standard';

    return (
      <CheckBoxStyles
        className={className + ' ' + propertyClasses.join(' ')}
        imgCheckmark={imgCheckmark}
        size={size}
        role="checkbox"
        aria-checked={checked}
        errorMessage={errorMessage}
      >
        <input
          ref={ref}
          disabled={disabled}
          className="form-checkbox"
          id={id}
          data-test-id={automationID}
          type={asRadio ? 'radio' : 'checkbox'}
          value={value || ''}
          // defaultChecked={checked}
          checked={checked}
          readOnly={true}
          {...inputProps}
        />

        <label tabIndex="0" htmlFor={id}>
          <span className="label">{label}</span>
        </label>
      </CheckBoxStyles>
    );
  }
);

export default CheckBox;

CheckBox.defaultProps = {
  label: '',
  className: '',
  primaryColor: Colors.primary.claytonBlue,
  checked: false,
};

CheckBox.props = {
  label: PropTypes.string,
  primaryColor: PropTypes.oneOf([
    Colors.primary.claytonBlue,
    Colors.secondary.kiddiePool,
    Colors.accent.summerNight,
    Colors.accent.ladyBug,
    Colors.accent.cheesePuff,
    Colors.secondary.rubberDucky,
  ]),
  checked: PropTypes.bool,
  value: PropTypes.string,
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  automationID: PropTypes.string,
  ref: PropTypes.node,
};

import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import React, { useEffect, useRef } from 'react';
import { fireSegmentEvent, formatCurrency } from '../../../utils';
import PriceFilterStyles from './PriceFilter.styled';

export const PriceFilter = ({
  values,
  updateMinPrice,
  updateMaxPrice,
  applyFilter,
  clearFilter,
  className,
}) => {
  const defaultFocusElement = useRef(null);
  const selectablePriceIncrement = 25000;

  useEffect(() => {
    // auto focus when class is set to show
    if (className === 'show') {
      defaultFocusElement.current.focus();
    }
  }, [className]);

  const generateMinPriceValues = () => {
    let content = [];
    for (
      let price = values.priceSort.lowerLimit;
      price < values.priceSort.max;
      price += selectablePriceIncrement
    ) {
      content.push(
        <option value={price} key={'minPrice' + price}>
          {formatCurrency(price)}
        </option>
      );
    }

    return content;
  };

  const generateMaxPriceValues = () => {
    let content = [];
    for (
      let price = values.priceSort.min + selectablePriceIncrement;
      price <= values.priceSort.higherLimit;
      price += selectablePriceIncrement
    ) {
      content.push(
        <option value={price} key={'maxPrice' + price}>
          {formatCurrency(price)}
        </option>
      );
    }

    return content;
  };

  return (
    <PriceFilterStyles className={className}>
      <div className="content-area">
        <select
          ref={defaultFocusElement}
          tabIndex="0"
          name="minPrice"
          id="minPrice"
          value={values.priceSort.min}
          onChange={(e) => {
            updateMinPrice(parseInt(e.target.value));
          }}
        >
          {generateMinPriceValues()}
        </select>
        <div className="dash"></div>
        <select
          tabIndex="0"
          name="maxPrice"
          id="maxPrice"
          value={values.priceSort.max}
          onChange={(e) => {
            updateMaxPrice(parseInt(e.target.value));
          }}
        >
          {generateMaxPriceValues()}
        </select>
      </div>
      <div className="button-area">
        <span
          className="clear-btn"
          tabIndex="0"
          onClick={() => {
            clearFilter('priceSort');
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              clearFilter('priceSort');
            }
          }}
        >
          CLEAR
        </span>
        <Button
          className="ga-filter-price"
          automationId="priceFilterBtn"
          tabIndex="0"
          onClick={() => {
            applyFilter();
            fireSegmentEvent('Facility Model List Filtered', {
              list: 'Homes List',
              filters: [
                {
                  type: 'Price',
                  value:
                    formatCurrency(values?.priceSort?.min) +
                    '-' +
                    formatCurrency(values?.priceSort?.max),
                },
              ],
              eventContext: 'available_homes_filter_price',
            });
          }}
        >
          APPLY
        </Button>
      </div>
    </PriceFilterStyles>
  );
};

export default PriceFilter;

import React, { useEffect, useState } from 'react';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import { Loader } from '@googlemaps/js-api-loader';
import MapMarker from '../../images/map-marker.png';
import PlusCircle from '../../images/svg/plus-circle-32x32.svg';
import MinusCircle from '../../images/svg/minus-circle-32x32.svg';
import { GOOGLE_MAPS_API_TOKEN, GOOGLE_MAPS_STYLES } from '../../utils/config';

/**
 * Send address info to Google Maps API to get a map for a home.
 *
 * @param {string} street_address
 * @param {string} zipcode
 */
const initMap = async (street_address, zipcode) => {
  if (!street_address || !zipcode) {
    throw 'Failed to initialize map: missing parameters';
  }
  const loader = new Loader({
    apiKey: GOOGLE_MAPS_API_TOKEN,
    version: 'weekly',
  });

  await loader.load();

  const geocoder = new google.maps.Geocoder();

  const address = await geocoder.geocode(
    {
      address: `${street_address} ${zipcode}`,
      region: 'US',
    },
    (results, status) => {
      if (status === 'OK') {
        const map = new google.maps.Map(document.getElementById('map'), {
          center: results[0].geometry.location,
          zoom: 11,
          disableDefaultUI: true,
          styles: GOOGLE_MAPS_STYLES,
        });

        const marker = new google.maps.Marker({
          position: results[0].geometry.location,
          map,
          title: 'Home',
          icon: MapMarker,
        });

        const ZoomControl = (controlDiv, map) => {
          controlDiv.style.padding = '30px';

          const controlWrapper = document.createElement('div');
          controlWrapper.style.backgroundColor = 'none';
          controlWrapper.style.borderStyle = 'none';
          controlWrapper.style.borderColor = 'none';
          controlWrapper.style.borderWidth = '0';
          controlWrapper.style.cursor = 'pointer';
          controlWrapper.style.textAlign = 'center';
          controlWrapper.style.width = '32px';
          controlWrapper.style.height = '74px';
          controlDiv.appendChild(controlWrapper);

          const zoomInButton = document.createElement('div');
          zoomInButton.style.width = '32px';
          zoomInButton.style.height = '32px';
          zoomInButton.style.backgroundColor = '#ffffff';
          zoomInButton.style.borderRadius = '50%';
          zoomInButton.style.marginBottom = '10px';
          zoomInButton.style.boxShadow = '0px 4px 16px rgba(0, 0, 0, 0.2)';
          zoomInButton.style.backgroundImage = `url(${PlusCircle})`;
          controlWrapper.appendChild(zoomInButton);

          const zoomOutButton = document.createElement('div');
          zoomOutButton.style.width = '32px';
          zoomOutButton.style.height = '32px';
          zoomOutButton.style.backgroundColor = '#ffffff';
          zoomOutButton.style.borderRadius = '50%';
          zoomOutButton.style.boxShadow = '0px 4px 16px rgba(0, 0, 0, 0.2)';
          zoomOutButton.style.backgroundImage = `url(${MinusCircle})`;
          controlWrapper.appendChild(zoomOutButton);

          google.maps.event.addDomListener(zoomInButton, 'click', () => {
            map.setZoom(map.getZoom() + 1);
          });

          google.maps.event.addDomListener(zoomOutButton, 'click', () => {
            map.setZoom(map.getZoom() - 1);
          });
        };

        marker.setMap(map);

        let zoomControlDiv = document.createElement('div');
        let zoomControl = new ZoomControl(zoomControlDiv, map);

        zoomControlDiv.index = 1;
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
          zoomControlDiv
        );
      } else {
        console.log(status);
        // show failure message
      }
    }
  );
};

const GoogleMap = ({ dealer, home }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await initMap(home.postalCode, home.address1);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        console.error(error);
      }
    })();
  }, []);

  if (isError) {
    return <></>;
  }

  return <div id="map"></div>;
};

export default GoogleMap;

import React, { useState, useRef, useEffect } from 'react';
import HomeListFilterStyles from './HomeListFilter.styled';
import SelectableCard from '../SmokeyTransitional/components/SelectableCard/SelectableCard';
import LandOptionsFilter from './LandOptionsFilter';
import LandFilter from './LandFilter';
import AvailabilityFilter from './AvailabilityFilter';
import FeaturesFilter from './FeaturesFilter';
import NameFilter from './NameFilter';
import PriceFilter from './PriceFilter';
import BedsBathsFilter from './BedsBathsFilter';
import SizeFilter from './SizeFilter';
import useClickOutside from '../../../hooks/useClickOutside';
import { checkOverflow } from '../../Shared/SmokeyTransitional/utils/utils';
import { formatCurrency } from '../../../utils';
import ChevronDown from '../../../images/svg/icons/ChevronDown';

const HomeListFilter = ({
  filters,
  updateFilters,
  clearFilter,
  isFilterActive,
  isLandPage,
  homes,
  hasActiveFilters,
}) => {
  // setMode to indicate which filter control is active/not active
  const [mode, setMode] = useState(null);
  const [scrollClass, setScrollClass] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  // detect any click outside the filter forms (inside flyout container)
  // and close any open flyout
  // container for our filter flyouts (various filter forms)
  const flyoutContainer = useRef(null);

  function outsideClick() {
    setMode(null);
  }

  useClickOutside(flyoutContainer, outsideClick);

  // tempFilter holds values as you change them
  // when user hits apply button, we actually update the homes by calling 'updateFilters'
  const [tempFilter, setTempFilter] = useState(filters);
  useEffect(() => {
    setTempFilter(filters);
    setMode(null);
  }, [filters]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Store scroll position on page unload
      window.addEventListener('beforeunload', () => {
        localStorage.setItem('scrollPosition', window.scrollY);
      });
    }
  }, [typeof window !== 'undefined' && window.scrollY]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        setScrollPosition(window.scrollY);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [typeof window !== 'undefined' && window.scrollY]);

  //add dark overlay to background of homelist container
  useEffect(() => {
    document
      .getElementById('maincontent')
      .classList.toggle('dark-overlay', mode);
  }, [mode]);

  useEffect(() => {
    const storedScrollPosition = localStorage.getItem('scrollPosition');
    window.addEventListener('scroll', () => {
      setScrollClass(storedScrollPosition > 200 || scrollPosition > 200);
    });
  }, [scrollPosition, typeof window !== 'undefined' && window.scrollY]);

  // call this as the user changes filter values to update our tempFilters
  const updateTempFilter = (value) => {
    setTempFilter({ ...tempFilter, ...value });
  };

  const applyFilter = (changes) => {
    const final = { ...changes, ...tempFilter };

    updateFilters(final);
    setTempFilter(final);
    setMode(null);
  };

  // TODO: add functions for each updatable field.
  // Here are a few examples of updating filter functions.

  const updateMinPrice = (value) => {
    updateTempFilter({
      priceSort: {
        ...filters.priceSort,
        min: value,
        max: tempFilter.priceSort.max,
      },
    });
  };

  const updateMaxPrice = (value) => {
    updateTempFilter({
      priceSort: {
        ...filters.priceSort,
        min: tempFilter.priceSort.min,
        max: value,
      },
    });
  };

  const updateMinBeds = (value) => {
    updateTempFilter({
      beds: {
        ...filters.beds,
        min: value,
        max: tempFilter.beds.max,
      },
    });
  };

  const updateMaxBeds = (value) => {
    updateTempFilter({
      beds: {
        ...filters.beds,
        min: tempFilter.beds.min,
        max: value,
      },
    });
  };

  const updateMinBaths = (value) => {
    updateTempFilter({
      baths: {
        ...filters.baths,
        min: value,
        max: tempFilter.baths.max,
      },
    });
  };

  const updateMaxBaths = (value) => {
    updateTempFilter({
      baths: {
        ...filters.baths,
        min: tempFilter.baths.min,
        max: value,
      },
    });
  };

  const updateMinSquareFeet = (value) => {
    updateTempFilter({
      squareFeet: {
        ...filters.squareFeet,
        min: value,
        max: tempFilter.squareFeet.max,
      },
    });
  };

  const updateMaxSquareFeet = (value) => {
    updateTempFilter({
      squareFeet: {
        ...filters.squareFeet,
        min: tempFilter.squareFeet.min,
        max: value,
      },
    });
  };

  const updateMinAcres = (value) => {
    updateTempFilter({
      acres: {
        ...filters.acres,
        min: value,
        max: tempFilter.acres.max,
      },
    });
  };

  const updateMaxAcres = (value) => {
    updateTempFilter({
      acres: {
        ...filters.acres,
        min: tempFilter.acres.min,
        max: value,
      },
    });
  };

  const updateSections = (value) => {
    if (value == 'all') {
      updateTempFilter({
        isMultiSection: null,
        isCrossMod: null,
        isSingleSection: null,
      });
    }
    if (value == 'single') {
      updateTempFilter({
        isMultiSection: false,
        isCrossMod: false,
        isSingleSection: true,
      });
    }
    if (value == 'multi') {
      updateTempFilter({
        isMultiSection: true,
        isCrossMod: null,
        isSingleSection: null,
      });
    }
    if (value == 'crossmod') {
      updateTempFilter({
        isMultiSection: null,
        isCrossMod: true,
        isSingleSection: null,
      });
    }
  };

  const updateAvailability = (value) => {
    if (value == 'move in ready') {
      updateTempFilter({
        isOnLand: true,
        isLand: null,
        isInStock: null,
      });
    }
    if (value == 'in stock') {
      updateTempFilter({
        isOnLand: null,
        isLand: null,
        isInStock: true,
      });
    }
    if (value == 'land only') {
      updateTempFilter({
        isOnLand: null,
        isLand: true,
        isInStock: null,
      });
    }
  };
  const updateIsLand = (value) => {
    updateTempFilter({
      isLand: value,
    });
  };

  const updateIsOnLand = (value) => {
    updateTempFilter({
      isOnLand: value,
    });
  };

  const toggleLandVsHomeOnLand = (value) => {
    updateTempFilter({
      isOnLand: value,
      isLand: !value,
    });
  };

  const updateIsInStock = (value) => {
    updateTempFilter({
      isInStock: value || null,
    });
  };

  const updateName = (value) => {
    updateTempFilter({ text: value });
  };

  const featureShouldChange = (featureName) => {
    let newSelections = [...tempFilter.features.selected];
    if (newSelections.includes(featureName)) {
      newSelections.splice(newSelections.indexOf(featureName), 1);
    } else {
      newSelections.push(featureName);
    }

    updateTempFilter({
      features: {
        ...tempFilter.features,
        selected: newSelections,
      },
    });
  };

  const overflowcheck = checkOverflow();

  const bedsAndBaths = () => {
    if (isFilterActive('beds') || isFilterActive('baths')) {
      return (
        (filters.beds.min === 0 ? '' : filters.beds.min + '+ Beds') +
        (filters.beds.min > 0 && filters.baths.min > 0 ? ', ' : '') +
        (filters.baths.min === 0 ? '' : filters.baths.min + '+ Baths')
      );
    } else {
      return 'Beds & Baths';
    }
  };

  return (
    <HomeListFilterStyles>
      <div className="filter-container" ref={flyoutContainer}>
        <div
          className={
            overflowcheck ? 'filter-controls scroller' : 'filter-controls'
          }
          id="filter-controls"
        >
          <div className="filter-by-label">FILTER BY:</div>

          <div>
            <SelectableCard
              icon={
                isFilterActive('priceSort') || mode === 'price' ? (
                  <ChevronDown color="white" width="10" height="6" />
                ) : (
                  <ChevronDown color="black" width="10" height="6" />
                )
              }
              tabIndex="0"
              className={`${mode === 'price' && 'focused'}`}
              text={
                isFilterActive('priceSort')
                  ? `${formatCurrency(
                      filters.priceSort.min
                    )} - ${formatCurrency(filters.priceSort.max)}`
                  : 'Price'
              }
              isSelected={isFilterActive('priceSort') ? 'true' : false}
              onCardClick={() => {
                setMode(mode === 'price' ? null : 'price');
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  setMode(mode === 'price' ? null : 'price');
                }
              }}
            />
            <PriceFilter
              values={tempFilter}
              updateMinPrice={updateMinPrice}
              updateMaxPrice={updateMaxPrice}
              applyFilter={applyFilter}
              clearFilter={clearFilter}
              className={
                'flyout ' +
                (mode === 'price' ? 'show' : 'hide') +
                (scrollClass ? ' scrollTop' : '')
              }
            />
          </div>

          <div>
            <SelectableCard
              icon={
                isFilterActive('beds') ||
                isFilterActive('baths') ||
                mode === 'bedsbaths' ? (
                  <ChevronDown color="white" width="10" height="6" />
                ) : (
                  <ChevronDown color="black" width="10" height="6" />
                )
              }
              tabIndex="0"
              text={bedsAndBaths()}
              className={`${mode === 'bedsbaths' && 'focused'}`}
              isSelected={
                isFilterActive('beds') || isFilterActive('baths')
                  ? 'true'
                  : false
              }
              onCardClick={() => {
                setMode(mode === 'bedsbaths' ? null : 'bedsbaths');
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  setMode(mode === 'bedsbaths' ? null : 'bedsbaths');
                }
              }}
            />
            <BedsBathsFilter
              values={tempFilter}
              updateMinBeds={updateMinBeds}
              updateMaxBeds={updateMaxBeds}
              updateMinBaths={updateMinBaths}
              updateMaxBaths={updateMaxBaths}
              applyFilter={applyFilter}
              clearFilter={clearFilter}
              className={
                'flyout ' +
                (mode === 'bedsbaths' ? 'show' : 'hide') +
                (scrollClass ? ' scrollTop' : '')
              }
            />
          </div>

          <div>
            <SelectableCard
              icon={
                isFilterActive('isInStock') ||
                isFilterActive('isOnLand') ||
                isFilterActive('isLand') ||
                mode === 'availability' ? (
                  <ChevronDown color="white" width="10" height="6" />
                ) : (
                  <ChevronDown color="black" width="10" height="6" />
                )
              }
              tabIndex="0"
              className={`${mode === 'availability' && 'focused'}`}
              text={'Availability'}
              isSelected={
                isFilterActive('isInStock') ||
                isFilterActive('isOnLand') ||
                isFilterActive('isLand')
                  ? 'true'
                  : false
              }
              onCardClick={() => {
                setMode(mode === 'availability' ? null : 'availability');
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  setMode(mode === 'availability' ? null : 'availability');
                }
              }}
            />
            <AvailabilityFilter
              values={tempFilter}
              updateAvailability={updateAvailability}
              applyFilter={applyFilter}
              clearFilter={clearFilter}
              className={
                'flyout ' +
                (mode === 'availability' ? 'show' : 'hide') +
                (scrollClass ? ' scrollTop' : '')
              }
            />
          </div>

          <div>
            <SelectableCard
              icon={
                isFilterActive('isCrossMod') ||
                isFilterActive('squareFeet') ||
                isFilterActive('isSingleSection') ||
                isFilterActive('isMultiSection') ||
                isFilterActive('acres') ||
                mode === 'size' ? (
                  <ChevronDown color="white" width="10" height="6" />
                ) : (
                  <ChevronDown color="black" width="10" height="6" />
                )
              }
              tabIndex="0"
              text="Size"
              className={`${mode === 'size' && 'focused'}`}
              isSelected={
                isFilterActive('isCrossMod') ||
                isFilterActive('squareFeet') ||
                isFilterActive('isSingleSection') ||
                isFilterActive('isMultiSection') ||
                isFilterActive('acres')
                  ? 'true'
                  : false
              }
              onCardClick={() => {
                setMode(mode === 'size' ? null : 'size');
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  setMode(mode === 'size' ? null : 'size');
                }
              }}
            />
            <SizeFilter
              values={tempFilter}
              updateMinSquareFeet={updateMinSquareFeet}
              updateMaxSquareFeet={updateMaxSquareFeet}
              updateSections={updateSections}
              updateMinAcres={updateMinAcres}
              updateMaxAcres={updateMaxAcres}
              applyFilter={applyFilter}
              clearFilter={clearFilter}
              className={
                'flyout ' +
                (mode === 'size' ? 'show' : 'hide') +
                (scrollClass ? ' scrollTop' : '')
              }
            />
          </div>

          {/* {isLandPage && (
            <div>
              <SelectableCard
                icon={
                  isFilterActive('isOnLand') ||
                  isFilterActive('isLand') ||
                  mode === 'landOptions' ? (
                    <ChevronDown color="white" width="10" height="6" />
                  ) : (
                    <ChevronDown color="black" width="10" height="6" />
                  )
                }
                tabIndex="0"
                className={`${mode === 'landOptions' && 'focused'}`}
                text="Land Options"
                isSelected={
                  isFilterActive('isOnLand') || isFilterActive('isLand')
                    ? 'true'
                    : false
                }
                onCardClick={() => {
                  setMode(mode === 'landOptions' ? null : 'landOptions');
                }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    setMode(mode === 'landOptions' ? null : 'landOptions');
                  }
                }}
              />
              <LandOptionsFilter
                values={tempFilter}
                toggleLandVsHomeOnLand={toggleLandVsHomeOnLand}
                updateIsLand={updateIsLand}
                updateIsOnLand={updateIsOnLand}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
                className={
                  'flyout ' + (mode === 'landOptions' ? 'show' : 'hide') + (scrollClass ? ' scrollTop' : '')
                }
              />
            </div>
          )} */}

          <div>
            <SelectableCard
              icon={
                isFilterActive('features') || mode === 'features' ? (
                  <ChevronDown color="white" width="10" height="6" />
                ) : (
                  <ChevronDown color="black" width="10" height="6" />
                )
              }
              tabIndex="0"
              className={`${mode === 'features' && 'focused'}`}
              text={
                isFilterActive('features')
                  ? `Features (${filters.features.selected.length})`
                  : 'Features'
              }
              isSelected={isFilterActive('features') ? 'true' : false}
              onCardClick={() => {
                setMode(mode === 'features' ? null : 'features');
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  setMode(mode === 'features' ? null : 'features');
                }
              }}
            />
            <FeaturesFilter
              values={tempFilter}
              featureShouldChange={featureShouldChange}
              applyFilter={applyFilter}
              clearFilter={clearFilter}
              className={
                'flyout ' +
                (mode === 'features' ? 'show' : 'hide') +
                (scrollClass ? ' scrollTop' : '')
              }
            />
          </div>

          <div>
            <SelectableCard
              icon={
                isFilterActive('text') || mode === 'name' ? (
                  <ChevronDown color="white" width="10" height="6" />
                ) : (
                  <ChevronDown color="black" width="10" height="6" />
                )
              }
              tabIndex="0"
              text="Model"
              className={`${mode === 'name' && 'focused'}`}
              isSelected={isFilterActive('text') ? 'true' : false}
              onCardClick={() => {
                setMode(mode === 'name' ? null : 'name');
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  setMode(mode === 'name' ? null : 'name');
                }
              }}
            />
            <NameFilter
              values={tempFilter}
              updateName={updateName}
              applyFilter={applyFilter}
              clearFilter={clearFilter}
              className={
                'flyout ' +
                (mode === 'name' ? 'show' : 'hide') +
                (scrollClass ? ' scrollTop' : '')
              }
              homes={homes}
            />
          </div>
          {hasActiveFilters && (
            <span
              className="clear-filters"
              onClick={() => {
                clearFilter('isLand');
                clearFilter('isOnLand');
                clearFilter('isInStock');
                clearFilter('priceSort');
                clearFilter('beds');
                clearFilter('baths');
                clearFilter('features');
                clearFilter('text');
                clearFilter('squareFeet');
                clearFilter('isMultiSection');
                clearFilter('isSingleSection');
                clearFilter('isCrossMod');
                clearFilter('acres');
              }}
            >
              Clear Filters
            </span>
          )}
          <div className="mobile-spacer"></div>
        </div>
      </div>
    </HomeListFilterStyles>
  );
};

export default HomeListFilter;

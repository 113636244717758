import styled from 'styled-components';

const HomeListSortStyles = styled.div`
  @media only screen and (min-width: 320px) {
    text-align: right;
    font: 700 10px/100% source-sans-pro, sans-serif;
    color: #4e5255;
    letter-spacing: 0.5px;
    align-items: center;
    display: flex;
    width: 235px;
  }

  @media only screen and (max-width: 768px) {
    justify-content: flex-end;
  }

  @media only screen and (min-width: 768px) {
    text-align: left;
    font-size: 12px;
    padding-left: 5px;
  }

  .dropdown-selected-text {
    padding-right: 20px;
  }
  .dropdown-list.compact .dropdown-chevron svg path {
    fill: black !important;
  }
  .dropdown-list {
    margin-left: 5px;

    /* @media only screen and (min-width: 320px) {
      width: 135px;
    }

    @media only screen and (min-width: 768px) {
      width: 159px;
    } */

    #flavor-drop-down-error-button {
      border: none;
      padding: 0;
      font: 700 12px/100% source-sans-pro, sans-serif;
      color: #4e5255;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      height: auto;

      label {
        font: 700 12px/100% source-sans-pro, sans-serif;
        color: #0075c9;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }

      span {
        color: #0075c9;
        font: 700 12px/100% source-sans-pro, sans-serif;
        overflow: visible;
        width: 100%;

        @media only screen and (min-width: 320px) {
          font-size: 10px;
        }

        @media only screen and (min-width: 768px) {
          font-size: 12px;
        }
      }
    }

    &.compact {
      .dropdown-chevron {
        top: -3px;
        right: 1px;
        svg {
          path {
            fill: #0075c9;
          }
        }
      }
    }

    .dropdown-display {
      padding: 10px 0;
      border: none;

      li {
        font: 700 12px/100% 'Source Sans Pro', sans-serif;
        width: 93%;
        margin: 0 auto;

        &:hover {
          background: rgba(0, 117, 201, 0.1) !important;
          border-radius: 4px;
        }

        &.selected {
          background: transparent;
          color: #0075c9;

          span {
            margin-left: 8px;
            @media only screen and (min-width: 1024px) {
              margin-left: 0;
            }
          }
          svg {
            width: 20px;
            height: 20px;
            @media only screen and (min-width: 1024px) {
              right: -2px;
            }

            path {
              fill: #0075c9;
            }
          }
        }
      }
    }

    &.open {
      .dropdown-menu {
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        @media only screen and (min-width: 1024px) {
          top: 0;
        }

        @media only screen and (min-width: 769px) {
          min-width: 170px;
          left: auto !important;
          right: 0;
        }
      }
    }
  }
`;

export default HomeListSortStyles;

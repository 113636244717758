import styled from 'styled-components';
import Colors from '../../../colors';

export const SaleHomeCardStyles = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 30px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 0px solid transparent;

  @media screen and (min-width: 900px) {
    height: 440px;
    padding: 24px;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  &:focus-within {
    .slider-btn-prev,
    .slider-btn-next {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  .favoriting-heart-container {
    position: absolute;
    top: 10px;
    right: 10px;
    left: auto;
    height: 33px;
    width: 33px;
    z-index: 9;
    .favorite-button {
      position: relative;
      background: white;
      height: 33px;
      width: 33px;
      border: 0px solid transparent;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
    }
  }

  .sticker {
    color: white;
    background-color: #00a3a0;
    font-family: source-sans-pro;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 12px;

    .in-stock-dot {
      display: none;
    }
  }

  .price-tooltip {
    flex-shrink: 0;
  }

  .card-heading {
    font-family: 'acumin-pro';
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.35px;
    margin: 10px 0px 0px;
    max-width: 500px;
    color: #25292d;
  }

  .card-home-specs {
    font-family: 'source-sans-pro';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #4e5255;
    list-style: none;
    display: flex;
    width: 100%;
    margin-top: 30px;
    li {
      display: flex;
      align-items: center;
      img {
        margin-right: 7px;
      }
      &:not(:last-child) {
        margin-right: 5px;
        padding-right: 5px;
        @media screen and (min-width: 900px) {
          margin-right: 10px;
          padding-right: 10px;
          border-right: 1px solid ${Colors.accent.grey5.standard};
        }
        @media screen and (min-width: 1100px) {
          margin-right: 33px;
          padding-right: 33px;
        }
      }
    }
  }

  .price {
    font-family: 'source-sans-pro';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 29px;
    color: #25292d;
  }

  .price-contact-us {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-family: 'source-sans-pro';
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #0075c9;
    font-weight: 600;
    flex-shrink: 0;
  }

  .sticker {
    color: white !important;
    background-color: #0075c9 !important;
  }

  .sticker-sale,
  .sticker-price-drop {
    background-color: #ef4b5d !important;
  }

  .sticker-in-stock {
    background-color: #00a3a0 !important;
  }

  .orange-sticker {
    background-color: ${Colors.accent.cheesePuff.standard} !important;
  }

  .purple-sticker {
    background-color: #7e71c1 !important;
  }

  .slider-wrapper {
    border-radius: 6px;
  }

  .info-circle {
    margin-left: 0px;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    filter: invert(11%) sepia(11%) saturate(844%) hue-rotate(164deg)
      brightness(96%) contrast(87%);
  }

  .phone-icon {
    margin-right: 1px;
    filter: invert(26%) sepia(46%) saturate(4667%) hue-rotate(189deg)
      brightness(95%) contrast(101%);
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }

  .control-dots {
    bottom: -4px;
    z-index: 7;

    .selected {
      opacity: 1 !important;
    }

    .dot {
      width: 7px;
      height: 7px;
      margin: 0 6px;
      opacity: 0.6;
      box-shadow: none !important;

      .selected {
        opacity: 1 !important;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .slide-link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .carousel-root,
  .carousel,
  .slider-wrapper,
  .slider,
  .slide,
  .slide div {
    height: 100%;
  }

  .slider-btns {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 8px 7px 0 0;
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: 38px;
    height: 38px;
    img {
      height: 38px;
      display: block;
    }
  }

  .slider-btn-prev {
    left: 7px;
    margin: 0;
    opacity: 0;
    transition: 0.2s;
    transform: translateX(-10px);
  }

  .slider-btn-next {
    right: 7px !important;
    margin: 0;
    opacity: 0;
    transition: 0.2s;
    transform: translateX(10px);
  }

  .carousel {
    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 60px;
      background: linear-gradient(
        180deg,
        rgba(156, 156, 156, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
      );
      content: '';
    }
    &:hover {
      .slider-btn-prev,
      .slider-btn-next {
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }

  .price-tooltip {
    position: relative;
    cursor: pointer;
  }

  .tippy-content {
    padding: 30px;
    .close-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }

  [data-tippy-root] {
    max-width: 90%;
  }

  [data-theme='clayton'] {
    background-color: ${Colors.primary.claytonBlue.standard};
    p {
      color: white;
      font-size: 14px;
      line-height: 22px;
    }
  }

  [data-theme='clayton'][data-animatefill] {
    background-color: transparent;
  }

  [data-theme='clayton'] .tippy-backdrop {
    background-color: ${Colors.primary.claytonBlue.standard};
  }

  .tippy-arrow {
    color: ${Colors.primary.claytonBlue.standard};
  }

  .features-toolip-ul {
    position: relative;
    list-style: none;
    li {
      color: white;
    }
  }

  .sticker-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    z-index: 8;
    .sticker {
      background-color: #fff;
      border-radius: 30px;
      color: ${Colors.primary.black.standard};
      padding: 5px 8px;
      margin-left: 7px;
    }
  }

  .card-image-area {
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 0;

    @media screen and (min-width: 900px) {
      max-width: 564px;
    }

    .sticker-container {
      position: absolute;
      left: 0px;
      top: 10px;
      display: none;
      @media screen and (max-width: 900px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }

    .loading-block {
      height: 100%;
      width: 100%;
      background-color: ${Colors.accent.grey5.standard};
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .slide img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .aspect-ratio-sizer {
      width: 100%;
    }

    & > div {
      position: absolute;
      top: 0;
      left: 0;
    }

    .card-image-single {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        border-radius: 6px;
        object-fit: cover;
      }
    }
  }

  .card-info-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    padding: 10px;

    @media screen and (min-width: 900px) {
      padding: 10px 10px 10px 40px;
    }

    .sticker-container {
      display: flex;
      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    .card-info-link {
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .card-preheading {
      font-family: 'source-sans-pro', sans-serif;
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: ${Colors.primary.claytonBlue.standard};
      display: block;
    }

    .bullet {
      color: #cacccf;
      padding: 4px;
      font-family: arial;
    }

    .price {
      position: relative;
      color: ${Colors.primary.black.standard};
      margin-top: -3px;
      padding-top: 10px;

      .price-detail {
        font-size: 12px;
        line-height: 12px;
        font-family: 'source-sans-pro';
        font-weight: 700;
        margin-left: 10px;
      }

      .label {
        margin: 0;
        padding: 0;
      }
      .intro {
        margin-top: -3px;
      }
    }
  }

  .lazy-load-image-background {
    height: 100%;
    width: 100%;
  }

  .sale-card-features {
    position: relative;
    margin-bottom: 20px;
    padding-top: 37px;
    max-width: 590px;
    pointer-events: none;
    @media screen and (min-width: 900px) {
      border-top: 1px solid ${Colors.accent.grey5.standard};
    }
    .h4 {
      margin-bottom: 0px;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.5px;
      font-weight: 700;
      text-transform: uppercase;
      font-family: 'source-sans-pro';
      font-stretch: normal;
      font-style: normal;
      color: #25292d;
    }
    .features-ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      .tippy-box {
        pointer-events: all;
      }
      li {
        flex: 0;
        flex-basis: 33.33%;
        line-height: 1.2;
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;

        &:first-letter {
          text-transform: capitalize;
        }
        @media screen and (min-width: 768px) {
          padding-right: 33px;
        }
        @media screen and (max-width: 768px) {
          flex-basis: 33.33%;
        }
        @media screen and (max-width: 500px) {
          flex-basis: 50%;
        }
      }
    }
    .see-more-features {
      color: ${Colors.primary.claytonBlue.standard};
      font-family: 'source-sans-pro';
      cursor: pointer;
      pointer-events: all;
    }
    .tippy-content {
      padding: 25px 15px 15px;
    }
  }
`;

import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const TextWithImageStyles = styled.section`
  position: relative;
  padding: 20px 0px 40px;
  z-index: 4;
  .container {
    position: relative;
    width: 100%;
    max-width: 1304px;
    margin: 0 auto;
    padding: 0px 40px;
    display: flex;
    @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
      max-width: 513px;
      flex-direction: column;
    }
    @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
      padding: 0px 20px;
    }
    .text-wrap {
      position: relative;
      display: flex;
      justify-content: center;
      flex: auto;
      align-items: ${(props) => (props.overlapImage ? 'center' : 'initial')};
      @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
        order: 2;
      }
      .text {
        padding-top: ${(props) => (props.overlapImage ? '0px' : '50px')};
        max-width: ${(props) => (props.overlapImage ? '620px' : '520px')};
        @media only screen and (max-width: 768px) {
          padding-top: 50px;
        }
        @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
          padding-right: 30px;
          min-width: 390px;
        }
        .small-headline {
          display: block;
          color: #6a6f72;
          font-family: 'source-sans-pro';
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 12px;
          letter-spacing: 0.5px;
          text-align: left;
          text-transform: uppercase;
          margin-bottom: 7px;
        }
        .large-headline {
          margin-bottom: 20px;
          max-width: ${(props) =>
            props.headlineWidth ? props.headlineWidth : '370px'};
          @media only screen and (max-width: 769px) {
            font-size: 40px;
            line-height: 48px;
          }
          @media only screen and (max-width: 553px) {
            font-size: 34px;
            line-height: 40px;
          }
        }
        .content {
          color: #25292d;
        }
        .line {
          display: flex;
          margin-top: 40px;
          width: 105px;
          height: 2px;
          background: ${Colors.accent.grey4.standard};
        }
        small {
          color: ${Colors.accent.grey1.standard};
          font-family: 'source-sans-pro';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0px;
          text-align: left;
          margin-bottom: 0px;
          margin-top: 20px;
          display: block;
        }
      }
    }
    .image-box {
      position: relative;
      height: ${(props) => (props.overlapImage ? '656px' : '500px')} !important;
      /* max-width: 500px; */
      max-width: 100%;
      width: 500px;
      background-color: ${Colors.accent.grey5.standard};
      margin-top: ${(props) => (props.overlapImage ? '-100px' : 'auto')};
      @media only screen and (max-width: 768px) {
        width: ${(props) => (props.overlapImage ? '352px' : '100%')};
        margin-left: auto;
        margin-right: auto;
        height: ${(props) =>
          props.overlapImage ? '468px' : '325px'} !important;
      }

      @media only screen and (max-width: 553px) {
        margin-top: ${(props) => (props.overlapImage ? '-50px' : 'auto')};
      }

      span,
      img {
        height: 100%;
        width: 100%;
      }
      img {
        object-fit: cover;
      }
    }
    .cta-button {
      background: ${Colors.primary.claytonBlue.standard};
      color: white;
      border-radius: 2px;
      padding: 17px 17px;
      font: 500 12px/100% 'source-sans-pro', sans-serif;
      margin-top: 50px;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.2s;
      text-transform: uppercase;
      font-weight: 700;
      display: inline-flex;
      align-self: center;
      &:hover {
        background: rgb(23, 158, 255);
      }
    }
  }
  .image-on-top {
    @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
      flex-direction: column-reverse;
      .line {
        margin-top: 28px !important;
      }
      .cta-button {
        margin-top: 20px;
      }
      .image-box {
        margin-top: 70px;
      }
    }
  }
`;

export default TextWithImageStyles;

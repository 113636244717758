import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import infoCircle from '../../images/svg/iconography-16x16/info-circle.svg';
import closeBtn from '../../images/svg/close.svg';

const TooltipIcon = () => {
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const showTooltip = () => setVisibleTooltip(true);
  const hideTooltip = () => setVisibleTooltip(false);
  return (
    <Tippy
      interactive
      theme="clayton"
      visible={visibleTooltip}
      onClickOutside={hideTooltip}
      placement="bottom"
      content={
        <>
          <img
            className="close-btn"
            onClick={visibleTooltip ? hideTooltip : showTooltip}
            src={closeBtn}
            alt="close tooltip"
          />
          <p>
            Base model starting prices do not include available options,
            required delivery & installation or taxes. Installed price will be
            higher. See home details for pricing details and home information.
          </p>
        </>
      }
    >
      <img
        className="info-circle"
        src={infoCircle}
        alt="information icon"
        onClick={visibleTooltip ? hideTooltip : showTooltip}
      />
    </Tippy>
  );
};

export default TooltipIcon;

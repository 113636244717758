import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import HomeCard from '../../Shared/SmokeyTransitional/components/HomeCards/Standard/HomeCard';
import HomeTileStyles from './HomeTile.styled';
import HomeTileModel from './HomeTileModel';
import infoCircle from '../../../images/svg/iconography-16x16/info-circle.svg';
import phoneIcon from '../../../images/svg/iconography-16x16/phone.svg';
import { DXAccountContext } from '../../../contexts/DXAccountContext';

const HomeTile = ({
  retailHomeObject,
  recentlyViewedHomeObject,
  dealer,
  homes,
  homesOffset,
  filters,
  showFloorPlans,
  isFeatured,
  noSwipe,
  ...props
}) => {
  const accountContext = useContext(DXAccountContext);
  // convert our retail site-specific home data into the desired format for
  // the shared HomeCard component
  const favoriteList = accountContext?.state.favorites
    ? accountContext?.state.favorites
    : [];

  const homeObject = recentlyViewedHomeObject
    ? HomeTileModel.initWithRecentlyViewedHomeObject(
        recentlyViewedHomeObject,
        {
          dealer,
          homes,
          homesOffset,
          filters,
        },
        favoriteList
      )
    : HomeTileModel.initWithRetailHomeObject(
        retailHomeObject,
        {
          dealer,
          homes,
          homesOffset,
          filters,
        },
        favoriteList
      );

  const generatePriceOverride = () => {
    // create pricing area markup based on price
    if (homeObject.price.includes('Was')) {
      const parts = homeObject.price.split(' ');
      const oldPrice = parts[1].slice(0, parts[1].length - 1);
      const newPrice = parts[3];
      return (
        <div className="price-wrap">
          <div className="price-top">
            WAS <del>{oldPrice}</del>
          </div>
          <div className="price-bottom">
            {newPrice + ' '}
            <img
              className="info-circle"
              src={infoCircle}
              alt="Pricing Disclaimer Information"
            />
          </div>
        </div>
      );
    }

    if (homeObject.price.includes('Before')) {
      const parts = homeObject.price.split(' ');
      const beforeOptionsPrice = parts[0];
      return (
        <div className="price-wrap">
          <div className="price-top">BEFORE OPTIONS</div>

          <div className="price-bottom">
            {beforeOptionsPrice}
            {!isFeatured && <>*</>}{' '}
            <img
              className="info-circle"
              alt="info circle icon"
              src={infoCircle}
            />
          </div>
        </div>
      );
    }

    if (homeObject.price.includes('Contact Us')) {
      return (
        <Link
          to={{ pathname: '/contact-us', state: { dealer, homes } }}
          className="price-contact-us"
        >
          <img className="phone-icon" alt="phone icon" src={phoneIcon} />
          {homeObject.price}
        </Link>
      );
    }

    if (homeObject.price.startsWith('$')) {
      return (
        <div className="price-bottom">
          {homeObject.price}{' '}
          <img
            className="info-circle"
            alt="info circle icon"
            src={infoCircle}
          />
        </div>
      );
    }
  };
  homeObject.priceOverride = generatePriceOverride;

  const brokerageLink = (email, phone) => {
    let link;
    let mobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        typeof navigator !== 'undefined' && navigator.userAgent
      );

    if ((phone && mobileDevice) || (!email && phone && !mobileDevice)) {
      link = 'tel:' + phone;
    }
    if ((!phone && email && mobileDevice) || (email && !mobileDevice)) {
      link = 'mailto:' + email;
    }
    if (!email && !phone) {
      link = null;
    }

    return link;
  };

  const generateDescription = () => {
    const landDescription =
      (homeObject.address1 ? homeObject.address1 : '') +
      (homeObject.address2 ? ' ' + homeObject.address2 : '');

    const landSubDescription =
      (homeObject.city ? ' ' + homeObject.city : '') +
      (homeObject.stateProvince ? ', ' + homeObject.stateProvince : '') +
      (homeObject.postalCode ? ' ' + homeObject.postalCode : '');

    const brokerageContactLink = brokerageLink(
      homeObject.brokerageEmail,
      homeObject.brokeragePhone
    );

    return (
      <>
        {homeObject && homeObject.brokerageName && brokerageContactLink && (
          <a href={brokerageContactLink} className="card-preheading">
            Contact {homeObject.brokerageName} to purchase
          </a>
        )}

        {homeObject && homeObject.isLand && landDescription && (
          <p className="h5 card-heading">
            {landDescription}
            {landSubDescription && (
              <>
                <br />
                {landSubDescription}
              </>
            )}
          </p>
        )}
        {homeObject && !homeObject.isLand && landDescription && (
          <p className="h5 card-heading">{homeObject.description}</p>
        )}
      </>
    );
  };
  homeObject.descriptionOverride = generateDescription;

  // show floor plan only if desired
  if (showFloorPlans && homeObject.floorPlanImage) {
    homeObject.images = [homeObject.floorPlanImage];
  }

  return (
    <HomeTileStyles className="retail-hometile">
      <HomeCard
        key={homeObject.id}
        className="home-card"
        {...props}
        homeObject={homeObject}
        showFavoritingHeart={true}
        isInStock={true}
        showStockIndicator={true}
        isFavorited={false}
        noSwipe={noSwipe}
        hideSaleSticker={
          dealer?.websiteTemplateDescription === 'BuilderModel' ? true : false
        }
      />
    </HomeTileStyles>
  );
};

export default HomeTile;

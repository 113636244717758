import styled from 'styled-components';
import Colors from '../../colors';

const HomeListStyles = styled.div`
  position: relative;

  .centered-page-content-container {
    width: 90%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .carousel .slide {
    background: transparent;
  }
  @media screen and (max-width: 768px) {
    .filter-controls {
      justify-content: center;
    }
  }

  .btn-next {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    background: #fff;
    border-left: 2px solid #e4e5e7;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .btn-prev {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    background: #fff;
    border-right: 1px solid #e4e5e7;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      display: none;
    }
    svg {
      transform: rotate(180deg);
    }
  }

  .hidden-arrow {
    visibility: hidden !important;
  }

  // .dropdown-list {
  //   width: 90px !important;
  // }

  .mobile-toggle-label {
    @media screen and (max-width: 768px) {
      white-space: nowrap;
    }
  }

  .top-area-container {
    background-color: rgba(228, 229, 231, 0.25);
    position: relative;

    align-content: center;

    .top-area-centered-content {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      flex: 1;
      display: flex;
      justify-content: space-between;
      position: relative;
      flex: 1;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-bottom: 20px;

      @media screen and (min-width: 768px) {
        width: 611px;
      }

      @media screen and (min-width: 1024px) {
        width: 750px;
      }

      @media screen and (min-width: 1277px) {
        width: 1200px;
      }
    }

    #flavor-drop-down-error-button {
      background: transparent !important;
    }

    .filter-arrow {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 9;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    @media screen and (min-width: 1024px) {
      height: 117px;
    }

    .available-homes-counter {
      font-family: 'acumin-pro';
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.3px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 800px) {
        font-size: 14px;
      }

      .counter {
        display: none;
        margin-left: 27px;

        @media screen and (min-width: 768px) {
          display: block;
        }
      }
    }

    @media screen and (max-width: 767px) {
      justify-content: center;
    }
  }

  .top-tools-container {
    position: relative;
    width: 325px;
    margin-left: auto;
    margin-right: auto;
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 20px;

    @media screen and (min-width: 1024px) {
      margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
      width: 511px;
    }

    @media screen and (min-width: 1024px) {
      width: 750px;
      justify-content: start;
    }

    @media screen and (min-width: 1277px) {
      width: 1200px;
    }
  }

  .counter {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    align-content: center;
    margin-right: 80px;

    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  .counter-mobile {
    font-size: 12px;
    font-weight: 300;

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  .sort-wrap {
    justify-content: flex-end;
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  .sort-wrap-mobile {
    justify-content: flex-end;
    // width: unset !important;

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
  .hidden {
    display: none;
  }

  .curated-collection-container {
    padding: 46px 0 63px;
    background-color: #0275c9;

    @media screen and (max-width: 700px) {
      padding: 33px 0 36px;
    }

    .slider-btns {
      background-color: transparent;
      border: none;
      cursor: pointer;
      position: absolute;
      top: calc(50% - 10px);
    }

    .slider-btn-prev {
      left: 70px;
      z-index: 99;

      @media only screen and (max-width: 500px) {
        left: 0;
      }
    }

    .slider-btn-next {
      right: 70px;
      z-index: 9;

      @media only screen and (max-width: 500px) {
        right: 0;
      }
    }

    .find-home-headline {
      color: ${Colors.primary.white.standard};
      font-weight: 700;
      font-size: 40px;
      line-height: 1.2;
      letter-spacing: -0.35px;
      font-family: 'acumin-pro';
      text-align: center;
      margin-bottom: 29px;

      @media screen and (max-width: 700px) {
        font-size: 20px;
        margin-bottom: 24px;
      }
    }

    .list-headline {
      max-width: 1208px;
      margin: 0 auto 31px;
      color: ${Colors.primary.white.standard};
      font-weight: 700;
      font-size: 20px;
      line-height: 1.2;
      letter-spacing: -0.35px;
      font-family: 'acumin-pro';

      @media screen and (max-width: 1024px) {
        max-width: 888px;
        width: 87%;
      }

      @media screen and (max-width: 700px) {
        font-size: 12px;
        text-align: center;
        font-weight: 600;
        font-family: source-sans-pro;
        letter-spacing: 1px;
        margin-bottom: 22px;
      }
    }

    .curated-collections {
      display: flex;
      justify-content: space-between;
      max-width: 1208px;
      margin: 0 auto;
      position: relative;

      @media screen and (max-width: 1024px) {
        max-width: 888px;
        width: 87%;
      }

      @media screen and (max-width: 700px) {
        display: block;
        max-width: 500px;
        width: auto;
      }

      @media screen and (max-width: 500px) {
        width: 93%;
      }

      &.four {
        @media screen and (max-width: 1024px) and (min-width: 701px) {
          .collection {
            text-align: center;
            position: relative;
            height: 100px;
            display: initial;

            img {
              display: block;
              margin: 0 auto 14px;
            }

            svg {
              position: absolute;
              right: 10px;
              top: 50%;
            }
          }
        }
        @media screen and (max-width: 855px) and (min-width: 701px) {
          .collection {
            font-size: 10px;
          }
        }
      }

      &.three {
        @media screen and (min-width: 701px) {
          .collection {
            width: 32%;
            max-width: 385px;
          }
        }
      }

      &.two {
        @media screen and (min-width: 701px) {
          .collection {
            width: 48%;
            max-width: none;
          }
        }
      }

      &.one {
        @media screen and (min-width: 701px) {
          .collection {
            width: 100%;
          }
        }
      }

      .collection {
        width: 24%;
        max-width: 287px;
        background: ${Colors.primary.white.standard};
        color: ${Colors.accent.grey1.standard};
        border-radius: 5px;
        padding: 11px 14px 11px 14px;
        height: 67px;
        font-family: source-sans-pro;
        font-size: 12px;
        font-weight: 700;
        line-height: 1.17;
        letter-spacing: 1px;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        text-transform: uppercase;

        @media screen and (max-width: 1024px) and (min-width: 701px) {
          width: 23%;
          max-width: 204px;
        }

        @media screen and (max-width: 700px) {
          width: auto;
          margin: 0 auto;
        }

        @media screen and (max-width: 375px) {
          width: 76%;
        }

        .icon {
          width: 45px;
          margin-right: 10px;
        }

        svg {
          margin-left: auto;
        }
      }
    }
  }

  .list-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 325px);
    grid-template-rows: min-content;
    grid-gap: 18px;
    justify-content: center;

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(1, 511px);
    }

    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(2, 393px);
    }

    @media screen and (min-width: 1277px) {
      grid-template-columns: repeat(3, 393px);
    }

    .item {
      background-color: #ccc;
      padding: 10px;
      color: #333;
    }

    .card-image-area {
      .slide img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        @media screen and (min-width: 1024px) {
          height: 263px;
        }
      }
    }
  }

  .toggle-container {
    @media screen and (max-width: 768px) {
      margin-right: 12px !important;
    }
  }

  .no-results {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 60px 0;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;

    @media screen and (min-width: 490px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media screen and (max-width: 489px) {
      h3 {
        font-size: 18px;
      }
    }

    .no-results-text {
      font-weight: 400;
      font-size: 14px;
      flex: 0 0 100%;
      text-align: center;

      @media screen and (min-width: 490px) {
        font-size: 18px;
      }

      @media screen and (min-width: 768px) {
        font-size: 23px;
      }
    }

    .clear-filters {
      color: #0773df;
      font-family: source-sans-pro;
      font-size: 14px;
      text-decoration: underline;
      align-self: center;
      cursor: pointer;
      flex: 0 0 100%;
      text-align: center;
      @media screen and (max-width: 700px) {
        margin-top: 10px;
      }
    }
  }

  .pricing-dislcaimer {
    font-family: source-sans-pro;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.69;
    letter-spacing: normal;
    margin-bottom: 155px;
  }

  .button-container {
    margin-top: 75px;
    margin-bottom: 40px;

    position: relative;
  }

  .button {
    line-height: 1.15px;

    @media only screen and (max-width: 1024px) {
      &:hover,
      &:active {
        background-color: ${Colors.primary.claytonBlue.standard};
      }
    }
  }

  .recently-viewed-homes-container {
    border-top: 1px solid #a1a3a6;
  }
  .promo-link {
    p {
      font-size: 12px;
    }
  }
  .clear-filters-mobile {
    display: flex;
    color: #0773df;
    font-size: 12px;
    font-family: source-sans-pro;
    text-decoration: underline;
    width: 325px;
    margin: 10px auto 0;
    cursor: pointer;

    @media screen and (min-width: 768px) {
      width: 511px;
    }
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
`;

export default HomeListStyles;

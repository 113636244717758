import styled from 'styled-components';
import Colors from '../../colors';

const checkBoxColorOptions = [
  Colors.primary.claytonBlue,
  Colors.secondary.kiddiePool,
  Colors.accent.ladyBug,
  Colors.accent.cheesePuff,
  Colors.secondary.rubberDucky,
  Colors.primary.white,
];

const checkBoxSizeStyles = {
  compact: {
    alignSelf: 'flex-start',
    containerPaddingLeft: '4px',
    containerPaddingTop: '18px',
    labelPaddingLeft: '20px',
    sizeCss: '12px',
    fontSizeCss: '12px',
  },
  standard: {
    alignSelf: 'flex-center',
    containerPaddingLeft: '4px',
    containerPaddingTop: '24px',
    labelPaddingLeft: '30px',
    sizeCss: '20px',
    fontSizeCss: '14px',
  },
};

const CheckBoxStyles = styled.div.attrs({ className: 'check-box' })`
  padding-left: ${(props) =>
    checkBoxSizeStyles[props.size].containerPaddingLeft};

  label {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    font-size: ${(props) => checkBoxSizeStyles[props.size].fontSizeCss};
    position: relative;
    font-family: 'SourceSansPro', sans-serif;
    align-items: center;
    height: 22px;
    color: ${Colors.accent.grey1.standard};
    padding-left: ${(props) => checkBoxSizeStyles[props.size].labelPaddingLeft};

    span {
      align-self: ${(props) => checkBoxSizeStyles[props.size].alignSelf};
    }
  }

  ${checkBoxColorOptions
    .map(
      (color) =>
        `&.${color.className} > :checked + label:before {
          background-color: ${color.standard} !important;
          border: 1px solid ${color.standard} !important;
        }
        &.${color.className} > :hover + label:before{  
            border: 1px solid ${color.standard} !important;  
        }             
    }`
    )
    .join('\n')}

  input {
    display: block;
    height: 0;
    transform: scale(0);
    width: 0;
    z-index: 1000;

    & + label:before {
      border: solid 1px
        ${(props) =>
          props.errorMessage
            ? Colors.accent.ladyBug.standard
            : Colors.accent.grey2.standard} !important;
      border-radius: 2px;
      content: '';
      height: ${(props) => checkBoxSizeStyles[props.size].sizeCss};
      left: 0;
      position: absolute;
      width: ${(props) => checkBoxSizeStyles[props.size].sizeCss};
      align-self: ${(props) => checkBoxSizeStyles[props.size].alignSelf};
    }

    &:focus + label:before {
      border: solid 1px
        ${(props) =>
          props.errorMessage
            ? Colors.accent.ladyBug.standard
            : Colors.primary.claytonBlue.standard} !important;
    }

    &:checked + label:before {
      background-image: url(${(props) => props.imgCheckmark});
      background-repeat: no-repeat;
      background-position: center;
      background-color: ${Colors.primary.claytonBlue.standard};
      background-size: 80%;
      border: solid 1px
        ${(props) =>
          props.errorMessage
            ? Colors.accent.ladyBug.standard
            : Colors.primary.claytonBlue.standard} !important;
      color: ${Colors.primary.claytonBlue.standard};
    }

    &:disabled + label:before {
      opacity: 0.6;
    }
    &:disabled + label {
      cursor: not-allowed;
    }
    &:not(:disabled):hover + label:before {
      opacity: 0.8;
    }
  }
`;

export default CheckBoxStyles;

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import Colors from '../../../colors';
import useSalesAgents from '../../../hooks/useSalesAgents';
import useWindowSize from '../../../hooks/useWindowSize';
import EnvelopeClosed from '../../../images/svg/icons/EnvelopeClosed';
import Phone from '../../../images/svg/icons/Phone';
import nextBtn from '../../../images/svg/next-btn.svg';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import MeetOurTeamStyles from './MeetOurTeam.styled';
import { fireSegmentEvent } from '../../../utils';

const MeetOurTeam = ({
  homes,
  dealer,
  gaClass,
  gaPhoneClass,
  gaEmailClass,
  hideButton,
}) => {
  const [loading, setLoading] = useState(true);
  const [width, height] = useWindowSize();
  const isMobile = width && width <= 1024;

  const {
    salesAgents,
    isLoading: areSalesAgentsLoading,
    isError: isSalesAgentsError,
    error: salesAgentsError,
  } = useSalesAgents(dealer.dealerId);

  let carousel = useRef(null);

  useEffect(() => {
    // some validation to set the slider to 0
    if (carousel && carousel?.state?.selectedItem < 0) {
      carousel.state.selectedItem = 0;
    }
  });

  const arrowStyles = {};

  //TODO Show a gif or something while isLoading is true

  if (!salesAgents || salesAgents.length <= 0) {
    return null;
  }
  return (
    <MeetOurTeamStyles>
      <div className="container">
        <div className="meet-our-team-header">
          <div>
            <h2>Meet Our Team Members</h2>
            <div className="sm-divider"></div>
          </div>
          {!isMobile && !hideButton && (
            <>
              {dealer?.websiteTemplateDescription !== 'BuilderModel' ? (
                <Link
                  to={{ pathname: '/about-us', state: { dealer, homes } }}
                  className={'cta-button ' + (gaClass ? gaClass : '')}
                >
                  Learn more About Us
                </Link>
              ) : (
                <Link
                  to={{ pathname: '/contact-us', state: { dealer, homes } }}
                  className={'cta-button ' + (gaClass ? gaClass : '')}
                >
                  Contact Us
                </Link>
              )}
            </>
          )}
        </div>
        <Carousel
          ref={(el) => (carousel = el)}
          showArrows={true}
          autoPlay={false}
          showIndicators={false}
          showStatus={false}
          swipeable={isMobile}
          selectedItem={isMobile ? 0 : salesAgents?.length <= 2 ? 0 : 1}
          centerMode={!isMobile}
          centerSlidePercentage={!isMobile ? 100 / 3 : 100}
          emulateTouch={isMobile}
          infiniteLoop={false}
          useKeyboardArrows={true}
          showThumbs={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev &&
            carousel &&
            ((!isMobile && carousel?.state?.selectedItem > 1) ||
              (isMobile && carousel?.state?.selectedItem > 0)) && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="slider-btns slider-btn-prev"
              >
                <img src={nextBtn} alt="next slide" />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext &&
            carousel &&
            ((!isMobile &&
              carousel?.state?.selectedItem !== salesAgents.length - 2) ||
              (isMobile &&
                carousel?.state?.selectedItem !== salesAgents.length - 1)) && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...arrowStyles }}
                className="slider-btns slider-btn-next"
              >
                <img src={nextBtn} alt="next slide" />
              </button>
            )
          }
        >
          {salesAgents &&
            salesAgents.map((agent, i) => {
              return (
                <div key={agent.salesAgentId} className="slide-container">
                  <OptimizedImage
                    src={`${agent.avatarImageReference}?width=375&height=375`}
                    srcSet={[
                      {
                        source: `${agent.avatarImageReference}?fm=webp&width=375&height=375`,
                      },
                    ]}
                    alt={
                      agent.firstName
                        ? agent.firstName
                        : '' + agent.lastName
                        ? agent.lastName
                        : '' + ' image'
                    }
                    lazy
                  />

                  <div className="team-member-info">
                    <p className="team-member-title">{agent.salesAgentTitle}</p>
                    <p className="h4 team-member-name">
                      {agent.firstName ? agent.firstName : ''}{' '}
                      {agent.lastName ? agent.lastName : ''}
                    </p>
                  </div>
                  <div className="team-member-contact-info">
                    <a
                      href={`mailto:${agent.email}`}
                      aria-label="sale agent email link"
                      className={
                        'meet-team-email ' + (gaEmailClass ? gaEmailClass : '')
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <EnvelopeClosed
                        color={Colors.primary.claytonBlue.standard}
                        width="25px"
                        height="25px"
                      />
                    </a>
                    <a
                      href={`tel:${dealer?.phoneNumber}`}
                      aria-label="sale agent phone link"
                      className={
                        'meet-team-phone ' + (gaPhoneClass ? gaPhoneClass : '')
                      }
                      onClick={(e) => {
                        e.stopPropagation() +
                          fireSegmentEvent('Phone Number Link Clicked', {
                            link: dealer?.phoneNumber
                              ? formatPhoneNumberFromNumber(dealer.phoneNumber)
                              : '',
                            eventContext: 'phone_sales_agent',
                          });
                      }}
                    >
                      <Phone
                        color={Colors.primary.claytonBlue.standard}
                        width="25px"
                        height="25px"
                      />
                    </a>
                  </div>
                </div>
              );
            })}
        </Carousel>
        {isMobile && !hideButton && (
          <>
            {dealer?.websiteTemplateDescription !== 'BuilderModel' ? (
              <Link
                to={{ pathname: '/about-us', state: { dealer, homes } }}
                className={'cta-button ' + (gaClass ? gaClass : '')}
              >
                Learn more About Us
              </Link>
            ) : (
              <Link
                to={{ pathname: '/contact-us', state: { dealer, homes } }}
                className={'cta-button ' + (gaClass ? gaClass : '')}
              >
                Contact Us
              </Link>
            )}
          </>
        )}
      </div>
    </MeetOurTeamStyles>
  );
};

MeetOurTeam.propTypes = {
  hideButton: PropTypes.bool,
};

export default MeetOurTeam;

import { useState, useEffect } from 'react';

export const debounceEvent = (eventHandler, debounceTime) => {
  let debounceTimer = 0;

  return function () {
    let localArgs = arguments;
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(function () {
      return eventHandler.apply(null, localArgs);
    }, debounceTime);
  };
};

export const isMobile = (size = 1051) => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < size;
  }
};

export function checkOverflow() {
  const [flow, setFlow] = useState(false);
  useEffect(() => {
    function handleFlow() {
      let scroller = document.querySelector('.scroller');
      let element = document.getElementById('filter-controls');
      if (element && element.scrollWidth > element.clientWidth) {
        if (scroller) {
          scroller.scrollBy({
            left: -scroller.clientWidth,
            top: 0,
            behavior: 'smooth',
          });
        }

        setFlow(true);
      } else {
        setFlow(false);
      }
    }
    window.addEventListener('resize', handleFlow);
    handleFlow();
    return () => window.removeEventListener('resize', handleFlow);
  }, []);

  return flow;
}

export const scrollToPrevItem = () => {
  let scroller = document.querySelector('.scroller');
  let nextBtn = document.querySelector('.btn-next');
  let prevBtn = document.querySelector('.btn-prev');
  if (scroller) {
    scroller.scrollBy({
      left: -scroller.clientWidth,
      top: 0,
      behavior: 'smooth',
    });
  }
  prevBtn.classList.add('hidden-arrow');
  nextBtn.classList.remove('hidden-arrow');
};

export const scrollToNextItem = () => {
  let outerElement = document.getElementById('filter-controls');
  let scroller = document.querySelector('.scroller');
  let nextBtn = document.querySelector('.btn-next');
  let prevBtn = document.querySelector('.btn-prev');
  if (scroller) {
    scroller.scrollBy({
      left: 100,
      top: 0,
      behavior: 'smooth',
    });
  }
  prevBtn.classList.remove('hidden-arrow');
  if (
    outerElement.scrollWidth - scroller.scrollLeft <=
    scroller.clientWidth + 130
  ) {
    nextBtn.classList.add('hidden-arrow');
  } else {
    nextBtn.classList.remove('hidden-arrow');
  }
};

export const getQueryParams = () => {
  var paramObject = {};
  var queryString = getQueryString();
  var queryStringSplit = queryString.split('&');

  queryStringSplit.forEach((keyValue) => {
    var firstEqualSignIndex = keyValue.indexOf('=');
    if (firstEqualSignIndex > -1) {
      var keyValueSplit = [
        keyValue.substring(0, firstEqualSignIndex),
        keyValue.substring(firstEqualSignIndex + 1),
      ];
      var key = keyValueSplit[0];
      var value = keyValueSplit[1];

      paramObject[key] = value;
    }
  });

  return paramObject;
};

export const getQueryString = () => {
  var hrefSplit = getSafeUrl().split('?');

  if (hrefSplit.length > 1) {
    return decodeURIComponent(hrefSplit[1]);
  }

  return '';
};

export const getSafeUrl = () => {
  return typeof window !== 'undefined' ? window?.location?.href : '';
};

export const buildQueryString = (queryParamObject) => {
  var queryString = [];

  for (var property in queryParamObject) {
    if (queryParamObject.hasOwnProperty(property)) {
      var value = queryParamObject[property];
      if (value) {
        queryString.push(
          `${encodeURIComponent(property)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  return '?' + queryString.join('&');
};

const localStorage = {};

export const getLocalStorage = (key) => {
  if (typeof window === 'undefined') {
    return localStorage[key];
  }

  return window.localStorage[key];
};

export const setLocalStorage = (key, value) => {
  if (typeof window === 'undefined') {
    localStorage[key] = value;
  }

  return window.localStorage[key];
};

export const sanitizeUrl = (url) => {
  var cleanedSlashes = url
    .replace(new RegExp('//', 'g'), '/')
    .replace(':/', '://');
  return cleanedSlashes.replace(new RegExp('\\?'), '?');
};

export const getUTMParameters = () => {
  const params = getQueryParams();
  return {
    utm_campaign: params['utm_campaign'] || '',
    utm_source: params['utm_source'] || '',
    utm_medium: params['utm_medium'] || '',
    utm_term: params['utm_term'] || '',
    utm_content: params['utm_content'] || '',
    kenshooId: params['kenshooid'] || '',
    gclid: params['gclid'] || '',
    fbclid: params['fbclid'] || '',
    msclkid: params['msclkid'] || '',
  };
};

export const getHashParams = () => {
  var paramObject = {};
  var queryString = getHashString();
  var queryStringSplit = queryString.split('&');

  queryStringSplit.forEach((keyValue) => {
    var firstEqualSignIndex = keyValue.indexOf('=');
    if (firstEqualSignIndex > -1) {
      var keyValueSplit = [
        keyValue.substring(0, firstEqualSignIndex),
        keyValue.substring(firstEqualSignIndex + 1),
      ];
      var key = keyValueSplit[0];
      var value = keyValueSplit[1];

      paramObject[key] = value;
    }
  });

  return paramObject;
};

export const getHashString = () => {
  var hrefSplit = getSafeUrl().split('#');

  if (hrefSplit.length > 1) {
    return decodeURIComponent(hrefSplit[1]);
  }

  return '';
};

// consider for deprecation
export const getWidthParameter = (imageReference) => {
  let widthParameter;

  if (imageReference.indexOf('ctfassets') > -1) {
    widthParameter = '?w=860';
  } else {
    widthParameter = '?width=860';
  }

  return widthParameter;
};

let utmKeywords = [
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
  'gclid',
  'msclkid',
  'fbclid',
];

export const getUTMSummary = (params) => {
  var utmSummary = {};
  utmKeywords.forEach(function (key) {
    utmSummary[key] = params[key] || '';
  });
  return utmSummary;
};

export const getUTMParametersFromSessionCache = () => {
  let defaultParams = getUTMParameters();

  if (defaultParams['utm_source'] !== '' || defaultParams['gclid'] !== '') {
    return defaultParams;
  }

  try {
    let params = {};
    utmKeywords.forEach(function (key) {
      params[key] =
        typeof window === 'undefined' ? '' : window.sessionStorage.getItem(key);
    });
    return getUTMSummary(params);
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const componentItemsEqual = (item1, item2) => {
  if (
    item1 === undefined ||
    item1 === null ||
    item2 === undefined ||
    item2 === null
  ) {
    return false;
  }

  return (
    (item1.value !== undefined ? item1.value : item1).toString() ===
    (item2.value !== undefined ? item2.value : item2).toString()
  );
};

export const getComponentItemValue = (item) => {
  if (item === undefined || item === null) {
    return null;
  }

  return (item.value !== undefined ? item.value : item).toString();
};

export const smoothScroll = (id, offset) => {
  const yOffset = offset;
  const element = document.querySelector(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ top: y, behavior: 'smooth' });
};

/**
 * Transforms model name to title case
 * @param modelName
 * @returns {string} Title Cased model name
 */
export const modelNameTransformer = (modelName) => {
  // Ensure input is a string
  if (!modelName || typeof modelName !== 'string') {
    return modelName;
  }
  // Split the string into words
  return (
    modelName
      .split(' ')
      // Loop through array and determine the case of the word
      .map((word) => shouldBeTitleCase(word))
      // Join results back together to return name
      .join(' ')
  );
};

/*******************************
 * Private Methods
 *******************************/

/**
 * Takes a lowercase word and returns the title case version
 * based on certain assumptions
 * @param {string} wordInput
 * @returns {string}
 */
function shouldBeTitleCase(wordInput) {
  // Convert input string to lower case for consistent matching
  const word = wordInput.toLowerCase();

  const exceptions = [
    'the',
    'to',
    'big',
    'its',
    'it',
    'a',
    'all',
    'si',
    'pad',
    'off',
    'box',
    'ole',
    'eye',
    'cay',
    'key',
    'buy',
    'ash',
    'gem',
    'ace',
    'dog',
    'net',
    'one',
    'rio',
    'red',
    'my',
    'yes',
    'new',
    'ali',
    'kay',
    'mod',
    'bay',
    'st',
    'my',
    'lt',
    'no',
    'mt',
    'air',
    'tgif',
    'day',
    'let',
    'be',
  ];

  const containsMc = /\b(mc)+([a-z]+)/gi;
  const containsMac = /\b(mac)+([a-z]+)/gi;
  const contactAbbrev = /\b(mc)+([a-z])\b/gi;
  //Check if word contains Mc or Mac
  //Return proper casing
  let formattedWord = '';
  if (containsMc.test(word) && !contactAbbrev.test(word)) {
    for (let i = 0; i < word.length; i++) {
      if (i === 0 || i === 2) {
        formattedWord += word.charAt(i).toUpperCase();
      } else {
        formattedWord += word.charAt(i).toLowerCase();
      }
    }
    return formattedWord;
  } else if (containsMac.test(word)) {
    for (let i = 0; i < word.length; i++) {
      if (i === 0 || i === 3) {
        formattedWord += word.charAt(i).toUpperCase();
      } else {
        formattedWord += word.charAt(i).toLowerCase();
      }
    }
    return formattedWord;
  }

  const containsDigitRegex = /\d/;

  // Check if word contains a digit
  // return uppercase
  if (containsDigitRegex.test(word)) {
    return word.toUpperCase();
  }

  // If word is less than three letters and not in the exceptions
  // return uppercase
  if (exceptions.indexOf(word) < 0 && word.length < 4) {
    return word.toUpperCase();
  }

  // If word starts with a quote mark or parenthesis, capitalize second letter
  let firstChar = word.split('')[0];
  if (firstChar === "'" || firstChar === '"' || firstChar === '(') {
    return firstChar + shouldBeTitleCase(word.substring(1));
  }

  // If word contains a -, split it and check if any of the results
  // are less than 3 characters. If so, leave them capitalized
  if (word.split('-').length > 1) {
    return word
      .split('-')
      .map((w) => modelNameTransformer(w))
      .join('-');
  }
  if (word.split('.').length > 1) {
    return word
      .split('.')
      .map((w) => modelNameTransformer(w))
      .join('.');
  }

  // Finally return title cased word
  return toTitleCase(word);
}

/**
 * Returns the title case of a single word
 * @param {string} word
 */
function toTitleCase(word) {
  let str = word.toLowerCase().split('');
  str[0] = str[0].toUpperCase();
  return str.join('');
}

import styled from 'styled-components';

const SpecialOffersStyles = styled.div`
  .spacer {
    position: relative;
    margin: 40px 0px;
    display: flex;
  }
`;

export default SpecialOffersStyles;
